<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span>Customization </span>
    </h2>
</div>
<div class="mr-0 ml-0" id="myComponent" #dialogContent>    
    <div class="row" style="overflow-y: hidden;" >
        <div class="col-8">
            <h2 style="text-align: center;"> <span class="titleofchart">Body Content</span></h2>
            <div (click)="onClick()">
                <div  #htmlContainer id="htmlContainer" [innerHTML]="htmlContent">
                </div>
            </div >
        </div>
        <div class="col-4" style="border-left:1px solid black;padding-left:5px !important;padding-right:5px !important;">
            <h2 style="text-align: center;"><span class="titleofchart">Selected Content</span> </h2>
            <form [formGroup]="Form">
                <div formArrayName="AddTags">
                <div class="row" *ngFor="let file of AddTags().controls; let i=index" [formGroupName]="i">
                    <div class="row">
                                <div class="col-4" style="padding-left: 5px !important;padding-right: 5px !important;">
                                    <mat-form-field appearance="legacy" class="w-100">
                                        <mat-label>Selected Text <span class="danger"></span></mat-label>
                                        <input matInput type="text" readonly="true" formControlName="selectedText">
                                    </mat-form-field>
                                </div>
                                <div class="col-3" style="padding-left: 5px !important;padding-right: 5px !important;">
                                    <mat-form-field  class="w-100">
                                        <mat-label>Entity</mat-label>
                                        <mat-select formControlName="Entity" >
                                            <mat-option value="User">User </mat-option>
                                            <mat-option value="Contact">Contact </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <ng-container *ngIf="getentityname(i)=='User'">
                                    <div class="col-4" style="padding-left: 5px !important;padding-right: 5px !important;">
                                        <mat-form-field  class="w-100">
                                            <mat-label>Column Name</mat-label>
                                            <mat-select formControlName="Columnname" >
                                                <mat-option *ngFor="let x of GetAllUsercolumes" [value]="x" >
                                                    {{x}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="getentityname(i)=='Contact'">
                                    <div class="col-4" >
                                        <mat-form-field  class="w-100">
                                            <mat-label>Column Name</mat-label>
                                            <mat-select formControlName="Columnname" >
                                                <mat-option *ngFor="let x of GetAllContactcolumes" [value]="x" >
                                                    {{x}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <div class="col-1 " style="padding-left: 5px !important;padding-right: 5px !important;">
                                    <mat-icon style="margin-top: 10px;color:black;" (click)="Removecondictuon(i)">delete</mat-icon>
                                </div>
                    </div>
                </div>
            </div>
            </form>
            <div class="row mt-2 mb-2  align-content-end" *ngIf="AddTags().controls.length!=0">
                <div class="col-4"><button mat-button mat-dialog-close>Cancel</button></div>
                <div class="col-4"><button mat-raised-button class="b-blue" (click)="onSubmit()">Next</button></div>
            </div>
        </div>
    </div>
</div>