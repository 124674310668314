import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { TargetService } from 'src/app/core/services/target.service';
import { TimeManagementService } from 'src/app/core/services/time-management.service';

@Component({
  selector: 'app-reporting-search',
  templateUrl: './reporting-search.component.html',
  styleUrls: ['./reporting-search.component.scss']
})
export class ReportingSearchComponent implements OnInit {

  filteredusers: any;
  users$: any;
  ListSearch!: FormGroup
  Projects: any;
  sites$: any;
  filteredSites: any;
  usersgroup$: any;
  filteredusersgroup: any;
  AllOption = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' },
    { v1: '<', v2: 'less than ' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<=', v2: 'less than or equal' }];
  filterGroup: any;
  AllGroup: any;
  selectedGroup :any;

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ReportingSearchComponent>,
    
  ) {

    this.ListSearch = this.formBuilder.group({
      Position: [''],
      UsersId:[''],
      UserGroupsId: [''],
      SitesId: [''],
      ProjectsId: [''],
      overtime: [''],
      SelactGroupIdes: [''],
      Conditions: this.formBuilder.array([]),
    })
    this.Addcondition()
   }

   Addcondition() {
    this.Conditions().push(this.newFile());
  }
  RemoveCondition(i: number) {
    this.Conditions().removeAt(i);
  }

  Conditions(): FormArray {
    return this.ListSearch.get("Conditions") as FormArray
  }

  newFile(): FormGroup {
    return this.formBuilder.group({
      conditionParameter: [""],
      conditionComparison: [""],
      value: [''],
    })
  }

  ngOnInit(): void {
    this.getProjects()
    this.getallusers()
    this.getallSites()
    this.getallUserGroups()
    this.getSiteGroup()
  }

  getProjects(){
    this.injector.get(TimeManagementService).getAllProject().subscribe(data => {
      console.log(data)
      this.Projects = data
    })
  }

  getallusers(){
    // get all users
    this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });
  } 

  getallSites(){
    // get all sites
    this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredSites = result;
      }
    });
  } 

  getallUserGroups(){
    this.injector.get(ManageUsersService).findAllUsersGroupDropdown().subscribe((result) => {
      if (result) {
        this.usersgroup$ = result;
        this.filteredusersgroup = result;
      }
    });
  }

  getSiteGroup() {
    this.injector.get(TargetService).fetchSiteGroup().subscribe((result: any) => {
      if(result){
        console.log(result);
        this.AllGroup = result.data;
        this.filterGroup = result.data;
      }
     
    });
  }

  filterOptions(value: string, type: string): void {
    console.log(type);
      this.filteredSites = this.sites$.filter((option:any) =>
        option?.companyName?.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusersgroup = this.usersgroup$.filter((option:any) =>
        option?.groupName?.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusers = this.users$.filter((option:any) =>
        option?.firstName?.toLowerCase().includes(value.toLowerCase())
      );
      this.filterGroup = this.AllGroup.filter(option =>
        option?.siteGroupName?.toLowerCase().includes(value.toLowerCase())
      );
  }

  onSubmit(){
    console.log(this.ListSearch.value)
    this.dialogRef.close(this.ListSearch.value);
  }

}
