<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100" *ngIf="data.type == 'User-TimeSheet'">
        {{'TimeSheet'|translate}}
    </h2>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100" *ngIf="data.type != 'User-TimeSheet'">
        {{'TimeSheet Approval'|translate}}
    </h2>
    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreMenu="matMenu" xPosition="before">
      <button *ngIf="(AdminStatus || ManagerStatus)" mat-menu-item (click)="openTimeTrack()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/punch_clock.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Track' | translate }}</strong></span>
      </button>
      <button mat-menu-item (click)="openTimeOff()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/timer_off.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Off' | translate }}</strong></span>
      </button>
      <button *ngIf="(AdminStatus || ManagerStatus)" mat-menu-item (click)="openManagerTime()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/insert_chart.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Report' | translate }}</strong></span>
      </button>
    </mat-menu>
    <mat-icon mat-dialog-close>close</mat-icon>
</div> 
    <mat-dialog-content class="mr-0 ml-0">
<ng-container *ngIf="data.type == 'User-TimeSheet'">

        <div class="container-fluid">
            <div class="row line">
                <div class="col-6"></div>
                <div class="col-4 mt-2 mb-3" style="text-align: end;">
                    <form [formGroup]="TimeSheetForm">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-date-range-input [rangePicker]="dateRangePicker">
                              <input matStartDate placeholder="Start date" formControlName="startDate">
                              <input matEndDate placeholder="End date" formControlName="endDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                          </mat-form-field>
                    </form>
                </div>
                <div class="col-2 mt-3">
                  <button (click)="ShowEntryResult()" class="allApproval">Show Results</button>
                </div>
            </div>

            <div class="row"> 
              
              <!-- Timesheet Section -->
              <div class="col-9 m-0 p-0 date-content border_right" >
                <div class="row" *ngFor="let entry of TimeSheetData; let i = index"  style="cursor: pointer;">
                  <!-- Day and Date in a single column (col-2) with gray background and no borders -->
                  <div class="col-2 day-date gray-background" (click)="selectday(i)" [ngClass]="{'red-background': selectedDayIndex == i}"> 
                    <div class="day">{{ entry.shortDay }}</div> <!-- Mon -->
                    <div class="date">{{ entry.date }}</div>   <!-- Oct 21 -->
                  </div>
          
                  <!-- Hours in a wide column (col-7) -->
                  <div class="col-10  hours  entry" [ngClass]="{'red-border': selectedDayIndex == i}">
                    <div style="display: flex;justify-content: start;margin-top: 2px;margin-bottom: 2px;">
                      <div style="width: 10%;">{{ entry.formattedtotal }}</div>
                      <div style="width: 90%;" *ngIf="entry.entries !== null">
                        <span *ngIf="entry?.entries?.[0]?.status === 1" style="padding: 5px;border-radius: 5px;background-color: #528bf1;color: white;float: right;">Approved</span>
                        <span *ngIf="entry?.entries?.[0]?.status === 2" style="padding: 5px;border-radius: 5px;background-color: #f15252;color: white;float: right;">Rejected</span>
                        </div>
                    </div>
                    <ng-container *ngIf="entry.entries !== null">
                      <div  *ngFor="let timeEntry of entry.entries;">
                        <div class="row" *ngIf="timeEntry.isTimeOff == true && selectedDayIndex == i ">
                          
                            <div class="row mt-2" [ngStyle]="{'color': 'black', 'background': 'rgb(233 233 233)'}" style="padding:6px;width: -webkit-fill-available;">
                              <div class="col-6 mt-2">
                                Category : <span [ngStyle]="{'color': timeEntry?.categoryFontColor, 'background': timeEntry?.categoryColor}" style="padding:6px;border-radius: 5px;">{{ timeEntry?.categoryName }}</span>
                              </div>
                              <div class="col-6 mt-2">
                                Type : <span style="color:gray">{{timeEntry.isFullDay == true ? 'Full Day' : 'Half Day'}}</span>
                              </div>
                              <div class="col-12 duration mt-2">Description : <span style="color:gray">{{ timeEntry.description  }}</span></div>
                            </div>
  
                        </div>
                        <div class="row details mx-2" *ngIf="selectedDayIndex == i && timeEntry.isTimeOff == false"
                         [ngClass]="getbodycolor(timeEntry)">
                          <div class="col-7 time">
                            <div class="row">
                                <div class="col-5">
                                    <div class="text-center">
                                        {{ timeEntry?.startIn12Hours }}
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="text-center">
                                        -
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="text-center">
                                        {{ timeEntry?.endIn12Hours }}
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="timeEntry?.isClockInClockOut == true">
                                <div class="col-5">
                                    <div class="row text-center mb-2">
                                      <span>{{ '(' + timeEntry?.startIpAddress + ')' }}</span>
                                    </div>
                                    <div class="row text-center">
                                      <span (click)="ShowMapDetails(timeEntry, 'startIp')" *ngIf="timeEntry?.distanceFromStartIP != null" [ngClass]="getdistanceFromStartIP(timeEntry?.distanceFromStartIP)">{{ (timeEntry?.distanceFromStartIP) }}</span>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="text-center">
                                        {{ ' ' }} 
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="row text-center mb-2">
                                        <span>{{ '(' + timeEntry?.stopIpAddress + ')'}}</span>
                                    </div>
                                    <div class="row text-center">
                                      <span (click)="ShowMapDetails(timeEntry, 'stopIp')" *ngIf="timeEntry?.distanceFromStopIP != null" [ngClass]="getdistanceFromStartIP(timeEntry?.distanceFromStopIP)">{{(timeEntry?.distanceFromStopIP) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                                             
                          <div class="col-2 duration" style="margin:auto;color:black">{{ timeEntry?.formattedDuration  }}</div>
                          <div class="col-3" style="margin:auto">
                            <span [ngStyle]="{'color': timeEntry?.projectFontColor, 'background': timeEntry?.projectColor}" style="padding:6px;border-radius: 5px;">{{ timeEntry?.projectName }}</span>
                            </div>
                            <div class="col-12 mt-3" *ngIf="timeEntry?.siteAddress">
                              <div class="d-flex align-items-center">
                                <mat-icon class="mr-2">location_on</mat-icon>
                                <div>
                                  <!-- Display site address -->
                                  <div class="fw-600">{{ timeEntry?.siteAddress }}</div>
                                  
                                  <!-- Display site city -->
                                  <span class=" fw-600 color_grey">
                                    {{ timeEntry?.siteCity || '' }}
                                  </span>
                                  
                                  <!-- Display site country and zip code -->
                                  <div>
                                    {{ (timeEntry?.siteCountry || '') + ' - ' + (timeEntry?.siteZipCode || '') }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
          
                  <!-- Details for each day's time (if any) -->
                 
                </div>
              </div>
          
              <!-- Sidebar Section (col-3) -->
              <div class="col-3 m-0 p-0" *ngIf="Mode == 'ClockInClockOut'">
                <div class="status-box">
                  <p class="right-side" *ngIf="!isEndTimeMidnight" style="font-size: 14px;">Not Clocked In</p>
                  <p class="right-side" *ngIf="isEndTimeMidnight" style="font-size: 14px;">Clocked In</p>
                  <p *ngIf="!isEndTimeMidnight" class="right-side" style="font-size: 20px;">{{ duration(TotalHoursPerDay) }} Today</p>
                  <p class="right-side-gray"  *ngIf="isEndTimeMidnight">Clocked In Time: {{ lastStartTime }}</p>
                  <p class="right-side-gray"  *ngIf="!isEndTimeMidnight">Clocked Out Time: {{ lastendTime ? lastendTime : '00:00' }}</p>
                  <button class="clock-in-btn" *ngIf="!isEndTimeMidnight" (click)="checkout()">Clock In</button>
                  <button class="clock-Out-btn" *ngIf="isEndTimeMidnight" (click)="checkout()">Clock Out</button>
                </div>
                <div class="row" *ngIf="Mode == 'ClockInClockOut' && clockInClockOutHistory?.length > 0">
                  <div class="col-12">
                    <p style="color:black;font-size: 15px;font-weight:bold">Today Clock In- Clock Out History</p>
                  </div>
                </div>
                <div class="row clockin-content" *ngIf="Mode == 'ClockInClockOut' && clockInClockOutHistory.length > 0">
                  <div class="col-12 mt-2" *ngFor="let x of clockInClockOutHistory; let i = index">
                    <span style="font-size: 16px;">{{i+1}}. {{x.startTime + ' '+ '-' + ' ' + (x.endTime === '12:00 AM' ? '' : x.endTime)}} </span>
                  </div>
                </div>
              </div>
            
              <div class="col-3 m-0 p-0" *ngIf="Mode == 'StartEndTime'">
                <div class="status-box">
                  <button class="clock-in-btn" (click)="addEntry()">Start Time - End Time</button>
                </div>
              </div>
              <div class="col-3 m-0 p-0" *ngIf="Mode == 'HoursPerDay'">
                <div class="status-box">
                  <button class="clock-in-btn" *ngIf="ClockInButton" (click)="addEntry()">No of Hours(Per Day)</button>
                </div>
              </div>
            </div>
          </div>
</ng-container>

    </mat-dialog-content>

