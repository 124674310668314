<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">search</mat-icon>{{'Advance Search'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
   <div class="search-filter-form">
        <form [formGroup]="searchForm">
            <!-- Search  -->
              <!-- Include/Exclude Search -->
              <div class="form-group checkbox-group">
                <mat-checkbox formControlName="includeSearch"> Search Include </mat-checkbox>
                <mat-checkbox formControlName="excludeSearch"> Search Exclude </mat-checkbox>
              </div>
          <!-- Search by Search -->
         <ng-container *ngIf="getIncludesearchststus()">
            <div class="form-group">
                <label for="keywords">Search Include</label>
                <input type="text" id="keywords" formControlName="includeSearchvalue" placeholder="Enter Search">
              </div>
         </ng-container>
         <ng-container *ngIf="getexcludeSearchstus()">
            <div class="form-group">
                <label for="keywords">Search Exclude</label>
                <input type="text" id="keywords" formControlName="excludeSearchvalue" placeholder="Enter Search">
              </div>
         </ng-container>


      <!-- Sender  -->
          <!-- Include/Exclude Sender -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeSender"> Sender Include </mat-checkbox>
            <mat-checkbox formControlName="excludeSender"> Sender Exclude </mat-checkbox>
          </div>
      
          <!-- Sender -->
            <ng-container *ngIf="getincludeSenderststus()">
                <div class="form-group">
                    <label for="sender">Sender Include</label>
                    <mat-select formControlName="SenderList" multiple>
                      <mat-option *ngFor="let option of senders" [value]="option">{{ option }}</mat-option>
                    </mat-select>
                  </div>
            </ng-container>
            <ng-container *ngIf="getexcludeSenderststus()">
                <div class="form-group">
                    <label for="sender">Sender Exclude</label>
                    <mat-select formControlName="SenderList" multiple>
                      <mat-option *ngFor="let option of senders" [value]="option">{{ option }}</mat-option>
                    </mat-select>
                  </div>
            </ng-container>


          <!-- Recipient  -->
               <!-- Recipient  -->
          <!-- Include/Exclude Recipient -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeRecipient"> Recipient Include </mat-checkbox>
            <mat-checkbox formControlName="excludeRecipient"> Recipient Exclude </mat-checkbox>
          </div>
      
          <!-- Recipient -->
         <ng-container *ngIf="getincludeRecipientststus()">
            <div class="form-group">
                <label for="Recipient">Recipient Include</label>
                <mat-select formControlName="RecipientList" multiple>
                  <mat-option *ngFor="let option of senders" [value]="option">{{ option }}</mat-option>
                </mat-select>
              </div>
         </ng-container>
         <ng-container *ngIf="getexcludeRecipientststus()">
            <div class="form-group">
                <label for="Recipient">Recipient Exclude</label>
                <mat-select formControlName="RecipientList" multiple>
                  <mat-option *ngFor="let option of senders" [value]="option">{{ option }}</mat-option>
                </mat-select>
              </div>
         </ng-container>
   <!-- ----------  -->
    <!-- ----  -->
      
          <!-- Include/Exclude Date Range -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeDateRange">Include Date Range</mat-checkbox>
            <mat-checkbox formControlName="excludeDateRange">Exclude Date Range</mat-checkbox>
          </div>

          
      
          <!-- Attachment Filter -->
          <div class="form-group">
            <label for="attachment">Attachment Type</label>
            <mat-select formControlName="attachment" multiple>
              <mat-option *ngFor="let extension of attachmentExtensions" [value]="extension">{{ extension }}</mat-option>
            </mat-select>
          </div>
      
          <!-- Include/Exclude Attachment -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeAttachment">Include Attachments</mat-checkbox>
            <mat-checkbox formControlName="excludeAttachment">Exclude Attachments</mat-checkbox>
          </div>
      
          <!-- Read/Unread Status -->
          <div class="form-group">
            <label for="readUnread">Read/Unread Status</label>
            <mat-checkbox formControlName="readUnread">Include Read/Unread</mat-checkbox>
          </div>
      
          <!-- Include/Exclude Read/Unread -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeReadUnread">Include Read/Unread</mat-checkbox>
            <mat-checkbox formControlName="excludeReadUnread">Exclude Read/Unread</mat-checkbox>
          </div>
      
          <!-- Email Size Filter -->
          <div class="form-group">
            <label for="emailSize">Email Size</label>
            <input type="number" formControlName="emailSize" placeholder="Size in MB">
            <mat-select formControlName="sizeComparison">
              <mat-option value="greaterThan">Greater than</mat-option>
              <mat-option value="lessThan">Less than</mat-option>
            </mat-select>
          </div>
      
          <!-- Include/Exclude Size -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeSize">Include Size</mat-checkbox>
            <mat-checkbox formControlName="excludeSize">Exclude Size</mat-checkbox>
          </div>
      
          <!-- Categories -->
          <div class="form-group">
            <label for="categories">Categories</label>
            <mat-select formControlName="categories" multiple>
              <mat-option *ngFor="let category of categories" [value]="category">{{ category }}</mat-option>
            </mat-select>
          </div>
      
          <!-- Include/Exclude Categories -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeCategories">Include Categories</mat-checkbox>
            <mat-checkbox formControlName="excludeCategories">Exclude Categories</mat-checkbox>
          </div>
      
          <!-- URL Filter -->
          <div class="form-group">
            <label for="urls">URLs in Emails</label>
            <input type="text" formControlName="urls" placeholder="Contains or exclude specific domains">
          </div>
      
          <!-- Include/Exclude URLs -->
          <div class="form-group checkbox-group">
            <mat-checkbox formControlName="includeUrls">Include URLs</mat-checkbox>
            <mat-checkbox formControlName="excludeUrls">Exclude URLs</mat-checkbox>
          </div>
      
          <!-- Submit Button -->
          <button type="submit" [disabled]="searchForm.invalid">Search</button>
        </form>
      </div>
      
      
      
</mat-dialog-content>

<!-- <mat-dialog-actions align="center" class="my-1">
    <div class="row mt-2 w-100 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="b-blue"  (click)="onSubmit()">Search</button>
        </div>
    </div>
</mat-dialog-actions> -->
