import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfilePickFormatService } from '../../services/profile-pick-format.service';
import { ReportingSearchComponent } from './reporting-search/reporting-search.component';
import { ReportingDetailsComponent } from './reporting-details/reporting-details.component';
import { TimeManagementService } from '../../services/time-management.service';
import { UserDetailsComponent } from 'src/app/pages/management/manage-users/user-details/user-details.component';
import { ManageUsersService } from '../../services/manage-users.service';
import { SiteDetailsComponent } from 'src/app/pages/management/manage-sites/site-details/site-details.component';
import { ManageSitesService } from '../../services/manage-sites.service';
import { ReportDashboardComponent } from './report-dashboard/report-dashboard.component';
import { ShowgroupComponent } from 'src/app/pages/management/manage-users/showgroup/showgroup.component';
import { ReportStatisticsComponent } from './report-statistics/report-statistics.component';
import { TimeTrackComponent } from '../time-track/time-track.component';
import { TimeOffComponent } from '../time-off/time-off.component';
import { TimesheetComponent } from '../timesheet/timesheet.component';
import { ReportExportComponent } from './report-export/report-export.component';

@Component({
  selector: 'app-time-reporting',
  templateUrl: './time-reporting.component.html',
  styleUrls: ['./time-reporting.component.scss']
})
export class TimeReportingComponent implements OnInit {

  period = ['Monthly', 'Daily', 'Custom'];
  Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 
    'October', 'November', 'December'
  ]
  loader=false

  ManagerApproval: FormGroup
  years: any[] = [];
  EntryTimeSheet: any;
  transformedData: any;
  uniqueDates: any[];
  dateEntriesMap: any;
  selectedPeriod: any;
  monthlyRecord: any;
  YearName: any;
  monthName: any;
  totalUsersCount: any;
  usersIds: any[] = [];
  userGroupIds: any[] = [];
  projectIds: any[] = [];
  siteIds: any[] = [];
  selectGroupIds: any[] = [];
  UserTotalRequired: any;
  UserTotalWorked: any;
  UserTotalOvertime: any;
  closeSearch: boolean = false
  TimeUserDetails: any;
  userList: any;
  AdminStatus: boolean = false;
  ManagerStatus: boolean = false;

  constructor(
    private dialog : MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private dialogRef: MatDialogRef<TimeReportingComponent>,
  ) { 

    this.AdminStatus = sessionStorage.getItem("isAdmin") == 'true' ? true : false; 
    this.ManagerStatus=sessionStorage.getItem("isManager") == 'true' ? true : false; 
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear(); 
    this.ManagerApproval = this.fb.group({
      period: ['Monthly'],
      Year: [currentYear],
      Month: [''],
      startDate: [''],
      endDate: [''],
    });
    this.selectedPeriod = 'Monthly'
    this.showResult(1, 10)
    this.generateYears()
  }

  // getPendingTimeRequests(pageNumber:any, pageSize:any){
  
  //   // Format the start and end dates (yyyy-mm-dd)
  //  const formattedStartDate = this.ManagerApproval.get('startDate').value;
  //  const formattedEndDate = this.ManagerApproval.get('endDate').value;
  //  this.YearName = this.ManagerApproval.get('Year').value
  //  this.monthName = this.ManagerApproval.get('Month').value
   
  //   this.injector.get(TimeManagementService)?.getApprovedTimesheet(
  //     formattedStartDate || undefined, 
  //     formattedEndDate || undefined, 
  //     this.YearName || undefined,
  //     pageNumber, 
  //     pageSize,
  //     this.monthName || undefined,
  //     this.userGroupIds || undefined,
  //     this.projectIds|| undefined,
  //     this.siteIds || undefined,
  //     this.usersIds || undefined,
  //     ).subscribe((data:any) => {
  //       console.log(data)
  //       this.EntryTimeSheet = data[0].approvedEntryDtos
  //       this.UserTotalRequired = data[0].totalUsersDuration
  //       this.UserTotalWorked = data[0].totalUsersWorkedDuration
  //       this.UserTotalOvertime = data[0].totalUsersOvertime


  //       var uniqueDates = [];
  //       var dateEntriesMap = {};
    
  //       // Transform data and populate unique dates and dateEntriesMap
  //       const transformedData = this.EntryTimeSheet?.reduce((result, entry) => {
  //         // Check if entry has a date and is formatted correctly
  //         if (entry?.date) {
  //           // Ensure the date is included in uniqueDates even if there are no userRequestDtos
  //           if(entry.fullDayName == null) {
  //             uniqueDates.push({
  //               monthName: entry.monthName,
  //               totalDuration: entry.totalDuration || "00:00:00",
  //               workedDuration: entry.workedDuration || "00:00:00",
  //               requestIds: [] 
  //             });
  //           }
  //           else if (!uniqueDates.find(d => d.date === entry.date)) {
  //             uniqueDates.push({
  //               date: entry.date,
  //               fullDayName: entry.fullDayName,
  //               monthName: entry.monthName,
  //               pendingApprovalCount: entry.pendingApprovalCount || 0, // Default to 0 if not available
  //               totalDuration: entry.totalDuration || "00:00:00",
  //               workedDuration: entry.workedDuration || "00:00:00",
  //               requestIds: [] 
  //             });
  //           }
  //         } 

  //         if(entry?.date && entry?.fullDayName){
  //           var dateEntry = uniqueDates.find(d => d.date === entry.date);
  //         } else {
  //           var dateEntry = uniqueDates.find(d => d.monthName === entry.monthName);
  //         }
         
        
  //         if (entry?.userRequestDtos) {
  //           entry.userRequestDtos.forEach(user => {
  //             let existingUser = result.find(u => u.User.creatorId === user.creatorId);
        
  //             // Initialize dateEntriesMap for user if not already present
  //             if (!dateEntriesMap[user.creatorId]) {
  //               dateEntriesMap[user.creatorId] = {};
  //             }
        
  //             // Check if requestDtos exists within the user and is non-empty
  //             if (user?.requestDtos && user.requestDtos.length > 0) {
  //               user.requestDtos.forEach(request => {
    
  //                 dateEntry.requestIds.push(request.id);
  //                 // Initialize date array if not present
  //                 if (entry.fullDayName != null && !dateEntriesMap[user.creatorId][entry.date]) {
  //                   dateEntriesMap[user.creatorId][entry.date] = [];
  //                 } else {
  //                   dateEntriesMap[user.creatorId][entry.monthName] = [];
  //                 }
                  
  //                 if(entry.fullDayName != null){
  //                   dateEntriesMap[user.creatorId][entry.date].push({
  //                     timeEntryId: request.id,
  //                     startIn12Hours: request.startIn12Hours,
  //                     endIn12Hours: request.endIn12Hours,
  //                     duration: request.duration,
  //                     projectColor: request.projectColor,
  //                     projectFontColor: request.projectFontColor,
  //                     projectName: request.projectName,
  //                     totalDuration: user.totalDuration,
  //                     workedDuration: user.workedDuration,
  //                     overTime: user.overTime
  //                   });
  //                 } else {
  //                   dateEntriesMap[user.creatorId][entry.monthName].push({
  //                     timeEntryId: request.id,
  //                     startIn12Hours: request.startIn12Hours,
  //                     endIn12Hours: request.endIn12Hours,
  //                     duration: request.duration,
  //                     projectColor: request.projectColor,
  //                     projectFontColor: request.projectFontColor,
  //                     projectName: request.projectName,
  //                     totalDuration: user.totalDuration,
  //                     workedDuration: user.workedDuration,
  //                     overTime: user.overTime
  //                   });
  //                 }
                  
                    
                 
  //                 // Append each request entry for the date
                  
        
  //                 // Log the dateEntriesMap entry after adding request data
  //                 console.log('Updated dateEntriesMap entry:', dateEntriesMap[user.creatorId][entry.monthName ? entry.monthName : entry.date]);
  //               });
  //             } else {
  //               console.log('No requestDtos found for user:', user);
                
  //               // Ensure that even if there are no requestDtos, we still have an entry for the date
  //               if (entry.fullDayName != null && !dateEntriesMap[user.creatorId][entry.date]) {
  //                 dateEntriesMap[user.creatorId][entry.date] = []; // Initialize empty array for no entries
  //               } else {
  //                 dateEntriesMap[user.creatorId][entry.monthName] = [];
  //               }
  //             }
        
  //             // Construct transformedData based on existing user
  //             if (existingUser) {
  //               existingUser.date.push({
  //                 date: entry.date,
  //                 fullDayName: entry.fullDayName,
  //                 monthName: entry.monthName,
  //               });
  //             } else {
  //               result.push({
  //                 User: user,
  //                 date: [{
  //                   date: entry.date,
  //                   fullDayName: entry.fullDayName,
  //                   monthName: entry.monthName,
  //                 }]
  //               });
  //             }
  //           });
  //         } else {
  //           console.log('No userRequestDtos found for entry:', entry);
  //         }
        
  //         return result;
  //       }, []);
        
  //       // Assign transformed data to component variables
  //       this.transformedData = transformedData;
  //       this.uniqueDates = uniqueDates;
  //       this.dateEntriesMap = dateEntriesMap;
  //       console.log('this.dateEntriesMap.....', this.dateEntriesMap)
      
  //     })
  // }

  next(e:any, value:any){
    if (value === 'single') {
      this.showResult(e.pageIndex + 1, 10);
    }
  }

  clearSearch(){
    const currentYear = new Date().getFullYear(); 
    this.ManagerApproval.get('period').setValue('Monthly')
    this.ManagerApproval.get('Year').setValue(currentYear)
    this.monthName = '',
    this.userGroupIds = [],
    this.projectIds = [],
    this.siteIds  = [],
    this.selectGroupIds = [],
    this.usersIds  = [],
    this.closeSearch = false
    this.showResult(1, 10);
    
  }

  showResult(pageNumber:any, pageSize:any){
    console.log(this.selectedPeriod)
    if (this.selectedPeriod == 'Custom') {
      this.YearName = '';
      this.monthName = '';
      const startDate = this.ManagerApproval.get('startDate').value; 
      const endDate = this.ManagerApproval.get('endDate').value;
  
      var formattedStartDate = new Date(Date.UTC(
        new Date(startDate).getFullYear(),
        new Date(startDate).getMonth(),
        new Date(startDate).getDate()
    )).toISOString().split('T')[0];
  
    var formattedEndDate = new Date(Date.UTC(
        new Date(endDate).getFullYear(),
        new Date(endDate).getMonth(),
        new Date(endDate).getDate()
    )).toISOString().split('T')[0];
    } else if (this.selectedPeriod == 'Monthly') {
      this.YearName = this.ManagerApproval.get('Year').value
    } else if (this.selectedPeriod == 'Daily') {
      this.YearName = this.ManagerApproval.get('Year').value
      this.monthName = this.ManagerApproval.get('Month').value
    }

    this.loader = true

  this.injector.get(TimeManagementService).getApprovedTimesheet( 
    formattedStartDate || undefined, 
    formattedEndDate || undefined, 
    this.YearName || undefined,
    pageNumber, 
    pageSize,
    this.monthName || undefined,
    this.userGroupIds || undefined,
    this.projectIds|| undefined,
    this.siteIds || undefined,
    this.usersIds || undefined,
    this.selectGroupIds || undefined,
  ).subscribe((data:any) => {
    console.log(data)
    if(data) {
      this.loader = false
      this.TimeUserDetails = data[0].userDurationDtos
    this.EntryTimeSheet = data[0].approvedEntryDtos
    this.UserTotalRequired = data[0].totalUsersDuration
    this.UserTotalWorked = data[0].totalUsersWorkedDuration
    this.UserTotalOvertime = data[0].totalUsersOverTimeDuration

    var uniqueDates = [];
    var dateEntriesMap = {};

    // Transform data and populate unique dates and dateEntriesMap
    const transformedData = this.EntryTimeSheet?.reduce((result, entry) => {
      // Check if entry has a date and is formatted correctly
      if (entry?.date) {
        // Ensure the date is included in uniqueDates even if there are no userRequestDtos
        if(entry.fullDayName == null && !uniqueDates.find(d => d.monthName === entry.monthName)) {
          uniqueDates.push({
            monthName: entry.monthName,
            totalDuration: entry.totalDuration || "00:00:00",
            workedDuration: entry.workedDuration || "00:00:00",
            overtime: entry.overtime,
            requestIds: [] 
          });
        }

        if (entry.fullDayName != null && !uniqueDates.find(d => d.date === entry.date)) {
          uniqueDates.push({
            date: entry.date,
            fullDayName: entry.fullDayName,
            monthName: entry.monthName,
            pendingApprovalCount: entry.pendingApprovalCount || 0, // Default to 0 if not available
            totalDuration: entry.totalDuration || "00:00:00",
            workedDuration: entry.workedDuration || "00:00:00",
            overtime: entry.overtime,
            requestIds: [] 
          });
        }
      } 


      if(entry?.date && entry?.fullDayName != null){
        var dateEntry = uniqueDates.find(d => d.date === entry.date);
      } 
      
      if (entry?.date && entry?.fullDayName == null) {
        var dateEntry = uniqueDates.find(d => d.monthName === entry.monthName);
      }
     
    
      if (entry?.userRequestDtos) {
        entry.userRequestDtos.forEach(user => {
          let existingUser = result.find(u => u.User.creatorId === user.creatorId);
    
          // Initialize dateEntriesMap for user if not already present
          if (!dateEntriesMap[user.creatorId]) {
            dateEntriesMap[user.creatorId] = {};
          }
    
          // Check if requestDtos exists within the user and is non-empty
          if (user?.requestDtos && user.requestDtos.length > 0) {
            user.requestDtos.forEach(request => {

              dateEntry.requestIds.push(request.id);
              // Initialize date array if not present
              if (entry.fullDayName != null && !dateEntriesMap[user.creatorId][entry.date]) {
                dateEntriesMap[user.creatorId][entry.date] = [];
              } 
              
              if(entry.fullDayName == null && !dateEntriesMap[user.creatorId][entry.monthName]) {
                dateEntriesMap[user.creatorId][entry.monthName] = [];
              }
              
              if(entry.fullDayName != null){
                dateEntriesMap[user.creatorId][entry.date].push({
                  timeEntryId: request.id,
                  startIn12Hours: request.startIn12Hours,
                  endIn12Hours: request.endIn12Hours,
                  duration: request.duration,
                  projectColor: request.projectColor,
                  projectFontColor: request.projectFontColor,
                  projectName: request.projectName,
                  totalDuration: user.totalDuration,
                  workedDuration: user.workedDuration,
                  overTime: user.overTime,
                  siteAddress: request.siteAddress,
                  siteCity: request.siteCity,
                  siteCountry: request.siteCountry,
                  siteName: request.siteName,
                  siteId: request.siteId,
                  siteZipCode: request.siteZipCode,
                  stopIpAddress: request.stopIpAddress,
                  startIpAddress: request.startIpAddress,
                  distanceFromStartIP: request.distanceFromStartIP,
                  distanceFromStopIP: request.distanceFromStopIP,
                  isTimeOff: request.isTimeOff,
                  categoryFontColor: request.categoryFontColor,
                  categoryColor: request.categoryColor, 
                  isFullDay: request.isFullDay,
                  description: request.description,
                  isClockInClockOut: request.isClockInClockOut,
                  formattedDuration: request.formattedDuration,
                  created: request.created,
                  startLatitude: request.startLatitude,
                  startLongitude: request.startLongitude,
                  stopLatitude: request.stopLatitude,
                  stopLongitude: request.stopLongitude
                });
              } 
              
              if (entry.fullDayName == null) {
                dateEntriesMap[user.creatorId][entry.monthName].push({
                  timeEntryId: request.id,
                  startIn12Hours: request.startIn12Hours,
                  endIn12Hours: request.endIn12Hours,
                  duration: request.duration,
                  projectColor: request.projectColor,
                  projectFontColor: request.projectFontColor,
                  projectName: request.projectName,
                  totalDuration: user.totalDuration,
                  workedDuration: user.workedDuration,
                  overTime: user.overTime,
                  siteAddress: request.siteAddress,
                  siteCity: request.siteCity,
                  siteCountry: request.siteCountry,
                  siteName: request.siteName,
                  siteId: request.siteId,
                  siteZipCode: request.siteZipCode,
                  stopIpAddress: request.stopIpAddress,
                  startIpAddress: request.startIpAddress,
                  distanceFromStartIP: request.distanceFromStartIP,
                  distanceFromStopIP: request.distanceFromStopIP,
                  isTimeOff: request.isTimeOff,
                  categoryFontColor: request.categoryFontColor,
                  categoryColor: request.categoryColor, 
                  isFullDay: request.isFullDay,
                  description: request.description,
                  isClockInClockOut: request.isClockInClockOut,
                  formattedDuration: request.formattedDuration,
                  created: request.created,
                  startLatitude: request.startLatitude,
                  startLongitude: request.startLongitude,
                  stopLatitude: request.stopLatitude,
                  stopLongitude: request.stopLongitude
                });
              }
               
             
              // Append each request entry for the date
              
    
              // Log the dateEntriesMap entry after adding request data
              console.log('Updated dateEntriesMap entry:', dateEntriesMap[user.creatorId][entry.monthName ? entry.monthName : entry.date]);
            });
          } else {
            console.log('No requestDtos found for user:', user);
            
            // Ensure that even if there are no requestDtos, we still have an entry for the date
            if (entry.fullDayName != null && !dateEntriesMap[user.creatorId][entry.date]) {
              dateEntriesMap[user.creatorId][entry.date] = []; // Initialize empty array for no entries
            } 
            
            if (entry.fullDayName == null && !dateEntriesMap[user.creatorId][entry.monthName]) {
              dateEntriesMap[user.creatorId][entry.monthName] = [];
            }
          }
    
          // Construct transformedData based on existing user
          if (existingUser) {
            existingUser.date.push({
              date: entry.date,
              fullDayName: entry.fullDayName,
              monthName: entry.monthName,
            });
          } else {
            result.push({
              User: user,
              date: [{
                date: entry.date,
                fullDayName: entry.fullDayName,
                monthName: entry.monthName,
              }]
            });
          }
        });
      } else {
        console.log('No userRequestDtos found for entry:', entry);
      }
    
      return result;
    }, []);
    
    // Assign transformed data to component variables
    this.transformedData = transformedData;
    this.uniqueDates = uniqueDates;
    this.dateEntriesMap = dateEntriesMap;
    console.log('this.dateEntriesMap.....', this.dateEntriesMap)
  
    }
    
    
  }, error => {
    this.loader= false
  })
  }

  
  formatMonthDate(date: string, monthName: string): string {
    if (!date || !monthName) return ''; // Handle invalid inputs
  
    const dayNumber = new Date(date).getDate();
  
    const shortMonth = monthName.slice(0, 3);
  
    return `${shortMonth} ${dayNumber}`;
  }

  FormatTotalDuration(totalDuration: string): string {
    if (!totalDuration) return '0h 0m'; // Handle empty input
    
    // Handle "00:00:00" format (hh:mm:ss)
    if (totalDuration.includes(':')) {
      const [hours, minutes, seconds] = totalDuration.split(':').map(Number);
      if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return '0h 0m'; // Handle invalid input
      return `${hours}h ${minutes}m`;
    }
  
    // Initialize hours and minutes
    let hours = 0;
    let minutes = 0;
  
    // Check for "Hrs" and extract hours
    const hoursMatch = totalDuration.match(/(\d+)\s*Hrs/);
    if (hoursMatch) {
      hours = parseInt(hoursMatch[1], 10);
      if (isNaN(hours)) hours = 0; // Default to 0 if invalid
    }
  
    // Check for "Min" and extract minutes
    const minutesMatch = totalDuration.match(/(\d+)\s*Min/);
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[1], 10);
      if (isNaN(minutes)) minutes = 0; // Default to 0 if invalid
    }
  
    // Return the formatted result
    return `${hours}h ${minutes}m`;
  }

  getDateEntry(userId: string, date: string) {
    return this.dateEntriesMap?.[userId]?.[date];
  }

  getDateEntrys(userId: string, month: string){
    return this.dateEntriesMap?.[userId]?.[month];
  }

  onPeriodChange(event:any){
    console.log(event)
    this.selectedPeriod = event
  }

  generateYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      this.years.push(i);
    }
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  AdvanceSearch() {
    const AdvanceSearch = this.dialog.open(
      ReportingSearchComponent,
      {
        disableClose: true,
        data: { type: 'Users' },
        width: '500px',
      }
    );
    
    AdvanceSearch.afterClosed().subscribe((result: any) => {
      console.log(result)
      if(result) {
        this.usersIds = result.UsersId || undefined,
        this.userGroupIds= result.UserGroupsId || undefined,
        this.projectIds= result.ProjectsId || undefined,
        this.siteIds= result.SitesId || undefined,
        this.selectGroupIds= result.SelactGroupIdes || undefined
      this.showResult(1, 20)
      this.closeSearch = true
      }
    })
  }

  approvalDetails(){
    const AdvanceSearch = this.dialog.open(
      ReportingDetailsComponent,
      {
        disableClose: true,
        data: { type: 'Users' },
        width: '500px',
      }
    );
    
    AdvanceSearch.afterClosed().subscribe((result: any) => {

    })
  }

  TimeDetails(user: any, date:any, dateEntries: any[], timeEntryId: number, mode:any){
    console.log(dateEntries)
    if(mode == 'date') {
      this.getDateEntry(user.userId, date.date) 
      var specificEntry = dateEntries.find((entry) => entry.timeEntryId === timeEntryId);
      console.log('Specific Entry:', specificEntry);
    } else {
      this.getDateEntrys(user.userId, date.date) 
      var specificEntry = dateEntries.find((entry) => entry.timeEntryId === timeEntryId);
      console.log('Specific Entry:', specificEntry);

    }
   
    const TimeDetails = this.dialog.open(
      ReportingDetailsComponent,
      {
        disableClose: true,
        data: { data: specificEntry, user:user, EntryDetails:dateEntries, type: 'TimeTrack' },
        width: '700px',
      }
    );
    
    TimeDetails.afterClosed().subscribe((result: any) => {
      
    })
  }

  OpenExport() {
    const reportDetails = this.dialog.open(
      ReportExportComponent,
      {
        disableClose: true,
        data: {type: 'Report' },
        width: '1100px',
      }
    );
    
    reportDetails.afterClosed().subscribe((result: any) => {
      
    })
  }
  
  ShowUserDEtails(row:any, type: string): void {
    this.dialog.open(UserDetailsComponent, {
      data: { id: row, type: type, },
      disableClose: true,
      width: '500px',
    },);
  }

  showUserList(group: any) {
    if (group?.dynamicGroupId == 0) {
      const GroupId = group?.groupId;
      this.injector.get(ManageUsersService).getGroupUserService(GroupId).subscribe((data) => {
        if (data) {
          this.userList = data;
          this.dialog.open(ShowgroupComponent, {
            data: { data: this.userList, entity: 'Users' },
            disableClose: true,
            width: '500px',
          },);
        }
      });
    } else {
      const GroupId = group?.dynamicGroupId;
      this.injector.get(ManageUsersService)
        .getDynamicGroupUserService('Users', GroupId)
        .subscribe((data) => {
          if (data) {
            this.userList = data;
          }
        });
    }
  }

    // ShowSiteDetails
    ShowSiteDetails(row:any, type: string): void {
      this.dialog.open(SiteDetailsComponent, {
        disableClose: true, 
        data: { id: row, type: type },
        width: '500px',
      });
    }

    showSiteList(group: any) {
      if (group.dynamicGroupId == null) {
        const GroupId = group.id;
        this.injector.get(ManageSitesService)
          .fetchSiteGroupByIdService(GroupId)
          .subscribe((data) => {
            if (data) {
              this.userList = data;
            }
          });
      } else {
        const GroupId = group.dynamicGroupId;
        this.injector.get(ManageSitesService)
          .getDynamicGroupService('Sites', GroupId)
          .subscribe((data) => {
            if (data) {
              this.userList = data;
            }
          });
      }
    }

    OpenDashboard(){
      const TimeDashboard = this.dialog.open(
        ReportDashboardComponent,
        {
          disableClose: true,
          data: { type: 'TimeDashboard' },
          width: '1100px',
        }
      );
      
      TimeDashboard.afterClosed().subscribe((result: any) => {
        
      })
    }

    OpenStatistics(){
      const TimeDashboard = this.dialog.open(
        ReportStatisticsComponent,
        {
          disableClose: true,
          data: { type: 'TimeStatistics' },
          width: '1100px',
        }
      );
      
      TimeDashboard.afterClosed().subscribe((result: any) => {
        
      })
    }

    openTimeTrack(){
      this.dialogRef.close(true)
      const TimeTrackDialog = this.dialog.open(TimeTrackComponent, {
        width: '1100px',
        height: '90vh',
        autoFocus: false,
        disableClose: true
      });
  
      TimeTrackDialog.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }

    openTimeOff(){
      this.dialogRef.close(true)
      const TimeOffDialog = this.dialog.open(TimeOffComponent, {
        width: '900px',
        height: '90vh',
        autoFocus: false,
        disableClose: true
      });
  
      TimeOffDialog.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  
    openTimeSheet(){
      this.dialogRef.close(true)
      const TimeSheetDialog = this.dialog.open(TimesheetComponent, {
        width: '1100px',
        height: '90vh',
        autoFocus: false,
        disableClose: true,
        data: {type: 'User-TimeSheet'}
      });
  
      TimeSheetDialog.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
 

}
