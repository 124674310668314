import { Component, ElementRef, Inject, Injector, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
@Component({
  selector: 'app-show-template-view',
  templateUrl: './show-template-view.component.html',
  styleUrls: ['./show-template-view.component.scss']
})
export class ShowTemplateViewComponent implements OnInit {
  Form: FormGroup;
  htmlContent: string = '';
  @ViewChild('dialogContent') dialogContent: ElementRef;
  @ViewChild('htmlContainer') htmlContainer: ElementRef<HTMLDivElement>;
  GetAllUsercolumes=[]
  GetAllContactcolumes=[]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public elementRef: ElementRef,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<ShowTemplateViewComponent>,
    private injector: Injector,
    private renderer: Renderer2, 
    private el: ElementRef
  ) {

    this.Form = this.formBuilder.group({
      AddTags: this.formBuilder.array([]),
    });
   }

  ngOnInit(): void {
    if (this.dialogContent && this.dialogContent.nativeElement) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
    this.scrollToTop()
    var myComponent = document.getElementById('myComponent');
    myComponent.scrollTop = 0;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if(this.data){
      if(this.data.htmlContent){
        this.htmlContent=this.data.htmlContent
      }
      this.GetAllTagColumns()
    }
    console.log(this.data.htmlContent)
  }

  scrollToTop() {
    const htmlContentContainer = this.elementRef.nativeElement.querySelector('.html-content');
    if (htmlContentContainer) {
      htmlContentContainer.scrollTop = 0;
    }
  }


  GetAllTagColumns(){
    this.injector.get(ManageUsersService).GetMapColumns('users').subscribe((result:any)=>{
      if(result){
        this.GetAllUsercolumes=result
      }
    })
    this.injector.get(ManageUsersService).GetMapColumns('contacts').subscribe((result:any)=>{
      if(result){
        this.GetAllContactcolumes=result
      }
    })
    }
      




  AddTags(): FormArray {
    return this.Form.get('AddTags') as FormArray;
  }

  newFile(selectedText,index): FormGroup {
    return this.formBuilder.group({
      selectedText: [selectedText],
      index: [index],
      Columnname:[''],
      Entity:['']
    });
  }

  getentityname(index){
    return this.AddTags().at(index).get('Entity').value
  }

  Removecondictuon(i: number) {
   const selectedText= this.AddTags().at(i).get('selectedText').value
   this.removeHighlightForSelectedText(selectedText)

    this.AddTags().removeAt(i);
  }


  onSubmit(){
    this.matDialogRef.close(this.Form.value.AddTags)
  }


  onClick(): void {
    const selectedText = window.getSelection()?.toString(); // Get the selected text
    if (selectedText) {
      const index = this.htmlContent.indexOf(selectedText);
      console.log('Index position:', index);
      console.log('selectedText', selectedText)
      console.log(this.Form.value.AddTags.lenght)
      console.log(this.Form.value.AddTags)
      console.log(this.Form)
      this.AddTags().push(this.newFile(selectedText,index));
      this.highlightAllMatchingText()
    }
  }

  highlightAllMatchingText() {
    const selection = window.getSelection();
  
    if (!selection || selection.rangeCount === 0 || selection.toString().trim() === '') {
      console.warn('No valid text selected.');
      return; // No valid text selected
    }
  
    const selectedText = selection.toString().trim();
  
    // Escape special characters in the selected text for RegExp
    const escapedText = selectedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedText, 'gi'); // Global and case-insensitive search
  
    const highlightTextInElement = (element: Node) => {
      if (element.nodeType === Node.TEXT_NODE) {
        const textContent = element.textContent || '';
  
        if (regex.test(textContent)) {
          const highlightedHTML = textContent.replace(
            regex,
            `<span style="background-color: yellow;">${selectedText}</span>`
          );
  
          // Create a temporary container to hold the highlighted content
          const wrapper = document.createElement('div');
          wrapper.innerHTML = highlightedHTML;
  
          // Replace the text node with the new HTML content
          const parent = element.parentNode;
          if (parent) {
            while (wrapper.firstChild) {
              parent.insertBefore(wrapper.firstChild, element);
            }
            parent.removeChild(element);
          }
        }
      } else if (element.nodeType === Node.ELEMENT_NODE) {
        Array.from(element.childNodes).forEach((child) => highlightTextInElement(child));
      }
    };
  
    // Select the container to search in
    const container = document.querySelector('#htmlContainer') as HTMLElement;
    if (container) {
      highlightTextInElement(container);
    } else {
      console.error('Container with ID #htmlContainer not found.');
    }
  
    // Clear the selection
    selection.removeAllRanges();
  }
  

  removeHighlightForSelectedText(selectedText) {
    console.log(selectedText)
    const selection = window.getSelection();
    // const selectedText = selection.toString().trim();
  
    // Escape special characters in the selected text for RegExp
    const escapedText = selectedText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`^${escapedText}$`, 'i'); // Match the exact selected text (case-insensitive)
  
    const removeHighlightInElement = (element: Node) => {
      if (element.nodeType === Node.ELEMENT_NODE) {
        const childNodes = Array.from(element.childNodes);
        childNodes.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE) {
            const childElement = child as HTMLElement;
  
            // Check if it's a highlighted span with the selected text
            if (
              childElement.tagName === 'SPAN' &&
              childElement.style.backgroundColor === 'yellow' &&
              regex.test(childElement.textContent || '')
            ) {
              const parent = childElement.parentNode;
              if (parent) {
                // Replace the <span> with its text content
                const textNode = document.createTextNode(childElement.textContent || '');
                parent.replaceChild(textNode, childElement);
              }
            } else {
              // Recursively process child nodes
              removeHighlightInElement(child);
            }
          } else if (child.nodeType === Node.TEXT_NODE) {
            // Text nodes don't require unwrapping
          }
        });
      }
    };
  
    // Select the container to search in (e.g., the HTML template)
    const container = document.querySelector('#htmlContainer') as HTMLElement;
    if (container) {
      removeHighlightInElement(container);
    } else {
      console.error('Container with ID #htmlContainer not found.');
    }
  
    // Clear the selection
    selection.removeAllRanges();
  }
  




}
