import { Component, Inject, NgZone, ViewChild, OnInit, Injector } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManageimportComponent } from '../../manageimport/manageimport.component';
import { ManageContactsService } from '../../../../../app/core/services/manage-contacts.service';
import { saveAs } from 'file-saver';
import { ManageUsersService } from '../../../../../app/core/services/manage-users.service';
import { ManageSitesService } from '../../../../../app/core/services/manage-sites.service';
import { ApiErrorMessagesComponent } from '../../../../../app/api-error-messages/api-error-messages.component';
import { PopupErrorMessageComponent } from '../../../../../app/popup-error-message/popup-error-message.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { AddFileNotesComponent } from '../../../../../app/pages/files/components/add-file-notes/add-file-notes.component';
import { CreateEventComponent } from '../../../../../app/pages/calendar/create-event/create-event.component';
import { CalendarService } from '../../../../../app/core/services/calendar.service';
import { MessageService } from '../../../../../app/core/services/message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { HttpClient } from '@angular/common/http';
import { GoogleValidationAddressService } from '../../../../../app/core/services/google-validation-address.service';
import { TranslateService } from '@ngx-translate/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { Category } from '../../../../../app/core/models/category.model';
// import * as _ from 'lodash';
import { AddtagCommonComponent } from '../../../../../app/pages/commonForAll/addtag-common/addtag-common.component';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { ChooseTaskOptionComponent } from '../../management-initiatives/create-initiatives/choose-task-option/choose-task-option.component';
import { ConformationComponent } from '../../management-initiatives/conformation/conformation.component';
import { ReminderCreateComponent } from '../../../../../app/pages/Reminder/reminder-create/reminder-create.component';
import { AccordianViewComponent } from '../../lead-automation/components/accordian-view/accordian-view.component';
import { DelegationService } from '../../../../../app/core/services/delegation.service';
import { PopupCommonComponent } from '../../../../../app/pages/commonForAll/popup-common/popup-common.component';
import { AuthService } from '../../../../../app/core/services/auth.service';
import { CategoriesModalComponent, DialogOverviewExampleDialog3 } from '../../../../../app/core/components/categories-modal/categories-modal.component';
import { ManageCreateComponent } from '../manage-create/manage-create.component';
import { ViewTaskComponent } from '../view-task/view-task.component';
import { CreateMilestoneComponent } from '../create-milestone/create-milestone.component';
import { ProfilePickFormatService } from '../../../../../app/core/services/profile-pick-format.service';
import { ManageProductsService } from '../../../../../app/core/services/manage-products.service';
import { ManageTransactionsService } from '../../../../../app/core/services/manage-transactions.service';
import { FileandnotesformanagementComponent } from '../../../../../app/pages/commonForAll/fileandnotesformanagement/fileandnotesformanagement.component';
import { AddFileComponent } from '../../../../../app/pages/files/components/add-file/add-file.component';
import { AddNotesComponent } from '../../../../../app/pages/files/components/add-notes/add-notes.component';
import { FormDataService } from '../../../../core/services/form-data.service';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit {
  @ViewChild('placesRef', { static: false }) placesRef!: GooglePlaceDirective;
  categories$!: Observable<Category[]>;
  CriticityId$!: Observable<any>;
  fileForm!: FormGroup;
  updateile:any= [];
  eventdailog = true;
  filesnotes = true;
  periodicity: any = [
    { id: '1', name: 'Daily' },
    { id: '2', name: 'Weekly' },
    { id: '3', name: 'Monthly' },
    { id: '4', name: 'Default' },
  ];
  users$ = [];
  filteredusers = [];
  contacts$ = [];
  filteredcontacts = [];
  sites$ = [];
  filteredsites = [];
  usersgroup$ = [];
  filteredusersgroup = [];
  contactsgroup$ = [];
  filteredcontactsgroup = [];
  products$:any = [];
  filteredproducts:any = [];
  sitesgroup$ = [];
  filteredsitesgroup = [];
  userGroupArray:any;
  contactArray:any;
  filterforuserlist: any;
  filterforcontactlist: any;
  filterforsiteslist: any;
  filterforuserGrouplist: any;
  filterforcontactGrouplist: any;
  filterforsiteGrouplist: any;
  minDate!: string;
  minTime: any;
  files: string[] = [];
  form!: FormGroup;
  showPeriodDate: boolean = false;
  showFilterDay: boolean = false;
  dropdown: boolean = false;
  selectedradioval: any;
  // selectedFile: ImageSnippet;
  viewtagmodel :any= {};
  showcheckbox!: boolean;
  selectednumeralval: any;
  tagarray :any= [];
  Freefeild :any= {};
  viewnumeral :any= {};
  showfreefeild!: boolean;
  dropdownvalue = [1, 2, 3, 4];
  i = 1;
  tagViewModels :any= [];
  tags: any;
  dropdownfeild :any= {};
  datadelete: any;
  datadeleteindex: any;
  checked: any;
  selectedvalue: any;
  selectedtagvalue: any;
  screenType: any;
  selectedDays = [];
  color1: string = '#2889e9';
  googleAutoinput: any;
  selectedrole: any = '1';

  isChild: boolean = true;
  isPredecessor: boolean = false;
  isSuccessor: boolean = false;
  loader = false
  listofPipelines:any
  listofPipelines$ :any= []
  listofinitiatives:any
  listofinitiatives$ :any= []
  listofisPredecessor:any
  listofisPredecessor$ :any= []
  listofisSuccessor:any
  listofisSuccessor$ :any= []
  listofSteps :any= []
  trade :any= [
    { id: 1, label: 'Sunday', selected: true },
    { id: 2, label: 'Monday', selected: true },
    { id: 3, label: 'Tuesday', selected: true },
    { id: 4, label: 'Wednesday', selected: true },
    { id: 5, label: 'Thursday', selected: true },
    { id: 6, label: 'Friday', selected: true },
    { id: 7, label: 'Saturday', selected: true },
  ];

  listofcountry:any = [];
  listofcountry$ :any= [];
  viewmodel :any= {};
  categoryname: any;
  creatorId: any;
  quickSearchValue!: string;
  categories: any;
  totalitems: any;
  category: boolean = false;
  publiccheckboxstatus:boolean=false;
  Userdropdownstatus:boolean=false;
  showcreate = 0;
  // value: any;
  // auto chip for keyword
  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordlist: string[] = [];
  AdminStatus: any;
  options = {
    types: ['address'],
    componentRestrictions: {},
  };
  googleAutoinputScreen = true;
  NormalAddressScreen = false;
  todaydate = new Date();
  startTime:any;
  endTime:any;
  valuedata = '';
  timeZone :any= 'Europe/Paris';
  taskiddata: any;
  listofTasks:any
  FindTaskStep: any;
  userGroups$: any;
  filteredList4: any;
  successorlist: any;
  predecessorlist: any;
  numbersArray: number[] = []; 
  ReminderList:any=[]
  selectedUsersIds: any = []; 
  showReminderUsers: boolean[] = [];
  timeList = [
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '14:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
  ];
  MandatoryColumns: any = [];
  lablesbasedonentity:any;
  UniqueCode
  AssignedTaskUsers: any = [];
  Orders$: any;
  filterfororderlist: any;
  AssignedTaskContacts: any;
  AssignedTaskProducts: any;
  AttachmentFileCount: any;
  AttachmentFileList: any;
  AttachmentNotesCount: any;
  AttachmentNotesList: any;
  ShowAttachmentFile = false
  ShowAttachmentNotes = false
  notesCount = 0;
  filesCount = 0;
  AssignedTaskOrders: any;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner=false
  AssignedTaskApprovers: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<CreateEventComponent>,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    public zone: NgZone,
    public GooglePlace: GooglePlaceDirective,
    private translate: TranslateService,
    private injector: Injector,
    private calendarService: CalendarService,
    private delegationService: DelegationService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.AdminStatus = sessionStorage.getItem('isAdmin');
    if (sessionStorage.getItem('lang')) {
      const lang:any=sessionStorage.getItem('lang')
      this.translate.use(lang);
    } else {
      this.translate.use('English');
    }
    this.AdminStatus = sessionStorage.getItem('isAdmin');
    this.timeZone = sessionStorage.getItem('timeZone');
    this.form = this.formBuilder.group({
      id: [0],
      ParentId: [''], 
      IsPublic:[true],
      PermittedUserIds:[], 
      Title: [''],
      StartDate: [this.todaydate],
      EndDate: [''],
      Address: [''],
      GoogleAddress: [''],
      ZipCode: [],
      City: [''],
      latitude: [],
      longitude: [],
      Description: [''],
      keyword: [''],
      CriticityId: [3],
      IsTemplate: [false],
      UserId: [],
      Approverids:[],
      PermittedUser:[],
      NeedAllApproval:[false],
      UserGroupsId: [[]],
      ContactId: [],
      ProductsId:[],
      ordersId:[],
      Country: ['France'],
      ContactGroupsId: [[]],
      SiteId: [],
      CategoryId: [131],
      Attachments: [[]],
      CategoryViewModel: [''],
      categoryname: [''],
      NumberOfUsers: [''],
      tagViewModels: [[]],
      tagType: [''],
      TagViewModels: [[]],
      Tagetitle: [''],
      tagdropdown: [''],
      ischecked: [''],
      numeralType: [''],
      IsPredecessortask: [false],
      IsSuccessortask: [false],
      freefeildTitle: [''],
      freefeildnumber: [''],
      feildname: [''],
      fieldvalue: [''],
      freefeildvalue: [''],
      dropdowntype: [''],
      dropdown: [''],
      Hours: [''],
      Minutes: ['0'],
      Period: [0],
      Addtages: this.formBuilder.array([]), 
      HasStep: [false],
      IsSubTask:[false],
      PipeLinetype: ['2'],
      AddSteps: this.formBuilder.array([]),
      pipelineID: [null],
      Pipelinename: [''],
      IsSavePipeline: [],
      Stage: [2], 
      initiatives:[''],
      ListofSuccessor:[''],
      ListofPredecessor:[''],
      IsMileStone:[false],
      addreminders: [false],
      Name: [null],
      ChildId: [null],
      previousStep:  [null],
      step: [null],
      showIcon:[null],
      showColor: [null],
      positions: [null],
      UniqueCode:[],
      subtaskmodule: this.formBuilder.array([]), 
    });
  }

  ngOnInit(): void {
    console.log(this.data);
    this.screenType = this.data.type;
    if(this.screenType == 'edit-task' || this.screenType == 'edit-task-initiatives' || this.screenType == 'View-task-initiatives') {
      this.FindUserByTaskid()
      this.FindContactsByTaskId()
      this.FindProductsByTaskId()
      this.FindOrdersByTaskId()
      this.FindApproversByTaskId()
    }
    if (this.screenType == 'edit-task') {
      this.spinner=true
      this.injector.get(ManageUsersService)?.FindTaskById(this.data.data.id).subscribe((res: any) => {
        if(res) {
          
          console.log(res)
          this.spinner=false
          this.taskiddata = res;
          this.filesCount = res?.filesCount;
          this.notesCount = res?.notesCount

          if(res.remindersCount !=0){  
            this.calendarService.FindReminderLinked(this.data.data.id, 3).subscribe((result) => {
           this.ReminderList=result
           this.form.get('addreminders')?.setValue(true)
           this.taskiddata.FindReminder=result
           console.log(result)
         });
        
         }
          this.getOneTask()
        }
        
      
        
        
      }, (error:any) => {
        this.spinner=false
      })
    }
    this.generateNumbersArray()
    console.log(this.numbersArray)
    if(this.screenType == 'Create-subTask'||this.screenType=='Create-task'||this.screenType == 'Create-task-initiatives'||this.screenType=='Create-subTask'||this.screenType=='addsubtask'||this.screenType == 'edit-task-initiatives' || this.screenType == 'View-task-initiatives'){
      if(this.data?.initiative){
        if(this.data?.initiative?.isPublic==false){
          this.form.get('IsPublic')?.setValue(this.data.initiative.isPublic)
          this.form.get('PermittedUserIds')?.setValue(this.data.initiative.PermittedUserIds)
          this.publiccheckboxstatus=true
        }else{
          this.form.get('IsPublic')?.setValue(this.data.initiative.isPublic)
          this.publiccheckboxstatus=true
        }
        if(this.data?.initiative?.UsersConditions){
          let ids:any=[]
          this.data?.initiative?.UsersConditions?.forEach((element:any) => {
            ids.push(element.userId)
          });
          this.form.get('UserId')?.setValue(ids)
          this.Userdropdownstatus=true

        }
      }
    } else if (this.data.screenType == 'EditTaskAutomation'){
      this.updateTaskAutomation(this.data.type)
    }
    else if(this.screenType=='Createtask_with_mail'){
      this.form.get('Title')?.setValue(this.data?.data?.senderName)

    }
    else if (this.screenType == 'Create-subTask') {
      this.form.get('ParentId')?.setValue(this.data?.data?.id)
    }

   
    else if (this.screenType == 'edit-task-initiatives' || this.screenType == 'View-task-initiatives' || this.screenType == 'View-subtask-task' || this.screenType == 'edit-step-task') {
      this.taskiddata = this.data.data;
      this.getOneTask()
    }

    this.getdatafordroupdown();
    this.showReminderUsers = new Array(this.ReminderList.length).fill(false);
    this.GetMandatoryColumns();
    this.GetLabelsByOrganization()
    this.genrattingNewCode();
    this.gettags();
  }

  getprivatestatus(){
    return this.form.get('IsPublic')?.value
  }

  getPredecessorstatus(){
    return this.form?.value.IsPredecessortask
  }
  getSuccessorstatus(){
    return this.form?.value.IsSuccessortask
  }

// reminders   
  showreminderspopup(){
    this.form.get('addreminders')?.setValue(true)
    this.showreminders()
  }
  getreminderststus(){
    return this.form.get('addreminders')?.value
  }
  selectedUsers(event:any) {
    console.log(event.value)
    this.selectedUsersIds = event.value

  }

  showreminders(){
    this.selectedUsersIds = this.form.get('UserId')?.value;
    const showreminders = this.dialog.open(ReminderCreateComponent, {
      width: '500px',
      data: { type:'Task',screenType: 'CreateReminder', selectedUsers: this.selectedUsersIds},
      
      autoFocus: false,
      disableClose: true,
    });
    showreminders.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result)
        result.reminderConditions?.forEach(element => {
          let users:any=[]
          element.UserId?.forEach(userid => {
            this.filteredusers?.forEach((User:any) => {
              if(User.id==userid){
                users.push(User)
              }
            });
          });
          element.users=users
          this.ReminderList.push(element)
          console.log(this.ReminderList)
        });
      }
    });
  }


 
  genrattingNewCode() {
    this.UniqueCode = '';
    this.authService.GenerateUniqueCodel('Task').subscribe((result:any)=>{
      this.UniqueCode=result.code
      this.form.get('UniqueCode')?.setValue(result.code)
    })
  }

  FindUserByTaskid(){
    this.injector.get(ManageUsersService).FindUserByTaskId(this.data.data.id).subscribe((res: any) => {
      console.log(res)
      if (Array.isArray(res)) {
        this.AssignedTaskUsers = res.map((element: any) => element.id);
      } else if (res) {
        this.AssignedTaskUsers = res.id; // Wrap single object's id in an array
      } else {
        this.AssignedTaskUsers = []; // Handle null or undefined response
      }
      console.log(this.AssignedTaskUsers)
    })
  }

  FindContactsByTaskId(){
    this.injector.get(ManageUsersService).FindContactsByTaskId(this.data.data.id).subscribe((res: any) => {
      console.log(res)
      if (Array.isArray(res)) {
        this.AssignedTaskContacts = res.map((element: any) => element.id);
      } else if (res) {
        this.AssignedTaskContacts = res.id; // Wrap single object's id in an array
      } else {
        this.AssignedTaskContacts = []; // Handle null or undefined response
      }
      console.log(this.AssignedTaskContacts)
    })
  }

  FindProductsByTaskId(){
    this.injector.get(ManageUsersService).FindProductsByTaskId(this.data.data.id).subscribe((res: any) => {
      console.log(res)
      if (Array.isArray(res)) {
        this.AssignedTaskProducts = res.map((element: any) => element.id);
      } else if (res) {
        this.AssignedTaskProducts = res.id; // Wrap single object's id in an array
      } else {
        this.AssignedTaskProducts = []; // Handle null or undefined response
      }
      console.log(this.AssignedTaskProducts)
    })
  }

  FindOrdersByTaskId(){
    this.injector.get(ManageUsersService).FindOrdersByTaskId(this.data.data.id).subscribe((res: any) => {
      console.log(res)
      if (Array.isArray(res)) {
        this.AssignedTaskOrders = res.map((element: any) => element.id);
      } else if (res) {
        this.AssignedTaskOrders = res.id; // Wrap single object's id in an array
      } else {
        this.AssignedTaskOrders = []; // Handle null or undefined response
      }
      console.log(this.AssignedTaskOrders)
    })
  }

  FindApproversByTaskId(){
    this.injector.get(ManageUsersService).FindApproversByTaskId(this.data.data.id).subscribe((res: any) => {
      console.log(res)
      if (Array.isArray(res)) {
        this.AssignedTaskApprovers = res.map((element: any) => element.id);
      } else if (res) {
        this.AssignedTaskApprovers = res.id; // Wrap single object's id in an array
      } else {
        this.AssignedTaskApprovers = []; // Handle null or undefined response
      }
      console.log(this.AssignedTaskApprovers)
    })
  }

  updateTaskAutomation(data: any) {
    data?.fieldMappings?.forEach((field: any) => {
      const fieldName = field.actionColumn.split(".")[1];
  
      if (this.form.get(fieldName)) {
        let value: any;
  
        // Set userInput based on actionDataType
        switch (field.actionDataType) {
          case 'boolean':
            value = field.userInput === 'true' || field.userInput === true;
            break;
          case 'number':
            value = !isNaN(Number(field.userInput)) ? Number(field.userInput) : null;
            break;
          case 'dateTime':
            value = new Date(field.userInput); // Parse userInput as a date
            break;
          case 'array':
            value = Array.isArray(field.userInput) ? field.userInput : [];
            break;
          case 'object':
            value = typeof field.userInput === 'object' && field.userInput !== null ? field.userInput : {};
            break;
          default:
            value = field.userInput || ''; // Default to string or empty value
        }
  
        // Set the value to the form control
        this.form.get(fieldName)?.setValue(value);
      }
  
      // Handle ID field for editing
      const idFieldName = `${fieldName}EditId`;
      if (!this.form.get(idFieldName)) {
        this.form.addControl(idFieldName, new FormControl());
      }
      this.form.get(idFieldName)?.setValue(field.id);
    });
  }
  
  onDoubleClick(value) {
    if(this.AdminStatus=='true'){
      const onDoubleClickDialog = this.dialog.open(PopupCommonComponent, {
        data: { value: value, screenType: 'onDoubleClick' },
        disableClose: true,
        width: '400px',
      });
      onDoubleClickDialog.afterClosed().subscribe((result) => {
        if (result) {
          const data={
            "id": this.lablesbasedonentity[value].id,
            "entityName": "task",
            "defaultColumnName": value,
            "newColumnName": result
          }
          this.authService.UpdateLabelbasedonentity(data).subscribe(
            (result) => {
              if (result) {
                this.snackBar.open('Update Label based on entity done', '', {
                  duration: 2000,
                });
                this.GetLabelsByOrganization();
              }
            },
            (error) => {
              if (error.status == 200) {
                this.snackBar.open('Update Label based on entity done', '', {
                  duration: 2000,
                });
                this.GetLabelsByOrganization();
              }
            }
          );
        }
      });
    }
  }

  GetLabelsByOrganization(){
this.authService.GetColumnMappingsByEntityName('task').subscribe((result)=>{
  console.log(result)
  this.lablesbasedonentity=result
})
  } 

  getlable(value){
    if(this.lablesbasedonentity){
      return this.lablesbasedonentity[value].value
    }else{
      return value
    }
  }


  GetMandatoryColumns() {
    this.delegationService.GetMandatoryColumns('Task').subscribe((res: any) => {
      console.log(res);
      this.MandatoryColumns = res.Task;
      res.Task?.forEach((element:any) => {
        if (element.isMandatory == true) {
            this.form.get(element.columnName)?.setValidators([Validators.required]);
            this.form.get(element.columnName)?.updateValueAndValidity()
        }
      });
    });
  }
  getlableisMandatory(value) {
    let Mandatory = false;
    if(this.MandatoryColumns.length!=0){
      this.MandatoryColumns?.forEach((element:any) => {
        if (element.columnName == value) {
          Mandatory = element.isMandatory;
        }
      })
    }
    return Mandatory;
  }

  generateNumbersArray() {
    for (let i = 1; i <= 100; i++) {
      this.numbersArray.push(i); // Push numbers 1 to 100 into the array
    }
  }

  getSteps() {
    return this.form.get("AddSteps") as FormArray
  }
  getsubtasks(){
    return this.form.get("subtaskmodule") as FormArray
  }


  Addsubtask() {
    this.getsubtasks().push(this.newtask());
  }

  AddSteps(stepCount?: number): void {
    const steps = this.getSteps();
  
    if (steps.length === 0) {
      // Initialize steps with the provided count or default to 2
      const count = stepCount || 2;
      for (let i = 0; i < count; i++) {
        const progress = i === 0 ? 0 : 100;
        steps.push(this.newSteps(progress));
      }
    } else {
      // Add a new step dynamically starting from the previous step's progress
      const previousProgress = this.getPreviousStepValue(steps.length);
      steps.push(this.newSteps(previousProgress));
  
      // Redistribute progress to ensure the last step ends at 100%
      this.redistributeProgress();
    }
  }
  
  
  


  getTasks(i){
   return this.getSteps().at(i).get('taskmodule') as FormArray
  }

  newSteps(progress: number = 0): FormGroup {
    return this.formBuilder.group({
      name: [''],
      Step: [''],
      Progress: [progress],
      taskmodule: this.formBuilder.array([]),
    })
  }

  
  

  getTotalProgress(): number {
    const steps = this.getSteps().controls;
    
    // Initialize total progress
    let totalProgress = 0;
  
    // Iterate over each step and calculate the progress based on the step range
    steps.forEach((step, index) => {
      const startProgress = index === 0 ? 0 : steps[index - 1].get('Progress')?.value || 0;
      const endProgress = step.get('Progress')?.value || 0;
      
      // Calculate the difference (range) between the start and end of the step
      const progress = endProgress - startProgress;
  
      // Add the range of the current step to the total progress
      totalProgress += progress;
    });
  
    return totalProgress;
  }
  

  getPreviousStepValue(index: number): number {
    if (index === 0) {
      return 0; // First step always starts at 0
    }
  
    const steps = this.getSteps().controls;
    let previousProgress = 0;
  
    for (let i = 0; i < index; i++) {
      const progress = steps[i].get('Progress')?.value || 0;
      previousProgress = progress;
    }
  
    return previousProgress;
  }
  
  
  
  onSliderChange(index: number, value: number): void {
    const steps = this.getSteps().controls;
  
    if (steps && steps[index]) {
      const min = this.getPreviousStepValue(index);
      const max = index === steps.length - 1 ? 100 : steps[index + 1]?.get('Progress')?.value || 100;
  
      // Clamp the slider value
      const validValue = Math.min(Math.max(value, min), max);
      steps[index].get('Progress')?.setValue(validValue);
  
      // Redistribute progress for subsequent steps
      this.redistributeProgress();
    }
  }

  isLastStepFull(): boolean {
    const steps = this.getSteps().controls;
    if (steps.length === 0) return false;
    const lastStep = steps[steps.length - 1];
    return lastStep.get('Progress')?.value === 100;
  }
  
  
  getProgressRange(index: number): string {
    const min = this.getPreviousStepValue(index);
    const progress = this.getSteps().controls[index]?.get('Progress')?.value || 0;
    return `${progress}`;
  }

  redistributeProgress(): void {
    const steps = this.getSteps().controls;
    let previousProgress = 0;
  
    steps.forEach((step, index) => {
      const currentProgress = step.get('Progress')?.value || 0;
  
      // Ensure the current step starts from the previous step's progress
      const min = previousProgress;
      const max = index === steps.length - 1 ? 100 : currentProgress;
  
      // Clamp the progress value to ensure it's within the valid range
      const validProgress = Math.max(min, Math.min(currentProgress, max));
      step.get('Progress')?.setValue(validProgress);
  
      // Update the previous progress for the next step
      previousProgress = validProgress;
    });
  
    // Ensure the last step always ends at 100%
    const lastStep = steps[steps.length - 1];
    if (lastStep) {
      lastStep.get('Progress')?.setValue(100);
    }
  }
  
  RemoveStep(index: number): void {
    const steps = this.getSteps();
    
    // Remove the step at the given index
    steps.removeAt(index);
  
    const remainingSteps = steps.controls;
  
    // If only one step is left, no need to modify
    if (remainingSteps.length <= 1) return;
  
    // Ensure the last step reaches 100% if needed
    const lastStep = remainingSteps[remainingSteps.length - 1];
    const lastStepProgress = lastStep.get('Progress')?.value || 0;
    lastStep.get('Progress')?.setValue(lastStepProgress + (100 - lastStepProgress));
  }
  
  
  
  
  
  
  
  getpercentage(index: number): number {
    const steps = this.getSteps().controls;
    return steps && steps[index]
      ? steps[index].get('Progress')?.value || 0
      : 0;
  }
  getMinValueForPrevious(i:any){
    console.log("indexxx", i)
    if(i !=0){
      return this.getSteps().at(i-1).get('Progress')?.value
    } else {
      return 0
    }
  }

  getpipelinestatus(){
    if(this.form?.value.pipelineID!= null ||this.form?.value.Pipelinename!=''){
      return true
    }else{
      return false
    }

  }
  // removetask(i:any) {
  //   this.getSteps().removeAt(i)
  // }

  getaddstepsstatus() {
    return this.form?.value.HasStep
  }
  getsubtaskstatus() {
    return this.form?.value.IsSubTask
  }

  filterOptionsforPredecessor(value: string): void {
    this.listofisPredecessor = this.listofisPredecessor$.filter((option:any) =>
      option.title.toLowerCase().includes(value.toLowerCase())
    );
}
filterOptionsforSuccessor(value: string): void {
  this.listofisSuccessor = this.listofisSuccessor$.filter((option:any) =>
    option.title.toLowerCase().includes(value.toLowerCase())
  );
}
filterOptionsforinitiatives(value: string): void {
  this.listofinitiatives = this.listofinitiatives$.filter((option:any) =>
    option.title.toLowerCase().includes(value.toLowerCase())
  );
}
filterOptionsforExistingPipeline(value: string): void {
  this.listofPipelines = this.listofPipelines$.filter((option:any) =>
    option.name.toLowerCase().includes(value.toLowerCase())
  );
}

  getAllPipelines(): void {
    this.injector.get(ManageUsersService).FindPipelinesfortask().subscribe((result) => {
      if (result) {
        this.listofPipelines = result;
        this.listofPipelines$ = result;
      }
    });
  }
  
  getAllinitiatives(): void {
    this.injector.get(ManageUsersService).FindAllInitiatives().subscribe((result) => {
      if (result) {
        this.listofinitiatives = result;
        this.listofinitiatives$ = result;
      }
    });
  }
  getAllisPredecessor(){
    this.injector.get(ManageUsersService).FindAllTasksByisPredecess(true,false).subscribe((result) => {
      if (result) {
        this.listofisPredecessor = result;
        this.listofisPredecessor$ = result;
      }
    });
  }
  getAllisSuccessor(){
    this.injector.get(ManageUsersService).FindAllTasksByisSuccessor(false,true).subscribe((result) => {
      if (result) {
        this.listofisSuccessor = result;
        this.listofisSuccessor$ = result;
      }
    });
  }
  
  getIsPublicstatus(){
    return this.form.get('IsPublic')?.value
  }
  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  } 
  getColor(index:any) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 
 
  onSelectionChange(event: MatSelectChange) {
    this.injector.get(ManageUsersService).FindExistPipelineSteps(event?.value).subscribe((result) => {
      if (result) {
        this.listofSteps = result
        // this.form?.value?.AddSteps.length=0
        this.getSteps().clear();
        this.AddSteps(this.listofSteps.length)
        this.listofSteps?.forEach((element:any, index:any) => {
          this.getSteps().at(index).get('name')?.setValue(element.stepName ? element.stepName : element.name)
          this.getSteps().at(index).get('Step')?.setValue(element.step)
          this.getSteps().at(index).get('Progress')?.setValue(element.progress)

          element?.subtaskDtos?.forEach((x:any , i:number) => {
            this.addtaskforstep(index)
            this.injector.get(ManageUsersService).FindTaskById(x.id).subscribe((result: any) => {
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Title')?.setValue(result?.title)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('id')?.setValue(result?.taskId)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Description')?.setValue(result?.description)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('StartDate')?.setValue(result?.startDate)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('EndDate')?.setValue(result?.endDate) 
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Hours')?.setValue(result?.hours)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Minutes')?.setValue(result?.minutes.toString())
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('CriticityId')?.setValue(result?.criticityId)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('SiteId')?.setValue(result?.sitedetaildto?.id)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Isparent')?.setValue(result?.isParent)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Address')?.setValue(result?.address)
              const zipCodeValue = result?.zipCode ? result?.zipCode.toString() : null;
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Zipcode')?.setValue(zipCodeValue)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('City')?.setValue(result?.city)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Country')?.setValue(result?.country)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('HasStep')?.setValue(result?.hasStep)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsMileStone')?.setValue(result?.IsMileStone?result?.IsMileStone:result?.isMileStone)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('initiatives')?.setValue(result?.initiativeId)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Addtages')?.setValue(result?.tagViewModels)
              if(result?.isPublic==false){
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsPublic')?.setValue(result?.isPublic)
                let data: any[]=[]
                result?.permittedUsers?.forEach((element:any) => {
                  data.push(element?.id)
                });
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('PermittedUserIds')?.setValue(data)
              }
              if(result?.successorTaskcount!=0){ this.getlinkedsuccessor(result?.id)}
              if(result?.predecessorTaskcount!=0){this.getlinkedpredecessor(result?.id)}

              if (result?.assignedTo && result?.assignedTo.length > 0) {
                const userIds = result?.assignedTo.map((item:any) => item.id);
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('UserId')?.setValue(userIds)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Stage')?.setValue(1)
              }else{
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Stage')?.setValue(2)
              }
              if (result?.contactdtls && result?.contactdtls.length > 0) {
                const contactIds = result?.contactdtls.map((item:any) => item.id);
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('ContactId')?.setValue(contactIds)
              }
              if (result?.keywords && result?.keywords.length > 0) {
                this.keywordlist = [];
                result?.keywords?.forEach((keyword:any) => {
                  this.keywordlist.push(keyword);
                });
              }
              //  steps true 
              if(result?.HasStep==true){
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('pipelineID')?.setValue(result?.pipelineId)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Pipelinename')?.setValue(result?.pipelineName)
                if(result?.pipelineId!=0&&result?.pipelineId!=null){
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsSavePipeline')?.setValue(false)
                }else if(result?.pipelineName!=null){
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsSavePipeline')?.setValue(false)
                }
              }else{
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsSavePipeline')?.setValue(false)
              }
              // 00000000 
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('ListofSuccessor')?.setValue(this.predecessorlist)
              this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('ListofPredecessor')?.setValue(this.successorlist)
            });
          })
        });
      }
    })
  }


  getPipeLinetype() {
    return this.form?.value.PipeLinetype
  }


// tag's section 

  Conditions(): FormArray {
    return this.form.get('Conditions') as FormArray;
  }



  createtageBody(): FormGroup {
    return this.formBuilder.group({
      tagTableId: 0,
      fieldName: '',
      colorCode:'',
      fieldType: '',
      fieldValue: '',
      dropdownValues: '',
      dropdownvaluetotal: '',
      TimePeriod :'',
      Value:'',
      files:this.formBuilder.array([])
    });
  }
  
  addfile(): FormGroup {
    return this.formBuilder.group({
      filename: '',
      sfile: '',
    });
  }

      // handleFileInput 
      handleFileInput(event:any,i,g) {
        const file = event.target.files[0];
        const fileToUploadname = event.target.files[0].name;
        this.gettagfiles(i).at(g).get('filename')?.setValue(fileToUploadname)
        this.gettagfiles(i).at(g).get('sfile')?.setValue(file)
      }
      tagcreatorId(i: number) {
        return this.gettageBody().at(i).get('creatorId')?.value;
      }
  addfilefortag(index){
    this.gettagfiles(index).push(this.addfile());
  }
  gettagfiles(index){
    return this.gettageBody().at(index).get('files') as FormArray
    }
    getfilename(i,k){
      this.gettagfiles(i).at(k).get('filename')
    }
    gettagfilename(i,g){
     return this.gettagfiles(i).at(g).get('filename')?.value
    }
  
    deletetagfile(i,g){
      this.gettagfiles(i).removeAt(g)
    }
  gettageBody() {
    return this.form.get('Addtages') as FormArray;
  }
  addtageBody() {
    this.gettageBody().push(this.createtageBody());
  }
  fieldname(i: number) {
    return this.gettageBody().at(i).get('fieldName')?.value;
  }
  colorCode(i: number) {
    return this.gettageBody().at(i).get('colorCode')?.value
  }

  dropdownvaluetotal(i: number) {
    return this.gettageBody().at(i).get('dropdownvaluetotal')?.value.split(',');
  }

  fieldType(i: number) {
    return this.gettageBody().at(i).get('fieldType')?.value;
  }
  tagTableId(i: number) {
    return this.gettageBody().at(i).get('tagTableId')?.value;
  }
  removeConditionalBody(i: number) {
    this.gettageBody().removeAt(i);
  }

// ------------ 



  deletetagDialog(i:any){
    const loginuserId=sessionStorage.getItem("id");
    if(this.tagcreatorId(i)==null){
    this.gettageBody().removeAt(i)
    }else if(this.tagcreatorId(i)==loginuserId){
      const tagid=this.tagTableId(i)
      this.ChooseTaskOption(tagid,i)
    }else{
      this.gettageBody().removeAt(i)
    }
  }




  ChooseTaskOption(id:any,i:any){
    const ChooseTaskOption = this.dialog.open(ConformationComponent, {
      width: '400px',
      autoFocus: false,
      data: { type:'tagdeleteconformation'},
      disableClose: true
    });
    ChooseTaskOption.afterClosed().subscribe((result: any) => {
      console.log(result)
      if(result){
        if (result=='2') {
          this.gettageBody().removeAt(i)
        this.injector.get(ManageContactsService).deletetag(id).subscribe((result) => {
      }), ((error:any) => {
      })
        }else if (result=='1'){
          this.gettageBody().removeAt(i)
        }
      }
    })
  }




  showdropdown() {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows you to add more parameter',
        'Add Tag'
      );
    } else {
      const openAddEventDialog = this.dialog.open(AddtagCommonComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: 'Tasks',
      });
      openAddEventDialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.gettageBody().clear();
          this.gettags();
        }
      });
    }
  } 
  
  gettags() {
    this.injector.get(ManageUsersService).gettag('Tasks').subscribe((data: any) => {
      if (data) {
        data?.forEach((element:any, index:any) => {
          this.addtageBody();
          this.gettageBody().at(index).get('tagTableId')?.setValue(element?.tagTableId);
          this.gettageBody().at(index).get('creatorId')?.setValue(element?.creatorId);
          this.gettageBody().at(index).get('fieldName')?.setValue(element?.fieldName);
          this.gettageBody().at(index).get('colorCode')?.setValue(element?.colorCode?element?.colorCode:'#fb4444');
          this.gettageBody().at(index).get('fieldType')?.setValue(element?.fieldType);
          this.gettageBody().at(index).get('fieldValue')?.setValue(element?.fieldValue);
          this.gettageBody().at(index).get('dropdownvaluetotal')?.setValue(element?.dropdownValues);
        });
      }
      this.tags = data;
    });
  }
  gettotalprogressvalue():number {
    var procressvalue=0
    this.getSteps().value?.forEach(element => {
      procressvalue=procressvalue+element.Progress
    });
    return procressvalue
  }

  getAllcountryList() {
    this.injector.get(CalendarService).getAllcountryList().subscribe((res: any) => {
      if (res) {
        this.listofcountry = res;
        this.listofcountry$ = this.listofcountry;
      }
    });
  }


  filterCountry(event:any) {
    const filterValue = event.toLowerCase();
    this.listofcountry$ = this.listofcountry.filter((option:any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }


  selactcountry(value:any) {
    this.options = {
      types: ['address'],
      componentRestrictions: { country: value },
    };
    setTimeout(() => {
      this.placesRef.reset();
    }, 100);
  }
  Applytask() {
    if(this.data?.Entity == 'Automation' || this.data?.screenType == 'EditTaskAutomation'){
      this.onSubmitAutomation()
    } else {
      if (sessionStorage.getItem('DemoOrganation') === 'true') {
        this.popupforerrormessage(
          'This button allows you to add new Task',
          'Create Task'
        );
      } else {
        if (this.form.get('CategoryId')?.value == 'OffTime') {
          window.alert('Off-Time');
        } else {
          if(this.form.valid){
            if(this.form?.value.HasStep==true){
              const totalProgress = this.getTotalProgress();
              console.log('totalProgress',totalProgress)
              if (totalProgress > 100) {
                this.snackBar.open('The total step progress exceeds 100%. Please adjust the values to reduce the excess.', '', {
                  duration: 5000,
                });
                  return;
              } else if (totalProgress < 100) {
                this.snackBar.open('The total step progress is less than 100%. Please adjust the progress Values.', '', {
                  duration: 5000,
                });
                  return;
              }
              if(this.form?.value.AddSteps.length<2){
                if(this.form?.value.AddSteps[this.form?.value.AddSteps.length - 1].Progress==100){
                  this.injector.get(MessageService).showMessage('Could you kindly provide the necessary steps?');
                this.onSubmit();
                return;
                }
              }
              this.onSubmit();
            }else{
              this.onSubmit();
            }
          }else{
            this.injector.get(MessageService).showMessage('Please complete the required fields.');
          }
        }
      }
    }
   
  }
  onRadioChange(event: any) {
    this.form.get('pipelineID')?.setValue(null)
    this.form.get('Pipelinename')?.setValue('')
    this.getSteps().clear()

  } 

  // -----------
  onSubmit() {
    this.form?.value.AddSteps?.forEach((element:any, index:number) => {
      element.Step=element.Step?element.Step:index+1
    });
    this.form.get('keyword')?.setValue(this.keywordlist);
    const formData = new FormData();
    if (this.form?.value.UserId) {
      this.form.get('Stage')?.setValue(1)
      formData.append('Stage', '1');
    } else {
      formData.append('Stage', '2');
      this.form.get('Stage')?.setValue(2)
    }
    formData.append('Title', this.form.get('Title')?.value);
    formData.append('Code', this.form.get('UniqueCode')?.value);
    formData.append('IsPublic', this.form?.value.IsPublic);
    formData.append('NeedAllApproval', this.form?.value.NeedAllApproval);
    if (this.screenType == 'Create-subTask'||this.screenType=='addsubtask') { 
      formData.append('ParentId', this.form.get('ParentId')?.value);
    }
    const startDate: any =
    this.datepipe.transform(this.form.value.StartDate, 'yyyy-MM-dd')
    formData.append('StartDate', startDate); 
    const endDate: any =
    this.datepipe.transform(this.form.value.EndDate, 'yyyy-MM-dd') 
    formData.append('EndDate', endDate);
    formData.append('IsPredecessortask', this.form.get('IsPredecessortask')?.value?this.form.get('IsPredecessortask')?.value:'');
    formData.append('IsSuccessortask', this.form.get('IsSuccessortask')?.value?this.form.get('IsSuccessortask')?.value:'');
    formData.append('initiatives', this.form.get('initiatives')?.value ? this.form.get('initiatives')?.value: '');
    formData.append('ListofSuccessor', this.form.get('ListofSuccessor')?.value?this.form.get('ListofSuccessor')?.value:'');
    formData.append('ListofPredecessor', this.form.get('ListofPredecessor')?.value?this.form.get('ListofPredecessor')?.value:'');
    formData.append('Address', this.form.get('Address')?.value );
    formData.append('ZipCode', this.form.get('ZipCode')?.value );
    formData.append('City', this.form.get('City')?.value);
    formData.append('Country', this.form.get('Country')?.value);
    if (this.form?.value.Minutes != null) {
      formData.append('Minutes', this.form.get('Minutes')?.value);
    } if (this.form?.value.Hours != null) {
      formData.append('Hours', this.form.get('Hours')?.value);
    }
    formData.append(
      'latitude',
      this.form.get('latitude')?.value == null || undefined
        ? 0
        : this.form.get('latitude')?.value
    );
    formData.append(
      'longitude',
      this.form.get('longitude')?.value == null || undefined
        ? 0
        : this.form.get('longitude')?.value
    );
    formData.append('Description', this.form.get('Description')?.value);
    formData.append('CriticityId', this.form.get('CriticityId')?.value);
    formData.append('IsTemplate', this.form.get('IsTemplate')?.value);
    formData.append('keyword', this.form.get('keyword')?.value);
    formData.append('HasStep', this.form.get('HasStep')?.value);

    // HasStep 
    if(this.form?.value.pipelineID) {
      formData.append('pipelineID', this.form.get('pipelineID')?.value);
    }
    if (this.form.get('HasStep')?.value == true) {
      formData.append('Pipelinename', this.form.get('Pipelinename')?.value);
      if (this.form.get('PipeLinetype')?.value == '1') {
        // Set isSavePipeline to true
        this.form.get('IsSavePipeline')?.setValue(true)
        formData.append('IsSavePipeline', 'true');
      } else {
        // Set isSavePipeline to fasle
        this.form.get('IsSavePipeline')?.setValue(false)
        formData.append('IsSavePipeline', 'false');
      }
      //  steps 
      if (this.form?.value.AddSteps.length != 0) {
        this.form?.value.AddSteps?.forEach((element:any, index:any) => {
          formData.append('StepsViewModel[' + index + '].Step', element.Step?element.Step:index+1);
          formData.append('StepsViewModel[' + index + '].name', element.name);
          formData.append('StepsViewModel[' + index + '].Progress', element.Progress);

          if (element.taskmodule.length !== 0) {
            element.taskmodule?.forEach((elementA: any, index2: any) => {
                formData.append(`StepsViewModel[${index}].TaskLink[${index2}].OrderNumber`, index2 + 1);
                
                // Loop through each key-value pair in elementA
                Object.entries(elementA)?.forEach(([key, value]: any) => {
                    // Handle StartDate and EndDate separately
                    if (key === 'StartDate' || key === 'EndDate') {
                        if (value) {
                            const formattedDate: any = this.datepipe.transform(value, 'yyyy-MM-dd');
                            if (formattedDate) {
                                formData.append(`StepsViewModel[${index}].TaskLink[${index2}].TaskModel.${key}`, formattedDate);
                            }
                        }
                    } 
                    else if (key === 'UserId' && Array.isArray(value)) {
                        value?.forEach((userId: any, userIndex: number) => {
                            if (userId) { 
                                formData.append(`StepsViewModel[${index}].TaskLink[${index2}].TaskModel.UserId[${userIndex}]`, userId);
                            }
                        });
                    }
                    else if (key === 'ContactId' && Array.isArray(value)) {
                      value?.forEach((contactId: any, contactIndex: number) => {
                          if (contactId) { 
                              formData.append(`StepsViewModel[${index}].TaskLink[${index2}].TaskModel.ContactId[${contactIndex}]`, contactId);
                          }
                      });
                  }
                    else if (Array.isArray(value)) {
                        if (value.length > 0) {
                            formData.append(`StepsViewModel[${index}].TaskLink[${index2}].TaskModel.${key}`, JSON.stringify(value));
                        }
                    }
                 
                    else {
                        if (value !== null && value !== undefined && value !== '') {
                            formData.append(`StepsViewModel[${index}].TaskLink[${index2}].TaskModel.${key}`, value);
                        }
                    }
                });
            });
        }
        
        });
      }
   
    }
   // subtaskmodule subtaskmodule
   if(this.form?.value.subtaskmodule.length!=0){
    formData.append('IsSubTask', 'true');
    this.form?.value.subtaskmodule?.forEach((elementA: any, index2: any) => {
        console.log(elementA)
        // Loop through each key-value pair in elementA
        Object.entries(elementA)?.forEach(([key, value]: any) => {
            // Handle StartDate and EndDate separately
            if (key === 'StartDate' || key === 'EndDate') {
                if (value) {
                    const formattedDate: any = this.datepipe.transform(value, 'yyyy-MM-dd');
                    if (formattedDate) {
                        formData.append(`SubTaskViewModels[${index2}].${key}`, formattedDate);
                    }
                }
            } 
            else if (key === 'UserId' && Array.isArray(value)) {
                value?.forEach((userId: any, userIndex: number) => {
                    if (userId) { 
                        formData.append(`SubTaskViewModels[${index2}].UserId[${userIndex}]`, userId);
                    }
                });
            }
            else if (key === 'ContactId' && Array.isArray(value)) {
              value?.forEach((contactId: any, contactIndex: number) => {
                  if (contactId) { 
                      formData.append(`SubTaskViewModels[${index2}].ContactId[${contactIndex}]`, contactId);
                  }
              });
          }
            else if (Array.isArray(value)) {
                if (value.length > 0) {
                    formData.append(`SubTaskViewModels[${index2}].${key}`, JSON.stringify(value));
                }
            }
         
            else {
                if (value !== null && value !== undefined && value !== '') {
                    formData.append(`SubTaskViewModels[${index2}].${key}`, value);
                }
            }
        });
    });
   }else{
     formData.append('IsSubTask', 'false');
     }
    //  ------- 
    formData.append('NumberOfUsers','2');
    if (
      this.form.get('CategoryId')?.value == 0 ||
      this.form.get('CategoryId')?.value == null
    ) {
      formData.append('CategoryViewModel.Color', this.viewmodel['color'] || '');
      formData.append('CategoryViewModel.CreatorId',  '');
      formData.append(
        'CategoryViewModel.CategoryName',
        this.viewmodel['categoryName'] || ''
      );
    }
    if (this?.value == 1) {
      for (var x = 0; x < this.selectedDays.length; x++) {
        formData.append('NamesOfDay[' + x + ']', this.selectedDays[x]);
      }
    }
    if(this.form.get('SiteId')?.value){
      formData.append('SiteId', this.form.get('SiteId')?.value);
    }
    if(this.form.get('CategoryId')?.value){
      formData.append('CategoryId', this.form.get('CategoryId')?.value || 0);
    }

    if(this.form.get('UserId')?.value){
      for (var x = 0; x < this.form.get('UserId')?.value.length; x++) {
        formData.append('UserId[' + x + ']', this.form.get('UserId')?.value[x]);
      }
    }
    if(this.form.get('ProductsId')?.value){
      for (var x = 0; x < this.form.get('ProductsId')?.value.length; x++) {
        formData.append('ProductId[' + x + ']', this.form.get('ProductsId')?.value[x]);
      }
    }
    if(this.form.get('ordersId')?.value){
      for (var x = 0; x < this.form.get('ordersId')?.value.length; x++) {
        formData.append('OrderQuoteId[' + x + ']', this.form.get('ordersId')?.value[x]);
      }
    }

    // ApproverViewModels 
    if(this.form.get('Approverids')?.value){
      for (var x = 0; x < this.form.get('Approverids')?.value.length; x++) {
        formData.append('ApproverViewModels[' + x + '].userId', this.form.get('Approverids')?.value[x]);
      }
    }

    // PermittedUser 
      if(this.form.get('PermittedUserIds')?.value){
        for (var x = 0; x < this.form.get('PermittedUserIds')?.value.length; x++) {
          formData.append('PermittedUserIds[' + x + ']', this.form.get('PermittedUserIds')?.value[x]);
        }
      }
 
    if(this.form.get('ContactId')?.value){
      for (var x = 0; x < this.form.get('ContactId')?.value.length; x++) {
        formData.append(
          'ContactId[' + x + ']',
          this.form.get('ContactId')?.value[x]
        );
      }
    }
    if(this.form.get('UserGroupsId')?.value){
      for (var x = 0; x < this.form.get('UserGroupsId')?.value.length; x++) {
        formData.append(
          'UserGroupsId[' + x + ']',
          this.form.get('UserGroupsId')?.value[x]
        );
      }
    }
    if(this.form.get('ContactGroupsId')?.value){
      for (var x = 0; x < this.form.get('ContactGroupsId')?.value.length; x++) {
        formData.append('ContactGroupsId[' + x + ']', this.form.get('ContactGroupsId')?.value[x]);
      }
    }
    if(this.screenType=='Createtask_with_mail'){
      formData.append('IsCreatedFromMail', 'true');
      formData.append('IsGmail', this.data?.data?.isGmail);
      formData.append('AccountId', this.data?.data.accountId);
      formData.append('MailMessageId', this.data?.data?.id);

    }

    // Addtages 
    let combineFormData = formData
    if (this.form?.value.Addtages.length != 0) {
    const prepareFormData1=this.injector.get(FormDataService).prepareFormData(this.form.value.Addtages )
     combineFormData=this.injector.get(FormDataService).combineFormData(prepareFormData1,combineFormData)
    }

    // for Reminder
    if(this.ReminderList.length!=0){
      const prepareFormData2=this.injector.get(FormDataService).prepareFormData(this.ReminderList )
      combineFormData=this.injector.get(FormDataService).combineFormData(prepareFormData2,combineFormData)
    }
    
    if (this.screenType == 'Create-task-initiatives' || this.screenType == 'edit-task-initiatives'||
     this.screenType == 'addsubtask'||this.screenType == 'View-subtask-initiatives'||this.screenType == 'edit-subtask-initiatives' || this.screenType == 'SubTaskForStep') {
      this.matDialogRef.close(this.form?.value);
    } else {
      if (this.form.get('id')?.value) {
        combineFormData.append('id', this.form.get('id')?.value);
        this.injector.get(ManageUsersService).UpdateTaskById(combineFormData).subscribe((res: any) => {
          if (res.response[0].code == '200'||res.response[0].code == 200) {
            this.injector.get(MessageService).showMessage(res.response[0].message);
           if(this.ReminderList.id){
              this.Reminderapicall(this.taskiddata) 
            }
            this.matDialogRef.close(true);
          } else {
            this.injector.get(MessageService).showMessage(res.response[0].message);

          }
        }, error => {
          console.log(error)
          if (error.status == 429) {
            this.APIerrormessage(error?.error, 'Update Tasks')
          }
        })
      } else {

        this.injector.get(CalendarService).createTask(combineFormData).subscribe((res: any) => {
          if (res?.code =='200'||res?.code ==200) {
            this.injector.get(MessageService).showMessage(res.message);
            if(this.ReminderList.length!=0){
              this.Reminderapicall(res) 
            }
             this.onCreateFile(res.taskId);
          } else {
            this.injector.get(MessageService).showMessage(res.message);
             this.onCreateFile(res.taskId);
          }
        }, error => {
          console.log(error)
          if (error.status == 429) {
            this.APIerrormessage(error?.error, 'Create Tasks')
          }
        });
      }
    }

  }

  toggleReminder(index: number) {
    this.showReminderUsers[index] = !this.showReminderUsers[index];
  }

  removereminder(index: number) {
    // Logic for removing a reminder
    this.ReminderList.splice(index, 1);
  }


Reminderapicall(res){
  const taskId = Number(res.taskId?res.taskId:res.id)
  const remindersPayload = {
    taskId: taskId,
    reminders: this.ReminderList.map((element: any) => ({
      reminderType: element.triggertype == 'Notification' ? 0 : element.triggertype == 'Emails' ? 1 : 2,
      conditionParameter: element.Parameter,
      isAfter: element.triggerMoment == 'After' ? true : false,
      value: element.value,
      period: element.durationtype == 'Minutes' ? 0 : element.durationtype == 'Hours' ? 1 : 2,
      reminderUsersIds: element.UserId
    }))
  };
  
  if(res) {
    if(this.data.data.id){
      this.calendarService.updateReminders(remindersPayload).subscribe((reminderRes: any) => {
        console.log('Update Reminders successfully:', reminderRes);
      }, error => {
        console.error('Error Update reminders:', error);
      });
    }else{
      this.calendarService.createReminders(remindersPayload).subscribe((reminderRes: any) => {
        console.log('Reminders created successfully:', reminderRes);
      }, error => {
        console.error('Error creating reminders:', error);
      });
    }
  }
} 


  getlinkedpredecessor(id:any){
    this.injector.get(ManageUsersService).GetLinkedTasks(id,false,true).subscribe((result)=>{
      if(result){
        this.predecessorlist=result.map((ele:any)=>{
          return ele.id
        })
      }
    })
}


getlinkedsuccessor(id:any){
    this.injector.get(ManageUsersService).GetLinkedTasks(id,true,false).subscribe((result)=>{
      if(result){
        this.successorlist=result.map((ele:any)=>{
          return ele.id
        })
      }
    })
  }
  

  getOneTask() { 
    console.log(this.taskiddata)
    this.form.get('id')?.setValue(this.taskiddata?.id)
    this.form.get('StartDate')?.setValue(this.taskiddata?.startDate ? this.taskiddata?.startDate : this.taskiddata?.StartDate)
    this.form.get('EndDate')?.setValue(this.taskiddata?.endDate ? this.taskiddata?.endDate : this.taskiddata?.EndDate)
    this.form.get('Title')?.setValue(this.taskiddata?.title ? this.taskiddata?.title : this.taskiddata?.Title)
    this.form.get('Address')?.setValue(this.taskiddata?.address ? this.taskiddata?.address : this.taskiddata?.Address)
    this.form.get('City')?.setValue(this.taskiddata?.city ? this.taskiddata?.city : this.taskiddata?.City)
    this.form.get('Country')?.setValue(this.taskiddata?.country ? this.taskiddata?.country : this.taskiddata?.Country)
    this.form.get('ZipCode')?.setValue(this.taskiddata?.zipCode ? this.taskiddata?.zipCode : this.taskiddata?.ZipCode)
    this.form.get('Description')?.setValue(this.taskiddata?.description ? this.taskiddata?.description : this.taskiddata?.Description)
    this.form.get('CriticityId')?.setValue(this.taskiddata?.criticityId ? this.taskiddata?.criticityId : this.taskiddata?.CriticityId)
    this.form.get('CategoryId')?.setValue(this.taskiddata?.categoryId ? this.taskiddata?.categoryId : this.taskiddata?.CategoryId)
    this.form.get('HasStep')?.setValue(this.taskiddata?.HasStep ? this.taskiddata?.hasStep : this.taskiddata?.hasStep)
    if(this.taskiddata?.successorTaskcount!=0){ this.getlinkedsuccessor(this.taskiddata?.id)}
    if(this.taskiddata?.predecessorTaskcount!=0){this.getlinkedpredecessor(this.taskiddata?.id)}
    if (this.screenType == 'edit-task-initiatives' || this.screenType == 'View-task-initiatives') {
      this.form.get('SiteId')?.setValue(this.taskiddata?.siteId ? this.taskiddata?.siteId : this.taskiddata?.SiteId)
        this.form.get('UserId')?.setValue(this.taskiddata?.UserId);
      this.form.get('ContactId')?.setValue(this.taskiddata?.ContactId);
      this.form.get('ContactGroupsId')?.setValue(this.taskiddata?.ContactGroupsId);
      this.form.get('UserGroupsId')?.setValue(this.taskiddata?.UserGroupsId);
      this.form.get('Minutes')?.setValue(this.taskiddata?.Minutes)
      this.form.get('Hours')?.setValue(this.taskiddata?.Hours)
      if (this.taskiddata?.keyword && this.taskiddata?.keyword.length > 0) {
        this.keywordlist = [];
        this.taskiddata?.keyword?.forEach((keyword:any) => {
          this.keywordlist.push(keyword);
        }); 
      }
    } else {
      this.form.get('SiteId')?.setValue(this.taskiddata?.siteId)
      if (this.taskiddata) {
        this.form.get('Minutes')?.setValue(this.taskiddata?.minutes?.toString())
        this.form.get('Hours')?.setValue(this.taskiddata?.hours?.toString())
      }

      if (this.taskiddata?.usersCount !== 0) {
        this.form.get('UserId')?.setValue(this.AssignedTaskUsers);
      }
      if (this.taskiddata?.contactsCount !== 0) {
        this.form.get('ContactId')?.setValue(this.AssignedTaskContacts);
      }

      if (this.taskiddata?.approversCount !== 0) {
        this.form.get('Approverids')?.setValue(this.AssignedTaskApprovers);
      }

      if (this.taskiddata?.productsCount !== 0) {
        const assignedProducts = Array.isArray(this.AssignedTaskProducts)
        ? this.AssignedTaskProducts
        : [this.AssignedTaskProducts].filter(item => item != null); // Wrap single value in array if needed
      this.form.get('ProductsId')?.setValue(assignedProducts);
      }

      if (this.taskiddata?.ordersCount !== 0) {
        const assignedOrders = Array.isArray(this.AssignedTaskOrders)
        ? this.AssignedTaskOrders
        : [this.AssignedTaskOrders].filter(item => item != null); // Wrap single value in array if needed
      this.form.get('ordersId')?.setValue(assignedOrders);
      }
      if (this.taskiddata?.keywords && this.taskiddata?.keywords.length > 0) {
        this.keywordlist = [];
        this.taskiddata?.keywords?.forEach((keyword:any) => {
          this.keywordlist.push(keyword);
        });
      }
    }
    if(this.taskiddata?.initiativeCount!=0){
      this.form.get('initiatives')?.setValue(this.taskiddata?.initiativeId?.split(','))
    }
    if(this.taskiddata?.isPublic==true){
      this.form.get('IsPublic')?.setValue(this.taskiddata?.isPublic)
    }
    if(this.taskiddata?.isPublic==false){
      this.form.get('IsPublic')?.setValue(this.taskiddata?.isPublic)
      let data:any=[]
      this.taskiddata?.permittedUsers?.forEach((element:any) => {
        data.push(element.id)
      });
    this.form.get('PermittedUserIds')?.setValue(data)
    }
    this.form.get('ListofSuccessor')?.setValue(this.predecessorlist)
    this.form.get('ListofPredecessor')?.setValue(this.successorlist)
    if(this.taskiddata?.stepsCount > 0 && this.taskiddata?.hasStep === true){
      console.log('Pipeline ID:', this.taskiddata?.pipelineId);
      this.form.get('pipelineID')?.setValue(this.taskiddata?.pipelineId)
      this.injector.get(ManageUsersService).FindExistPipelineSteps(this.taskiddata?.pipelineId).subscribe((res:any) => {
        this.FindTaskStep = res;
        console.log("findStep", this.FindTaskStep)
        this.AddSteps( this.FindTaskStep.length);
        this.FindTaskStep?.forEach((element:any , index:number) => {
          this.getSteps().at(index).get('name')?.setValue(element.name ? element.name : element.stepName)
          this.getSteps().at(index).get('Step')?.setValue(element.step)
          this.getSteps().at(index).get('Progress')?.setValue(element.progress)
         

            element?.subtaskDtos?.forEach((x:any , i:number) => {
              this.addtaskforstep(index)
              this.injector.get(ManageUsersService).FindTaskById(x.id).subscribe((result: any) => {
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Title')?.setValue(result?.title)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('id')?.setValue(result?.taskId)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Description')?.setValue(result?.description)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('StartDate')?.setValue(result?.startDate)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('EndDate')?.setValue(result?.endDate) 
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Hours')?.setValue(result?.hours)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Minutes')?.setValue(result?.minutes.toString())
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('CriticityId')?.setValue(result?.criticityId)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('SiteId')?.setValue(result?.sitedetaildto?.id)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Isparent')?.setValue(result?.isParent)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Address')?.setValue(result?.address)
                const zipCodeValue = result?.zipCode ? result?.zipCode.toString() : null;
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Zipcode')?.setValue(zipCodeValue)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('City')?.setValue(result?.city)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Country')?.setValue(result?.country)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('HasStep')?.setValue(result?.hasStep)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsMileStone')?.setValue(result?.IsMileStone?result?.IsMileStone:result?.isMileStone)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('initiatives')?.setValue(result?.initiativeId)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Addtages')?.setValue(result?.tagViewModels)
                if(result?.isPublic==false){
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsPublic')?.setValue(result?.isPublic)
                  let data: any[]=[]
                  result?.permittedUsers?.forEach((element:any) => {
                    data.push(element?.id)
                  });
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('PermittedUserIds')?.setValue(data)
                }
                if(result?.successorTaskcount!=0){ this.getlinkedsuccessor(result?.id)}
                if(result?.predecessorTaskcount!=0){this.getlinkedpredecessor(result?.id)}
  
                if (result?.assignedTo && result?.assignedTo.length > 0) {
                  const userIds = result?.assignedTo.map((item:any) => item.id);
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('UserId')?.setValue(userIds)
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Stage')?.setValue(1)
                }else{
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Stage')?.setValue(2)
                }
                if (result?.contactdtls && result?.contactdtls.length > 0) {
                  const contactIds = result?.contactdtls.map((item:any) => item.id);
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('ContactId')?.setValue(contactIds)
                }
                if (result?.keywords && result?.keywords.length > 0) {
                  this.keywordlist = [];
                  result?.keywords?.forEach((keyword:any) => {
                    this.keywordlist.push(keyword);
                  });
                }
                //  steps true 
                if(result?.HasStep==true){
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('pipelineID')?.setValue(result?.pipelineId)
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Pipelinename')?.setValue(result?.pipelineName)
                  if(result?.pipelineId!=0&&result?.pipelineId!=null){
                    this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsSavePipeline')?.setValue(false)
                  }else if(result?.pipelineName!=null){
                    this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsSavePipeline')?.setValue(false)
                  }
                }else{
                  this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsSavePipeline')?.setValue(false)
                }
                // 00000000 
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('ListofSuccessor')?.setValue(this.predecessorlist)
                this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('ListofPredecessor')?.setValue(this.successorlist)
              });
            })

        })
      })
      
    }

  }

  updateGroup(id:any) {
    console.log(id);
    let selectedRes :any= [];
    let usegrps = [];
    // _.forEach(id, (val) => {
    //   console.log(id, val);
    //   selectedRes.push(val.id);
    // });
    this.form.get('UserGroupsId')?.setValue(selectedRes);
  }

  radioChange(eve:any) {
    this.showPeriodDate = true;
    this.form.get('IsPeriodicity')?.setValue(true);
  }

  clear() {
    this.form.get('PeriodicityId')?.setValue(false);
  }
  changeTradesByCategory(event:any, id:any) {
    let a = event.target;
    console.log(event.target);
    if (event.target.checked === false) {
      this.trade?.forEach((x:any) => {
        if (x.id === id) {
          x.selected = false;
        }
      });
    } else {
      this.trade?.forEach((x:any) => {
        if (x.id === id) {
          x.selected = true;
        }
      });
    }
    let b;
    let array1 :any= [];
    b = this.trade.filter((item:any) => item.selected === true);
    console.log('tradevlaueb' + b);
    console.log(b);
    b.filter((item:any) => {
      array1.push(item.label);
    });
    console.log(array1);
    this.selectedDays = array1;
  }

  onEventLog(event: string, data: any): void {
    this.viewmodel['id'] = 0;
    this.viewmodel['color'] = data;
    this.viewmodel['categoryName'] = this.form.get('categoryname')?.value;
  }

  updatecategory(v:any) {
    console.log(v);
    this.categoryname = v.categoryName;
    this.color = v.color;
    this.creatorId = v.creatorId;
  }

  createcategory() {
    const categoryFilterDialog = this.dialog.open(DialogOverviewExampleDialog3, {
      width: '500px'
    });

    categoryFilterDialog.afterClosed().subscribe(result => {
      this.getallCategory()
    });
 
  } 

  closecategory() {
    this.category = false;
    this.showcreate = 0;
    this.viewmodel['id'] = '';
    this.viewmodel['color'] = '';
    this.viewmodel['categoryName'] = '';
  }

  removecategory() {
    this.form.get('CategoryId')?.setValue(0);
  }

  importdata() {
    const openAddEventDialog = this.dialog.open(ManageimportComponent, {
      width: '600px',
      autoFocus: false,
      disableClose: true,
      data: 'Events',
    });
  }

  downloadTemplate() {
    const Name = 'Events';
    this.injector.get(ManageContactsService).downloadTemplate(Name).subscribe((result: Blob) => {
      const data: Blob = new Blob([result], {
        type: 'text/xlsx;charset=utf-8',
      });
      saveAs(data, 'Events.xlsx');
    });
  }

  // ------------
  onCreateFile(taskId:any) {
    this.injector.get(ManageUsersService).FindTaskById(taskId).subscribe((res) => {
      if (res) {
        const addFileNotesDialog = this.dialog.open(AddFileNotesComponent, {
          data: {
            data: res,
            attachmentLevel: 'Tasks',
            ismanage: true,
          },
          disableClose: false,
          width: '500px',
          panelClass: ['files'],
        });
        addFileNotesDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.ngOnInit();
            this.matDialogRef.close(true);
          }
        });
      }
    });
  }

  

  // ------------
  updateAddress(eve:any) {
    if (eve?.value == undefined) {
      this.googleAutoinputScreen = true;
      this.NormalAddressScreen = false;
      this.form.get('Address')?.setValue(null);
      this.form.get('ZipCode')?.setValue(null);
      this.form.get('City')?.setValue(null);
      this.form.get('Country')?.setValue(null);
    } else {
      this.googleAutoinputScreen = false;
      this.NormalAddressScreen = true;
      this.injector.get(ManageSitesService).fetchSiteByIdService(eve?.value).subscribe((res) => {
        if (res) {
          this.form.get('Address')?.setValue(res.address);
          this.form.get('ZipCode')?.setValue(res.zipCode);
          this.form.get('City')?.setValue(res.city);
          this.form.get('Country')?.setValue(res.country);
        }
      });
    }
  }

  // ----------------
  // auto chip for keyword
  // -----------
  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      this.keywordlist.push(value);
    }
    event.input.value = ''; 
    this.form.get('keyword')?.setValue(null);

  }


  remove(value: string) {
    const index = this.keywordlist.indexOf(value);
    if (index >= 0) {
      this.keywordlist.splice(index, 1);
    }
  } 



  selacttagType(): void {
    this.form.get('tagType')?.valueChanges.subscribe((val) => {
      console.log(val);
      this.selectedradioval = val;
    });
  }
  addAdditionalFieldLine() {
    this.dropdownvalue.push(this.form.get('dropdowntype')?.value);
  }
  // Add tage
  applytag() {
    console.log(this.form?.value);
    if (this.selectedradioval == 'Checkbox') {
      this.viewtagmodel['fieldName'] = this.form.get('Tagetitle')?.value;
      this.viewtagmodel['fieldType'] = 'Bool';
      this.viewtagmodel['dropdownValues'] = '';
      this.tagarray.push(this.viewtagmodel);
      this.showcheckbox = true;
      this.tagViewModels.push(this.viewtagmodel);
    } else if (this.selectedradioval == 'Freefeild') {
      if (this.selectednumeralval == 'Alphanumeric') {
        this.Freefeild['fieldName'] = this.form.get('freefeildTitle')?.value;
        this.Freefeild['fieldType'] = 'Text';
        this.Freefeild['dropdownValues'] = '';
        this.tagarray.push(this.Freefeild);
        this.showfreefeild = true;
        this.tagViewModels.push(this.Freefeild);
      } else {
        this.viewnumeral['fieldName'] = this.form.get('freefeildnumber')?.value;
        this.viewnumeral['fieldType'] = 'Number';
        this.viewnumeral['dropdownValues'] = '';
        this.tagarray.push(this.viewnumeral);
        this.showfreefeild = true;
        this.tagViewModels.push(this.viewnumeral);
      }
    } else if (this.selectedradioval == 'Dropdown') {
      this.dropdownfeild['fieldName'] = this.form.get('Tagetitle')?.value;
      this.dropdownfeild['fieldType'] = 'Dropdown';
      this.dropdownfeild['dropdownValues'] =
        this.form.get('dropdowntype')?.value;
      this.tagViewModels.push(this.dropdownfeild);
    }
    console.log(this.tagViewModels);
  }

  checkChanges() {
    this.form.get('numeralType')?.valueChanges.subscribe((val) => {
      console.log(val);
      this.selectednumeralval = val;
    });
  }

  filterPredecessor(){
    if(this.form?.value.ListofPredecessor.length!=0){
      this.form?.value.ListofPredecessor?.forEach((id:any) => {
        const data=this.listofisSuccessor
        this.listofisSuccessor = data.filter((option:any) =>
          option.taskId!=id
        );
      });
    }
  }

  filterSuccessor(){
    if(this.form?.value.ListofSuccessor.length!=0){
      this.form?.value.ListofSuccessor?.forEach((id:any) => {
        const data=this.listofisPredecessor
        this.listofisPredecessor = data.filter((option:any) =>
          option.taskId!=id
        );
      });
    }
  }

  filterOptions(value: string, type: string): void {
    console.log(type);
    if (type === 'users') {
      this.filteredusers = this.users$.filter((option:any) =>
        option.firstName?.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'Sites') {
      this.filteredsites = this.sites$.filter((option:any) =>
        option?.companyName?.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'Contacts') {
      this.filteredcontacts = this.contacts$.filter((option:any) =>
        option.firstName?.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'UserGroups') {
      this.filteredusersgroup = this.usersgroup$.filter((option:any) =>
        option.groupName?.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'ContactGroups') {
      this.filteredcontactsgroup = this.contactsgroup$.filter((option:any) =>
        option.groupName?.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'Products') {
      this.filteredproducts = this.products$.filter((option:any) =>
        option.productName?.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  getdatafordroupdown() {
    this.getAllcountryList();
    this.getAllPipelines()
    this.getAllinitiatives()
    this.getAllisPredecessor()
    this.getAllisSuccessor()
    this.getallCategory()
    // get all users
    this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });

    this.injector.get(ManageUsersService).findAllUsersGroupDropdown().subscribe((res) => {
      this.userGroups$ = res;
      this.userGroups$.sort(function (a:any, b:any) {
        var textA = a.groupName.toUpperCase();
        var textB = b.groupName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this.filteredList4 = this.userGroups$.slice()
    })




    // get all contacts
    this.injector.get(ManageContactsService).findAllContactsDropdown().subscribe((result) => {
      if (result) {
        this.contacts$ = result;
        this.filteredcontacts = result;
      }
    });
    //get all sites
    this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredsites = result;
      }
    });
    // get all User Group
    this.injector.get(ManageUsersService).findAllUsersGroupDropdown().subscribe((result) => {
      if (result) {
        this.usersgroup$ = result;
        this.filteredusersgroup = result;
      }
    });
    // get all contact Group
    this.injector.get(ManageContactsService).findAllContactsGroupDropdown().subscribe((result) => {
      if (result) {
        this.contactsgroup$ = result;
        this.filteredcontactsgroup = result;
      }
    });
    this.injector.get(ManageProductsService).findAllProductsDropdown().subscribe((result) => {
      if (result) {
        this.products$ = result;
        this.filteredproducts = result;
      }
    });
   // get all orders
      this.injector.get(ManageTransactionsService).FindOrderDropdown().subscribe((res: any) => {
        console.log(res);
        this.Orders$ = res;
        this.filterfororderlist = res;
      }); 
  }

  getallCategory(){
    // fetchCategoryService
    this.injector.get(CalendarService).fetchCategoryService(100, 1).subscribe((res) => {
      this.categories = res;
      this.totalitems = res.length;
    });
    this.CriticityId$ = this.injector.get(CalendarService).findCriticity();
  }


  GroupToSingleconvert(group:any) {
    this.injector.get(ManageUsersService).getGroupUserService(group.id).subscribe((data: any) => {
      if (data) {
        const ids:any = []
        data?.forEach((element:any) => {
          ids.push(element.id)
          console.log(element)
        })
        if(this.form?.value.UserId.length!=0){
          this.form?.value.UserId?.forEach((element:any) => {
            ids.push(element)
          });
        }
        this.form.get('UserId')?.setValue(ids)
      }
    });
  }

  // ------------0000000000000
  handleAddressChange(place: object) {
    const data = this.injector.get(GoogleValidationAddressService).getFullAddress(place);
    this.form.get('IsOnlineEvent')?.setValue(false);
    this.form.get('IsOnlineEvent')?.disable();
    this.form.get('latitude')?.setValue(data.latitude);
    this.form.get('longitude')?.setValue(data.longitude);
    if (
      data.Address != undefined &&
      data.Address != null &&
      data.Address != 'undefined' &&
      data.Address != 'null'
    ) {
      this.form.get('Address')?.setValue(data.Address);
    } else {
      this.form.get('Address')?.setValue('');
    }
    if (
      data.pincode != undefined &&
      data.pincode != null &&
      data.pincode != 'undefined' &&
      data.pincode != 'null'
    ) {
      this.form.get('ZipCode')?.setValue(data.pincode);
    } else {
      this.form.get('ZipCode')?.setValue(null);
    }
    if (
      data.city != undefined &&
      data.city != null &&
      data.city != 'undefined' &&
      data.city != 'null'
    ) {
      this.form.get('City')?.setValue(data.city);
    } else {
      this.form.get('City')?.setValue('');
    }
    if (
      data.country != undefined &&
      data.country != null &&
      data.country != 'undefined' &&
      data.country != 'null'
    ) {
      this.form.get('Country')?.setValue(data.country);
    } else {
      this.form.get('Country')?.setValue('');
    }
  }

  // --------
  get f() {
    return this.form.controls;
  }

  popupforerrormessage(message:any, header:any) {
    this.dialog.open(PopupErrorMessageComponent, {
      width: '550px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
  }
  APIerrormessage(message:any, header:any) {
    this.dialog.open(ApiErrorMessagesComponent, {
      width: '550px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
  }

  onSubmitAutomation() {
    this.form.get('id')?.setValue(this.data?.type?.id);
    this.form.get('Name')?.setValue('Create Task');
    this.form.get('ChildId')?.setValue(this.data?.id);
    this.form.get('ParentId')?.setValue(this.data?.parentId);
    this.form.get('previousStep')?.setValue(this.data?.previousStep || this.data?.type?.previousStep);
    this.form.get('step')?.setValue(this.data?.step || this.data?.type?.step);
    this.form.get('showIcon')?.setValue(this.data?.showIcon || this.data?.type?.icon.name);
    this.form.get('showColor')?.setValue(this.data?.showColor || this.data?.type?.icon.color);
    this.form.get('positions')?.setValue(this.data?.Positions || this.data?.type?._currentPosition)
    this.form.value.isActive = this.data?.type?.isActive;
    this.matDialogRef.close(this.form);
  }

  openPopup(formControlName: string) {
    if(this.data?.Entity == 'Automation' || this.data.screenType == 'EditTaskAutomation'){
      const openAddEventDialog = this.dialog.open(AccordianViewComponent, {
        width: '350px',
        data: {
          Entity: this.data?.entity,
          ParentEntity: this.data?.ParentEntity,
          mappingEntity:this.data?.type?.mappingEntity,
          beforeParentEntity: this.data?.beforeParentEntity,
        },
        autoFocus: false,
        disableClose: true,
        position: { left: '1100px' },
      });
      openAddEventDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get(formControlName)?.setValue(formControlName + ' ' + 'called' + ' ' + `{${result}}`);
        }
      });
    }
    
  }

  ChooseSubTaskOption(value:any, index:any) {
    const CreateDialog = this.dialog.open(ManageCreateComponent, {
      width: '500px',
      panelClass: ['files'],
      data: { create: value, screenType: 'SubTaskForStep' },
      disableClose: false,
    });

    CreateDialog.afterClosed().subscribe((result: any) => {
      if(result) {
        if(index==null){
          console.log(result)
          this.Addsubtask()
          this.datasetinsubtask(result)
        }else{
          this.addtaskforstep(index)
          this.datasetintask(result, index) 
        }
      } 
    })
  }

  getsteptaskmodule(i:any) {
    return this.getSteps()?.at(i)?.get("taskmodule") as FormArray
  }

  addtaskforstep(i:number) {
    this.getsteptaskmodule(i).push(this.newtask())
  }

  opentaskViewDialog(id:number) {
    this.dialog.open(ViewTaskComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: id
    });
  }

  newtask(): FormGroup {
    return this.formBuilder.group({
      Title: ['', [Validators.required]],
      IsPublic:[true],
      StartDate: [''],
      EndDate: [''],
      CriticityId: [3],
      id: [0],
      UserId: [],
      PermittedUserIds:[],
      CategoryId: [131],
      Isparent: [true],
      Period: [0],
      Hours: [],
      Minutes: [],
      Address: [''],
      Zipcode: [],
      City: [''],
      Country: [''],
      latitude: [''],
      longitude: [''],
      Description: [''],
      keyword: [''],
      IsTemplate: false,
      UserGroupsId: [[]],
      ContactId: [],
      ProductsId: [],
      ordersId: [],
      SelactCountry: ['France'],
      ContactGroupsId: [[]],
      SiteId: [],
      Addtages:[''],
      HasStep:[true],
      PipeLinetype:['2'],
      StepsViewModel: [[]],
      SubTaskViewModels:[[]],
      pipelineID:[],
      Pipelinename:[],
      IsSavePipeline:[false],
      Stage:[2],
      initiatives:[''], 
      ListofSuccessor:[''],
      ListofPredecessor:[''],
      IsMileStone:[false]
    })
  }

   // 111111111111 task
   gettasks() {
    return this.form.get("taskmodule") as FormArray
  }
  Addtask() {
    this.gettasks().push(this.newtask());
  }

  gettaskname(i:number) { 
    return this.gettasks().at(i).get('Title')?.value
  }
  gettaskIsMileStone(i:number) { 
    return this.gettasks().at(i).get('IsMileStone')?.value
  }
  gettaskid(i:number) {
    return this.gettasks().at(i).get('id')?.value
  }
  removetask(i:number) {
    this.gettasks().removeAt(i)
  }

  getstepinsidetaskname(i:number, m:number) {
    return this.getsteptaskmodule(i).at(m).get('Title')?.value
  }
  getsubtaskname(i:number) {
    return this.getsubtasks().at(i).get('Title')?.value
  }
  getstepinsidetaskIsMileStone(i:number, m:number) {
    return this.getsteptaskmodule(i).at(m).get('IsMileStone')?.value
  }
  getsubtaskIsMileStone(i:number) {
    return this.getsubtasks().at(i).get('IsMileStone')?.value
  }
  getstepinsidetaskid(i:number, m:number) {
    return this.getsteptaskmodule(i).at(m).get('id')?.value
  }
  removestepinsidetas(i:number, m:number) {
    this.getsteptaskmodule(i).removeAt(m)
  }

  getsteptaskmoduleForsubtask(i:Number,k:number){
    return this.getsteptaskmodule(i).at(k).get("SubTaskViewModels") as FormArray
  }

  showtaskdetails(i:number, m:number) {
    if (this.form.value.HasStep == true) {
        var data:any = this.getTasks(i).at(m)
        console.log(data)
        if(data.value.id){
          this.opentaskViewDialog(data.value.id)
        }else{
          this.edittask(i,m,'View-subtask-task')
        }
      }
  }

  getOneSubTask(i:number, data:any) {
    console.log(data)
    if (data[0].taskId) {
      data?.forEach((element:any, index:number) => {
        if (this.form.value.HasStep == true) {
          this.addtaskforstep(i)
          this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('Title')?.setValue(element.title)
          this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('id')?.setValue(element.taskId)
          this.getsteptaskmodule(i).at(this.getsteptaskmodule(i)?.value.length - 1).get('IsMileStone')?.setValue(element.IsMileStone)
        }
        else {
          this.Addtask()
          this.gettasks().at(this.gettasks()?.value.length - 1).get('Title')?.setValue(element.title)
          this.gettasks().at(this.gettasks()?.value.length - 1).get('id')?.setValue(element.taskId)
          this.gettasks().at(this.gettasks()?.value.length - 1).get('IsMileStone')?.setValue(element.IsMileStone)
        }
      });
    } else {
      if (this.form.value.HasStep == true) {
        this.addtaskforstep(i)
        // this.datasetintask1(i, data[0])
      }
      else {
        this.Addtask()
        // this.datasetintask(data[0])
      }
    }

  }

  edittask(i:number, m:any,type?:any) {
    if (this.form.value.HasStep == true) {
    var data:any = this.getTasks(i).at(m)
    }else{
      var data:any = this.getTasks(i).at(m)
    }
    console.log(data)
    if(this.getstepinsidetaskIsMileStone(i,m) == true) {
      const dialogRef = this.dialog.open(CreateMilestoneComponent, {
        disableClose: true,
        data: { data: data.value,initiative:this.form.value, type: type ? type:'edit-step-task' },
        width: '500px'
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          console.log(result)
          if (this.form.value.HasStep == true) {
            this.getsteptaskmodule(i).at(m).get('Title')?.setValue(result?.Title)
            this.getsteptaskmodule(i).at(m).get('IsPublic')?.setValue(result?.IsPublic)
            this.getsteptaskmodule(i).at(m).get('StartDate')?.setValue(result?.StartDate)
            this.getsteptaskmodule(i).at(m).get('EndDate')?.setValue(result?.EndDate)
            this.getsteptaskmodule(i).at(m).get('CriticityId')?.setValue(result?.CriticityId)
            this.getsteptaskmodule(i).at(m).get('id')?.setValue(result?.id)
            this.getsteptaskmodule(i).at(m).get('UserId')?.setValue(result?.UserId)
            this.getsteptaskmodule(i).at(m).get('CategoryId')?.setValue(result?.CategoryId)
            this.getsteptaskmodule(i).at(m).get('Isparent')?.setValue(result?.Isparent)
            this.getsteptaskmodule(i).at(m).get('Period')?.setValue(result?.Period)
            this.getsteptaskmodule(i).at(m).get('Hours')?.setValue(result?.Hours)
            this.getsteptaskmodule(i).at(m).get('Minutes')?.setValue(result?.Minutes)
            this.getsteptaskmodule(i).at(m).get('Address')?.setValue(result?.Address)
            this.getsteptaskmodule(i).at(m).get('Zipcode')?.setValue(result?.ZipCode)
            this.getsteptaskmodule(i).at(m).get('City')?.setValue(result?.City)
            this.getsteptaskmodule(i).at(m).get('Country')?.setValue(result?.Country)
            this.getsteptaskmodule(i).at(m).get('latitude')?.setValue(result?.latitude)
            this.getsteptaskmodule(i).at(m).get('longitude')?.setValue(result?.longitude)
            this.getsteptaskmodule(i).at(m).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
            this.getsteptaskmodule(i).at(m).get('Description')?.setValue(result?.Description)
            this.getsteptaskmodule(i).at(m).get('keyword')?.setValue(result?.keyword.toString())
            this.getsteptaskmodule(i).at(m).get('IsTemplate')?.setValue(result?.IsTemplate)
            this.getsteptaskmodule(i).at(m).get('UserGroupsId')?.setValue(result?.UserGroupsId)
            this.getsteptaskmodule(i).at(m).get('ContactId')?.setValue(result?.ContactId)
            this.getsteptaskmodule(i).at(m).get('SelactCountry')?.setValue(result?.SelactCountry)
            this.getsteptaskmodule(i).at(m).get('ContactGroupsId')?.setValue(result?.ContactGroupsId)
            this.getsteptaskmodule(i).at(m).get('HasStep')?.setValue(result?.HasStep)
            this.getsteptaskmodule(i).at(m).get('PipeLinetype')?.setValue(result?.PipeLinetype)
            this.getsteptaskmodule(i).at(m).get('StepsViewModel')?.setValue(result?.AddSteps)
            this.getsteptaskmodule(i).at(m).get('pipelineID')?.setValue(result?.pipelineID)
            this.getsteptaskmodule(i).at(m).get('Pipelinename')?.setValue(result?.Pipelinename)
            this.getsteptaskmodule(i).at(m).get('Stage')?.setValue(result?.Stage)
            this.getsteptaskmodule(i).at(m).get('initiatives')?.setValue(result?.initiatives)
            this.getsteptaskmodule(i).at(m).get('ListofSuccessor')?.setValue(result?.ListofSuccessor)
            this.getsteptaskmodule(i).at(m).get('ListofPredecessor')?.setValue(result?.ListofPredecessor)
            this.getsteptaskmodule(i).at(m).get('IsMileStone')?.setValue(result?.IsMileStone)
            this.getsteptaskmodule(i).at(m).get('Addtages')?.setValue(result?.Addtages)
            if(result?.HasStep==true){
              if(result?.PipeLinetype=='1'){
               this.getsteptaskmodule(i).at(m).get('IsSavePipeline')?.setValue(true)
              }else{
               this.getsteptaskmodule(i).at(m).get('IsSavePipeline')?.setValue(false)
              }
            }else{
             this.getsteptaskmodule(i).at(m).get('IsSavePipeline')?.setValue(false)
            }
          }
          else {
            this.gettasks().at(i).get('Title')?.setValue(result?.Title)
            this.gettasks().at(i).get('IsPublic')?.setValue(result?.IsPublic)
            this.gettasks().at(i).get('StartDate')?.setValue(result?.StartDate)
            this.gettasks().at(i).get('EndDate')?.setValue(result?.EndDate)
            this.gettasks().at(i).get('CriticityId')?.setValue(result?.CriticityId)
            this.gettasks().at(i).get('id')?.setValue(result?.id)
            this.gettasks().at(i).get('UserId')?.setValue(result?.UserId)
            this.gettasks().at(i).get('CategoryId')?.setValue(result?.CategoryId)
            this.gettasks().at(i).get('Isparent')?.setValue(result?.Isparent)
            this.gettasks().at(i).get('Period')?.setValue(result?.Period)
            this.gettasks().at(i).get('Hours')?.setValue(result?.Hours)
            this.gettasks().at(i).get('Minutes')?.setValue(result?.Minutes)
            this.gettasks().at(i).get('Address')?.setValue(result?.Address)
            this.gettasks().at(i).get('Zipcode')?.setValue(result?.ZipCode)
            this.gettasks().at(i).get('City')?.setValue(result?.City)
            this.gettasks().at(i).get('Country')?.setValue(result?.Country)
            this.gettasks().at(i).get('latitude')?.setValue(result?.latitude)
            this.gettasks().at(i).get('longitude')?.setValue(result?.longitude)
            this.gettasks().at(i).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
            this.gettasks().at(i).get('Description')?.setValue(result?.Description)
            this.gettasks().at(i).get('keyword')?.setValue(result?.keyword.toString())
            this.gettasks().at(i).get('IsTemplate')?.setValue(result?.IsTemplate)
            this.gettasks().at(i).get('UserGroupsId')?.setValue(result?.UserGroupsId)
            this.gettasks().at(i).get('ContactId')?.setValue(result?.ContactId)
            this.gettasks().at(i).get('SelactCountry')?.setValue(result?.SelactCountry)
            this.gettasks().at(i).get('ContactGroupsId')?.setValue(result?.ContactGroupsId)
            this.gettasks().at(i).get('SiteId')?.setValue(result?.SiteId)
            this.gettasks().at(i).get('HasStep')?.setValue(result?.HasStep)
            this.gettasks().at(i).get('PipeLinetype')?.setValue(result?.PipeLinetype)
            this.gettasks().at(i).get('StepsViewModel')?.setValue(result?.AddSteps)
            this.gettasks().at(i).get('pipelineID')?.setValue(result?.pipelineID)
            this.gettasks().at(i).get('Pipelinename')?.setValue(result?.Pipelinename)
            this.gettasks().at(i).get('Stage')?.setValue(result?.Stage)
            this.gettasks().at(i).get('initiatives')?.setValue(result?.initiatives)
            this.gettasks().at(i).get('ListofSuccessor')?.setValue(result?.ListofSuccessor)
            this.gettasks().at(i).get('ListofPredecessor')?.setValue(result?.ListofPredecessor)
            this.gettasks().at(i).get('IsMileStone')?.setValue(result?.IsMileStone)
            this.gettasks().at(i).get('Addtages')?.setValue(result?.Addtages)
            if(result?.HasStep==true){
              if(result?.PipeLinetype=='1'){
               this.gettasks().at(i).get('IsSavePipeline')?.setValue(true)
              }else{
               this.gettasks().at(i).get('IsSavePipeline')?.setValue(false)
              }
            }else{
             this.gettasks().at(i).get('IsSavePipeline')?.setValue(false)
            }
          }
  
        }
      })
    } else {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: { data: data.value,initiative:this.form.value, type: type ? type:'edit-step-task' },
        width: '500px'
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          console.log(result)
          if (this.form.value.HasStep == true) {
            this.getsteptaskmodule(i).at(m).get('Title')?.setValue(result?.Title)
            this.getsteptaskmodule(i).at(m).get('IsPublic')?.setValue(result?.IsPublic)
            this.getsteptaskmodule(i).at(m).get('StartDate')?.setValue(result?.StartDate)
            this.getsteptaskmodule(i).at(m).get('EndDate')?.setValue(result?.EndDate)
            this.getsteptaskmodule(i).at(m).get('CriticityId')?.setValue(result?.CriticityId)
            this.getsteptaskmodule(i).at(m).get('id')?.setValue(result?.id)
            this.getsteptaskmodule(i).at(m).get('UserId')?.setValue(result?.UserId)
            this.getsteptaskmodule(i).at(m).get('CategoryId')?.setValue(result?.CategoryId)
            this.getsteptaskmodule(i).at(m).get('Isparent')?.setValue(result?.Isparent)
            this.getsteptaskmodule(i).at(m).get('Period')?.setValue(result?.Period)
            this.getsteptaskmodule(i).at(m).get('Hours')?.setValue(result?.Hours)
            this.getsteptaskmodule(i).at(m).get('Minutes')?.setValue(result?.Minutes)
            this.getsteptaskmodule(i).at(m).get('Address')?.setValue(result?.Address)
            this.getsteptaskmodule(i).at(m).get('Zipcode')?.setValue(result?.ZipCode)
            this.getsteptaskmodule(i).at(m).get('City')?.setValue(result?.City)
            this.getsteptaskmodule(i).at(m).get('Country')?.setValue(result?.Country)
            this.getsteptaskmodule(i).at(m).get('latitude')?.setValue(result?.latitude)
            this.getsteptaskmodule(i).at(m).get('longitude')?.setValue(result?.longitude)
            this.getsteptaskmodule(i).at(m).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
            this.getsteptaskmodule(i).at(m).get('Description')?.setValue(result?.Description)
            this.getsteptaskmodule(i).at(m).get('keyword')?.setValue(result?.keyword.toString())
            this.getsteptaskmodule(i).at(m).get('IsTemplate')?.setValue(result?.IsTemplate)
            this.getsteptaskmodule(i).at(m).get('UserGroupsId')?.setValue(result?.UserGroupsId)
            this.getsteptaskmodule(i).at(m).get('ContactId')?.setValue(result?.ContactId)
            this.getsteptaskmodule(i).at(m).get('SelactCountry')?.setValue(result?.SelactCountry)
            this.getsteptaskmodule(i).at(m).get('ContactGroupsId')?.setValue(result?.ContactGroupsId)
            this.getsteptaskmodule(i).at(m).get('HasStep')?.setValue(result?.HasStep)
            this.getsteptaskmodule(i).at(m).get('PipeLinetype')?.setValue(result?.PipeLinetype)
            this.getsteptaskmodule(i).at(m).get('StepsViewModel')?.setValue(result?.AddSteps)
            this.getsteptaskmodule(i).at(m).get('pipelineID')?.setValue(result?.pipelineID)
            this.getsteptaskmodule(i).at(m).get('Pipelinename')?.setValue(result?.Pipelinename)
            this.getsteptaskmodule(i).at(m).get('Stage')?.setValue(result?.Stage)
            this.getsteptaskmodule(i).at(m).get('initiatives')?.setValue(result?.initiatives)
            this.getsteptaskmodule(i).at(m).get('ListofSuccessor')?.setValue(result?.ListofSuccessor)
            this.getsteptaskmodule(i).at(m).get('ListofPredecessor')?.setValue(result?.ListofPredecessor)
            this.getsteptaskmodule(i).at(m).get('IsMileStone')?.setValue(result?.IsMileStone)
            this.getsteptaskmodule(i).at(m).get('Addtages')?.setValue(result?.Addtages)
            if(result?.HasStep==true){
              if(result?.PipeLinetype=='1'){
               this.getsteptaskmodule(i).at(m).get('IsSavePipeline')?.setValue(true)
              }else{
               this.getsteptaskmodule(i).at(m).get('IsSavePipeline')?.setValue(false)
              }
            }else{
             this.getsteptaskmodule(i).at(m).get('IsSavePipeline')?.setValue(false)
            }
          }
          else {
            this.gettasks().at(i).get('Title')?.setValue(result?.Title)
            this.gettasks().at(i).get('IsPublic')?.setValue(result?.IsPublic)
            this.gettasks().at(i).get('StartDate')?.setValue(result?.StartDate)
            this.gettasks().at(i).get('EndDate')?.setValue(result?.EndDate)
            this.gettasks().at(i).get('CriticityId')?.setValue(result?.CriticityId)
            this.gettasks().at(i).get('id')?.setValue(result?.id)
            this.gettasks().at(i).get('UserId')?.setValue(result?.UserId)
            this.gettasks().at(i).get('CategoryId')?.setValue(result?.CategoryId)
            this.gettasks().at(i).get('Isparent')?.setValue(result?.Isparent)
            this.gettasks().at(i).get('Period')?.setValue(result?.Period)
            this.gettasks().at(i).get('Hours')?.setValue(result?.Hours)
            this.gettasks().at(i).get('Minutes')?.setValue(result?.Minutes)
            this.gettasks().at(i).get('Address')?.setValue(result?.Address)
            this.gettasks().at(i).get('Zipcode')?.setValue(result?.ZipCode)
            this.gettasks().at(i).get('City')?.setValue(result?.City)
            this.gettasks().at(i).get('Country')?.setValue(result?.Country)
            this.gettasks().at(i).get('latitude')?.setValue(result?.latitude)
            this.gettasks().at(i).get('longitude')?.setValue(result?.longitude)
            this.gettasks().at(i).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
            this.gettasks().at(i).get('Description')?.setValue(result?.Description)
            this.gettasks().at(i).get('keyword')?.setValue(result?.keyword.toString())
            this.gettasks().at(i).get('IsTemplate')?.setValue(result?.IsTemplate)
            this.gettasks().at(i).get('UserGroupsId')?.setValue(result?.UserGroupsId)
            this.gettasks().at(i).get('ContactId')?.setValue(result?.ContactId)
            this.gettasks().at(i).get('SelactCountry')?.setValue(result?.SelactCountry)
            this.gettasks().at(i).get('ContactGroupsId')?.setValue(result?.ContactGroupsId)
            this.gettasks().at(i).get('SiteId')?.setValue(result?.SiteId)
            this.gettasks().at(i).get('HasStep')?.setValue(result?.HasStep)
            this.gettasks().at(i).get('PipeLinetype')?.setValue(result?.PipeLinetype)
            this.gettasks().at(i).get('StepsViewModel')?.setValue(result?.AddSteps)
            this.gettasks().at(i).get('pipelineID')?.setValue(result?.pipelineID)
            this.gettasks().at(i).get('Pipelinename')?.setValue(result?.Pipelinename)
            this.gettasks().at(i).get('Stage')?.setValue(result?.Stage)
            this.gettasks().at(i).get('initiatives')?.setValue(result?.initiatives)
            this.gettasks().at(i).get('ListofSuccessor')?.setValue(result?.ListofSuccessor)
            this.gettasks().at(i).get('ListofPredecessor')?.setValue(result?.ListofPredecessor)
            this.gettasks().at(i).get('IsMileStone')?.setValue(result?.IsMileStone)
            this.gettasks().at(i).get('Addtages')?.setValue(result?.Addtages)
            if(result?.HasStep==true){
              if(result?.PipeLinetype=='1'){
               this.gettasks().at(i).get('IsSavePipeline')?.setValue(true)
              }else{
               this.gettasks().at(i).get('IsSavePipeline')?.setValue(false)
              }
            }else{
             this.gettasks().at(i).get('IsSavePipeline')?.setValue(false)
            }
          }
  
        }
      })
    }
   
  }



  datasetintask(result:any, index:any) {
    this.getSteps().at(index).value
    console.log( this.getSteps().at(index).get('taskmodule')?.value.length - 1)
    console.log( this.getSteps().at(index))
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Title')?.setValue(result?.Title)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('IsPublic')?.setValue(result?.IsPublic)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('StartDate')?.setValue(result?.StartDate)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('EndDate')?.setValue(result?.EndDate)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('CriticityId')?.setValue(result?.CriticityId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('id')?.setValue(result?.id)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('UserId')?.setValue(result?.UserId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('CategoryId')?.setValue(result?.CategoryId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Isparent')?.setValue(result?.Isparent)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Period')?.setValue(result?.Period)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Hours')?.setValue(result?.Hours)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Minutes')?.setValue(result?.Minutes)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Address')?.setValue(result?.Address)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Zipcode')?.setValue(result?.ZipCode)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('City')?.setValue(result?.City)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Country')?.setValue(result?.Country)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('latitude')?.setValue(result?.latitude)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('longitude')?.setValue(result?.longitude)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Description')?.setValue(result?.Description)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('keyword')?.setValue(result?.keyword.toString())
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('IsTemplate')?.setValue(result?.IsTemplate)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('UserGroupsId')?.setValue(result?.UserGroupsId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('ContactId')?.setValue(result?.ContactId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('SelactCountry')?.setValue(result?.SelactCountry)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('ContactGroupsId')?.setValue(result?.ContactGroupsId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('SiteId')?.setValue(result?.SiteId)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('HasStep')?.setValue(result?.HasStep)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('PipeLinetype')?.setValue(result?.PipeLinetype)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('StepsViewModel')?.setValue(result?.AddSteps)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('pipelineID')?.setValue(result?.pipelineID)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Pipelinename')?.setValue(result?.Pipelinename)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Stage')?.setValue(result?.Stage)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('initiatives')?.setValue(result?.initiatives)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('ListofSuccessor')?.setValue(result?.ListofSuccessor)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('ListofPredecessor')?.setValue(result?.ListofPredecessor)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('IsMileStone')?.setValue(result?.IsMileStone)
    this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('Addtages')?.setValue(result?.Addtages)
    if(result?.HasStep==true){
      if(result?.PipeLinetype=='1'){
        this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('IsSavePipeline')?.setValue(true)
      }else{
        this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('IsSavePipeline')?.setValue(false)
      }
    }else{
      this.getTasks(index).at(this.getSteps().at(index).get('taskmodule')?.value.length - 1).get('IsSavePipeline')?.setValue(false)
    }
  }



  datasetinsubtask(result:any) {
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Title')?.setValue(result?.Title)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('IsPublic')?.setValue(result?.IsPublic)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('StartDate')?.setValue(result?.StartDate)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('EndDate')?.setValue(result?.EndDate)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('CriticityId')?.setValue(result?.CriticityId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('id')?.setValue(result?.id)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('UserId')?.setValue(result?.UserId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('CategoryId')?.setValue(result?.CategoryId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Isparent')?.setValue(result?.Isparent)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Period')?.setValue(result?.Period)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Hours')?.setValue(result?.Hours)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Minutes')?.setValue(result?.Minutes)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Address')?.setValue(result?.Address)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Zipcode')?.setValue(result?.ZipCode)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('City')?.setValue(result?.City)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Country')?.setValue(result?.Country)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('latitude')?.setValue(result?.latitude)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('longitude')?.setValue(result?.longitude)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Description')?.setValue(result?.Description)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('PermittedUserIds')?.setValue(result?.PermittedUserIds)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('keyword')?.setValue(result?.keyword.toString())
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('IsTemplate')?.setValue(result?.IsTemplate)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('UserGroupsId')?.setValue(result?.UserGroupsId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('ContactId')?.setValue(result?.ContactId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('SelactCountry')?.setValue(result?.SelactCountry)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('ContactGroupsId')?.setValue(result?.ContactGroupsId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('SiteId')?.setValue(result?.SiteId)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('HasStep')?.setValue(result?.HasStep)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('PipeLinetype')?.setValue(result?.PipeLinetype)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('StepsViewModel')?.setValue(result?.AddSteps)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('pipelineID')?.setValue(result?.pipelineID)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Pipelinename')?.setValue(result?.Pipelinename)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Stage')?.setValue(result?.Stage)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('initiatives')?.setValue(result?.initiatives)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('ListofSuccessor')?.setValue(result?.ListofSuccessor)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('ListofPredecessor')?.setValue(result?.ListofPredecessor)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('IsMileStone')?.setValue(result?.IsMileStone)
    this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('Addtages')?.setValue(result?.Addtages)
    if(result?.HasStep==true){
      if(result?.PipeLinetype=='1'){
        this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('IsSavePipeline')?.setValue(true)
      }else{
        this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('IsSavePipeline')?.setValue(false)
      }
    }else{
      this.getsubtasks().at(this.getsubtasks()?.value.length - 1).get('IsSavePipeline')?.setValue(false)
    }
  }

  addNewFileAndNote() {
    const addNewFileAndNote = this.dialog.open(FileandnotesformanagementComponent, {
      width: '500px',
    })
    addNewFileAndNote.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'file') {
          this.AddFile()
        } else {
          this.AddNote()
        }
      }
    });
  }

    // AddFile
    AddFile() {
      const addfile = this.dialog.open(AddFileComponent, {
        disableClose: true,
        width: '500px',
        data: {
          data: this.taskiddata,
          attachmentLevel: 'Tasks',
          ismanage: true,
          type: 'single'
        },
        panelClass: ['addFiles']
      });
      addfile.afterClosed().subscribe(result => {
        this.ShowAttachmentFile = true
        this.ShowAttachmentNotes = true
        this.ngOnInit();
        this.findnotesLinked();
        this.findfilesLinked();
      });
    }
  
    // AddNote
    AddNote() {
      const addnote = this.dialog.open(AddNotesComponent, {
        disableClose: true,
        data: {
          data: this.taskiddata,
          attachmentLevel: 'Tasks',
          ismanage: true,
          type: 'single'
        },
        width: '600px',
        panelClass: ['addNotes']
      });
      addnote.afterClosed().subscribe(result => {
        this.ShowAttachmentFile = true
        this.ShowAttachmentNotes = true
        this.ngOnInit();
        this.findnotesLinked();
        this.findfilesLinked();
      });
    }

      // findfilesLinked 
  findfilesLinked() {
    this.injector.get(ManageUsersService).getfile(this.taskiddata.id, 'Tasks').subscribe((res:any) => {
      this.AttachmentFileList = res;
      this.ShowAttachmentFile = !this.ShowAttachmentFile
    });
  }
  // findnotesLinked
  findnotesLinked() {
    this.injector.get(ManageUsersService).getnotes(this.taskiddata.id, 'Tasks').subscribe((res:any) => {
      this.AttachmentNotesList = res;
      this.ShowAttachmentNotes = !this.ShowAttachmentNotes
    });
  }



}
