<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{'Create Category'}}</h2>
    <mat-icon  (click)="closepopup()">close</mat-icon>
  </div>
  
    <mat-dialog-content class="mr-0 ml-0">
      <form [formGroup]="categoriesForm" id="categories-form">
  
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>{{'Name'}}<span class="danger"> * </span></mat-label>
        <input matInput type="text"  formControlName="categoryName">
      </mat-form-field>
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>{{'Description'}}</mat-label>
        <input matInput type="text"  formControlName="Description">
      </mat-form-field>
      <div class="row">
        <div class="col-md-5">
            <mat-label>{{'Category Color'}} <span class="danger">* </span></mat-label>
            <input style="border: none;" formControlName="color" [style.background]="color1" [(colorPicker)]="color1"
              (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
              (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
          </div>
          <div class="col-md-5">
            <mat-label>{{'Text Color'}} <span class="danger">* </span></mat-label> 
            <input style="border: none;" formControlName="fontColor" [style.background]="color2" [(colorPicker)]="color2"
              (colorPickerOpen)="onEventLog1('colorPickerOpen', $event)"
              (colorPickerClose)="onEventLog1('colorPickerClose', $event)" />
          </div>
      </div>
      <div>
        <div class="row mt-4 condictionbodystyle">
            <div class="col-10"> <p style="text-align: center;padding: 5px;margin: 0 !important;"><b> Conditions</b></p> </div>
            <div class="col-2">
                <mat-icon (click)="addConditionalBody()">add_box</mat-icon>
            </div>
        </div>
        <div class="row w-100" formArrayName="Conditions" style="border: 1px solid gray;">
            <div class="row  Conditionsbody " *ngFor="let conditionsBodyGroup of getConditionsBody().controls; let i=index" [formGroupName]="i">
                    <div class="row ">
                        <div class="col-4" style="padding: 2px !important;">
                        <mat-form-field  class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option [value]="x" *ngFor="let x of Parameter"
                                            (click)="selactdatatype(x,i)">
                                            {{x}}
                                        </mat-option>
                                    </mat-select>
                        </mat-form-field>
                        </div>
                        <div class="col-3" style="padding: 2px !important;">
                                <mat-form-field  class="w-100">
                                    <mat-label>{{'Comparison'}}</mat-label>
                                    <mat-select formControlName="conditionComparison">
                                        <mat-option [value]="x" *ngFor="let x of AllOption">
                                            {{x}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                        </div>
                        <div class="col-4" style="padding: 2px !important;">
                            <mat-form-field  class="w-100">
                                <mat-label>{{'value'}}</mat-label>
                                <input matInput formControlName="value">
                            </mat-form-field>
                        </div>
                        <div class="col-1">
                            <mat-icon *ngIf="i!=0" (click)="removeConditionalBody(i)">delete</mat-icon>
                        </div>
                    </div>
            </div>
        </div>
      </div>
    </form>
  
    </mat-dialog-content>
  
  <mat-dialog-actions align="end" class="my-1">
    <div class="row w-100">
      <div class="col-4">
        <button mat-button mat-dialog-close>{{'Cancel'}}</button>
  
      </div>
      <div class="col-4">
        <button mat-raised-button class="b-blue" (click)="createcategory()">{{'Create'}}</button>
  
      </div>
    </div>
  </mat-dialog-actions>