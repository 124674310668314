<div class="d-flex flex-row  modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Create Basic Auth</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="CreateBasicAuthForm">
        <div class="row w-100">
            <mat-form-field class="w-100">
                <input matInput type="text" placeholder="Url"
                    formControlName="url">
            </mat-form-field>
        </div>
        <div class="row w-100" >
            <div class="col-12 p-0">
                <mat-form-field appearance="fill" class="w-100 m-t-10">
                    <mat-label>Method</mat-label>
                    <mat-select formControlName="method">
                        <mat-option *ngFor="let app of apiMethods" [value]="app.id">
                            {{app.name|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row p-2 mt-3 justify-content-around"
        style="color: white; background-color:#f6272e; font-weight: 600;">
        <div class="col-6">{{'Headers'}} </div>
        <div class="col-2">
            <mat-icon style="color: white;" (click)="AddHeaders()">add_box</mat-icon>
        </div>
        </div>
        <div style="border: 1px solid gray;padding:10px;" class="row w-100">
            <div class="col-12" formArrayName="Conditions">
                    <div *ngFor="let file of Conditions().controls; let i=index" [formGroupName]="i">
                        <div class="row p-2 justify-content-around"
                            style="color: white; background-color:#818582; font-weight: 600;">
                            <div class="col-10"> {{'Header'}}-{{i+1}}</div>
                            <div class="col-2">
                                <mat-icon *ngIf="i!=0" (click)="RemoveHeader(i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row strategycondictions" style="border: 1px solid gray;padding:10px;">
                        
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" placeholder="Name" formControlName="HeaderName">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" placeholder="value" formControlName="Headervalue">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <div class="row p-2 mt-3 justify-content-around"
        style="color: white; background-color:#f6272e; font-weight: 600;">
        <div class="col-6">{{'Query String'}} </div>
        <div class="col-2">
            <mat-icon style="color: white;" (click)="AddQuery()">add_box</mat-icon>
        </div>
        </div>
        <div style="border: 1px solid gray;padding:10px;" class="row w-100">
            <div class="col-12" formArrayName="QueryConditions">
                    <div *ngFor="let file of QueryConditions().controls; let i=index" [formGroupName]="i">
                        <div class="row p-2 justify-content-around"
                            style="color: white; background-color:#818582; font-weight: 600;">
                            <div class="col-10"> {{'Query'}}-{{i+1}}</div>
                            <div class="col-2">
                                <mat-icon *ngIf="i!=0" (click)="RemoveQuery(i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row strategycondictions" style="border: 1px solid gray;padding:10px;">
                        
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" placeholder="Name" formControlName="Query">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" placeholder="value" formControlName="Query">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row w-100" >
            <div class="col-12 p-0">
                <mat-form-field appearance="fill" class="w-100 m-t-10">
                    <mat-label>Body Type</mat-label>
                    <mat-select formControlName="bodyType" (selectionChange)="onBodyTypeChange($event)">
                        <mat-option *ngFor="let app of BodyType" [value]="app.id">
                            {{app.name|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100" *ngIf="SelectedBody == 'Raw'">
            <div class="col-12 p-0">
                <mat-form-field appearance="fill" class="w-100 m-t-10">
                    <mat-label>Content Type</mat-label>
                    <mat-select formControlName="bodyType" (selectionChange)="onContentTypeChange($event)">
                        <mat-option *ngFor="let app of ContentType" [value]="app.id">
                            {{app.name|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100" *ngIf="SelectedBody == 'Raw' && SelectedContent == 'Custom'">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Value</mat-label>
                    <textarea matInput placeholder="value" formControlName="Customvalue" rows="4"></textarea>
                    
                </mat-form-field>
             </div>
        </div>
        <div class="row w-100" *ngIf="SelectedBody == 'Raw'">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Request Content</mat-label>
                    <textarea matInput placeholder="value" formControlName="RequestValue" rows="4"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="SelectedBody != 'Raw'" class="row p-2 mt-3 justify-content-around"
        style="color: white; background-color:#f6272e; font-weight: 600;">
        <div class="col-6">{{'Fields'}} </div>
        <div class="col-2">
            <mat-icon style="color: white;" (click)="AddItem()">add_box</mat-icon>
        </div>
        </div>
        <div *ngIf="SelectedBody != 'Raw'" style="border: 1px solid gray;padding:10px;" class="row w-100">
            <div class="col-12" *ngIf="SelectedBody == 'Multipart/form-data'">
                <mat-form-field class="w-100 ">
                    <mat-label>{{'Field type'}}</mat-label>
                    <mat-select formControlName="Trigger">
                        <mat-option [value]="type.id" *ngFor="let type of FieldType">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12" formArrayName="QueryFields">
                    <div *ngFor="let file of QueryFields().controls; let i=index" [formGroupName]="i">
                        <div class="row p-2 justify-content-around"
                            style="color: white; background-color:#818582; font-weight: 600;">
                            <div class="col-10"> {{'Query'}}-{{i+1}}</div>
                            <div class="col-2">
                                <mat-icon *ngIf="i!=0" (click)="RemoveItem(i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row strategycondictions" style="border: 1px solid gray;padding:10px;">
                        
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" placeholder="Key" formControlName="FieldKey">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" placeholder="value" formControlName="Fieldvalue">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </form>
    <mat-dialog-actions align="end" class="mb-24">
        <div class="row mt-2 mb-4 justify-content-center" >
            <div class="col-4">
                <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
            </div>
            <div class="col-4">
                <button mat-raised-button class="b-blue" [disabled]="!CreateBasicAuthForm.valid">{{"Create"}}</button>
            </div>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>
