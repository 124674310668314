<header>
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Add New Tag</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
</header>

    <mat-card>
<form [formGroup]="tagForm">
        <mat-card-content class="mr-0 ml-0">
        <h4 style="text-align: center;"><b> Select Tag Type</b></h4>
         <mat-radio-group class="example-radio-group" aria-label="Select an option" formControlName="tagType"
             (click)="onChanges()">
                    <div class="row p-0">
                        <div class="col-4 p-1">
                          <mat-radio-button class="example-radio-button" value="Checkbox">
                            <span><img src="../../../../assets/images/iconsForsvg/tag_check_box.svg"></span>
                            <span style="margin-left: 5px;">Check box</span>
                        </mat-radio-button>
                        </div>
                        <div class="col-4 p-1">
                           <mat-radio-button class="example-radio-button" value="Dropdown">
                            <span><img src="../../../../assets/images/iconsForsvg/tag_drop_down.svg"></span>
                            <span style="margin-left: 5px;">Dropdown list</span>
                            
                        </mat-radio-button>
                        </div>
                        <div class="col-4 p-1">
                           <mat-radio-button class="example-radio-button" value="Freefeild">
                            <span><img src="../../../../assets/images/iconsForsvg/tag_free-fields.svg"></span>
                            <span style="margin-left: 5px;">Free Field</span>
                           </mat-radio-button>
                        </div>


                        <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="email">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_email.svg"></span>
                                <span style="margin-left: 5px;">Email</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="PhoneNumber">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_phone.svg"></span>
                                <span style="margin-left: 5px;">Phone Number</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="URL">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_url.svg"></span>
                                <span style="margin-left: 5px;">URL</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="date">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_date.svg"></span>
                                <span style="margin-left: 5px;">Date</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="percentage">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_Percent.svg"></span>
                                <span style="margin-left: 5px;">percentage</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="hour&minutes">
                                <span><img src="../../../../assets/images/iconsForsvg/tag-hour_mini.svg"></span>
                                <span style="margin-left: 5px;">Hour & minutes</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="Squarefeet">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_square_feet.svg"></span>
                                <span style="margin-left: 5px;">Square feet</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="Squaremeter">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_Square.svg"></span>
                                <span style="margin-left: 5px;">Square meter</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="Days">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_days.svg"></span>
                                <span style="margin-left: 5px;">Days</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="Entity">
                                <span><img style="width: 20px;height: 20px;" src="../../../../assets/logo/logo-sm.png"></span>
                                <span style="margin-left: 5px;">Entity</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="Files">
                                <span><img src="../../../../assets/images/iconsForsvg/tag_file.svg"></span>
                                <span style="margin-left: 5px;">Files</span>
                            </mat-radio-button>
                         </div>
                         <div class="col-4 p-1">
                            <mat-radio-button class="example-radio-button" value="Currency">
                                <span><img src="../../../../assets/images/iconsForsvg/tag-currency.svg"></span>
                                <span style="margin-left: 5px;">Currency</span>
                            </mat-radio-button>
                         </div>

                    </div>
                </mat-radio-group>

                <!-- Checkbox  -->
                <ng-container *ngIf="selectedradioval == 'Checkbox'">
                    <div class="row justify-content-start">
                        <div class="col-12">
                            <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                        </div><br>
                           <div class="row mt-2">
                            <div class="col-9">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Title </mat-label>
                                    <input matInput type="text" formControlName="title">
                                </mat-form-field>
                           </div>
                           <div class="col-3 p-1">
                            <mat-label>Tag icon Color</mat-label>
                            <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                              (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                              (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                           </div>
                           
                        </div>
                    </div>
                </ng-container>
            <!-- Freefeild  -->
            <ng-container *ngIf="selectedradioval == 'Freefeild'">
                <div class="row w-100" >
                    <mat-radio-group class="example-radio-group" aria-label="Select an option" formControlName="numeralType"
                        (click)="checkChanges()">
                        <mat-radio-button class="example-radio-button" checked="true" value="Alphanumeric">Alphanumeric
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button" value="Numerals">Numerals Only</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
            </ng-container>
            <!-- Dropdown  -->
             <ng-container *ngIf="selectedradioval == 'Dropdown'">
                <div class="row justify-content-start" >
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="col-9">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                    <div class="col-4 p-1">
                        <button mat-raised-button class="b-blue" (click)="addcreateBody()">Add value</button>
                    </div>
                    <div class="col-12">
                        <div class="row w-100" formArrayName="Adddropdownvalue">
                            <div class="row w-100" *ngFor="let conditionsBody of getfileBody().controls; let i=index"
                                [formGroupName]="i">
                                <div class="row w-100">
                                    <div class="col-11 p1">
                                        <mat-form-field class="w-100">
                                            <input matInput placeholder="value" formControlName="dropdownvalue">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="i!=0" class="col-1">
                                        <mat-icon (click)="removeConditionalBody(i)">delete</mat-icon>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
             </ng-container>

               <!-- email  -->
               <ng-container *ngIf="selectedradioval == 'email'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
            </ng-container>
                           <!-- PhoneNumber  -->
           <ng-container *ngIf="selectedradioval == 'PhoneNumber'">
            <div class="row justify-content-start">
                <div class="col-12">
                    <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                </div><br>
                <div class="row mt-2">
                    <div class="col-9">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title </mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                   </div>
                   <div class="col-3 p-1">
                    <mat-label>Tag icon Color</mat-label>
                    <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                      (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                      (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                   </div>
                   
                </div>
            </div>
            </ng-container>

            <!-- URL  -->
            <ng-container *ngIf="selectedradioval == 'URL'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                            <!-- currency  -->
            <ng-container *ngIf="selectedradioval == 'Currency'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Currency : </mat-label>
                                <mat-select>
                                  <mat-option value="€">€ Euro</mat-option>
                                  <mat-option value="£">£ Pound</mat-option>
                                  <mat-option value="¥">¥ Yen</mat-option>
                                  <mat-option value="$">$ Dollar</mat-option>
                                  <mat-option value="₣">₣ Franc</mat-option>
                                  <mat-option value="₽">₽ Ruble</mat-option>
                                  <mat-option value="₹">₹ Rupee</mat-option>
                                  <mat-option value="₺">₺ Lira</mat-option>
                                  <mat-option value="₴">₴ Hryvnia</mat-option>
                                  <mat-option value="₣">₣ Swiss Franc</mat-option>
                                  <mat-option value="A$">A$ Australian Dollar</mat-option>
                                  <mat-option value="B$">B$ Brazilian Real</mat-option>
                                  <mat-option value="C$">C$ Canadian Dollar</mat-option>
                                  <mat-option value="R$">R$ Brazilian Real</mat-option>
                                  <mat-option value="₹">₹ Indian Rupee</mat-option>
                                  <mat-option value="S$">S$ Singapore Dollar</mat-option>
                                  <mat-option value="HK$">HK$ Hong Kong Dollar</mat-option>
                                  <mat-option value="₣">₣ Swiss Franc</mat-option>
                                  <mat-option value="kr">kr Swedish Krona</mat-option>
                                  <mat-option value="kr">kr Danish Krone</mat-option>
                                </mat-select>
                              </mat-form-field>
                              
                        </div>
                        <div class="col-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label >Time Period Type: </mat-label>
                                <mat-select >
                                    <mat-option [value]="x" *ngFor="let x of CurrencyRange">
                                        {{x|titlecase}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                </ng-container>
                                            <!-- percentage  -->
            <ng-container *ngIf="selectedradioval == 'percentage'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                        <!-- date  -->
            <ng-container *ngIf="selectedradioval == 'date'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                     <!-- hour&minutes  -->
            <ng-container *ngIf="selectedradioval == 'hour&minutes'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                    <!-- Squarefeet  -->
            <ng-container *ngIf="selectedradioval == 'Squarefeet'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                            <!-- Squaremeter  -->
            <ng-container *ngIf="selectedradioval == 'Squaremeter'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                        <!-- days  -->
            <ng-container *ngIf="selectedradioval == 'Days'">
                <div class="row justify-content-start">
                    <div class="col-12">
                        <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                    </div><br>
                    <div class="row mt-2">
                        <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </div>
                </ng-container>
                <!-- Entity  -->
                <ng-container *ngIf="selectedradioval == 'Entity'">
                    <div class="row mt-2">
                        <div class="col-10">
                            <mat-form-field appearance="fill" style="width: 100% !important;" class="w-100">
                                <mat-label>{{"Select Entity"|translate}}</mat-label>
                                <mat-select formControlName="title">
                                    <mat-option [value]="type" *ngFor="let type of managementType" (click)="clickEntity(type)">
                                        {{type|translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                       </div>
                       <div class="col-9">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Title </mat-label>
                                <input matInput type="text" formControlName="title">
                            </mat-form-field>
                       </div>
                       <div class="col-3 p-1">
                        <mat-label>Tag icon Color</mat-label>
                        <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                          (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                          (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                       </div>
                       
                    </div>
                </ng-container>
                <!-- Files  -->
                <ng-container *ngIf="selectedradioval == 'Files'">
                    <div class="row justify-content-start">
                        <div class="col-12">
                            <mat-checkbox formControlName="isMandatory">{{"Mandatory"|translate}}</mat-checkbox>
                        </div><br>
                        <div class="row mt-2">
                            <div class="col-9">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Title </mat-label>
                                    <input matInput type="text" formControlName="title">
                                </mat-form-field>
                           </div>
                           <div class="col-3 p-1">
                            <mat-label>Tag icon Color</mat-label>
                            <input formControlName="colorcode" [style.background]="color1" style="border:none;width: 75%;" [(colorPicker)]="color1"
                              (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                              (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                           </div>
                           
                        </div>
                    </div>
                    </ng-container>
        </mat-card-content>
    </form>

        <!-- end   -->


        <!-- actions   -->
        <mat-card-actions>
            <div class="row w-100">
                <div class="col-6"></div>
                <div class="col-6">
                    <button mat-button mat-dialog-close (click)="close()">Cancel</button>
                    <button mat-raised-button class="b-blue" (click)="applytag()">Create</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>