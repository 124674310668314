import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailOptionsService {

  constructor() { }

  private sidemenuRules: { [key: string]: string[] } = {
    Trash: ['Restoreststus', 'MovetoFolderststus'],
    Schedule: ['Cancelststus', 'MovetoDraftsststus', 'Editststus'],
    Inbox: [
      'Markspammailststus',
      'Replyemailststus',
      'ReplyAllemailststus',
      'Forwardemailststus',
      'BlockEmailststus',
      'Markasunreadststus',
      'MarkasImportantststus',
      'Archiveststus',
      'Movetoststus',
      'CreateTaskststus',
      'CreateEventststus',
      'CreateInitiativeststus',
      'ConverttoContactststus',
      'Activitylogsststus',
      'Printststus',
      'Downloadststus',
    ],
    Sent: ['Replyemailststus', 'ReplyAllemailststus', 'Forwardemailststus', 'Archiveststus', 'Printststus', 'Downloadststus'],
    Spam: ['MarkasNotSpamststus', 'BlockSenderststus', 'ReportPhishingststus'],
    Draft: ['SaveasTemplateststus', 'MovetoFolderststus'],
  };

  checkStatus(action: string, sidemenusection: string): boolean {
    return this.sidemenuRules[sidemenusection]?.includes(action) || false;
  }
}
