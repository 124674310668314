<div class="d-flex flex-row align-items-center modal-header">
    <h2 *ngIf="screenType=='Planning'" mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">event</mat-icon>
        Other User Planning
    </h2>
    <h2 *ngIf="screenType=='Map'" mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">map</mat-icon>
        Other User Map
    </h2>
    <mat-icon matDialogClose>close</mat-icon>
</div> 

<mat-dialog-content class="mr-0 ml-0" *ngIf="screenType=='Map'">
    <form [formGroup]="otherPepoleform">
        <div class="row mt-2"> 
            <div class="col-12  col-sm-12  col-md-12 col-lg-6 col-xl-6 ">
                <mat-form-field class="w-100">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" (ngModelChange)="filterSearch($event)" placeholder="Search"
                        formControlName="search">
                </mat-form-field>
            </div>
            <div *ngIf="showicon" class="col-3">
                <button mat-raised-button class="b-blue" (click)="closesearch()">Remove
                    Filter</button>
            </div>
        </div>

        <div class="row m-t-10">
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-xl-6  p-2" *ngFor="let data of AllUsersdata">
                <mat-radio-button [value]="data.id" class="example-margin" (change)="checkBoxSelact(data)">
                    <img class="avatar" [src]="data?.profilePicture ? data.profilePicture:'assets/avatar.svg'"
                        onError="this.src='assets/avatar.svg'">
                    {{data.firstName|titlecase}} {{data.lastName|titlecase}}
                </mat-radio-button>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-content class="mr-0 ml-0" *ngIf="screenType=='Planning'">



  <div class="table-container">
    <!-- Toggle Between Month and Year View -->
     <!-- <div class="row mb-3 mt-3">
      <div class="col-4  mt-10 calender-nav text-sm-center text-md-left text-lg-left text-xl-left">
        <mat-button-toggle-group #group="matButtonToggleGroup" *ngIf="alldata !== '2'">
            <mat-button-toggle value="center" aria-label="" mwlCalendarPreviousView
            (click)="navigate('previous')">
                <mat-icon [disabled]="alldata == 2">keyboard_arrow_left</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="center" aria-label="" mwlCalendarNextView
            (click)="navigate('next')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-4 text-md-center" style="margin:auto">
      <span style="font-weight: bold;"> {{ viewDisplay }}  </span>
    </div>
    <div class="col-4 view-toggle  text-end fw-600">
        <button (click)="viewMode = 'month'" [class.active]="viewMode === 'month'">Month View</button>
        <button (click)="viewMode = 'year'" [class.active]="viewMode === 'year'">Year View</button>
      </div>
     </div>
    -->
 
<div class="row mt-4 mb-2">
  <form [formGroup]="Analytics" class="w-100">
    <div class="row w-100">
        <div class="col-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"Start Date"|translate}}</mat-label>
                <input matInput formControlName="StartDate" [matDatepicker]="startdatepicker3" style="color: black;">
                <mat-datepicker-toggle matSuffix [for]="startdatepicker3"></mat-datepicker-toggle>
                <mat-datepicker #startdatepicker3></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"End Date"|translate}}</mat-label>
                <input matInput formControlName="EndDate" [matDatepicker]="enddatepicker3" style="color: black;">
                <mat-datepicker-toggle matSuffix [for]="enddatepicker3"></mat-datepicker-toggle>
                <mat-datepicker #enddatepicker3></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-3">
            <button mat-raised-button  
            class="b-blue" (click)="getfilterdata()">{{"Apply"|translate}}</button>
        </div>
    </div> 
</form>
</div>
  
    <table class="month-table" *ngIf="AllUsersdatabaseddate.length!=0">
      <thead>
        <tr>
          <th>Select Period</th>
          <th *ngFor="let day of AllUsersdatabaseddate[0].dates">{{ getdayonly(day.date) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of AllUsersdatabaseddate; let index = index;">
          <td>
            <div class="user-info" (click)="showotheruserplan(user)">
              <img *ngIf="user.creatorProfile" [src]="user.creatorProfile" alt="User Image" class="m-0 user-image" />
              <div *ngIf="user?.creatorProfile==''||user?.creatorProfile==null" [style.background]="getColor(index)"  class="profile_default">{{getuserFLnames(user?.creatorFirstName+' '+user?.creatorLastName)}}</div>
              <div style="margin-left: 5px;">
                <div class="user-name m-0" style="text-align: left;">{{ user.creatorFirstName }} {{ user.creatorLastName }}</div>
                <div class="user-role" style="text-align: left;">{{ user.creatorPosition }}</div>
              </div>
            </div>
          </td>
          <td *ngFor="let day of AllUsersdatabaseddate[index].dates">
            <div class="bar-container">
              <div
                class="bar" (click)="ViewHours(day, user)"
                [style.height.%]="day.hoursWorked * 5 || 0"
                [title]="roundedValue(day.hoursWorked) || 0"
              ></div>
            </div>
            {{roundedValue(day.hoursWorked)|| 0}}
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  

</mat-dialog-content>


<div *ngIf="screenType=='Map'" class="row mt-2 justify-content-end" style="margin-bottom: 8px;">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="b-blue m-l-10" (click)="GetData()">Apply</button>
    </div>
</div>

<!-- <div class="overlay" *ngIf="spinner.isVisible() | async">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div> -->