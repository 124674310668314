import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ManageProductsService } from '../../../../app/core/services/manage-products.service';
import { MessageService } from '../../../../app/core/services/message.service';

@Component({
  selector: 'app-addtag-common',
  templateUrl: './addtag-common.component.html',
  styleUrls: ['./addtag-common.component.scss']
})
export class AddtagCommonComponent implements OnInit {
  selectedradioval: any;
  selectednumeralval: any;
  tagForm: FormGroup;
  viewtagmodel :any= {};
  showfreefeild!: boolean;
  tagViewModels:any = [];
  showcheckbox!: boolean;
  dropdownValues = [1, 2, 3, 4, 5]
  tags: any;
  tagType: any
  selectedvalue: any;
  value!: number;
  i = 1;
  dropdownValue :any= []
  type: any;

  managementType = [
    'Users',
    'Contacts',
    'Sites',
    'Products',
  ];
  CurrencyRange=['Hour','Day','Month','Year']

  public color1: string = '#EA3323';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddtagCommonComponent>,
    private manageProductsService: ManageProductsService,
    private messageService: MessageService,
  ) {
    this.tagForm = this.formBuilder.group({
      TagViewModels: [[]],
      tagType: [''],
      title: [''],
      colorcode:[''],
      tagdropdown: [''],
      ischecked: [''],
      numeralType: [''],
      freefeildTitle: ['', Validators.pattern('^[a-zA-Z \-\']+')],
      freefeildnumber: [''],
      feildname: [''],
      fieldvalue: [],
      dropdownValue: [''],
      freefeildvalue: [''],
      dropdowntype: [''],
      dropdown: [''],
      isMandatory:[false],
      Adddropdownvalue: this.formBuilder.array([
        this.createBody()
      ])
    });
  }

  // --------- 
  public onEventLog(event: string, data: any): void {
    console.log(event, data);
    this.tagForm.get('colorcode')?.setValue(data);
  }

  createBody(): FormGroup {
    return this.formBuilder.group({
      dropdownvalue: ['']
    })
  }
  addcreateBody() {
    this.getfileBody().push(this.createBody())
  }

  removeConditionalBody(i: number) {
    this.getfileBody().removeAt(i);
  }
  getfileBody() {
    return this.tagForm.get('Adddropdownvalue') as FormArray;
  }
  ngOnInit(): void {
    this.tagType = this.data
  }

  close() {
    this.dialogRef.close(true)
  }
  checkChanges() {
    this.tagForm.get('numeralType')?.valueChanges.subscribe(val => {
      this.selectednumeralval = val;
    });
  }
  getdropdown(i:number) {
    this.selectedvalue = i;
  }
  onChanges() {
    this.tagForm.get('tagType')?.valueChanges.subscribe(val => {
      this.selectedradioval = val;
    });
  }
  clickEntity(type){
    console.log(type)
    this.type=type
  }

  applytag() {
    console.log(this.tagForm.value)
    if (this.tagType == 'TransactionsfororderScreen') {
      this.dialogRef.close(this.tagForm.value)
    } else {
      if (this.tagForm.value.Adddropdownvalue.length != 0) {
        this.tagForm.value.Adddropdownvalue.forEach((element:any) => {
          this.dropdownValue.push(element.dropdownvalue)
        })
      }

      this.tagViewModels = [];
      this.viewtagmodel['fieldName'] = this.tagForm.value.title;
      this.viewtagmodel['ColorCode'] = this.tagForm.value.colorcode;
      this.viewtagmodel['isMandatory'] = this.tagForm.value.isMandatory;
      this.viewtagmodel['fieldValue'] = '';
      this.viewtagmodel['fieldType'] = this.selectedradioval;


      if (this.selectedradioval == 'Dropdown') {
        this.viewtagmodel['dropdownValues'] = this.dropdownValue.toString();
      }else{
        this.viewtagmodel['dropdownValues'] = ''
      }
      this.tagViewModels.push(this.viewtagmodel);
      this.manageProductsService.createtag(this.tagViewModels, this.tagType).subscribe(
        (data) => {
          this.messageService.showMessage(data['response'][0].message);
          this.tagViewModels = [];
          this.dialogRef.close(true)
        });
    }


  }


}
