<pnm-top-navbar></pnm-top-navbar>
<div mat-dialog-title
    style="display: flex;justify-content: space-around;background-color: #f3f0f069;overflow: hidden;">
    <div style="width: 10%;position: relative;top: 10px;">
        <!-- <mat-icon style="color: black;" (click)="clocepopup()">keyboard_backspace</mat-icon> -->
    </div>
    <div style="width: 80%;position: relative;top: 10px;">
        <h1 style="text-align: center;"><b>Service</b></h1>
        </div>
    <div style="width: 10%;position: relative;top: 10px;">
        <mat-icon style="color: black;" (click)="downloadFile()">cloud_download</mat-icon>
        </div>
</div>

   <div class="row justify-content-center">
    <div class="col-11">
        <div class="row mt-3">
            <h2 style="text-align: center;">
                <b><u>GENERAL CONDITIONS OF PLANNING&ME SERVICE</u></b></h2> 
            <h2>The content accessible from the address www.PLANNINGANDME.com is
                provided by PLANNING&ME</h2>
            <h2><b><u>Preamble</u></b></h2>
            <h2>The Service provided by PLANNING&ME is accessible only to legal
                entities as well as individuals who take out a subscription.</h2>
            <h2>Browsing the Platform implies acceptance by any User of these
                General Conditions. Simply connecting to the Online Platform will
                imply full acceptance of these General Conditions. When registering
                on the Platform, this acceptance will be confirmed by checking the
                box corresponding to the following sentence: “I acknowledge having
                read and accepted the General Conditions”. The User also
                acknowledges having read them fully and accepts them without
                restriction.</h2><br>
    
            <h2><b><u>Article 1: Definitions</u></b></h2>
            <h2><b>“Subscription”</b>designates the period for which the User
                subscribes to the services (under conditions limited by a number of
                credits) which may be monthly or annual.</h2>
            <h2><b>“General Conditions” </b>means these general conditions of use of
                the PLANNING&ME service.</h2>
            <h2><b>“Content”: </b>Data of a professional nature concerning
                exclusively companies directly or indirectly</h2>
            <h2><b>“Platform” </b>means the PlanningandMe.com Online Services</h2>
            <h2><b>“Service” </b>designates all of the services offered by
                Planning&Me on the PLANNINGANDME.com </h2>
            <h2><b>“User” </b>means the legal or natural person who has subscribed
                to a Subscription and who purchases content on PlanningandMe.com</h2><br>
    
            <h2><b><u>Article 2: Purpose and limitation of the Service</u></b></h2>
            <h2>PLANNINGANDME is a decision-making aid platform linked to
                professional sales.</h2>
            <h2>The main purpose of the data made available on PlanningandMe.com is
                to enable its users to send commercial information to professionals
                relating to products or services that concern them.</h2>
            <h2>The data concerned are generally:</h2>
            <h2>- First and last names,</h2>
            <h2>-Functions,</h2>
            <h2>- Professional email addresses,</h2>
            <h2>- Address of head offices,</h2>
            <h2>- SIRET number,</h2>
            <h2>- RCS number,</h2>
            <h2>-Etc …</h2>
            <h2>These General Conditions constitute the contract by which
                Planning&Me offers and provides the Service to its users and
                Clients.</h2><br>
    
            <h2><b><u>Article 3: Creation of a personal space</u></b></h2>
            <h2>Use of the Service requires the creation of a personal space.</h2>
            <h2>If he chooses to create a personal space, the User must choose a
                connection identifier and a password which must contain at least 8
                characters of 4 different types: lowercase, uppercase, numbers and
                special characters and that in accordance with the recommendations
                of the CNIL on the subject
                (https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-un-bon-mot-de-passe).</h2>
            <h2>This login ID and password are personal, confidential and not
                transferable to third parties. The User is the owner of the account
                created and has access to it via his login ID and password. The
                login ID/password combination is strictly personal. This combination
                is specific to each User. It is therefore up to the User to
                guarantee that the confidentiality of this combination allowing
                access to the Service is maintained.</h2>
            <h2>In the event of loss and/or forgetting of the password, the User may
                request a password reset from the Site. The User also undertakes not
                to communicate this identification and password information to any
                other person, nor to lend it, give it and, in general, make the
                account and/or password available from a third party, for any reason
                whatsoever. Otherwise, Planning&Me cannot be held liable in any way
                in the event of fraudulent use of this information.</h2>
            <h2>After having previously informed the User, Planning&Me may, for the
                purposes of operating the Service and preserving data security,
                modify, change or delete a username and/or password at any time.</h2>
            <h2>The User records his personal information (first and last name,
                email, password).</h2>
            <h2>The User guarantees the veracity and accuracy of the information he
                communicates to Planning&Me via the Website. The creation of the
                personal space can only be validated following the express
                acceptance of these General Conditions by the User, who will thereby
                acknowledge having read them.</h2><br>
    
            <h2><b><u>Article 4: Price and payment conditions</u></b></h2>
            <h2>The prices indicated on the Planning&Me Website for subscribing to a
                Subscription are in euros and excluding taxes (HT). Planning&Me
                reserves the right to modify these prices at any time. The price
                change is not applicable to orders already placed.</h2>
            <h2>Prices are valid as long as they are displayed on the site</h2>
            <h2>To subscribe to a Subscription, the Planning&Me User must choose
                from the offers offered to them and enter the information necessary
                for invoicing.</h2>
            <h2>The payment of the price is broken down as follows:</h2>
            <h2>- The payment of a fixed price when subscribing to the Subscription
                “with commitment” which can be monthly, annual and also depends on
                the volume of accounts ordered.</h2>
            <h2>- The payment of an “additional” price within the meaning</h2>
            <h2>o a number of calls exceeding the authorized volume on a daily
                basis. The price of the overage varies depending on the number of
                calls.</h2>
            <h2>o A file storage volume exceeding the authorized limit of 2 GB per
                license. The overage price varies depending on the number of files.</h2>
            <h2>o A database storage volume exceeding the authorized limit of 20 GB
                for the entire organization plus an additional 0.5 GB per
                organization license. The price of overage varies depending on the
                volume of database storage</h2>
            <h2>Payment for the Service is made online by credit card. As long as
                the payment form is not validated and the payment accepted by the
                banking operator, the subscription to the Subscription cannot be
                considered completed.</h2>
            <h2>Once payment has been made, the User receives an order confirmation
                email.</h2>
            <h2>If the payment is accepted by the banking operator but is
                subsequently rejected (in particular for opposition, lack of funds,
                fraudulent use, etc.), Planning&Me may automatically cancel the
                order , without formality or compensation of any kind or asking the
                User to make payment by any means.</h2>
            <h2>The User acknowledges and accepts that the execution of the
                provision of the Services begins from the confirmation of the order
                by Planning&Me.</h2><br>
    
            <h2><b><u>Article 5: Use of the Website</u></b></h2>
            <h2>To use the Planning&Me online Platform, the User must be legally
                capable of entering into a contract.</h2>
            <h2>A User has the possibility of connecting using an identifier which
                is the email address and a password, information provided at the
                time of registration. If the connection is successful, the User has
                access to their space. A connected User has the possibility of
                disconnecting from their space. This ends the session that was
                opened at the time of connection.</h2><br>
    
            <h2><b><u>Article 6: Technical prerequisites</u></b></h2>
            <h2>Planning&Me has been tested on phones and tablets running iOS and
                Android as well as PC and MAC computers running the following
                operating systems:</h2>
            <h2>⮚ Windows</h2>
            <h2>⮚ Mac OS X >=X recommended</h2>
            <h2>As well as for browsers:</h2>
            <h2>⮚ Chromium</h2>
            <h2>⮚ Firefox</h2>
            <h2>⮚ Internet Explorer</h2>
            <h2>⮚ Safari</h2>
            <h2>⮚ Opera</h2>
            <h2>The User is informed that there is a possibility that malfunctions
                may appear on computers or from browsers not appearing in the
                aforementioned lists, or from computers with a particularly slow
                Internet connection.</h2>
            <h2>The User ensures that it has read, prior to signing this document,
                the technical prerequisites referred to in this article.</h2><br>
    
            <h2><b><u>Article 7: Obligations and responsibility of Planning&Me</u></b></h2>
            <h2>Planning&Me only responds to an obligation of means concerning the
                platform, PlanningandMe.com, subject hereof.</h2>
            <h2>Planning&Me will not, moreover, be held liable in the event of force
                majeure or fault of the User, as defined in this article:</h2>
            <h2>For the purposes of these General Conditions, any impediment,
                limitation or disruption of the Service due to fire, epidemic,
                explosion, earthquake, fluctuations in temperature will be
                considered as a case of force majeure opposable to the User.
                bandwidth, failure attributable to the access provider, failure of
                transmission networks, collapse of installations, illicit or
                fraudulent use of passwords, codes or references provided to the
                User, computer hacking, a security breach attributable to the
                platform host or developers, flood, power outage, war, embargo, law,
                injunction, request or requirement of any government, requisition,
                strike, boycott, or other circumstances (intervention of law
                enforcement in areas where its premises or those of its servers are
                located) beyond the reasonable control of PlanningandMe.com. In such
                circumstances, Planning&Me will be exempt from the performance of
                its obligations within the limits of this impediment, this
                limitation or this disturbance.</h2>
            <h2>Planning&Me cannot be held responsible if the non-execution or delay
                in the execution of any of its obligations, as described herein,
                results from a case of force majeure, within the meaning of article
                1218. of the Civil Code, and in particular in the following cases:
                breakdown or malfunction of telecommunications networks (Internet,
                telephony, etc.), preventing the availability of the Service,
                unusual traffic disruptions (demonstration, bad weather, traffic
                accident, etc.)</h2>
            <h2>For the purposes of these General Conditions, any misuse of the
                platform, fault, negligence, omission or failure on their part or
                that of their agents, non-compliance with the advice given will be
                considered as a fault of the User against the latter. by Planning&Me
                on its Platform, any disclosure or illicit use of the User's
                password, codes and references, as well as the provision of
                incorrect information or the absence of updating of such information
                in their personal space. The implementation of any technical
                process, such as robots, or automatic requests, the implementation
                of which would contravene the letter or spirit of these General
                Conditions will also be considered as a fault of the User.</h2>
            <h2>In the event of impossibility of access to the platform, due to
                technical problems of any nature, the User will not be able to claim
                damage and will not be able to claim any compensation. The
                unavailability, even prolonged and without any limitation period, of
                one or more online Services, cannot constitute harm to Users and
                cannot in any way give rise to the award of damages from
                Planning&Me.</h2><br>
    
            <h2><u>Article 8: Obligations and responsibility of the User</u></h2>
            <h2><u>8.1 Respect of PlanningandMe.com servers</u></h2>
            <h2>The User also undertakes not to hinder or disrupt the servers of
                PlanningandMe.com and to comply with the required conditions,
                procedures and general rules communicated to him by
                PlanningandMe.com.</h2>
            <h2>Failing to comply with the aforementioned obligations,
                PlanningandMe.com reserves the right to suspend the User's
                PlanningandMe.com account at any time or even delete it permanently.</h2>
            <h2>In the absence of conclusive justification demonstrating that the
                User complies with the provisions of these General Conditions,
                PlanningandMe.com therefore reserves the right, without further
                notice or formality, to suspend or delete access to the platform,
                after the have notified by email.</h2>
            <h2>In the event of non-compliance by the User with these commitments,
                PlanningandMe.com also reserves the right to use legal action to
                obtain, from the User, compensation for the damage they have
                suffered.</h2>
            <h2><u>8.2 Termination and/or Restriction of access to the Service</u></h2>
            <h2>Planning&Me may restrict access to the Service to any User, without
                notice, in particular but not limited to, in the following cases:</h2>
            <h2>- non-compliance by the User with their obligations</h2>
            <h2>The same reasons and in general, the User's failure to comply with
                the provisions set out in Article 8 may result in termination and
                therefore deletion of the User's account.</h2><br>
            <h2><b><u>Article 9: Availability and access</u></b></h2>
            <h2>Planning&Me undertakes to ensure that the User can connect to the
                platform at any time, excluding maintenance and update periods.</h2>
            <h2>Any observation of total or partial unavailability of the
                planningandme.com platform by the User may be notified to
                Planning&Me at the address PlanningandMe.com with the subject
                “Report a malfunction”.</h2>
            <h2>Planning&Me cannot be held responsible in the event of
                unavailability and/or malfunction and/or breakdown occurring on the
                electronic communication and mobile radio communication networks and
                which would limit or prevent access to all or part of the Service
                (examples (network congestion, failure of Internet service
                providers, power failure, software or hardware malfunction, etc.)</h2><br>
    
            <h2><b><u>Article 10. Reversibility of data</u></b></h2>
            <h2>At the Client's request, Planning&Me will provide the User with a
                copy of all of their Data in CSV format if they request it before or
                concurrently with the termination of the contract to the following
                email address:</h2>
            <h2>contact@planningandme.com</h2>
            <h2>The Data will be returned within a maximum period of 30 days.</h2>
            <h2>Reversibility operations will begin, at the User's request, as soon
                as possible, taking into account the expiry date of the Contract.</h2>
            <h2>In this regard, Planning & Me has no right of retention, for any
                reason whatsoever, on any element belonging to the User.</h2><br>
            <h2><b><u>Article 11: Information technology and freedoms and personal
                        data</u></b></h2>
            <h2><u>11.1. Collection of personal data</u></h2>
            <h2>In application of law 78-17 of January 6, 1978 and the General Data
                Protection Regulation (GDPR) of April 27, 2016, it is recalled that
                the personal data requested from the User are necessary to process
                their order and the preparation of invoices, in particular.</h2>
            <h2>The User has, in accordance with national and European regulations
                in force, a right of permanent access, modification, rectification
                and opposition with regard to information concerning him.</h2>
            <h2>This right can be exercised:</h2>
            <h2>- By letter :</h2>
            <h2>Planning & Me (Landry NGAKAM) 40 Avenue de Saint Mandé 75012 PARIS
                FRANCE</h2>
            <h2>- by email to the address:</h2>
            <h2>contact@PlanningandMe.com</h2>
            <h2>Planning&Me undertakes to ensure the confidentiality of personal
                data communicated by the User.</h2>
            <h2>The User is informed that Planning&Me may use personal data
                concerning the User in order to send them commercial offers likely
                to be of interest to them.</h2>
            <h2>The User consents upon registration of his or her email address to
                receive by email any newsletter sent by Planning&Me.</h2>
            <h2>The data collected is kept by Planning&Me for the duration of the
                contractual relationship and then, up to 3 years after the end of
                this relationship.</h2>
            <h2><u>11.2 The purposes of the processing</u></h2>
            <h2>The data collected by Planning&Me has the main purpose of allowing
                its users to send commercial information to professionals relating
                to products or services that concern them.</h2>
            <h2><u>11.3 Right of complaint, erasure and forgetting</u></h2>
            <h2>The User is informed of the fact that he has the possibility of
                submitting a complaint to the Commission Nationale de l’Informatique
                et des Libertés either, via the following URL:
                https://www.cnil.fr/fr/plaintes or, via postal mail (3 Place de
                Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07).</h2>
            <h2>The data subject has the right to obtain from the controller the
                erasure, without undue delay, of personal data concerning him or her
                and the controller has the obligation to erase such personal data
                without undue delay. , when the personal data are no longer
                necessary for the purposes for which they were collected or
                otherwise processed (closure of the personal space); by writing to
                PlanningandMe.com</h2>
            <h2>Furthermore, when the person concerned was a minor at the time of
                data collection, they can obtain from Planning&Me the erasure of
                data that they consider problematic for which Planning&Me is the
                host, as soon as possible; by writing to PlanningandMe.com.</h2>
            <h2><u>11.4 Rights of deceased persons</u></h2>
            <h2>Planning&Me allows people to give instructions relating to the
                conservation, erasure and communication of their data after their
                death by writing to PlanningandMe.COM.</h2>
            <h2>Through this, a person can be designated to carry out these
                directives. The latter then has the right, when the person has died,
                to read the directives and request their implementation from the
                data controllers concerned.</h2>
            <h2>In the absence of directives given during his lifetime by the
                person, the heirs, who will demonstrate this (act of notoriety) will
                have the possibility of exercising the right of access, on the
                condition that it is necessary for the payment of the deceased's
                estate, the right of opposition in order to close the deceased's
                user accounts and oppose the processing of their data.</h2>
            <h2><u>11.5 Cookie management</u></h2>
            <h2>A “cookie” is an information file of limited size, installed on your
                personal equipment by the Websites visited by the Premium User.
                Cookies allow you to store browsing information, such as your
                browsing preferences or profile information.</h2>
            <h2>Cookies can be created by our Site, they are then called first-party
                cookies, or come from third-party Sites.</h2>
            <h2>Certain internal cookies are necessary for the operation of the
                Site, they allow it to function optimally.</h2>
            <h2>Other internal cookies are not necessary for the operation of the
                Site.</h2>
            <h2>Third-party cookies come from third-party sites, they are not
                necessary for the operation of the Site.</h2>
            <h2>During their first visit to the PlanningandMe.com Site, a banner
                informs the User of the presence of these cookies and invites them
                to indicate their choice. They are then only deposited if the User
                continues to browse the PlanningandMe.com Site. The User can at any
                time object to all or part of the cookies and delete them using your
                browser settings. However, if he objects to the cookies necessary
                for the operation of the Site, his user experience risks being
                degraded.</h2>
            <h2>Without deletion by the Premium User, cookies are kept on their
                equipment for a maximum period of 13 months.</h2>
            <h2>Among cookies, some have the purpose of audience measurement. It's
                about :</h2>
            <h2>- Analytical and performance cookies. They help us estimate the
                number of our visitors and evaluate their usage habits on the Site.
                They are also used to track conversions.</h2>
            <h2>- Targeting or advertising cookies. They help us recognize the User
                when they return to our Site and allow us to personalize our Site or
                offer them targeted offers based on their interests.</h2>
            <h2>The User's choices regarding cookies can be expressed directly with
                their terminal. In this case, he can authorize or not the recording
                of cookies in his terminal by using the appropriate settings of his
                Internet browsing software. Cookie configuration may vary from one
                browser to another:</h2>
            <h2><b>●</b> For Internet Explorer:</h2>
            <h2><u>http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</u></h2>
            <h2><b>●</b> For Safari:</h2>
            <h2><u>http://support.apple.com/kb/PH5042</u></h2>
            <h2><b>●</b> For Chrome:</h2>
            <h2><u>http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647</u></h2>
            <h2><b>●</b> For Firefox:</h2>
            <h2><u>http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</u></h2>
            <h2><b>●</b> For Opera:</h2>
            <h2><u>http://help.opera.com/Windows/10.20/fr/cookies.html</u></h2><br>
            <h2><u>11.6 No data transfers outside the EU</u></h2>
            <h2>Planning&Me rents a dedicated server from the company AMAZON which
                assures it that the data stored by Users will be hosted in the
                European Union.</h2><br>
            <h2><b><u>Article 12: End of the contract and fate of the data</u></b></h2>
            <h2>At the end of the Contract or in the event of early termination of
                the latter for any reason whatsoever, Planning&Me will destroy the
                copies of the Data held in its computer systems within a reasonable
                period unless the request for reversibility, under the conditions
                provided for in the article 10 of these Conditions, is carried out
                before or concomitantly with the termination.</h2><br>
            <h2><b><u>Article 13: Service Level Commitment (SLA)</u></b></h2>
            <h2>Planning&Me will endeavor to ensure the availability of the Service
                24 hours a day, 7 days a week, subject to any planned maintenance
                requiring temporary technical interruption.</h2>
            <h2>Planning&Me, which subcontracts the hosting service it provides on
                behalf of the User, commits to the following level of Service:</h2>
            <h2>An overall monthly availability rate of 95%.</h2>
            <h2>A monthly availability rate of 99% during working hours of a
                business day (Monday to Friday from 9 a.m. to 7 p.m.) is also
                assured.</h2>
            <h2>Planning&Me commits to a maximum of 4 hours for handling a major
                incident as well as a maximum recovery time of 12 hours during
                opening hours in Paris from 7:30 a.m. to 3:30 p.m. Monday to Friday.</h2><br>
            <h2><b><u>Article 14: Modifications</u></b></h2>
            <h2>Planning&Me may modify these terms as necessary. In this case, the
                modifications made will only be applicable upon the expiry of a
                period of 30 working days from the modification. The User is invited
                to regularly consult this page and monitor possible changes compared
                to previous versions.</h2><br>
            <h2><b><u>Article 15: Intellectual property</u></b></h2>
            <h2>The content, structure and software implemented for the operation of
                the Platform are protected by copyright. Any representation or
                reproduction in whole or in part of the Platform without the
                agreement of Planning&Me, the legal entity publishing the Site
                PlanningandMe.com constitutes a violation of the provisions of the
                Intellectual Property Code and may give rise to legal proceedings
                (article 335- 2 et seq. of the Intellectual Property Code). Any
                reproduction of data disclosed on the platform engages the
                responsibility of the User and may, moreover, lead to legal
                proceedings, in particular for counterfeiting. The same applies to
                translation, adaptation or transformation, arrangement or
                reproduction by any art or process.</h2><br>
            <h2><b><u>Article 16: Evidence agreement</u></b></h2>
            <h2>The parties expressly agree that the use of the User's account by
                themselves or by a third party will be evidence between them.
                Planning&Me's recording systems are considered proof of the date and
                duration of use. All elements relating to the use of the account
                will be kept and archived by Planning&Me. PlanningandMe.com may
                rely, in particular for evidentiary purposes, on any act, file,
                recording, monitoring report, statistics on all media including the
                computer media established, received or kept by it. These methods of
                proof constitute a presumption which could only be rebutted in the
                presence of elements establishing that the means of recording and
                storage of PlanningandMe.com were actually faulty.</h2><br>
            <h2><b><u>Article 17: Right of withdrawal</u></b></h2>
            <h2>The right of withdrawal does not apply to the monthly and annual
                Services that the User orders in particular in consideration of the
                exception to the provisions provided for in article L121-21 of the
                Consumer Code set out in article L. 121-21-8 1° of the said Code
                reproduced below:</h2>
            <h2>“The right of withdrawal cannot be exercised for Service contracts
                fully executed before the end of the withdrawal period and whose
                execution has begun after the express prior agreement of the
                consumer and express waiver of his right of withdrawal”.</h2>
            <h2>The User acknowledges that the Service to which Planning&Me gives
                him access involves in particular the rapid provision, and in any
                case within a period of less than fourteen (14) days, of said
                Premium Service, thus making it impossible for the User to benefit
                from his right of withdrawal.</h2><br>
            <h2><b><u>Article 18: Final provisions</u></b></h2>
            <h2>These General Conditions are subject to French law. Planning&Me is
                authorized to modify the content of these General Conditions.
                Planning&Me keeps each version of its General Conditions and can
                send them to the User upon simple request. The licensing conditions
                of program publishers, independent of Planning&Me, included in the
                platform provided by Planning&Me, are applicable cumulatively with
                these General Conditions, which the User accepts without
                reservation. The nullity of one of the clauses of these General
                Conditions will not result in the nullity of the other clauses of
                the General Conditions or of the General Conditions in their
                entirety, which will retain their full effect and scope. In such a
                case, the parties must, as far as possible, replace the canceled
                stipulation with a valid stipulation corresponding to the spirit and
                purpose hereof. The absence of exercise by Planning&Me of the rights
                granted to it hereby cannot under any circumstances be interpreted
                as a waiver of the right to assert said rights. In the event that
                the User were to be a professional, the French courts within the
                jurisdiction of the Paris Court of Appeal will have sole
                jurisdiction.</h2>
    
        </div>
    </div>
   </div>
   <pnm-navbar-footer></pnm-navbar-footer>
