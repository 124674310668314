import { Component, Injector, OnInit } from '@angular/core';
import { ReportingSearchComponent } from '../reporting-search/reporting-search.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';

@Component({
  selector: 'app-report-export',
  templateUrl: './report-export.component.html',
  styleUrls: ['./report-export.component.scss']
})
export class ReportExportComponent implements OnInit {

  ReportForm: FormGroup

  users = [
    {
      firstName: 'John',
      lastName: 'Doe',
      profile: '', // Profile image URL
      position: 'Software Engineer',
      weekHours: [40, 42, 38, 45, 40, 39, 40, 42, 38, 45],
    },
    {
      firstName: 'Jane',
      lastName: 'Smith',
      profile: '', // Profile image URL
      position: 'Product Manager',
      weekHours: [38, 40, 42, 41, 39, 43, 42, 44, 40, 39],
    },
    {
      firstName: 'Michael',
      lastName: 'Brown',
      profile: '', // Profile image URL
      position: 'UI/UX Designer',
      weekHours: [42, 44, 40, 39, 41, 45, 36, 38, 40, 42],
    },
    {
      firstName: 'Emily',
      lastName: 'Johnson',
      profile: '', // Profile image URL
      position: 'DevOps Engineer',
      weekHours: [36, 38, 40, 42, 40, 38, 36, 38, 40, 42],
    },
    {
      firstName: 'Siva',
      lastName: 'Babu',
      profile: '', // Profile image URL
      position: 'UI/UX Designer',
      weekHours: [42, 44, 40, 39, 41, 45, 36, 38, 40, 42],
    },
    {
      firstName: 'Arun',
      lastName: 'Kumar',
      profile: '', // Profile image URL
      position: 'Software Engineer',
      weekHours: [40, 42, 38, 45, 40, 39, 40, 42, 38, 45],
    },
  ];
  

  weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'week 7', 'week 8', 'week 9', 'week 10'];
  displayedColumns = ['name', ...this.weeks];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private injector: Injector,
  ) {

    this.ReportForm = this.formBuilder.group({
      startDate: [''],
      endDate: ['']
    })
   }

  ngOnInit(): void {
  }

  AdvanceSearch() {
    const AdvanceSearch = this.dialog.open(
      ReportingSearchComponent,
      {
        disableClose: true,
        data: { type: 'Users' },
        width: '500px',
      }
    );
    
    AdvanceSearch.afterClosed().subscribe((result: any) => {
      console.log(result)
       
    })
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

}
