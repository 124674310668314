import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Inject,
  Injector,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ManageuserService } from '../../manage-users/manageuser.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ManageimportComponent } from '../../manageimport/manageimport.component';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../core/services/auth.service';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageUsersService } from '../../../../core/services/manage-users.service';
import { PopupCommonComponent } from '../../../commonForAll/popup-common/popup-common.component';
import { MessageService } from '../../../../core/services/message.service';
import { MessagePopupComponent } from '../../../commonForAll/message-popup/message-popup.component';
import { AddtagCommonComponent } from '../../../commonForAll/addtag-common/addtag-common.component';
import { DeleteCommomComponent } from '../../../commonForAll/delete-commom/delete-commom.component';
import { ManageContactsService } from '../../../../core/services/manage-contacts.service';
import { AddFileComponent } from '../../../files/components/add-file/add-file.component';
import { AddNotesComponent } from '../../../files/components/add-notes/add-notes.component';
import { FileandnotesformanagementComponent } from '../../../commonForAll/fileandnotesformanagement/fileandnotesformanagement.component';
import { FileService } from '../../../../core/services/file.service';
import { FilesAndNotesShareLinkComponent } from '../../../files/components/files-and-notes-share-link/files-and-notes-share-link.component';
import { ViewNotesComponent } from '../../../files/components/view-notes/view-notes.component';
import { ApiErrorMessagesComponent } from '../../../../api-error-messages/api-error-messages.component';
import { PopupErrorMessageComponent } from '../../../../popup-error-message/popup-error-message.component';
import { ApiSuccessMessagesComponent } from '../../../../api-success-messages/api-success-messages.component';
import { AccordianViewComponent } from '../../lead-automation/components/accordian-view/accordian-view.component';
import { AddAutomationComponent } from '../../lead-automation/components/add-automation/add-automation.component';
import { ManageProductsService } from '../../../../core/services/manage-products.service';
import { ManageSitesService } from '../../../../core/services/manage-sites.service';
import { DelegationService } from '../../../../core/services/delegation.service';
import { ImageCompressionService } from '../../../../core/services/image-compression.service';
import { GoogleValidationAddressService } from '../../../../core/services/google-validation-address.service';
import { FormDataService } from '../../../../core/services/form-data.service';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
})
export class CreateUserComponent implements OnInit {
  // 000000
  filterforuserlist: any;
  filterforcontactlist: any;
  filterforsiteslist: any;
  filterforuserGrouplist: any;
  filterforcontactGrouplist: any;
  filterforsiteGrouplist: any;
  filterforProsuctlist: any;
  filterforProsucGrouptlist: any;
  filterfortransactionlist: any;
  filterfororderlist: any;
  filterforeventslist: any;
  // ------
  userForm: FormGroup;
  additionalFieldLines: any[] = [];
  lineCounter: number = 0;
  users$ = [];
  filteredusers = [];
  dropdown!: boolean;
  selectedradioval: any;

  // selectedFile: ImageSnippet;
  viewtagmodel = {};
  showcheckbox!: boolean;
  selectednumeralval: any;
  tagarray = [];
  Freefeild = {};
  viewnumeral = {};
  showfreefeild!: boolean;
  value!: number;
  dropdownvalue = [];
  i = 1;
  tagViewModels = [];
  tags: any;
  dropdownfeild = {};
  datadelete: any;
  datadeleteindex: any;
  checked: any;
  selectedvalue: any;
  selectedtagvalue: any;
  profilePick: any;
  profilePickid: any;
  useriddata: any;
  location = 'BARNES LE MARAIS125, Rue Vieille-du-Temple,Paris 3ème,France';
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
  options = {
    types: ['address'],
    componentRestrictions: {},
  };
  screenType = '';
  AttachmentFileCount: any;
  AttachmentFileList: any;
  AttachmentNotesCount: any;
  AttachmentNotesList: any;
  ShowAttachmentFile = false;
  ShowAttachmentNotes = false;
  notesCount = 0;
  filesCount = 0;
  matDialogRefin: any;
  profileName: any;
  ContactsValue: any[] = [];
  ProductsValue: any[] = [];
  SitesValue: any[] = [];
  UsersValue: any[] = [];
  EventsValue: any[] = [];
  TransationValue: any[] = [];
  CurrencyRange=['Hour','Day','Month','Year']
  managementType = [
    'Users',
    'Contacts',
    'Sites',
    'Products',
    'Transactions',
    'Events',
  ];
  AllOption = [
    { v1: '=', v2: 'equal' },
    { v1: '!=', v2: 'not equal' },
    { v1: '>', v2: 'greater than' },
    { v1: '<', v2: 'less than ' },
    { v1: '=>', v2: 'greater than or equal' },
    { v1: '<=', v2: 'less than or equal' },
  ];
  isRestrictedUserAccessible = false;
  MandatoryColumns: any = [];
  AdminStatus: any;
  lablesbasedonentity:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateUserComponent>,
    private translate: TranslateService,
    private injector: Injector,
    private delegationService: DelegationService,
    private authService: AuthService,
    public spinner: SpinnerService,
    private snackBar: MatSnackBar,
  ) {
    this.AdminStatus = sessionStorage.getItem("isAdmin");
    this.isRestrictedUserAccessible =
      sessionStorage.getItem('isRestrictedUserAccessible') == 'true'
        ? true
        : false; 
    if (sessionStorage.getItem('lang')) {
      const lang: any = sessionStorage.getItem('lang');
      this.translate.use(lang);
    } else {
      this.translate.use('English');
    }
    this.userForm = this.formBuilder.group({
      id: [''],
      created: [''],
      FirstName: [''],
      LastName: [''],
      Gender: [''],
      Position: [''],
      Email: [''],
      Address: [''],
      ZipCode: [''],
      City: [''],
      Country: [''],
      ManagerId: [''],
      ManageUsers: [[]],
      PhoneNumber: [null],
      Profile: [''],
      profileName: [null],
      isActive: [{ value: 'true', disabled: true }],
      Latitude: [0],
      Longitude: [0],
      Addtages: this.formBuilder.array([]),
      RestrictUser: [false],
      Name: [null],
      ParentId: [null],
      ChildId: [null],
      previousStep: [null],
      step: [null],
      showIcon: [null],
      showColor: [null],
      positions: [null],
    });  
  }


  ngOnInit(): void {
    console.log(this.data);
    this.screenType = this.data.screenType;
    this.loadUsers();
    this.gettags();
    this.getAllColoumns();
    this.GetMandatoryColumns();
    this.GetLabelsByOrganization()
    if (this.screenType == 'UpdateUser') {
      this.updateuserDialog();
    } else if (this.screenType == 'EditUserAutomation') {
      this.updateUserAutomation(this.data.type)
    }
  }



// tag's section 

  Conditions(): FormArray {
    return this.userForm.get('Conditions') as FormArray;
  }



  createtageBody(): FormGroup {
    return this.formBuilder.group({
      tagTableId: 0,
      fieldName: '',
      colorCode:'',
      fieldType: '',
      fieldValue: '',
      dropdownValues: '',
      dropdownvaluetotal: '',
      TimePeriod :'',
      Value:'',
      files:this.formBuilder.array([])
    });
  }
  
  addfile(): FormGroup {
    return this.formBuilder.group({
      filename: '',
      sfile: '',
    });
  }

      // handleFileInput 
      handleFileInput(event:any,i,g) {
        const file = event.target.files[0];
        const fileToUploadname = event.target.files[0].name;
        this.gettagfiles(i).at(g).get('filename')?.setValue(fileToUploadname)
        this.gettagfiles(i).at(g).get('sfile')?.setValue(file)
      }

  addfilefortag(index){
    this.gettagfiles(index).push(this.addfile());
  }
  gettagfiles(index){
    return this.gettageBody().at(index).get('files') as FormArray
    }
    getfilename(i,k){
      this.gettagfiles(i).at(k).get('filename')
    }
    gettagfilename(i,g){
     return this.gettagfiles(i).at(g).get('filename')?.value
    }
  
    deletetagfile(i,g){
      this.gettagfiles(i).removeAt(g)
    }
  gettageBody() {
    return this.userForm.get('Addtages') as FormArray;
  }
  addtageBody() {
    this.gettageBody().push(this.createtageBody());
  }
  fieldname(i: number) {
    return this.gettageBody().at(i).get('fieldName')?.value;
  }
  colorCode(i: number) {
    return this.gettageBody().at(i).get('colorCode')?.value
  }

  dropdownvaluetotal(i: number) {
    return this.gettageBody().at(i).get('dropdownvaluetotal')?.value.split(',');
  }

  fieldType(i: number) {
    return this.gettageBody().at(i).get('fieldType')?.value;
  }
  tagTableId(i: number) {
    return this.gettageBody().at(i).get('tagTableId')?.value;
  }
  removeConditionalBody(i: number) {
    this.gettageBody().removeAt(i);
  }

// ------------ 



  onDoubleClick(value) {
    if(this.AdminStatus=='true'){
      const onDoubleClickDialog = this.dialog.open(PopupCommonComponent, {
        data: { value: value, screenType: 'onDoubleClick' },
        disableClose: true,
        width: '400px',
      });
      onDoubleClickDialog.afterClosed().subscribe((result) => {
        if (result) {
          const data={
            "id": this.lablesbasedonentity[value].id,
            "entityName": "User",
            "defaultColumnName": value,
            "newColumnName": result
          }
          this.authService.UpdateLabelbasedonentity(data).subscribe(
            (result) => {
              if (result) {
                this.snackBar.open('Update Label based on entity done', '', {
                  duration: 2000,
                });
                this.GetLabelsByOrganization();
              }
            },
            (error) => {
              if (error.status == 200) {
                this.snackBar.open('Update Label based on entity done', '', {
                  duration: 2000,
                });
                this.GetLabelsByOrganization();
              }
            }
          );
        }
      });
    }
  }

  GetLabelsByOrganization(){
this.authService.GetColumnMappingsByEntityName('User').subscribe((result)=>{
  console.log(result)
  this.lablesbasedonentity=result
})
  } 

  getlable(value){
    if(this.lablesbasedonentity){
      return this.lablesbasedonentity[value].value
    }else{
      return value
    }
  }


  GetMandatoryColumns() {
    this.delegationService.GetMandatoryColumns('User').subscribe((res: any) => {
      console.log(res);
      this.MandatoryColumns = res.User;
      res.User.forEach((element) => {
        if (element.isMandatory == true) {
            this.userForm.get(element.columnName)?.setValidators([Validators.required]);
            this.userForm.get(element.columnName)?.updateValueAndValidity()
        }
      });
    });
  }
  getlableisMandatory(value) {
    let Mandatory = false;
    if(this.MandatoryColumns.length!=0){
      this.MandatoryColumns.forEach((element:any) => {
        if (element.columnName == value) {
          Mandatory = element.isMandatory;
        }
      })
    }
    return Mandatory;
  }
  
  loadUsers() {
    this.injector
      .get(ManageUsersService)
      .findAllUsersDropdown()
      .subscribe((result) => {
        if (result) {
          this.users$ = result;
          this.filteredusers = result;
        }
      });
  }

  filterOptions(value: string, type: string): void {
    console.log(type);
    this.filteredusers = this.users$.filter((option: any) =>
      option.firstName.toLowerCase().includes(value.toLowerCase())
    );
  }

  updateuserDialog() {
    this.injector
      .get(ManageUsersService)
      .fetchUserByIdService(
        this.data.data.id ? this.data.data.id : this.data.data
      )
      .subscribe((data: any) => {
        console.log(data);
        this.useriddata = data;
        this.filesCount = data?.filesCount;
        this.notesCount = data?.notesCount;
        this.profilePick = this.useriddata.profilePicture;
        this.profilePickid = this.useriddata.id;
        this.userForm.get('id')?.setValue(this.useriddata.id);
        this.userForm.get('created')?.setValue(this.useriddata.created);
        this.userForm.get('FirstName')?.setValue(this.useriddata.firstName);
        this.userForm.get('LastName')?.setValue(this.useriddata.lastName);
        this.userForm.get('Gender')?.setValue(this.useriddata.gender);
        this.userForm.get('Position')?.setValue(this.useriddata.position);
        this.userForm.get('Email')?.setValue(this.useriddata.email);
        this.userForm.get('Address')?.setValue(this.useriddata.address);
        this.userForm.get('ZipCode')?.setValue(this.useriddata.zipCode);
        this.userForm.get('City')?.setValue(this.useriddata.city);
        this.userForm.get('Country')?.setValue(this.useriddata.country);
        this.profileName = this.useriddata.profileName;
        this.userForm.get('Latitude')
          ?.setValue(this.useriddata?.Latitude ? this.useriddata?.Latitude : 0);
        this.userForm.get('Longitude')
          ?.setValue(
            this.useriddata?.Longitude ? this.useriddata?.Longitude : 0
          );
        this.userForm.get('PhoneNumber')?.setValue(this.useriddata.phoneNumber);
        this.userForm.get('ManagerId')?.setValue(this.useriddata.managerId);
      });
  }
  openImageInNewWindow(url: string): void {
    // Open the image URL in a new browser window/tab
    window.open(url, '_blank');
  }

  async onFileChange(e: any) {
    if (this.screenType == 'CreateUser') {
      const compressedFile = await this.injector.get(ImageCompressionService).compressImage(e.target.files[0]);
      console.log(compressedFile);
      console.log(
        `Orginal file  size ${e.target.files[0].size / 1024 / 1024} MB`
      );
      console.log(
        `Compressed File size ${compressedFile.size / 1024 / 1024} MB`
      );
      const imageUrl = URL.createObjectURL(compressedFile);
      this.openImageInNewWindow(imageUrl);

      var file = e.target.files[0];
      this.profileName = file.name;
      var formData = new FormData();
      formData.append('file', file);
      this.userForm.get('Profile')
        ?.setValue(file, { emitModelToViewChange: false });

      if (e.target.files) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event: any) => {
          this.profilePick = event.target.result;
        };
      }
    } else {
      var file = e.target.files[0];
      this.profileName = file.name;
      const id = this.profilePickid;
      var formData = new FormData();
      formData.append('file', file);
      this.injector.get(ManageUsersService)
        .updateprofileUserId(id, formData).subscribe((res) => {
          console.log(res);
        });
      if (e.target.files) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event: any) => {
          this.profilePick = event.target.result;
        };
      }
    }
  }
  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userForm.get('Profile')?.setValue(file);
    }
  }


  handleAddressChange(place: object) {
    console.log(place)
    const data = this.injector.get(GoogleValidationAddressService).getFullAddress(place);
    this.userForm.get('Latitude')?.setValue(data.latitude);
    this.userForm.get('Longitude')?.setValue(data.longitude);

    if (
      data.Address != undefined &&
      data.Address != null &&
      data.Address != 'undefined' &&
      data.Address != 'null'
    ) {
      this.userForm.get('Address')?.setValue(data.Address);
    } else {
      this.userForm.get('Address')?.setValue('');
    }
    if (
      data.pincode != undefined &&
      data.pincode != null &&
      data.pincode != 'undefined' &&
      data.pincode != 'null'
    ) {
      this.userForm.get('ZipCode')?.setValue(data.pincode);
    } else {
      this.userForm.get('ZipCode')?.setValue('');
    }
    if (
      data.city != undefined &&
      data.city != null &&
      data.city != 'undefined' &&
      data.city != 'null'
    ) {
      this.userForm.get('City')?.setValue(data.city);
    } else {
      this.userForm.get('City')?.setValue('');
    }
    if (
      data.country != undefined &&
      data.country != null &&
      data.country != 'undefined' &&
      data.country != 'null'
    ) {
      this.userForm.get('Country')?.setValue(data.country);
    } else {
      this.userForm.get('Country')?.setValue('');
    }
  }

  onSubmitAutomation() {
    this.userForm.get('id')?.setValue(this.data?.type?.id);
    if(this.data.screenType == 'CreateUser') {
      this.userForm.get('Name')?.setValue('Create User');
    } else if (this.data.screenType == 'UpdateUser') {
      this.userForm.get('Name')?.setValue('Update User');
    }
    this.userForm.get('ChildId')?.setValue(this.data?.id);
    this.userForm.get('ParentId')?.setValue(this.data?.parentId);
    this.userForm.get('previousStep')?.setValue(this.data?.previousStep || this.data?.type?.previousStep);
    this.userForm.get('step')?.setValue(this.data?.step || this.data?.type?.step);
    this.userForm.get('showIcon')?.setValue(this.data?.showIcon || this.data?.type?.icon.name);
    this.userForm.get('showColor')?.setValue(this.data?.showColor || this.data?.type?.icon.color);
    this.userForm.get('positions')?.setValue(this.data?.Positions || this.data?.type?._currentPosition)
    this.userForm.value.isActive = this.data?.type?.isActive;
    this.dialogRef.close(this.userForm);
  }

  updateUserAutomation(data:any){

    data?.fieldMappings?.forEach((field:any) => {
      const fieldName = field.actionColumn.split(".")[1];

      if (this.userForm.get(fieldName)) {
          const value = field.userInput || '';
          this.userForm.get(fieldName)?.setValue(value);
      }
      const idFieldName = `${fieldName}EditId`; 
      if (!this.userForm.get(idFieldName)) {
          this.userForm.addControl(idFieldName, new FormControl());
      }
      this.userForm.get(idFieldName)?.setValue(field.id);
  });
  }

  async onSubmit() {
    if(this.data?.Entity == 'Automation' || this.screenType == 'EditUserAutomation'){
      this.onSubmitAutomation()
    } else {
      const formData = new FormData(); 
      if (this.screenType == 'CreateUser') {
        if(this.userForm.get('Profile')?.value){
          const file=this.injector.get(ImageCompressionService).compressImage(this.userForm.get('Profile')?.value)
          formData.append('Profile', await file,'compressed-image.jpg');
        }
     
      } else {
        formData.append('id', this.userForm.get('id')?.value);
        formData.append('profileName', this.profileName);
      }
      formData.append(
        'IsRestrictedUser',
        this.userForm.get('RestrictUser')?.value
      );
      formData.append('FirstName', this.userForm.get('FirstName')?.value);
      formData.append('LastName', this.userForm.get('LastName')?.value);
      formData.append('Gender', this.userForm.get('Gender')?.value);
      formData.append('Position', this.userForm.get('Position')?.value);
      formData.append('Email', this.userForm.get('Email')?.value);
      formData.append('Address', this.userForm.get('Address')?.value);
      formData.append('ZipCode', this.userForm.get('ZipCode')?.value);
      formData.append('City', this.userForm.get('City')?.value); 
      formData.append('Country', this.userForm.get('Country')?.value);
      formData.append('ManagerId', this.userForm.get('ManagerId')?.value);
      let userid = this.userForm.get('ManageUsers')?.value;
      for (var x = 0; x < userid.length; x++) {
        formData.append('ManageUsers[' + x + ']', userid[x]);
      }
      formData.append(
        'PhoneNumber',
        this.userForm.get('PhoneNumber')?.value == null
          ? '0'
          : this.userForm.get('PhoneNumber')?.value
      );
      formData.append('ProfileName', this.userForm.get('profileName')?.value);
      formData.append('isActive', this.userForm.get('isActive')?.value);
      formData.append(
        'Latitude',
        this.userForm.get('Latitude')?.value == null || undefined
          ? 0
          : this.userForm.get('Latitude')?.value
      );
      formData.append(
        'Longitude',
        this.userForm.get('Longitude')?.value == null || undefined
          ? 0
          : this.userForm.get('Longitude')?.value
      );


   // Addtages 
   let combineFormData = formData
   if (this.userForm?.value.Addtages.length != 0) {
   const prepareFormData1=this.injector.get(FormDataService).prepareFormData(this.userForm.value.Addtages )
    combineFormData=this.injector.get(FormDataService).combineFormData(prepareFormData1,combineFormData)
   }

      if (this.screenType == 'CreateUser') {
        if (sessionStorage.getItem('DemoOrganation') === 'true') {
          this.popupforerrormessage(
            'This button allows you to add new Users',
            'Create User'
          );
        } else {
          this.injector.get(ManageUsersService) .createUserService(combineFormData).subscribe(
              (data: any) => {
                this.dialogRef.close();
                this.router.navigate(['/mail/VerificationEmailSent']);
                this.injector.get(ManageuserService).manageuser$.next(true);
              },
              (error) => {
                console.log(error);
                if (error.status == 429) {
                  this.ShowerrorMessagePopup(error?.error);
                } else if (error.status == 409) {
                  this.ShowerrorMessagePopup(error?.error.response[0].message);
                } else if (error.status == 400) {
                  this.ShowerrorMessagePopup(error?.error.response[0].message);
                }
              }
            );
        }
      } else {
        if (sessionStorage.getItem('DemoOrganation') === 'true') {
          this.popupforerrormessage(
            'This button allows you to Update Users',
            'Update User'
          );
        } else {
          this.injector.get(ManageUsersService).updateUserService(combineFormData).subscribe(
              (data: any) => {
                this.dialogRef.close();
                this.injector.get(MessageService).showMessage(data['response'][0].message);
                this.router.navigate(['/app/home/management/m/users']);
                this.injector.get(ManageuserService).manageuser$.next(true);
              },
              (error) => {
                console.log(error);
              }
            );
        }
      }
    }
   
  }

  get f() {
    return this.userForm.controls;
  }

  Restrictdetails() {
    const message = 'Contact product owner for this feature';
    this.ShowMessagePopup(message);
  }

  ShowMessagePopup(message: any) {
    this.dialog.open(MessagePopupComponent, {
      data: { message: message, screenType: 'moredetails' },
      width: '400px',
    });
  }

  showdropdown() {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows you to add more parameter',
        'Add Tag'
      );
    } else {
      const openAddEventDialog = this.dialog.open(AddtagCommonComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: 'Users',
      });
      openAddEventDialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.gettageBody().clear();
          this.gettags();
        }
      });
    }
  }

  deletetagDialog(i: any, type: any) {
    const data = {
      id: this.tagTableId(i),
      type: 'Users',
    };
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: type, entity: 'Users' },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      if (result) {
        this.gettageBody().clear();
        this.gettags();
      }
    });
  }
  

  gettags() {
    this.injector.get(ManageUsersService).gettag('Users').subscribe((data: any) => {
        if (data) {
          data.forEach((element: any, index: number) => {
            this.addtageBody();
            this.gettageBody().at(index).get('tagTableId')?.setValue(element?.tagTableId);
            this.gettageBody().at(index).get('fieldName')?.setValue(element?.fieldName);
            this.gettageBody().at(index).get('colorCode')?.setValue(element?.colorCode?element?.colorCode:'#fb4444');
            this.gettageBody().at(index).get('fieldType')?.setValue(element?.fieldType);
            this.gettageBody().at(index).get('fieldValue')?.setValue(element?.fieldValue);
            this.gettageBody().at(index).get('dropdownvaluetotal')?.setValue(element?.dropdownValues);
            if (element?.fieldType == 'Entity') {
              this.getdatafordroupdown(element?.fieldName);
            }
          });
        }
        this.tags = data;
      });
  }

  importdata() {
    const openAddEventDialog = this.dialog.open(ManageimportComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: 'Users',
    });
  }

  downloadTemplate() {
    const Name = 'Users';
    this.injector
      .get(ManageContactsService)
      .downloadTemplate(Name)
      .subscribe((result: Blob) => {
        const data: Blob = new Blob([result], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(data, 'Users.csv');
      });
  }

  // AddFile
  AddFile() {
    const addfile = this.dialog.open(AddFileComponent, {
      disableClose: true,
      width: '500px',
      data: {
        data: this.useriddata,
        attachmentLevel: 'Users',
        ismanage: true,
        type: 'single',
      },
      panelClass: ['addFiles'],
    });
    addfile.afterClosed().subscribe((result) => {
      this.ShowAttachmentFile = true;
      this.ShowAttachmentNotes = true;
      this.updateuserDialog();
      this.findnotesLinked();
      this.findfilesLinked();
    });
  }

  // AddNote
  AddNote() {
    const addnote = this.dialog.open(AddNotesComponent, {
      disableClose: true,
      data: {
        data: this.useriddata,
        attachmentLevel: 'Users',
        ismanage: true,
        type: 'single',
      },
      width: '600px',
      panelClass: ['addNotes'],
    });
    addnote.afterClosed().subscribe((result) => {
      this.ShowAttachmentFile = true;
      this.ShowAttachmentNotes = true;
      this.updateuserDialog();
      this.findnotesLinked();
      this.findfilesLinked();
    });
  }

  addNewFileAndNote() {
    const addNewFileAndNote = this.dialog.open(
      FileandnotesformanagementComponent,
      {
        width: '500px',
      }
    );
    addNewFileAndNote.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'file') {
          this.AddFile();
        } else {
          this.AddNote();
        }
      }
    });
  }

  // findfilesLinked
  findfilesLinked() {
    this.injector.get(ManageUsersService).getfile(this.useriddata.id, 'Users').subscribe((res) => {
        this.AttachmentFileList = res;
        this.ShowAttachmentFile = !this.ShowAttachmentFile;
      });
  }
  // findnotesLinked
  findnotesLinked() {
    this.injector.get(ManageUsersService).getnotes(this.useriddata.id, 'Users').subscribe((res) => {
        this.AttachmentNotesList = res;
        console.log(this.AttachmentNotesList);
        this.ShowAttachmentNotes = !this.ShowAttachmentNotes;
      });
  }
  // ViewLink
  ViewLink(name: any, id: any) {
    if (name == 'File') {
      this.injector.get(FileService).getFile(id).subscribe((res) => {
          console.log(res);
          if (res) {
            window.open(res.attachedFile, '_blank');
          }
        });
    } else {
      this.injector.get(FileService).getNote(id, 1, 10).subscribe((res) => {
          console.log(res);
          if (res) {
            window.open(res.notes, '_blank');
          }
        });
    }
  }
  // sharefileAndNote
  sharefileAndNote(name: any, data: any) {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows  to share File and Note',
        'share File and Note'
      );
    } else {
      const editFileDialog = this.dialog.open(FilesAndNotesShareLinkComponent, {
        width: '500px',
        data: data,
      });
      editFileDialog.afterClosed().subscribe((result) => {
        console.log(result);
      });
    }
  }
  // EditNote
  EditfileAndNote(name: any, id: any) {
    if (name == 'File') {
      if (sessionStorage.getItem('DemoOrganation') === 'true') {
        this.popupforerrormessage(
          'This button allows  to Edit File',
          'Edit File'
        );
      } else {
        this.injector.get(FileService).getFile(id).subscribe((data) => {
            const editFileDialog = this.dialog.open(AddFileComponent, {
              width: '500px',
              data: {
                FileEdit: true,
                data: data,
              },
            });
            editFileDialog.afterClosed().subscribe((result) => {
              this.ShowAttachmentFile = true;
              this.ShowAttachmentNotes = true;
              this.updateuserDialog();
              this.findnotesLinked();
              this.findfilesLinked();
            });
          });
      }
    } else {
      if (sessionStorage.getItem('DemoOrganation') === 'true') {
        this.popupforerrormessage(
          'This button allows  to Edit File',
          'Edit File'
        );
      } else {
        this.injector.get(FileService).getNote(id, 1, 10).subscribe((data) => {
            const editFileDialog = this.dialog.open(AddNotesComponent, {
              width: '600px',
              data: {
                NoteEdit: true,
                data: data,
              },
            });
            editFileDialog.afterClosed().subscribe((result) => {
              this.ShowAttachmentFile = true;
              this.ShowAttachmentNotes = true;
              this.updateuserDialog();
              this.findnotesLinked();
              this.findfilesLinked();
            });
          });
      }
    }
  }

  Downloadfile(data: any) {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows  to Download File',
        'download File'
      );
    } else {
      console.log(data);
      this.injector.get(FileService)
        .downloadFile(data.id, data?.isPrivate == false ? true : false).subscribe(
          (result) => {
            if (result) {
              const blob = new Blob([result], { type: result.type });
              FileSaver.saveAs(blob);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  // DeleteFileAreNote
  deleteDialogConfirmation(data: any, type: any) {
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: type },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.ShowAttachmentFile = true;
      this.ShowAttachmentNotes = true;
      this.updateuserDialog();
      this.findnotesLinked();
      this.findfilesLinked();
    });
  }

  // ViewNote content
  ViewNoteContent(data: any) {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows  to View Note',
        'View Note'
      );
    } else {
      this.injector.get(FileService).getNote(data.id, 1, 10).subscribe((Result: any) => {
          console.log(Result);
          const viewNoteDialog = this.dialog.open(ViewNotesComponent, {
            width: '500px',
            autoFocus: false,
            disableClose: true,
            data: { type: 'view', data: Result },
          });
          viewNoteDialog.afterClosed().subscribe((result) => {
            if (result) {
              this.ShowAttachmentFile = true;
              this.ShowAttachmentNotes = true;
              this.updateuserDialog();
              this.findnotesLinked();
              this.findfilesLinked();
            }
          });
        });
    }
  }

  APIerrormessage(message: any, header: any) {
    this.dialog.open(ApiErrorMessagesComponent, {
      width: '550px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
  }

  popupforerrormessage(message: any, header: any) {
    this.dialog.open(PopupErrorMessageComponent, {
      width: '550px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
  }

  ShowerrorMessagePopup(message: any) {
    this.dialog.open(MessagePopupComponent, {
      data: { message: message, screenType: 'loginpage' },
      width: '400px',
    });
  }
  ApiSuccessMessages(message: any, header: any) {
    const ApiSuccessMessages = this.dialog.open(ApiSuccessMessagesComponent, {
      width: '500px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
    ApiSuccessMessages.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  openPopup(formControlName: string) {
    if(this.data?.Entity == 'Automation' || this.screenType == 'EditUserAutomation'){
      const openAddEventDialog = this.dialog.open(AccordianViewComponent, {
        width: '350px',
        data: {
          Entity: this.data?.entity,
          ParentEntity: this.data?.ParentEntity,
          mappingEntity:this.data?.type?.mappingEntity,
          beforeParentEntity: this.data?.beforeParentEntity,
        },
        autoFocus: false,
        disableClose: true,
        position: { left: '1100px' },
      });
      openAddEventDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.userForm.get(formControlName)?.setValue(formControlName + ' ' + 'called' + ' ' + `{${result}}`);
        }
      });
    }
    
  }

  addCondition() {
    const AddConditionDialog = this.dialog.open(AddAutomationComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: { type: 'CreateCondition' },
    });
    AddConditionDialog.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.setFormValuesFromData(result);
      }
    });
  }

  setFormValuesFromData(data: any) {
    // Clear existing conditions
    this.Conditions().clear();

    // Set Trigger value if it exists in the data
    if (data.Trigger) {
      this.userForm.get('Trigger')?.setValue(data.Trigger);
    }

    // Check if there are conditions to add
    if (data.Conditions && Array.isArray(data.Conditions)) {
      data.Conditions.forEach((condition: any) => {
        this.Conditions().push(
          this.formBuilder.group({
            conditionParameter: [condition.conditionParameter || ''],
            conditionComparison: [condition.conditionComparison || ''],
            value: [condition.value || ''],
            isTag: [condition.isTag || ''],
            dataType: [condition.dataType || ''],
          })
        );
      });
    }
  }
  getdatafordroupdown(entity) {
    if (entity == 'Users') {
      // get all users
      this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((res: any) => {
          console.log(res);
          const data = res;
          this.filterforuserlist = data;
        });
    } else if (entity == 'Contacts') {
      // get all contacts
      this.injector .get(ManageContactsService) .findAllContactsDropdown() .subscribe((res) => {
          console.log(res);
          const data = res;
          this.filterforcontactlist = data;
        });
    } else if (entity == 'Sites') {
      //get all sites
      this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((res) => {
          console.log(res);
          const data = res;
          this.filterforsiteslist = data;
        });
    } else if (entity == 'Products') {
      // get all product
      this.injector.get(ManageProductsService).findAllProductsDropdown().subscribe((res) => {
          console.log(res);
          const data = res;
          this.filterforProsuctlist = data;
        });
    }
  }

  getAllColoumns() {
    this.FindImportColumnsUsers();
    this.FindImportColumnsContacts();
    this.FindImportColumnsSites();
    this.FindImportColumnsProducts();
    this.FindImportColumnsTransactions();
    this.FindImportColumnsEvents();
  }

  FindImportColumnsUsers() {
    let name = 'Users';
    this.injector
      .get(ManageUsersService)
      .FindParameters(name, false)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          this.UsersValue.push(e);
        });
      });
  }

  FindImportColumnsContacts() {
    let name = 'Contacts';
    this.injector
      .get(ManageUsersService)
      .FindParameters(name, false)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          this.ContactsValue.push(e);
        });
      });
  }
  FindImportColumnsSites() {
    let name = 'Sites';
    this.injector
      .get(ManageUsersService)
      .FindParameters(name, false)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          this.SitesValue.push(e);
        });
      });
  }
  FindImportColumnsProducts() {
    let name = 'Products';
    this.injector
      .get(ManageUsersService)
      .FindParameters(name, false)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          this.ProductsValue.push(e);
        });
      });
  }

  FindImportColumnsTransactions() {
    let name = 'Transactions';
    this.injector
      .get(ManageUsersService)
      .FindParameters(name, false)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          this.TransationValue.push(e);
        });
      });
  }
  FindImportColumnsEvents() {
    let name = 'Events';
    this.injector
      .get(ManageUsersService)
      .FindParameters(name, false)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          this.EventsValue.push(e);
        });
      });
  }
}
