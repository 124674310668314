import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { TargetService } from 'src/app/core/services/target.service';
import { TimeManagementService } from 'src/app/core/services/time-management.service';

@Component({
  selector: 'app-report-statistics',
  templateUrl: './report-statistics.component.html',
  styleUrls: ['./report-statistics.component.scss']
})
export class ReportStatisticsComponent implements OnInit {

  statisticsForm: FormGroup

  summary = {
    regularHours: '35:31',
    overtime: '10:30',
    paidTimeOff: '00:00',
    totalPaidHours: '46:01',
    unpaidBreaks: '02:00',
    unpaidTimeOff: '00:00',
  };

  displayedColumns: string[] = [
    'date',
    'totalHours',
    'dailyTotal',
    'closedHours',
    'TimeOffHours',
    'totalOvertime',
    'totalRegular',
  ];

  tableData = [
    {
      date: 'Mon 06/01',
      status: 'Project...',
      start: '03:50 PM',
      end: '03:56 PM',
      totalHours: '--',
      breakHours: '--',
      dailyTotal: '00:06',
      totalOvertime: '--',
      weeklyTotal: '--',
      totalRegular: '--',
    },
    {
      date: 'Tue 07/01',
      status: 'Project...',
      start: '09:30 AM',
      end: '06:30 PM',
      totalHours: '09:00',
      breakHours: '00:30',
      dailyTotal: '08:30',
      totalOvertime: '00:30',
      weeklyTotal: '--',
      totalRegular: '--',
    },
    {
      date: 'Wed 08/01',
      status: 'Project...',
      start: '09:00 AM',
      end: '07:00 PM',
      totalHours: '10:00',
      breakHours: '00:30',
      dailyTotal: '09:30',
      totalOvertime: '01:30',
      weeklyTotal: '--',
      totalRegular: '--',
    },
    {
      date: 'Thu 09/01',
      status: 'Project...',
      start: '09:00 AM',
      end: '07:00 PM',
      totalHours: '10:00',
      breakHours: '00:30',
      dailyTotal: '09:30',
      totalOvertime: '01:30',
      weeklyTotal: '--',
      totalRegular: '--',
    },
    {
      date: 'Fri 10/01',
      status: 'Project...',
      start: '09:00 AM',
      end: '08:00 PM',
      totalHours: '11:00',
      breakHours: '00:30',
      dailyTotal: '10:30',
      totalOvertime: '02:30',
      weeklyTotal: '--',
      totalRegular: '--',
    },
    // Add more rows here
  ];
  filteredusers: any;
  users$: any;
  StatisticsOption = [{id: '1', name: 'Users'}, {id: '2', name: 'User Groups'}, {id: '3', name: 'Sites'}, {id: '4', name: 'Site Groups'},
    {id: '5', name: 'Projects'}
  ]
  Projects: Object;
  sites$: any;
  filteredSites: any;
  usersgroup$: any;
  filteredusersgroup: any;
  AllGroup: any;
  filterGroup: any;
  StatisticsDetails:any
  tableDataSource: any;
  LoginUserId:any

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
  ) { 
    this.LoginUserId = sessionStorage.getItem("id"); 
    this.statisticsForm = this.formBuilder.group({
      UsersId: [this.LoginUserId],
      StatisticId: ['1'],
      startDate: [''],
      endDate: [''],
      UserGroupsId: [''],
      SitesId: [''],
      ProjectsId: [''],
      SelactGroupIdes: [''],
    })
  }

  ngOnInit(): void {
    this.getProjects()
    this.getallusers()
    this.getallSites()
    this.getallUserGroups()
    this.getSiteGroup()
    this.setWeekDates()
    this.getStatistics()
    this.statisticsForm.valueChanges.subscribe((values) => {
      const { startDate, endDate, UsersId, UserGroupsId, SitesId, SelactGroupIdes, ProjectsId } = values;
      if (startDate && endDate && (UsersId || UserGroupsId || SitesId || SelactGroupIdes || ProjectsId)) {
         this.getStatistics()
      }
    });
  }

  setWeekDates(): void {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  
    // Calculate the start of the week (Monday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() + distanceToMonday);
  
    // Calculate the end of the week (Friday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);
  
    // Format dates as yyyy-mm-dd
    const formattedStartDate = startOfWeek.toISOString().split('T')[0];
    const formattedEndDate = endOfWeek.toISOString().split('T')[0];
  
    // Set these values in the form
    this.statisticsForm.get('startDate').setValue(formattedStartDate);
    this.statisticsForm.get('endDate').setValue(formattedEndDate);
  }


  getStatistics() {

    const formValues = this.statisticsForm.value;
    const startDate = this.statisticsForm.get('startDate').value; 
    const endDate = this.statisticsForm.get('endDate').value;

    const formattedStartDate = new Date(Date.UTC(
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
      new Date(startDate).getDate()
  )).toISOString().split('T')[0];

  const formattedEndDate = new Date(Date.UTC(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate()
  )).toISOString().split('T')[0];

  const paramsObject = {
    StartDate: formattedStartDate,
    EndDate: formattedEndDate,
    "UserId[0]": formValues.UsersId || null,
    UserGroupId: formValues.UserGroupsId,
    SiteId: formValues.SitesId,
    ProjectId: formValues.ProjectsId,
    SiteGroupId: formValues.SelactGroupIdes
  };

    this.injector.get(TimeManagementService).getStatisticsData(paramsObject).subscribe((data:any) => {
      console.log(data)
      this.StatisticsDetails = data
      this.tableDataSource = data.workDetailsDtos
    })
  }

  getProjects(){
    this.injector.get(TimeManagementService).getAllProject().subscribe(data => {
      console.log(data)
      this.Projects = data
    })
  }

  getallusers(){
    // get all users
    this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });
  } 

  getallSites(){
    // get all sites
    this.injector.get(ManageSitesService).findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredSites = result;
      }
    });
  } 

  getallUserGroups(){
    this.injector.get(ManageUsersService).findAllUsersGroupDropdown().subscribe((result) => {
      if (result) {
        this.usersgroup$ = result;
        this.filteredusersgroup = result;
      }
    });
  }

  getSiteGroup() {
    this.injector.get(TargetService).fetchSiteGroup().subscribe((result: any) => {
      if(result){
        console.log(result);
        this.AllGroup = result.data;
        this.filterGroup = result.data;
      }
     
    });
  }

  filterOptions(value: string, type: string): void {
    console.log(type);
      this.filteredSites = this.sites$.filter((option:any) =>
        option?.companyName?.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusersgroup = this.usersgroup$.filter((option:any) =>
        option?.groupName?.toLowerCase().includes(value.toLowerCase())
      );
      this.filteredusers = this.users$.filter((option:any) =>
        option?.firstName?.toLowerCase().includes(value.toLowerCase())
      );
      this.filterGroup = this.AllGroup.filter(option =>
        option?.siteGroupName?.toLowerCase().includes(value.toLowerCase())
      );
  }

  formatDuration(totalDuration:any){
    if (!totalDuration) return '0h 0m';
    let hours = 0;
    let minutes = 0;
    const hoursMatch = totalDuration.match(/(\d+)\s*Hrs/);
    if (hoursMatch) {
      hours = parseInt(hoursMatch[1], 10);
      if (isNaN(hours)) hours = 0; // Default to 0 if invalid
    }
  
    // Check for "Min" and extract minutes
    const minutesMatch = totalDuration.match(/(\d+)\s*Min/);
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[1], 10);
      if (isNaN(minutes)) minutes = 0; // Default to 0 if invalid
    }
    return `${hours}h ${minutes}m`;
  }


}
