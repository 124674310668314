import { InfoWindowManager, MarkerManager } from '@agm/core';
import { AgmMarkerCluster, ClusterManager } from '@agm/js-marker-clusterer';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { SiteDetailsComponent } from 'src/app/pages/management/manage-sites/site-details/site-details.component';
import { UserDetailsComponent } from 'src/app/pages/management/manage-users/user-details/user-details.component';

@Component({
  selector: 'app-reporting-details',
  templateUrl: './reporting-details.component.html',
  styleUrls: ['./reporting-details.component.scss'],
  providers: [ClusterManager, { provide: MarkerManager, useExisting: ClusterManager }, InfoWindowManager,]
})
export class ReportingDetailsComponent implements OnInit {
  @ViewChild(AgmMarkerCluster) markerCluster!: AgmMarkerCluster;
  DetailedUser:any;
  viewDate:any;
  selectedDayIndex: number | null = null;
  groupedRequestDtos: any[];
  markerEvents: any[] = [];
  zoom = 8;  
  lat = 0;
  lng = 0; 
  EntryDetails: any;
  DetailedHours: any;
  mapData: any;
  LoginStartLat: any;
  LoginStartLong: any;
  LoginStopLat: any;
  LoginStopLong: any;
  eventLat: any;
  eventLong: any;
  UserData:any;
  EventData:any;
  siteid: any;
  LoginUserId: any;

  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    this.LoginUserId = sessionStorage.getItem("id"); 
   }

  ngOnInit(): void {
    console.log(this.data)
    this.UserData = this.data.user
    this.EventData = this.data.data
    this.mapData = this.data.data
    if (this.mapData.startLat && this.mapData.startLong) {
      this.mapData.stopLat = null
      this.mapData.stopLong = null
      this.markerEvents.push({
        lat: this.mapData.startLat,
        lng: this.mapData.startLong,
        // label: 'Start Location',
        title: 'Start IP',
        type: 'User',
      });
    }
    if (this.mapData.stopLat && this.mapData.stopLong) {
      this.mapData.startLat = null
      this.mapData.startLong = null
      this.markerEvents.push({
        lat: this.mapData.stopLat,
        lng: this.mapData.stopLong,
        // label: 'Stop Location',
        title: 'Stop IP',
        type: 'User',
      });
    }
    if (this.mapData.eventLat && this.mapData.eventLong) {
      this.markerEvents.push({
        lat: this.mapData.eventLat,
        lng: this.mapData.eventLong,
        // label: 'Event Location',
        title: 'Event',
        type: 'Site',
        siteId: this.mapData.siteId,
      });
    }
    if (this.markerEvents.length > 0) {
        this.lat = this.markerEvents[0].lat,
        this.lng = this.markerEvents[0].lng
    }
    console.log(this.markerEvents)
    if(this.data.type != 'TimeMap') {
      this.EntryDetails = this.data.data
      this.DetailedHours = this.groupByCreatedDate(this.data.EntryDetails)
      this.DetailedUser = this.data?.user
      // this.viewDate = this.data?.user?.date[0]
    }

  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  }

  selectday(index: number) {
    console.log(index)
    this.selectedDayIndex = index;  // Set the selected index
  }

  getdistanceFromStartIP(data){
    const value = data.split(' ')
    if(value[1] == 'km'){
      return 'red-colorred'
    } else {
      if(value[0] < 500){
        return 'green-colorred'
      } else {
        return ''
      }
    }
  }


  groupByCreatedDate(entries: any[]): any[] {
    const groupedData = entries?.reduce((acc, entry) => {
      // Convert created date to local date string
      const createdDate = new Date(entry?.created).toLocaleDateString('en-CA'); // 'en-CA' ensures YYYY-MM-DD format
      if (!acc[createdDate]) {
        acc[createdDate] = [];
      }
      acc[createdDate].push(entry);
      return acc;
    }, {});
  
    // Convert the grouped object into an array for *ngFor compatibility
    return Object.entries(groupedData).map(([date, entries]) => ({
      date,
      entries,
    }));
  }
  // Handle zoom changes
  onZoomChange(event: any) {
    console.log('Zoom level changed:', event);
  }

  // Handle map click
  onMapClick(event: any) {
    console.log('Map clicked:', event);
  }

  // Handle cluster click
  onClusterClick(event: any) {
    console.log('Cluster clicked:', event);
  }

  // Handle marker drag end
  markerDragEnd(marker: any, event: any) {
    console.log('Marker dragged:', marker, event);
  }

  // Icon URL for marker (optional)
  iconURL(eve: any): string {
    let value=''
    if (eve.type == "Site") {
      value= "assets/images/iconsForsvg/icon_green.svg";
    } else {
      value= "assets/images/iconsForsvg/icon_red.svg";
    }
    return value
  }

  clickedMarker(index: number, data:any): void {
    console.log(data)
    let type = data.type;
    if (type === "Site") {
      this.siteid = data.siteId;
      this.dialog.open(SiteDetailsComponent, {
        data: { id: this.siteid, type: 'site' },
        width: '500px'
      });
    } else {
      this.dialog.open(UserDetailsComponent, {
        data: { id: this.LoginUserId, type: 'user' },
        width: '500px'
      });
    }
  }

  ShowMapDetails(data:any, type:any){
    let dialogData: any = {};
    if (data) {
      if (type == 'startIp') {
        this.LoginStartLat = data.startLatitude;
        this.LoginStartLong = data.startLongitude;

        this.eventLat = data.eventLatitude;
        this.eventLong = data.eventLongitude;
      } else if (type == 'stopIp') {
        this.LoginStopLat = data.stopLatitude;
        this.LoginStopLong = data.stopLongitude;

        this.eventLat = data.eventLatitude;
        this.eventLong = data.eventLongitude;
      }
    
     

      dialogData = {
        startLat: this.LoginStartLat,
        startLong: this.LoginStartLong,
        stopLat: this.LoginStopLat,
        stopLong: this.LoginStopLong,
        eventLat: this.eventLat,
        eventLong: this.eventLong
      };
    } else {
      return '';
    }
    const AdvanceSearch = this.dialog.open(
      ReportingDetailsComponent,
      {
        disableClose: true,
        data: { data:dialogData, type: 'TimeMap' },
        width: '700px',
      }
    );
    
    AdvanceSearch.afterClosed().subscribe((result: any) => {

    })
  }

  FormatTotalDuration(totalDuration: string): string {
    if (!totalDuration) return '0h 0m'; // Handle empty input
    
    // Handle "00:00:00" format (hh:mm:ss)
    if (totalDuration.includes(':')) {
      const [hours, minutes, seconds] = totalDuration.split(':').map(Number);
      if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return '0h 0m'; // Handle invalid input
      return `${hours}h ${minutes}m`;
    }
  
    // Initialize hours and minutes
    let hours = 0;
    let minutes = 0;
  
    // Check for "Hrs" and extract hours
    const hoursMatch = totalDuration.match(/(\d+)\s*Hrs/);
    if (hoursMatch) {
      hours = parseInt(hoursMatch[1], 10);
      if (isNaN(hours)) hours = 0; // Default to 0 if invalid
    }
  
    // Check for "Min" and extract minutes
    const minutesMatch = totalDuration.match(/(\d+)\s*Min/);
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[1], 10);
      if (isNaN(minutes)) minutes = 0; // Default to 0 if invalid
    }
  
    // Return the formatted result
    return `${hours}h ${minutes}m`;
  }

  getTotalDurationForGroup(entryGroup: any): string {
    let totalSeconds = 0;
  
    entryGroup.entries.forEach((entry: any) => {
      if (entry?.duration) {
        const [hours, minutes, seconds] = entry.duration.split(':').map(Number);
        totalSeconds += hours * 3600 + minutes * 60 + seconds;
      }
    });
  
    // Convert total seconds to hours and minutes
    const totalHours = Math.floor(totalSeconds / 3600);
    const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
  
    return `${totalHours}h ${totalMinutes}m`;
  }

  convertDecimalToDuration(hours: number): string {
    const totalSeconds = Math.round(hours * 3600); // Convert hours to total seconds
    const hh = Math.floor(totalSeconds / 3600); // Extract hours
    const mm = Math.floor((totalSeconds % 3600) / 60); // Extract minutes
    const ss = totalSeconds % 60; // Extract seconds
  
    // Format as HH:mm:ss
    return `${hh.toString().padStart(2, '0')}h ${mm.toString().padStart(2, '0')}m`;
  }
  
  

}
