import { Component, OnInit, ViewChild, Inject, NgZone, Injector } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from '../../../../app/core/models/user.model';
import { Contact } from '../../../../app/core/models/contact.model';
import { Site } from '../../../../app/core/models/site.model';
import { Category } from '../../../../app/core/models/category.model';
import { ManageUsersService } from '../../../../app/core/services/manage-users.service';
import { ManageContactsService } from '../../../../app/core/services/manage-contacts.service';
import { ManageSitesService } from '../../../../app/core/services/manage-sites.service';
import { CalendarService } from '../../../../app/core/services/calendar.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
// import * as _ from 'lodash';
import { MessageService } from '../../../../app/core/services/message.service';
import { ManageimportComponent } from '../../management/manageimport/manageimport.component';
import { AddNotesComponent } from '../../files/components/add-notes/add-notes.component';
import { AddFileNotesComponent } from '../../files/components/add-file-notes/add-file-notes.component';
import { data, event } from 'jquery';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddFileComponent } from '../../files/components/add-file/add-file.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GoogleValidationAddressService } from '../../../../app/core/services/google-validation-address.service';
import { PopupErrorMessageComponent } from '../../../../app/popup-error-message/popup-error-message.component';
import { TranslateService } from '@ngx-translate/core';
import { ApiErrorMessagesComponent } from '../../../../app/api-error-messages/api-error-messages.component';
import { ReminderCreateComponent } from '../../Reminder/reminder-create/reminder-create.component';
import { PopupCommonComponent } from '../../commonForAll/popup-common/popup-common.component';
import { AccordianViewComponent } from '../../management/lead-automation/components/accordian-view/accordian-view.component';
import { AddAutomationComponent } from '../../management/lead-automation/components/add-automation/add-automation.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TimeManagementService } from '../../../../app/core/services/time-management.service';
import { AddtagCommonComponent } from '../../commonForAll/addtag-common/addtag-common.component';
import { ManageProductsService } from '../../../core/services/manage-products.service';
import { FormDataService } from '../../../core/services/form-data.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
  providers: [AddFileNotesComponent, AddNotesComponent, DatePipe],
})
export class CreateEventComponent implements OnInit {
  @ViewChild('placesRef', { static: false }) placesRef!: GooglePlaceDirective;
  categories$!: Observable<Category[]>;
  CriticityId$!: Observable<any>;
  fileForm!: FormGroup;
  updateile:any= [];
  eventdailog = true;
  filesnotes = true;
  periodicity: any = [
    { id: '1', name: 'Daily' },
    { id: '2', name: 'Weekly' },
    { id: '3', name: 'Monthly' },
    { id: '4', name: 'Default' },
  ];
  users$:any = [];
  filteredusers:any = [];
  contacts$:any = [];
  filteredcontacts:any = [];
  sites$:any = [];
  filteredsites:any = [];
  usersgroup$:any = [];
  filteredusersgroup:any = [];
  contactsgroup$:any = [];
  filteredcontactsgroup:any = [];
  sitesgroup$:any = [];
  filteredsitesgroup:any = [];
  userGroupArray:any;
  contactArray:any;
  filterforuserlist: any;
  filterforcontactlist: any;
  filterforsiteslist: any;
  filterforuserGrouplist: any;
  filterforcontactGrouplist: any;
  filterforsiteGrouplist: any;
  minDate!: string;
  minTime: any;
  files: string[] = [];
  form!: FormGroup;
  showPeriodDate: boolean = false;
  showFilterDay: boolean = false;
  dropdown: boolean = false;
  selectedradioval: any;
  // selectedFile: ImageSnippet;
  viewtagmodel :any= {};
  showcheckbox!: boolean;
  selectednumeralval: any;
  tagarray :any= [];
  Freefeild :any= {};
  viewnumeral :any= {};
  showfreefeild!: boolean;
  value!: number;
  dropdownvalue :any= [1, 2, 3, 4];
  i = 1;
  tagViewModels :any= [];
  tags: any;
  dropdownfeild :any= {};
  datadelete: any;
  datadeleteindex: any;
  checked: any;
  selectedvalue: any;
  selectedtagvalue: any;
  screenType: any;
  selectedDays = [];
  color1: string = '#2889e9';
  googleAutoinput: any;
  timeList = [
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '14:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45',
  ];

  trade = [
    { id: 1, label: 'Sunday', selected: true },
    { id: 2, label: 'Monday', selected: true },
    { id: 3, label: 'Tuesday', selected: true },
    { id: 4, label: 'Wednesday', selected: true },
    { id: 5, label: 'Thursday', selected: true },
    { id: 6, label: 'Friday', selected: true },
    { id: 7, label: 'Saturday', selected: true },
  ];

  listofcountry :any= [];
  listofcountry$ :any= [];
  viewmodel :any= {};
  categoryname: any;
  color: any;
  creatorId: any;
  quickSearchValue!: string;
  categories: any;
  totalitems: any;
  category: boolean = false;
  showcreate = 0;
  // value: any;
  // auto chip for keyword
  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordlist: string[] = [];
  AdminStatus: any;
  options = {
    types: ['address'],
    componentRestrictions: {},
  };
  googleAutoinputScreen = true;
  NormalAddressScreen = false;
  todaydate = new Date();
  startTime:any;
  endTime:any;
  valuedata :any= '';
  timeZone :any= 'Europe/Paris';
  ReminderList:any=[]
  selectedUsersIds: any = [];
  ContactsValue: any[] = []
  ProductsValue: any[] = []
  SitesValue: any[] = []
  UsersValue: any[] = []
  EventsValue: any[] = []
  TransationValue: any[] = []
  managementType = ['Users', 'Contacts', 'Sites', 'Products', 'Transactions', 'Events'];
  AllOption = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' },
    { v1: '<', v2: 'less than ' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<=', v2: 'less than or equal' }];
  Projects: Object;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<CreateEventComponent>,
    private userService: ManageUsersService,
    private contactsService: ManageContactsService,
    private manageSitesService: ManageSitesService,
    private dialog: MatDialog,
    private calendarService: CalendarService,
    private messageService: MessageService,
    private _snackBar: MatSnackBar,
    public datepipe: DatePipe,     
    public zone: NgZone,
    public GooglePlace: GooglePlaceDirective,
    private http: HttpClient,
    private googleAddress: GoogleValidationAddressService,
    private translate: TranslateService,
    private injector: Injector
  ) {
    if (sessionStorage.getItem('lang')) {
      const lang:any=sessionStorage.getItem('lang')
      this.translate.use(lang);
    } else {
      this.translate.use('English');
    }
    this.AdminStatus = sessionStorage.getItem('isAdmin');
    this.timeZone = sessionStorage.getItem('timeZone');
    this.form = this.formBuilder.group({
      Title: ['', [Validators.required]],
      Name: [null],
      ParentId: [null],
      ChildId: [null],
      previousStep:  [null],
      step: [null],
      showIcon: [null],
      showColor: [null],
      positions: [null],
      eventName: [null],
      StartDate: [this.todaydate, [Validators.required]],
      StartTime: [this.startTime, [Validators.required]],
      EndDate: ['', [Validators.required]],
      EndTime: [this.endTime, [Validators.required]],
      PeriodicityTime: [''],
      Address: [''],
      GoogleAddress: [''],
      ZipCode: [''],
      City: [''],
      Country: [''],
      latitude: [''],
      longitude: [''],
      Description: ['', [Validators.required]],
      isPublic: [false],
      addreminders: [false],
      IsOnlineEvent: [true],
      keyword: [''],
      CriticityId: [3],
      PeriodicityId: [0],
      IsPeriodicity: false,
      IsSimulation: false,
      IsTemplate: false,
      PeriodicityEndDate: ['0001-01-01 00:00:00.0000000'],
      UserId: [''],
      UserGroupsId: [[]],
      ContactId: [''],
      SelactCountry: ['India'],
      ContactGroupsId: [[]],
      SiteId: [''],
      CategoryId: [131],
      Attachments: [[]],
      CategoryViewModel: [''],
      categoryname: [''],
      NumberOfUsers: [''],
      tagViewModels: [[]],
      tagType: [''],
      TagViewModels: [[]],
      Tagetitle: [''],
      tagdropdown: [''],
      ischecked: [''],
      numeralType: [''],
      freefeildTitle: ['', Validators.pattern("^[a-zA-Z -']+")],
      freefeildnumber: [''],
      feildname: [''],
      fieldvalue: [''],
      freefeildvalue: [''],
      dropdowntype: [''],
      dropdown: [''],
      ProjectId: [null],
      IsEventOrganizer:[false],
      EventOwner:[null],
      Trigger: [null, [Validators.required]],
      Conditions: this.formBuilder.array([]),
      Addtages: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    console.log(this.data.screenType);
    console.log(this.data);
    this.screenType = this.data.screenType;
      if(this.data.screenType == 'CreateEvent_with_mail'){
      this.form.get('Title')?.setValue(this.data?.data?.senderName);
      }
    if (this.data.screenType == 'CreateEvent') {
      this.form.get('IsSimulation')?.setValue(false);
    }
     else {
      this.form.get('IsSimulation')?.setValue(true);
    }
    this.getdatafordroupdown();
    this.getAllcountryList();
    this.getAllColoumns();
    this.getProjects();
    this.gettags()
  }



 // tag's section 

 Conditions(): FormArray {
  return this.form.get('Conditions') as FormArray;
}



createtageBody(): FormGroup {
  return this.formBuilder.group({
    tagTableId: 0,
    fieldName: '',
    fieldType: '',
    colorCode:'',
    fieldValue: '',
    dropdownValues: '',
    dropdownvaluetotal: '',
    files:this.formBuilder.array([])
  });
}
addfile(): FormGroup {
  return this.formBuilder.group({
    filename: '',
    sfile: '',
  });
}

    // handleFileInput 
    handleFileInput(event:any,i,g) {
      const file = event.target.files[0];
      const fileToUploadname = event.target.files[0].name;
      this.gettagfiles(i).at(g).get('filename')?.setValue(fileToUploadname)
      this.gettagfiles(i).at(g).get('sfile')?.setValue(file)
    }

addfilefortag(index){
  this.gettagfiles(index).push(this.addfile());
}
gettagfiles(index){
  return this.gettageBody().at(index).get('files') as FormArray
  }
  getfilename(i,k){
    this.gettagfiles(i).at(k).get('filename')
  }
  gettagfilename(i,g){
   return this.gettagfiles(i).at(g).get('filename')?.value
  }

  deletetagfile(i,g){
    this.gettagfiles(i).removeAt(g)
  }
gettageBody() {
  return this.form.get('Addtages') as FormArray;
}
addtageBody() {
  this.gettageBody().push(this.createtageBody());
}
fieldname(i: number) {
  return this.gettageBody().at(i).get('fieldName')?.value;
}
colorCode(i: number) {
  return this.gettageBody().at(i).get('colorCode')?.value
}
dropdownvaluetotal(i: number) {
  return this.gettageBody().at(i).get('dropdownvaluetotal')?.value.split(',');
}

fieldType(i: number) {
  return this.gettageBody().at(i).get('fieldType')?.value;
}
tagTableId(i: number) {
  return this.gettageBody().at(i).get('tagTableId')?.value;
}
removeConditionalBody(i: number) {
  this.gettageBody().removeAt(i);
}

// ------------ 


  gettags() {
    this.injector.get(ManageProductsService).gettag('Events').subscribe((data: any) => {
      if (data) {
        data.forEach((element:any, index:any) => {
          this.addtageBody()
          this.gettageBody().at(index).get('tagTableId')?.setValue(element?.tagTableId)
          this.gettageBody().at(index).get('fieldName')?.setValue(element?.fieldName)
          this.gettageBody().at(index).get('colorCode')?.setValue(element?.colorCode?element?.colorCode:'#fb4444');
          this.gettageBody().at(index).get('fieldType')?.setValue(element?.fieldType)
          this.gettageBody().at(index).get('fieldValue')?.setValue(element?.fieldValue)
          this.gettageBody().at(index).get('dropdownvaluetotal')?.setValue(element?.dropdownValues)
        });
      }
      this.tags = data;
    });
  }

  getProjects(){
    this.injector.get(TimeManagementService).getAllProject().subscribe(data => {
      console.log(data)
      this.Projects = data
    })
  }




  getAllcountryList() {
    this.calendarService.getAllcountryList().subscribe((res: any) => {
      if (res) {
        this.listofcountry = res;
        this.listofcountry$ = this.listofcountry;
      }
    });
  }

  filterCountry(event:any) {
    const filterValue = event.toLowerCase();
    this.listofcountry$ = this.listofcountry.filter((option:any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  
  getEventOrganizer(){
    return this.form.value.IsEventOrganizer
  }


  selactcountry(value:any) {
    this.options = {
      types: ['address'],
      componentRestrictions: { country: value },
    };
    setTimeout(() => {
      this.placesRef.reset();
    }, 100);
  }
  ApplyEvent() {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows you to add new Event',
        'Create Event'
      );
    } else {
      if(this.data?.Entity == 'Automation'){
        this.AutomationSubmit();
      } else {
        this.onSubmit();
      }
      

    }
  }

  AutomationSubmit(){
    this.form.get('Name')?.setValue('Create Event');
    this.form.get('ChildId')?.setValue(this.data?.id);
    this.form.get('ParentId')?.setValue(this.data?.parentId);
    this.form.get('previousStep')?.setValue(this.data?.previousStep)
    this.form.get('step')?.setValue(this.data?.step)
    this.form.get('showIcon')?.setValue(this.data?.showIcon)
    this.form.get('showColor')?.setValue(this.data?.showColor)
    this.form.get('eventName')?.setValue('Event')
    this.form.get('positions')?.setValue(this.data?.Positions)
    this.matDialogRef.close(this.form)
  }



  showreminders(){
    const showreminders = this.dialog.open(ReminderCreateComponent, {
      width: '500px',
      data: { type:'Event',screenType: 'CreateReminder', selectedUsers: this.selectedUsersIds},
      
      autoFocus: false,
      disableClose: true,
    });
    showreminders.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result)
        result.reminderConditions.forEach(element => {
          let users:any=[]
          element.UserId.forEach(userid => {
            this.filteredusers.forEach(User => {
              if(User.id==userid){
                users.push(User)
              }
            });
          });
          element.users=users
          this.ReminderList.push(element)
          console.log(this.ReminderList)
        });
      }
    });
  }
  removereminder(index){
    this.ReminderList.splice(index, 1)
  }

  showreminderspopup(){
    this.form.get('addreminders')?.setValue(true)
    this.showreminders()
  } 
  getreminderststus(){
    return this.form.get('addreminders')?.value
  }



  // -----------
  onSubmit() {
    this.form.get('keyword')?.setValue(this.keywordlist);
    const isPublic = !this.form.get('isPublic')?.value;
    this.form.get('isPublic')?.setValue(isPublic);
    const formData = new FormData();
    formData.append('Title', this.form.get('Title')?.value);
    const startDate: any =
      this.datepipe.transform(this.form.value.StartDate, 'yyyy-MM-dd') + 
      'T' +
      this.form.value.StartTime;
    formData.append('StartDate', startDate);  
    const endDate: any =
      this.datepipe.transform(this.form.value.EndDate, 'yyyy-MM-dd') + 
      'T' +
      this.form.value.EndTime;
    formData.append('EndDate', endDate);
    formData.append('Address', this.form.get('Address')?.value || '');
    formData.append('ZipCode', this.form.get('ZipCode')?.value || '');
    formData.append('City', this.form.get('City')?.value || '');
    formData.append('Country', this.form.get('Country')?.value || '');
    formData.append(
      'latitude',
      this.form.get('latitude')?.value == null || undefined
        ? 0
        : this.form.get('latitude')?.value
    );
    formData.append(
      'longitude',
      this.form.get('longitude')?.value == null || undefined
        ? 0
        : this.form.get('longitude')?.value
    );
    formData.append('Description', this.form.get('Description')?.value);
    formData.append('CriticityId', this.form.get('CriticityId')?.value);
    formData.append('PeriodicityId', this.form.get('PeriodicityId')?.value);
    formData.append('IsSimulation', this.form.get('IsSimulation')?.value);
    formData.append('IsPeriodicity', this.form.get('IsPeriodicity')?.value);
    formData.append('IsTemplate', this.form.get('IsTemplate')?.value);
    formData.append('isPublic', this.form.get('isPublic')?.value);
    formData.append('IsOnlineEvent', this.form.get('IsOnlineEvent')?.value);
    formData.append('keyword', this.form.get('keyword')?.value);
    if( this.form.get('PeriodicityEndDate')?.value =='0001-01-01 00:00:00.0000000'){
      formData.append('PeriodicityEndDate',this.form.get('PeriodicityEndDate')?.value);
    }else{
      const date = this.form.get('PeriodicityEndDate')?.value
    formData.append('PeriodicityEndDate',date);

    }
    formData.append('SiteId', this.form.get('SiteId')?.value || '');
    formData.append('ProjectId', this.form.get('ProjectId')?.value || '');
    formData.append('CategoryId', this.form.get('CategoryId')?.value || 0);
    // formData.append('Attachments', this.form.get('Attachments')?.value);
    formData.append(
      'NumberOfUsers',
      this.form.value.UserId.length == 0 ? 0 : this.form.value.UserId.length
    );
    // formData.append('tagViewModels', this.form.get('tagViewModels')?.value);
    if (
      this.form.get('CategoryId')?.value == 0 ||
      this.form.get('CategoryId')?.value == null
    ) {
      formData.append('CategoryViewModel.Color', this.viewmodel['color'] || '');
      formData.append('CategoryViewModel.CreatorId',  '');
      formData.append(
        'CategoryViewModel.CategoryName',
        this.viewmodel['categoryName'] || ''
      );
    }
    if (this.value < 4) {
      formData.append('IsPeriodicity', 'true');
      formData.append('PeriodicityId', this.form.get('PeriodicityId')?.value);
      formData.append(
        'PeriodicityEndDate',
        this.datepipe.transform(
          this.form.value.PeriodicityEndDate,
          'yyyy-MM-dd'
        ) +
          'T' +
          this.form.value.PeriodicityTime
      );
    }
    if (this.value == 1) {
      for (var x = 0; x < this.selectedDays.length; x++) {
        formData.append('NamesOfDay[' + x + ']', this.selectedDays[x]);
      }
    }
    for (var x = 0; x < this.form.get('UserId')?.value.length; x++) {
      formData.append('UserId[' + x + ']', this.form.get('UserId')?.value[x]);
    }
    for (var x = 0; x < this.form.get('ContactId')?.value.length; x++) {
      formData.append(
        'ContactId[' + x + ']',
        this.form.get('ContactId')?.value[x]
      );
    }
    for (var x = 0; x < this.form.get('UserGroupsId')?.value.length; x++) {
      formData.append(
        'UserGroupsId[' + x + ']',
        this.form.get('UserGroupsId')?.value[x]
      );
    }
    for (var x = 0; x < this.form.get('ContactGroupsId')?.value.length; x++) {
      formData.append(
        'ContactGroupsId[' + x + ']',
        this.form.get('ContactGroupsId')?.value[x]
      );
    }
    if(this.screenType=='CreateEvent_with_mail'){
      formData.append('IsCreatedFromMail', 'true');
      formData.append('IsGmail', this.data?.data?.isGmail);
      formData.append('AccountId', this.data?.data.accountId);
      formData.append('MailMessageId', this.data?.data?.id);
    }
    if(this.form.value.IsEventOrganizer&&this.form.value.EventOwner!=null){
      formData.append('EventOwner',this.form.value.EventOwner);
      formData.append('IsEventOrganizer',this.form.value.IsEventOrganizer );
    }
     // Addtages 
     let combineFormData = formData
     if (this.form?.value.Addtages.length != 0) {
     const prepareFormData1=this.injector.get(FormDataService).prepareFormData(this.form.value.Addtages )
      combineFormData=this.injector.get(FormDataService).combineFormData(prepareFormData1,combineFormData)
     }

    // Api call
    this.calendarService.createEvent(combineFormData).subscribe((res: any) => {
      console.log(res)
      if(res?.eventId){
        this.messageService.showMessage('Event create  successfully'); 
        this.calendarService.createevent$.next(true);
        this.matDialogRef.close(true)
        this.createreminder(res)
        this.onCreateFile(res.eventId); 
      }else if(res[0].code=='400'||res[0].code==400){
        this.messageService.showMessage(res[0].message); 
      }
    },error=>{
      console.log(error)
      if(error.status==429){
        this.APIerrormessage(error?.error,'Create Events')
      }
    });
  }


  createreminder(res){
    const eventId = res.eventId
    const remindersPayload = {
      eventId: eventId,
      reminders: this.ReminderList.map((element: any) => ({
        reminderType: element.triggertype == 'Notification' ? 0 : element.triggertype == 'Emails' ? 1 : 2,
        conditionParameter: element.Parameter,
        isAfter: element.triggerMoment == 'After' ? true : false,
        value: element.value,
        period: element.durationtype == 'Minutes' ? 0 : element.durationtype == 'Hours' ? 1 : 2,
        reminderUsersIds: element.UserId
      }))
    };
    
    if(res) {
      this.calendarService.createReminders(remindersPayload).subscribe((reminderRes: any) => {
        console.log('Reminders created successfully:', reminderRes);
      }, error => {
        console.error('Error creating reminders:', error);
      });
  
    }
  }


  

  getseletedUsers(){
    var users:any=[]
    if(this.form.value.UserId.length!=0){
      this.filteredusers.forEach(element => {
        this.form.value.UserId.forEach((Userid:any) => {
          if(Userid==element.id){
            users.push(element)
          }
        });
      });
      return users
    }else{
      return users
    }
 

  }

  updateGroup(id:any) {
    console.log(id);
    let selectedRes:any = [];
    let usegrps = [];
    // _.forEach(id, (val:any) => {
    //   console.log(id, val);
    //   selectedRes.push(val.id);
    // });
    this.form.get('UserGroupsId')?.setValue(selectedRes);
  }

  radioChange(eve:any) {
    this.showPeriodDate = true;
    this.form.get('IsPeriodicity')?.setValue(true);
  }

  clear() {
    this.form.get('PeriodicityId')?.setValue(false);
  }
  changeTradesByCategory(event:any, id:number) {
    let a = event.target;
    console.log(event.target);
    if (event.target.checked === false) {
      this.trade.forEach((x) => {
        if (x.id === id) {
          x.selected = false;
        }
      });
    } else {
      this.trade.forEach((x) => {
        if (x.id === id) {
          x.selected = true;
        }
      });
    }
    let b;
    let array1:any= [];
    b = this.trade.filter((item) => item.selected === true);
    console.log('tradevlaueb' + b);
    console.log(b);
    b.filter((item) => {
      array1.push(item.label);
    });
    console.log(array1);
    this.selectedDays = array1;
  }

  onEventLog(event: string, data: any): void {
    this.viewmodel['id'] = 0;
    this.viewmodel['color'] = data;
    this.viewmodel['categoryName'] = this.form.get('categoryname')?.value;
  }

  updatecategory(v:any) {
    console.log(v);
    this.categoryname = v.categoryName;
    this.color = v.color;
    this.creatorId = v.creatorId;
  }

  createcategory() {
    this.category = true;
    this.showcreate = 1;
  }

  closecategory() {
    this.category = false;
    this.showcreate = 0;
    this.viewmodel['id'] = '';
    this.viewmodel['color'] = '';
    this.viewmodel['categoryName'] = '';
  }

  removecategory() {
    this.form.get('CategoryId')?.setValue(0);
  }

  importdata() {
    const openAddEventDialog = this.dialog.open(ManageimportComponent, {
      width: '600px',
      autoFocus: false,
      disableClose: true,
      data: 'Events',
    });
  }

  downloadTemplate() {
    const Name = 'Events';
    this.contactsService.downloadTemplate(Name).subscribe((result: Blob) => {
      const data: Blob = new Blob([result], {
        type: 'text/xlsx;charset=utf-8',
      });
      saveAs(data, 'Events.xlsx');
    });
  }

  // ------------
  onCreateFile(eventID:any) {
    this.calendarService.FindEventById(eventID).subscribe((res) => {
      if (res) {
        const addFileNotesDialog = this.dialog.open(AddFileNotesComponent, {
          data: {
            data: res,
            attachmentLevel: 'Events',
            ismanage: true,
          },
          disableClose: false,
          width: '500px',
          panelClass: ['files'],
        });
        addFileNotesDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.ngOnInit();
            this.matDialogRef.close(true);
          }
        });
      }
    });
  }

  // ------------
  updateAddress(eve:any) {
    if (eve.value == undefined) {
      this.googleAutoinputScreen = true;
      this.NormalAddressScreen = false;
      this.form.get('IsOnlineEvent')?.setValue(true);
      this.form.get('Address')?.setValue('');
      this.form.get('ZipCode')?.setValue('');
      this.form.get('City')?.setValue('');
      this.form.get('Country')?.setValue('');
    } else {
      this.googleAutoinputScreen = false;
      this.NormalAddressScreen = true;
      this.form.get('IsOnlineEvent')?.setValue(false);
      this.form.get('IsOnlineEvent')?.disable();
      this.manageSitesService.fetchSiteByIdService(eve.value).subscribe((res) => {
          if (res) {
            this.form.get('Address')?.setValue(res.address);
            this.form.get('ZipCode')?.setValue(res.zipCode);
            this.form.get('City')?.setValue(res.city);
            this.form.get('Country')?.setValue(res.country);
            this.form.get('latitude')?.setValue(res.latitude);
            this.form.get('longitude')?.setValue(res.longitude);
          }
        });
    }
  }

  // ----------------
  // auto chip for keyword
  // -----------
  add(event: MatChipInputEvent) {
    console.log(event.value);
    const value = (event.value || '').trim();
    if (value) {
      this.keywordlist.push(value);
    }
    // Clear the input value
    event.input.value = '';
    this.form.get('keyword')?.setValue(null);
  }
  remove(value: string) {
    const index = this.keywordlist.indexOf(value);
    if (index >= 0) {
      this.keywordlist.splice(index, 1);
    }
  }

  showdropdown() {
    if (sessionStorage.getItem('DemoOrganation') === 'true') {
      this.popupforerrormessage(
        'This button allows you to add more parameter',
        'Add Tag'
      );
    } else {
    const openAddEventDialog = this.dialog.open(AddtagCommonComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: 'Events',
    });
    openAddEventDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.gettageBody().clear()
        this.gettags();
      }
    })
  }
  }



  selacttagType(): void {
    this.form.get('tagType')?.valueChanges.subscribe((val) => {
      console.log(val);
      this.selectedradioval = val;
    });
  }
  addAdditionalFieldLine() {
    this.dropdownvalue.push(this.form.get('dropdowntype')?.value);
  }
  // Add tage
  applytag() {
    console.log(this.form.value);
    if (this.selectedradioval == 'Checkbox') {
      this.viewtagmodel['fieldName'] = this.form.get('Tagetitle')?.value;
      this.viewtagmodel['fieldType'] = 'Bool';
      this.viewtagmodel['dropdownValues'] = '';
      this.tagarray.push(this.viewtagmodel);
      this.showcheckbox = true;
      this.tagViewModels.push(this.viewtagmodel);
    } else if (this.selectedradioval == 'Freefeild') {
      if (this.selectednumeralval == 'Alphanumeric') {
        this.Freefeild['fieldName'] = this.form.get('freefeildTitle')?.value;
        this.Freefeild['fieldType'] = 'Text';
        this.Freefeild['dropdownValues'] = '';
        this.tagarray.push(this.Freefeild);
        this.showfreefeild = true;
        this.tagViewModels.push(this.Freefeild);
      } else {
        this.viewnumeral['fieldName'] = this.form.get('freefeildnumber')?.value;
        this.viewnumeral['fieldType'] = 'Number';
        this.viewnumeral['dropdownValues'] = '';
        this.tagarray.push(this.viewnumeral);
        this.showfreefeild = true;
        this.tagViewModels.push(this.viewnumeral);
      }
    } else if (this.selectedradioval == 'Dropdown') {
      this.dropdownfeild['fieldName'] = this.form.get('Tagetitle')?.value;
      this.dropdownfeild['fieldType'] = 'Dropdown';
      this.dropdownfeild['dropdownValues'] =
        this.form.get('dropdowntype')?.value;
      this.tagViewModels.push(this.dropdownfeild);
    }
    console.log(this.tagViewModels);
  }

  checkChanges() {
    this.form.get('numeralType')?.valueChanges.subscribe((val) => {
      console.log(val);
      this.selectednumeralval = val;
    });
  }

  filterOptions(value: string, type: string): void {
    console.log(type);
    if (type === 'users') {
      this.filteredusers = this.users$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'Sites') {
      this.filteredsites = this.sites$.filter((option:any) =>
        option.companyName.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'Contacts') {
      this.filteredcontacts = this.contacts$.filter((option:any) =>
        option.firstName.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'UserGroups') {
      this.filteredusersgroup = this.usersgroup$.filter((option:any) =>
        option.groupName.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === 'ContactGroups') {
      this.filteredcontactsgroup = this.contactsgroup$.filter((option:any) =>
        option.groupName.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  selectedUsers(event:any) {
    console.log(event.value)
    this.selectedUsersIds = event.value
  }

  getdatafordroupdown() {
    // get all users
    this.userService.findAllUsersDropdown().subscribe((result) => {
      if (result) {
        this.users$ = result;
        this.filteredusers = result;
      }
    });

    
    // get all contacts
    this.contactsService.findAllContactsDropdown().subscribe((result) => {
      if (result) {
        this.contacts$ = result;
        this.filteredcontacts = result;
      }
    });
    //get all sites
    this.manageSitesService.findAllSitesDropdown().subscribe((result) => {
      if (result) {
        this.sites$ = result;
        this.filteredsites = result;
      }
    });
    // get all User Group
    this.userService.findAllUsersGroupDropdown().subscribe((result) => {
      if (result) {
        this.usersgroup$ = result; 
        this.filteredusersgroup = result;
      }
    });
    // get all contact Group
    this.contactsService.findAllContactsGroupDropdown().subscribe((result) => {
      if (result) {
        this.contactsgroup$ = result;
        this.filteredcontactsgroup = result;
      }
    });

    // fetchCategoryService
    this.calendarService.fetchCategoryService(100, 1).subscribe((res) => {
      this.categories = res;
      this.totalitems = res.length;
    });
    this.CriticityId$ = this.calendarService.findCriticity();
  }

  // ------------0000000000000
  handleAddressChange(place: object) {
    const data = this.googleAddress.getFullAddress(place);
    this.form.get('IsOnlineEvent')?.setValue(false);
    this.form.get('IsOnlineEvent')?.disable();
    this.form.get('latitude')?.setValue(data.latitude);
    this.form.get('longitude')?.setValue(data.longitude);
    if (
      data.Address != undefined &&
      data.Address != null &&
      data.Address != 'undefined' &&
      data.Address != 'null'
    ) {
      this.form.get('Address')?.setValue(data.Address);
    } else {
      this.form.get('Address')?.setValue('');
    }
    if (
      data.pincode != undefined &&
      data.pincode != null &&
      data.pincode != 'undefined' &&
      data.pincode != 'null'
    ) {
      this.form.get('ZipCode')?.setValue(data.pincode);
    } else {
      this.form.get('ZipCode')?.setValue('');
    }
    if (
      data.city != undefined &&
      data.city != null &&
      data.city != 'undefined' &&
      data.city != 'null'
    ) {
      this.form.get('City')?.setValue(data.city);
    } else {
      this.form.get('City')?.setValue('');
    }
    if (
      data.country != undefined &&
      data.country != null &&
      data.country != 'undefined' &&
      data.country != 'null'
    ) {
      this.form.get('Country')?.setValue(data.country);
    } else {
      this.form.get('Country')?.setValue('');
    }
  }

  // --------
  get f() {
    return this.form.controls;
  }

  popupforerrormessage(message:any, header:any) {
    this.dialog.open(PopupErrorMessageComponent, {
      width: '550px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
  }
  APIerrormessage(message:any, header:any) {
    this.dialog.open(ApiErrorMessagesComponent, {
      width: '550px',
      data: { message: message, header: header },
      autoFocus: false,
      disableClose: true,
    });
  }
  openPopup(formControlName: string) {
    if(this.data?.Entity == 'Automation'){
      const openAddEventDialog  = this.dialog.open(AccordianViewComponent, {
        width: '350px',
        data: { Entity: this.data?.entity, ParentEntity: this.data?.ParentEntity, beforeParentEntity: this.data?.beforeParentEntity },
        autoFocus: false,
        disableClose: true,
        position: { left: '1100px' }
      });
      openAddEventDialog.afterClosed().subscribe((result) => {
        if(result) {
          this.form.get(formControlName)?.setValue(formControlName + ' ' + 'called' + ' ' + `{${result}}`);
        } 
      })
    }
   
  }


  addCondition(){
    const AddConditionDialog = this.dialog.open(AddAutomationComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: { type: 'CreateCondition'},
    });
    AddConditionDialog.afterClosed().subscribe(result => {
      if (result) {
        console.log(result)
        this.setFormValuesFromData(result);
      }
    });
  }
  
  setFormValuesFromData(data: any) {
    // Clear existing conditions
    this.Conditions().clear();
  
    // Set Trigger value if it exists in the data
    if (data.Trigger) {
      this.form.get('Trigger')?.setValue(data.Trigger);
    }
  
    // Check if there are conditions to add
    if (data.Conditions && Array.isArray(data.Conditions)) {
      data.Conditions.forEach((condition: any) => {
        this.Conditions().push(this.formBuilder.group({
          conditionParameter: [condition.conditionParameter || ''],
          conditionComparison: [condition.conditionComparison || ''],
          value: [condition.value || ''],
          isTag: [condition.isTag || ''],
          dataType: [condition.dataType || '']
        }));
      });
    }
  }

  onDrop(event: any, formControlName: string): void {
    const droppedItem = event.item.element.nativeElement.innerText.trim();
    if (droppedItem) {
      this.form.get(formControlName)?.setValue(droppedItem);
    }
  }



  

  getAllColoumns() {
    this.FindImportColumnsUsers()
    this.FindImportColumnsContacts()
    this.FindImportColumnsSites()
    this.FindImportColumnsProducts()
    this.FindImportColumnsTransactions()
    this.FindImportColumnsEvents()
  }

  FindImportColumnsUsers() {
    let name = 'Users'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.UsersValue.push(e)
      })
    })
  }

  FindImportColumnsContacts() {
    let name = 'Contacts'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.ContactsValue.push(e)
      })
    })
  }
  FindImportColumnsSites() {
    let name = 'Sites'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.SitesValue.push(e)
      })
    })
  }
  FindImportColumnsProducts() {
    let name = 'Products'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.ProductsValue.push(e)
      })
    })
  }

  FindImportColumnsTransactions() {
    let name = 'Transactions'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.TransationValue.push(e)
      })
    })
  }
  FindImportColumnsEvents() {
    let name = 'Events'
    this.injector.get(ManageUsersService).FindParameters(name, false).subscribe((res: any) => {
      res.forEach((e: any) => {
        this.EventsValue.push(e)
      })
    })
  }
}
