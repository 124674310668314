<div class="d-flex flex-row align-items-center modal-header">
  <h2 *ngIf="!taskData?.isMileStone" mat-dialog-title
    class="m-0 dialog-title text-center w-100">
    <mat-icon style="position: relative;right: 10px;">assignment_turned_in</mat-icon>
    {{'Task Details'|translate}}
  </h2>
  <h2 *ngIf="taskData?.isMileStone" mat-dialog-title
    class="m-0 dialog-title text-center w-100">
    <img style="margin-right: 12px;"
      src="../../../../../assets/images/iconsForsvg/nearby_white.svg">
    {{'MileStone Details'|translate}}
  </h2>
  <mat-icon class="pointer" (click)="closepopup()">close</mat-icon>
</div>
<mat-dialog-content  class="mr-0 ml-0">
    <ng-container *ngIf="!loader">
      <div class="row mt-2">
        <div class="col-5">
        </div>
        <div class="col-2" *ngIf="!taskData?.isMileStone">
          <div style="display: flex;">
            <img style="width: 40px;height: 50px;" *ngIf="taskData?.isConvertedtoEvent"
              src="../../../../../../assets/images/iconsForsvg/event_upcoming.svg">
              <img style="width: 40px;height: 40px;" *ngIf="taskData?.approversCount!=0" src="../../../../../assets/images/iconsForsvg/approval.svg" alt="">
            <mat-icon style="font-size: 50px;color: green !important;"> assignment_turned_in</mat-icon>
          </div>
        </div>
        <div class="col-2" *ngIf="taskData?.isMileStone">
          <img style="margin-right: 12px;width:50px"
            src="../../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">
        </div>
        <div class="col-5">
          <button 
            style="display: flex;justify-content: end;position: relative;left: 50px;"
            [matMenuTriggerFor]="beforeMenu" class="mr-1 b-color">
            <mat-icon class="icon-options">more_vert</mat-icon>
          </button>
    
          <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openEditdEventDialog(taskData,'edit-task')">
              <mat-icon>edit</mat-icon> 
              <span class="fw-600 color_black"> {{'Edit'|translate}}</span>
            </button>
            <button mat-menu-item
              (click)="deleteDialogConfirmation(taskData,'deleteTask')">
              <mat-icon>delete</mat-icon> 
              <span class="fw-600 color_black"> {{'Delete'|translate}}</span>
            </button>
            <button class="fw-600" mat-menu-item (click)="AddFile(taskData,'single')">
              <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>
              <span class="fw-600 color_black"> {{'Add File'|translate}}</span>
          </button>
          <button class="fw-600" mat-menu-item (click)="AddNote(taskData,'single')">
              <mat-icon style="color:rgb(255,193,111) ;">speaker_notes </mat-icon>
              <span class="fw-600 color_black"> {{'Add Note'|translate}}</span>
          </button>
          </mat-menu>
        </div>
      </div>
    <!-- title  -->
        <div class="mt-4">
          <p
          style="text-align: center; align-items: center;margin: 20!important; padding: 0 !important;font-size: 18px;font-weight: 900;">
          <span> {{taskData?.title|titlecase}}</span>
      </p>
        </div> 
        <!-- UnSubscribe Subscribe -->
       <ng-container *ngIf="taskData?.isPublic==true">
        <div style="display: flex;justify-content: center;margin-bottom: 20px;">
          <div (click)="SubscribestatusUpdate(taskData)">
            <span class="Subscribe" *ngIf="Subscribestatus">Subscribe</span>
            <span class="UnSubscribe" *ngIf="!Subscribestatus">UnSubscribe</span>
          </div>
        </div>
       </ng-container>
      <!-- ------------  -->
      <section class="detail-wrapper" style="font-size: 16px !important;">
        <mat-list-item *ngIf="taskData?.status!='Not_Assigned'">
          <div class="fw-600 row">{{'Progress'|translate}} :
            <span class="ml-3 mb-2 fw-600 color_grey" style="width: 300px;">
              <div class="row justify-content-center"
                style="position: relative;top:-16px;">
                <div class="col-12"
                  style="text-align: center;">{{taskData?.progress+'%'}}</div>
                <div class="col-12">
                  <mat-progress-bar [value]="taskData?.progress"
                    [ngClass]="assignCssClass(taskData?.progress)">
                  </mat-progress-bar>
                </div>
              </div>
            </span></div>
        </mat-list-item>
        <!-- Completed  status   -->
        <ng-container *ngIf="(taskData?.status=='In_Progress'&& taskData?.hasStep==false)||taskData?.status=='Assigned'&& taskData?.hasStep==false">
          <mat-list-item class="mt-2">
            <mat-slide-toggle [checked]="taskData?.status == 'Completed'"
              (change)="toggleTaskCompletion(taskData, '4')"
              style="margin-bottom: 25px;">
              <ng-container *ngIf="!taskData?.isMileStone">
                <span
                  [ngClass]="{'border-box': taskData?.status != 'Completed', 'border-box-complete': taskData?.status == 'Completed'}">
                  {{ taskData?.status == 'Completed' ? 'Completed' :
                  'Complete The Task' }}
                </span>
              </ng-container>
              <ng-container *ngIf="taskData?.isMileStone">
                <span
                  [ngClass]="{'border-box': taskData?.status != 'Completed', 'border-box-complete': taskData?.status == 'Completed'}">
                  {{ taskData?.status == 'Completed' ? 'Completed' :
                  'Complete The MileStone' }}
                </span>
              </ng-container>
            </mat-slide-toggle>
          </mat-list-item>
        </ng-container>
        <!-- Abort status  -->
        <ng-container *ngIf="taskData?.status!='Completed'&&taskData?.status!='Canceled'&&taskData?.status!='Failed'">
          <mat-list-item class="mt-2">
            <mat-slide-toggle [checked]="taskData?.status == 'Canceled'"(change)="toggleTaskCompletion(taskData, '5')"style="margin-bottom: 25px;">
              <ng-container *ngIf="!taskData?.isMileStone">
                <span class="border-box-Abort">
                  {{ 'Abort The Task' }}
                </span>
              </ng-container>
              <ng-container *ngIf="taskData?.isMileStone">
                <span class="border-box-Abort">
                  {{ 'Abort The MileStone' }}
                </span>
              </ng-container>
            </mat-slide-toggle>
          </mat-list-item>
        </ng-container>
        <!-- update step status  -->
        <ng-container *ngIf="taskData?.stepsCount!=0&& taskData?.hasStep==true&& taskData?.status!='Not_Assigned'">
          <div >
            <mat-form-field class="w-100">
              <mat-label style="color: black !important;"><b>Update Step Status</b></mat-label>
              <mat-select>
                <mat-option *ngFor="let steps of taskData?.stepsDto; let i = index"
                  
                   [value]="steps.id">
                  <span>{{steps.name}}
                    <mat-slide-toggle 
                    class="step-toggle mx-3" 
                    [checked]="steps.isDone" 
                    [disabled]="isToggleDisabled(i)"
                    (change)="onToggleChange(steps.id, $event.checked, i)">
                    {{ steps.isDone == false ? 'To Complete' : 'Over' }}
                  </mat-slide-toggle>
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
    
    
    
        <mat-list-item class="mt-2" (dblclick)="onDoubleClick('StartDate')"
          *ngIf="taskData?.startDate!=null && taskData?.isMileStone != true">
          <div class="fw-600"><b><mat-icon>event</mat-icon>
              {{getlable('StartDate')|translate}} :</b>
            <span class="ml-3 fw-600 color_grey"
              *ngIf="taskData?.isConvertedtoEvent==true">{{taskData?.startDate|
              date:'dd-MMM-yyyy H:mm'}}</span>
            <span class="ml-3 fw-600 color_grey"
              *ngIf="taskData?.isConvertedtoEvent==false">{{taskData?.startDate|
              date:'dd-MMM-yyyy'}}</span>
          </div><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2" (dblclick)="onDoubleClick('EndDate')"
          *ngIf="taskData?.endDate!=null || taskData?.isMileStone == true">
          <div class="fw-600"><b><mat-icon>event</mat-icon> {{getlable('EndDate')|translate}}
              :</b>
            <span class="ml-3 fw-600 color_grey"
              *ngIf="taskData?.isConvertedtoEvent==true"> {{taskData?.endDate|
              date:'dd-MMM-yyyy H:mm'}}</span>
            <span class="ml-3 fw-600 color_grey"
              *ngIf="taskData?.isConvertedtoEvent==false"> {{taskData?.endDate|
              date:'dd-MMM-yyyy'}}</span>
          </div><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2"
          *ngIf="taskData?.endTime!=null && taskData?.isMileStone == true">
          <div class="fw-600"><b><mat-icon>event</mat-icon> {{'End Time'|translate}}
              :</b>
            <span class="ml-3 fw-600 color_grey">{{taskData?.endTime}}</span>
          </div>
          <br>
        </mat-list-item>
    
      <mat-list-item class="mt-2" *ngIf="taskData?.code!=null">
        <tr>
          <td><b
              class="fw-600 align_items_icon"><mat-icon></mat-icon>{{'Code'|translate}} : </b></td>
          <td class="fw-600 color_grey">
            <span>{{taskData?.code}}</span>
          </td>
        </tr><br>
      </mat-list-item>
    
    
        <mat-list-item class="mt-2">
          <div class="fw-600"><b> <mat-icon
                style="color: green;">group</mat-icon> {{' Public'|translate}}:</b>
            <span class="ml-3 fw-600 color_grey">
              <span *ngIf="taskData?.isPublic==true">{{'Yes'|translate}}</span>
              <span *ngIf="taskData?.isPublic==false">{{'No'|translate}}</span>
            </span>
          </div><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2" *ngIf="taskData?.approversCount!=0">
          <div class="fw-600"><b>
            <img style="width: 20px;height: 20px;"  src="../../../../../assets/images/iconsForsvg/approval.svg" alt="">
            {{'Approval Status'|translate}}:</b>
            <span class="ml-3 fw-600 color_grey">{{taskData?.approvalStatus}}</span>
          </div><br>
        </mat-list-item>
        
    
        <mat-list-item class="mt-2" *ngIf="taskData?.duration && taskData?.isMileStone != true" (dblclick)="onDoubleClick('Hours')">
          <tr >
            <td><b
                class="fw-600 align_items_icon"><mat-icon></mat-icon>{{getlable('Hours')|translate}}:</b></td>
            <td class="fw-600 color_grey">
              <span>{{taskData?.duration}}</span>
            </td>
          </tr><br>
        </mat-list-item>
    
        
    
        <!-- ----------  -->
        <mat-list-item class="mt-2">
          <tr >
            <td><b class="fw-600 align_items_icon"><mat-icon></mat-icon>{{'Status'|translate}}:</b></td>
            <td>
              <div style="display: flex;">
                <div>
                  <span
                    [ngClass]="getStatusColorClass(taskData?.status)">{{getstatus(taskData?.status)}}</span>
                </div>
                <div>
                  <span style="position: relative;left: 10px;"
                    *ngIf="taskData?.status=='Completed'">
                    <mat-icon style="cursor: pointer;"
                      (click)="toggleTaskCompletion(taskData, '3')">cached</mat-icon>
                    <span style="font-weight: 400px !important;position: relative;
                      left: 10px;cursor: pointer;">{{taskData?.isMileStone==true?'Revert a MileStone Status':'Revert a Task Status'}}
                    </span>
                  </span>
                </div>
              </div>
            </td>
          </tr><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2" *ngIf="taskData?.categoryName!=null">
          <tr >
            <td><b
                class="fw-600 align_items_icon"><mat-icon></mat-icon>{{'Category'|translate}}:</b></td>
            <td class="fw-600 color_grey">
              <button [ngStyle]="{'background':taskData?.color}"
                class="Categorystyle">
                <span> {{taskData?.categoryName|titlecase}}</span></button>
            </td>
          </tr><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2" *ngIf="taskData?.hasStep&&taskData?.pipelineName">
          <tr >
            <td><b
                class="fw-600 align_items_icon"><mat-icon></mat-icon>{{'Pipeline Name'|translate}}:</b></td>
            <td class="fw-600 color_grey">
              <span>{{taskData?.pipelineName}}</span>
            </td>
          </tr><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2">
          <tr >
            <td><b
                class="fw-600 align_items_icon"><mat-icon></mat-icon>{{'Critic level'|translate}}:</b></td>
            <td class="fw-600 color_grey">{{taskData?.criticityName|titlecase}}</td>
          </tr>
        </mat-list-item>
    
        <mat-list-item class="mt-2">
          <tr >
            <td><b
                class="fw-600 align_items_icon"><mat-icon></mat-icon>{{'Keywords'|translate}}:</b></td>
            <td class="fw-600 color_grey">
              <span>
                <button class="TaskReff">
                    <span>{{taskData?.code|titlecase}}</span>
                </button>
            </span>
            <span *ngFor="let x of taskData?.keywords ;let i=index">
                <button class="normalkeyword">
                    <span>{{x|titlecase}}</span>
                </button>
            </span>
            </td>
          </tr>
      </mat-list-item>
    
        
        <mat-list-item class="mt-2" (dblclick)="onDoubleClick('Description')"
         *ngIf="taskData?.description != ''&&taskData?.description !=null">
          <tr>
            <td><mat-icon></mat-icon><b>{{getlable('Description')|translate}}:</b></td>
            <td class="fw-600 color_grey"><span
                class="formatTextLine">{{taskData?.description}}</span></td>
          </tr><br>
        </mat-list-item>
    
        <mat-list-item class="mt-2" *ngIf="taskData?.remindersCount!=0">
          <tr >
            <td><b
                class="fw-600 align_items_icon"><img src="../../../../../assets/images/iconsForsvg/remainder.svg" alt="">
                {{'Reminders'|translate}}:</b></td>
            <td class="fw-600 color_grey">
              <span (click)="findRemindersLinked(taskData?.id)">
                {{taskData?.remindersCount}} {{taskData?.remindersCount > 1 ? 'Reminders' : 'Reminder'}}</span>
            </td>
          </tr>
        </mat-list-item>
        <ng-container *ngIf="showReminder" style="width:100% !important;margin-top: 5px;">
          <div *ngFor="let reminder of remindersLinkedById; let i = index" class="pointer fw-600 color_grey" 
          (click)="openReminderDialog(reminder, 'Task', taskData?.id)">
              <span style="padding-left: 10px">
                  <span *ngIf="reminder.reminderTypeName=='Email'"><mat-icon> email</mat-icon></span>
                   <span *ngIf="reminder.reminderTypeName=='Notification'"><mat-icon>campaign</mat-icon></span>
                   <span>{{reminder.value}} {{reminder.periodName}} {{reminder.isAfter ? 'After' : 'Before'}} the Task  {{reminder.conditionParameter}} date and time</span>
                  </span>
          </div>
        </ng-container>
    
         
        <mat-list-item class="mt-2" *ngIf="taskData?.initiativeCount!=0">
          <tr>
            <td><b>
                <mat-icon>ballot</mat-icon> {{'Initiatives'|translate}} :</b></td>
            <td class="pointer fw-600 color_grey" (click)="findInitiativeLinked(taskData?.initiativeCount)">
              {{taskData?.initiativeCount}}   {{taskData?.initiativeCount > 1 ? 'Initiatives' : 'Initiative'}}
            </td>
          </tr><br>
        </mat-list-item> 
        <div *ngIf="showinitiative"
            [ngClass]="{'auto_height': taskData?.initiativeCount > 4,'overflow': taskData?.initiativeCount < 4 }">
            <div class="row float-l pointer fw-600 color_grey" *ngFor="let initiative of assignedInitiatives">
              <div style="width: 200px; margin: 0 !important;">
                <span class="m-l-28" (click)="openInitiativeDialog(initiative, 'initiative')">
                  <img style="border-radius: 50%;" width="30" height="30"
                    [src]="initiative.profile ? initiative.profile :'assets/avatar.svg'"></span>
                <span style="padding-left:10px" (click)="openInitiativeDialog(initiative, 'initiative')">
                  {{initiative.name|titlecase}}
                </span>
              </div>
            </div>
          </div>
    
        <mat-list-item class="mt-2" *ngIf="taskData?.siteId!=null">
          <tr>
            <td><b>
                <img src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                {{' Site'|translate}} :</b></td>
            <td class="pointer fw-600 color_grey"
              (click)="openSiteDialog(taskData?.siteId, 'site')">
              {{taskData?.siteName!=null ? taskData?.siteName
              : '-' |titlecase}}</td>
          </tr><br>
        </mat-list-item>
    
        <!-- showusers  -->
        <mat-list-item class="mt-2" *ngIf="taskData?.initiativeCount!=0">
          <tr>
            <td><b>
                <mat-icon>people</mat-icon> {{'Team'|translate}} :</b></td>
            <td class="pointer fw-600 color_grey"
              (click)="findUserLinked(taskData?.usersCount)">{{taskData?.usersCount+1}}
              {{"Users" | translate}}</td>
          </tr><br>
        </mat-list-item> 
    
        <mat-list-item class="mt-2" *ngIf="taskData?.initiativeCount==0">
          <tr>
            <td><b>
                <mat-icon>people</mat-icon> {{'Users'|translate}} :</b></td>
            <td class="pointer fw-600 color_grey"
              (click)="findUserLinked(taskData?.usersCount)">{{taskData?.usersCount+1}}
              {{"Users" | translate}}</td>
          </tr><br>
        </mat-list-item> 
    
        <div *ngIf="showusers"
          [ngClass]="{'auto_height': taskData?.usersCount > 4,'overflow': taskData?.usersCount < 4 }">
          <!-- creater  -->
          <div class="row  m-t-10 m-b-10 600 color_grey"*ngIf="taskData?.taskCreatorId!=null ">
            <span class="m-l-28">
              <img style="border-radius: 50%; cursor: pointer;" width="30"
                height="30" onError="this.src='assets/avatar.svg'"
                [src]="taskData?.taskCreatorProfile ? taskData?.taskCreatorProfile :'assets/avatar.svg'">
            </span>
            <span class="p-l-10 fw-600 color_grey"style="cursor: pointer;font-size: 16px;">
              {{taskData?.taskCreatorFirstName+' '+taskData?.taskCreatorLastName}}
              <span class="border-box1">Creator</span>
            </span>
          </div>
    
          <!-- user list  -->
            <div class="row  m-t-10 m-b-10 600 color_grey" *ngFor="let user of assignedUser">
              <span class="m-l-28">
                <img style="border-radius: 50%; cursor: pointer;" width="30" height="30" onError="this.src='assets/avatar.svg'"
                  (click)="openUserDialog(user, 'user')"  [src]="user?.profile ? user?.profile :'assets/avatar.svg'">
                </span>
              <span class="p-l-10 fw-600 color_grey" style="cursor: pointer;font-size: 16px;"
                (click)="openUserDialog(user, 'user')">
                {{user?.firstName|titlecase}} {{user?.lastName|titlecase}}</span><span class="border-box">Assignee</span>
            </div>
        </div>
    
    
        <mat-list-item class="mt-2" *ngIf="taskData?.approversCount!=0">
          <tr (click)="getApproversList(taskData?.id)">
            <td><b>
                <mat-icon>people</mat-icon> {{'Approvers'|translate}} :</b></td>
            <td class="pointer fw-600 color_grey">{{taskData?.approversCount}}
              {{"pepoles" | translate}}</td>
          </tr><br>
        </mat-list-item> 
              <!-- Approvers list  -->
        <ng-container *ngIf="showApprovers">
          <div class="row  m-t-10 m-b-10 600 color_grey" *ngFor="let user of ApproversList">
            <span class="m-l-28">
              <img style="border-radius: 50%; cursor: pointer;" width="30" height="30" onError="this.src='assets/avatar.svg'"
                (click)="openUserDialog(user, 'user')"  [src]="user?.profile ? user?.profile :'assets/avatar.svg'">
              </span>
            <span class="p-l-10 fw-600 color_grey" style="cursor: pointer;font-size: 16px;"
              (click)="openUserDialog(user, 'user')">
              {{user?.firstName|titlecase}} {{user?.lastName|titlecase}}</span>
          </div>
        </ng-container>
    
          <mat-list-item class="mt-2" *ngIf="taskData?.contactsCount!=0">
            <tr>
              <td><b>
                  <mat-icon style="color: #6495ed;">contacts</mat-icon> {{'Contacts'|translate}} :</b></td>
              <td class="pointer fw-600 color_grey" (click)="findContactLinked(taskData?.contactsCount)">
                {{taskData?.contactsCount}} {{"Contacts" | translate}}</td>
            </tr><br>
          </mat-list-item>
          <div *ngIf="showcontact"
              [ngClass]="{'auto_height': taskData?.contactsCount > 4,'overflow': taskData?.contactsCount < 4 }">
              <div class="row float-l pointer fw-600 color_grey" *ngFor="let contact of assignedContact">
                <div style="width: 200px; margin: 0 !important;">
                  <span class="m-l-28" (click)="openContactDialog(contact, 'contact')">
                    <img style="border-radius: 50%;" width="30" height="30"
                      [src]="contact.profile ? contact.profile :'assets/avatar.svg'"></span>
                  <span style="padding-left:10px" (click)="openContactDialog(contact, 'contact')">
                    {{contact.firstName|titlecase}} {{contact.lastName|titlecase}}
                  </span>
                </div>
              </div>
            </div>
               <!-- files  -->
          <mat-list-item class="mt-2" >
            <tr >
              <td><b>
                  <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon> {{'Files'|translate}}:</b></td>
                  <span class="ml-3 fw-600 color_grey m-t-6 pointer" (click)="findfilesLinked(taskData?.filesCount,'Files')"
                  [ngClass]="{'color_grey': taskData?.filesCount > 0,'name_red': taskData?.filesCount == 0 }">
                  {{taskData?.filesCount}}
                  {{taskData?.filesCount > 1 ? 'Files' : 'File'}}</span>
            </tr><br>
          </mat-list-item>

          <ng-container *ngIf="showfile">
            <div class="col-10 p-0   m-b-15" 
            [ngClass]="{'auto_height': taskData?.filesCount > 4,'overflow': taskData?.filesCount < 4 }">
            <div class="row m-l-10  m-t-10 " *ngFor="let file of getfile ; let i = index">
                <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>
                <a style="cursor:pointer;" (click)="gotofiles(file)"
                    class="color_grey m-l-10">{{file.title}}</a>
            </div>
        </div>
          </ng-container>

                <!-- Notes -->
          <mat-list-item class="mt-2" >
            <tr >
              <td><b>
                  <mat-icon style="color:rgb(255,193,111) ;">speaker_notes</mat-icon> {{'Notes'|translate}}:
                </b></td>
                <span class="ml-3 fw-600 color_grey m-t-6 pointer" (click)="findnotesLinked(taskData?.notesCount,'Notes')"
                [ngClass]="{'color_grey': taskData?.notesCount > 0,'name_red': taskData?.notesCount == 0 }">
                {{taskData?.notesCount}}
                {{taskData?.notesCount > 1 ? 'Notes' : 'Note'}}</span>
            </tr><br>
          </mat-list-item>
              <ng-container *ngIf="shownotes">
                <div class="col-10 p-0 pointer " 
                [ngClass]="{'auto_height': taskData?.notesCount > 4,'overflow': taskData?.notesCount < 4 }">
                <div (click)="ShowNote(notes.id)" class="row  m-t-10  m-l-10 fw-600  "
                    *ngFor="let notes of getnotes ; let i = index">
                    <mat-icon style="color:rgb(255,193,111) ;" class=" vertical-bottom">speaker_notes</mat-icon>
                    <span class="m-l-10 color_grey">{{notes.title|titlecase}}</span>
                </div>
            </div>
              </ng-container>
             

          <!-- <mat-list-item>
            <tr class="mt-2">
              <td><b>
                  <mat-icon style="color: red ;">event</mat-icon> {{'Events'|translate}}:</b></td>
                  <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                  [ngClass]="{'color_grey': taskData?.eventsCount > 0,'name_red': taskData?.eventsCount == 0 }">
                  {{taskData?.eventsCount}}
                  {{taskData?.eventsCount > 1 ? 'Events' : 'Event'}}</span>
            </tr><br>
          </mat-list-item> -->
          <!-- predecessor  -->
          <mat-list-item class="mt-2" *ngIf="taskData?.predecessorTaskcount!=0">
            <tr class="mt-2">
              <td><b>
                  <span><img
                      src="../../../../../assets/images/iconsForsvg/left-arrow.svg"></span>
                  {{'predecessor'|translate}}:</b></td>
              <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                (click)="getlinkedpredecessor(taskData)"
                [ngClass]="{'color_grey': taskData?.predecessorTaskcount > 0,'name_red': taskData?.predecessorTaskcount == 0 }">
                {{taskData?.predecessorTaskcount}}
                {{taskData?.predecessorTaskcount > 1 ? 'predecessors' :
                'predecessor'}}</span>
            </tr><br>
          </mat-list-item>
    
          <div class="col-12 pointer mb-2" *ngIf="showpredecessor"
            [ngClass]="{'auto_height': taskData?.predecessorTaskcount > 4, 'overflow': taskData?.predecessorTaskcount < 4}">
            <div *ngFor="let predecessor of predecessorlist; let i = index">
              <div style="display: flex;margin-bottom: 5px;">
                <div style="width: 60%;">
                  <div style="display: flex">
                    <div>
                      <mat-icon *ngIf="!predecessor?.isMilesStone"
                        style="color: green !important;">
                        assignment_turned_in</mat-icon>
                      <img *ngIf="predecessor?.isMilesStone"
                        style="margin-right: 12px;width:50px"
                        src="../../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">
                    </div>
                    <div>
                      <span class="fw-600 color_grey"
                        (click)="opentaskDialog(predecessor.taskId)">
                        {{ get50TenCharacters(predecessor.title)}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <!-- successor  -->
          <mat-list-item class="mt-2" *ngIf="taskData?.successorTaskCount!=0">
            <tr class="mt-2">
              <td><b>
                  <span><img
                      src="../../../../../assets/images/iconsForsvg/right-arrow.svg"></span>
                  {{'successor'|translate}}:</b></td>
              <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                (click)="getlinkedsuccessor(taskData)"
                [ngClass]="{'color_grey': taskData?.successorTaskCount > 0,'name_red': taskData?.successorTaskCount == 0 }">
                {{taskData?.successorTaskCount}}
                {{taskData?.successorTaskCount > 1 ? 'successors' :
                'successor'}}</span>
            </tr><br>
          </mat-list-item>
    
          <div class="col-12 pointer mb-1" *ngIf="showsuccessor"
            [ngClass]="{'auto_height': taskData?.successorTaskcount > 4, 'overflow': taskData?.successorTaskcount < 4}">
            <div *ngFor="let successor of successorlist; let i = index">
              <div style="display: flex;margin-bottom: 5px;">
                <div style="width: 60%;">
                  <div style="display: flex;">
                    <div>
                      <mat-icon *ngIf="!successor?.isMilesStone"
                        style="color: green !important;">
                        assignment_turned_in</mat-icon>
                      <img *ngIf="successor?.isMilesStone"
                        style="margin-right: 12px;width:50px"
                        src="../../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">
                    </div>
                    <div>
                      <span class="fw-600 color_grey"
                        (click)="opentaskDialog(successor.taskId)">
                        {{ get50TenCharacters(successor.title)}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- steps -->
          <ng-container *ngIf="taskData?.hasStep&&taskData?.stepsCount!=0">
            <mat-list-item class="mt-2">
              <div class="fw-600 mb-3">
                <mat-icon class="vertical-bottom">linear_scale</mat-icon>
                {{'Steps'}} : <span class="ml-3 fw-600 color_grey m-t-6 pointer"
                  [ngClass]="{'color_grey': taskData?.stepsCount > 0,'name_red': taskData?.stepsCount == 0 }"
                  (click)="findstepsLinked()">{{taskData?.stepsCount}}
                  {{taskData?.stepsCount > 1 ? 'Steps' : 'Step'}}</span>
              </div>
            </mat-list-item>
            <div class="col-12 pointer mb-2" *ngIf="showsteps"
              [ngClass]="{'auto_height': taskData?.stepsCount > 4, 'overflow': taskData?.stepsCount < 4}">
              <div *ngFor="let steps of taskData?.stepsDto; let i = index">
                <div style="display: flex;margin-bottom: 5px;">
                  <div style="width: 60%;">
                    <div style="display: flex;margin-top: 10px;">
                      <!-- <div *ngIf="steps?.hasSubtask&&steps?.subTaskCount!=0">
                        <mat-icon style="color: black;" >expand_less</mat-icon>
                        <mat-icon style="color: black;" >expand_more</mat-icon>
                      </div> -->
                      <div>
                        <mat-icon class="vertical-bottom">linear_scale</mat-icon>
                      </div>
                      <div>
                        <span class="fw-600 color_grey">
                          {{ get50TenCharacters(steps.name)}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 40%;">
                    <div class="circular-progress-bar">
                      <svg viewBox="0 0 100 100">
                        <circle
                          class="progress-bar-background"
                          [attr.cx]="50"
                          [attr.cy]="50"
                          [attr.r]="radius">
                        </circle>
                        <circle
                          [ngClass]="assignCssClass1(steps.progress)"
                          [attr.cx]="50"
                          [attr.cy]="50"
                          [attr.r]="radius"
                          [attr.stroke-dasharray]="circumference"
                          [attr.stroke-dashoffset]="calculateDashoffset(steps.progress)">
                        </circle>
                        <text x="50" y="40" text-anchor="middle"
                          dominant-baseline="central">
                          <tspan x="50" dy="0.5em">{{ temformat(steps.progress )
                            }}</tspan>
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
                <!-- subTasks  -->
                <ng-container *ngIf="steps?.subTaskCount">
                   <div *ngFor="let subtasks of steps?.subtaskDtos">
                    <div style="display: flex;margin-bottom: 5px;">
                      <div style="width: 2%;"></div>
                         <div style="width: 50%;">
                          <div style="display: flex; margin-top: 5px;">
                              <div> 
                                  <mat-icon style="color:green;" class="vertical-bottom"> assignment_turned_in</mat-icon>
                               </div>
                              <div>
                                  <span class="fw-600 color_grey" (click)="opentaskDialog(subtasks?.id)">
                                      {{ get50TenCharacters(subtasks?.title) }}</span>
                              </div>
                          </div>
                      </div>
                      <div style="width: 40%;margin-top:5px;">
                          <span>
                              <span [ngClass]="getStatusColorClass(subtasks?.status)">{{getstatus(subtasks?.status)}}</span> 
                          </span>
                      </div>
                  </div>
                   </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
    
          <!-- Activity logs  -->
          <mat-list-item class="mt-2">
            <div class="row mb-4">
              <div class="fw-600" (click)="showActivitylogs(taskData)">
                <img src="../../../../../assets/images/iconsForsvg/autivity log.svg" alt="">
                <span *ngIf="taskData?.activityLogCount > 0" class="Activity_badge">{{ taskData?.activityLogCount }}</span>
                {{'Activity logs'|translate}} 
            </div>
            <span (click)="showActivitylogs(taskData)" class="ml-3 fw-600 color_grey  pointer">
                <mat-icon>touch_app</mat-icon>
            </span>
            </div>
        </mat-list-item> 
    <!-- tagViewModels  -->
    <div class="row  p-0" *ngFor="let tags of taskData?.tagViewModels">
      <ng-container *ngIf="tags!.fieldValue">
          <div class="col-10 p-0" *ngIf="tags.fieldValue !='null' && tags.fieldValue !==null">
              <mat-list-item>
                  <div class="fw-600" >
                      <ng-container *ngIf="tags?.fieldType=='Checkbox'||tags?.fieldType=='Bool'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">check_box</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Freefeild'||tags?.fieldType=='Text'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">text_fields</span>
                             <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='file'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">description</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Currency'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">Payments</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Dropdown'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">Arrow Drop Down Circle
                          </span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='email'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">mail</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='URL'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">link</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='PhoneNumber'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">call</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='date'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">calendar_month</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Days'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">timer</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='hour&minutes'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">check_box</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Entity'">
                          <img style="width: 20px;height: 20px;" src="../../../../assets/logo/logo-sm.png">
                          <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Squarefeet'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">square_foot</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                      <ng-container *ngIf="tags?.fieldType=='Squaremeter'">
                          <span style="padding-right: 5px;" class="material-icons" [ngStyle]="{'color':tags?.colorCode?tags?.colorCode:'#fb4444'}">Crop Square</span>
                           <span class="fw-600" style="font-size: 16px;">{{tags.fieldName}} :</span>
                      <span class="ml-3 fw-600 color_grey" style="font-size: 16;">{{tags.fieldValue}}</span>
                      </ng-container>
                  </div> 
              </mat-list-item> 
          </div>
      </ng-container>
  </div>
        </section>
    </ng-container>

    <ng-container *ngIf="loader">
      <app-skeleton-loader></app-skeleton-loader>
      </ng-container>
  </mat-dialog-content>
