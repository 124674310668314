<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
      <h2 mat-dialog-title *ngIf="screenType == 'YearlyView'" class="m-0 dialog-title text-center w-100">
        Monthly Hours</h2>
        <h2 mat-dialog-title *ngIf="screenType == 'MonthlyHour'" class="m-0 dialog-title text-center w-100">
            Daily Hours</h2>
</div>

<mat-dialog-content class="mr-0 ml-0">
<!-- Yearly view -->
<ng-container *ngIf="screenType == 'YearlyView'">
    <div class="row mt-3 mb-2">
        <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Select Year</mat-label>
                <mat-select [(ngModel)]="selectedYear" (selectionChange)="onYearChange($event.value)">
                  <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>
    <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Month</th>
            <th scope="col">Required Hours</th>
            <th scope="col">Worked Hours</th>
            <th scope="col">Overtime Hours</th>
          </tr>
        </thead>
        <tbody>
          <!-- Display data dynamically based on the month index -->
          <tr *ngFor="let entry of monthData">
            <td (click)="openHourlyView(data.data.id, 'MonthlyHour', entry.month, lastselectedYear)"><span style="color: black; font-weight: 900;"><b>{{ entry.month }}</b></span></td>
            <td style="text-align: center;">{{ entry.monthlyRequiredHours }}</td>
            <td style="text-align: center;">{{ entry.totalWorkedHours }}</td>
            <td style="text-align: center;">{{ entry.overtimeHours }}</td>
          </tr>
        </tbody>
      </table>
  </ng-container>
<!-- Hourly view -->
<ng-container *ngIf="screenType == 'MonthlyHour'">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Required Hours</th>
                <th scope="col">Worked Hours</th>
                <th scope="col">Overtime Hours</th>
              </tr>
            </thead>
            <tbody>
              <!-- Display data dynamically based on the month index -->
              <tr *ngFor="let entry of DailyHours">
                <td><span style="color: black; font-weight: 900;"><b>{{ entry.date }}</b></span></td>
                <td style="text-align: center;">{{ entry.dailyRequiredHours }}</td>
                <td style="text-align: center;">{{ entry.trackedHours }}</td>
                <td style="text-align: center;">{{ entry.overtimeHours }}</td>
              </tr>
            </tbody>
          </table>
  </ng-container>
  <ng-container *ngIf="loader">
    <app-skeleton-loader></app-skeleton-loader>
    </ng-container>
</mat-dialog-content>
