import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class TimeManagementService {

  constructor(private http: HttpClient) { }


  createMode(mode:any): Observable<any> {
    let params = new HttpParams();
     params = params.append('Mode', mode);
      return this.http.post(baseUrl + '/api/TimeSheet/UpdateTrackingMode', {}, { params });
  }

  createprojects(name: string, color:string, fontcolor:string): Observable<any> {
    console.log(name,color,fontcolor)
    let params = new HttpParams();
     params = params.append('ProjectName', name);
        params = params.append('Color', color);
        params = params.append('FontColor', fontcolor);
        return this.http.post(baseUrl + '/api/TimeSheet/CreateProject', {}, { params });
  }

  updateProject(id:any, name: string, color:string, fontcolor:string) {
    let params = new HttpParams();
    params = params.append('projectId', id);
    params = params.append('ProjectName', name);
       params = params.append('Color', color);
       params = params.append('FontColor', fontcolor);
       return this.http.put(baseUrl + '/api/TimeSheet/UpdateProject', {}, { params });
  }

  deleteProject(id:any){
    return this.http.delete(baseUrl + '/api/TimeSheet/DeleteProject?projectId=' + id);
  }

  createTimeTrack(requestData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/TimeSheet/UpdateTrackingOption', requestData, httpOptions);
  }

  createRequest(requestData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/TimeSheet/CreateTimeOffRequest', requestData, httpOptions);
  }

  getAllProjects(pageno:any, numberofRecord:any){
      return this.http.get(baseUrl + '/api/TimeSheet/GetProjects?PageNumber=' + pageno + '&numberofRecord=' + numberofRecord)
  }

  getAllProject(){
    return this.http.get(baseUrl + '/api/TimeSheet/GetProjects')
}

  getTimeTrackings(){
    return this.http.get(baseUrl + '/api/TimeSheet/FindUserTimeTracking')
}


  getAllClockInHistory(){
    return this.http.get(baseUrl + '/api/TimeSheet/GetClockInHistory')
}

getAllEventClockInHistory(id:any){
  return this.http.get(baseUrl + '/api/TimeSheet/GetClockInHistory?EventId=' + id)
}

getUserMonthlyHours(id:any, year:any){
  return this.http.get(baseUrl + '/api/TimeSheet/MonthlyUserReport?userId=' + id + '&year=' + year)
}

getUserDailyHours(id:any, year:any, month:any){
  return this.http.get(baseUrl + '/api/TimeSheet/DailyUserReport?userId=' + id + '&year=' + year + '&month=' + month)
}

  getPendingApprovals(pageno:any, numberofRecord:any){
    return this.http.get(baseUrl + '/api/TimeSheet/GetManagerPendingRequests?PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord)
  }

  getTimesheetPendings(startdate:any, enddate:any, PageNumber:any, PageSize:any){
    const params = new URLSearchParams();
    if (startdate) params.append('StartDate', startdate);
    if (enddate) params.append('EndDate', enddate);
    if (PageNumber) params.append('PageNumber', PageNumber)
    if (PageSize) params.append('PageSize', PageSize)
    return this.http.get(`${baseUrl}/api/TimeSheet/GetPendingTimeEntries?${params.toString()}`);
  }

  getApprovedTimesheet(
    startdate: any, 
    enddate: any, 
    year: any, 
    PageNumber: any, 
    PageSize: any, 
    month: any,
    userGroupIds: any, 
    projectIds: any,  
    siteIds: any, 
    usersIds: any,
    selectGroupIds: any
  ) {
    console.log(usersIds, siteIds, projectIds, selectGroupIds);
    const params = new URLSearchParams();
  
    if (startdate) params.append('StartDate', startdate);
    if (enddate) params.append('EndDate', enddate);
    if (year) params.append('Year', year);
    if (month) params.append('Month', month);
    if (PageNumber) params.append('PageNumber', PageNumber);
    if (PageSize) params.append('PageSize', PageSize);
  
    // Helper function to append IDs with index
    const appendIdsWithIndex = (key: string, ids: any[]) => {
      if (ids && ids.length > 0) {
        ids.forEach((id, index) => params.append(`${key}[${index}]`, id));
        return true; 
      }
      return false; 
    };
  
    // Append multiple IDs and determine if a filter is applied
    const isFilter =
      appendIdsWithIndex('UserId', usersIds) ||
      appendIdsWithIndex('SiteId', siteIds) ||
      appendIdsWithIndex('SiteGroupId', selectGroupIds) ||
      appendIdsWithIndex('ProjectId', projectIds) ||
      appendIdsWithIndex('UserGroupId', userGroupIds);
  
    // Add IsFilter based on the determined condition
    params.append('IsFilter', isFilter.toString());
  
    return this.http.get(`${baseUrl}/api/TimeSheet/GetApprovedTimeEntries?${params.toString()}`);
  }
  

  approveRequest(id:number, status:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.put(baseUrl + '/api/TimeSheet/ApproveRequest?requestId=' + id + '&Status=' + status , httpOptions);
  }

  getSelectedNode(){
    return this.http.get(baseUrl + '/api/TimeSheet/FindUserTimeTrackingMode')
  }

  getEntryWithDate(startDate:any, endDate:any, search:any){
    return this.http.get(baseUrl + '/api/TimeSheet/FindEntries?StartDate=' + startDate + '&EndDate=' + endDate + '&search=' + search);
  }

  getchartData(startDate:any, endDate:any){
    return this.http.get(baseUrl + '/api/TimeSheet/GetTimeReportDashboardGraph?StartDate=' + startDate + '&EndDate=' + endDate);
  }

  getDashboardTableData(startDate:any, endDate:any) {
    return this.http.get(baseUrl + '/api/TimeSheet/GetTimeReportDashboardTable?StartDate=' + startDate + '&EndDate=' + endDate);
  }

  CreateEntry(requestData:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/TimeSheet/AddEntry', requestData, httpOptions);
  }

  UpdateEntry(requestData:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.put(baseUrl + '/api/TimeSheet/UpdateEntry', requestData, httpOptions);
  }

  ClockInTime(startTime:any, projectId:any){
    let params = new HttpParams();
    params = params.append('ProjectId', projectId);
       params = params.append('startTime', startTime);
       return this.http.post(baseUrl + '/api/TimeSheet/StartTimesheet', {}, { params });
  }

  approveTimeEntry(data:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.put(baseUrl + '/api/TimeSheet/ApproveTimeEntry', data, httpOptions);
  }

  getStatisticsData(paramsObject: any) {
    let params = new HttpParams();
  
    for (const key in paramsObject) {
      if (paramsObject[key] !== null && paramsObject[key] !== '') {
        params = params.append(key, paramsObject[key]);
      }
    }
  
    return this.http.get(baseUrl + '/api/TimeSheet/GetTimeSheetStats', { params });
  }
}
