import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Injector, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConformationComponent } from 'src/app/pages/management/management-initiatives/conformation/conformation.component';
import { ReminderCreateComponent } from 'src/app/pages/Reminder/reminder-create/reminder-create.component';
import { TimeManagementService } from '../../services/time-management.service';
import { CreateProjectComponent } from './create-project/create-project.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfilePickFormatService } from '../../services/profile-pick-format.service';
import { PopupCommonComponent } from 'src/app/pages/commonForAll/popup-common/popup-common.component';
import { ReportingDetailsComponent } from '../time-reporting/reporting-details/reporting-details.component';
import { UserDetailsComponent } from 'src/app/pages/management/manage-users/user-details/user-details.component';
import { TimesheetComponent } from '../timesheet/timesheet.component';
import { TimeOffComponent } from '../time-off/time-off.component';
import { TimeReportingComponent } from '../time-reporting/time-reporting.component';
@Component({
  selector: 'app-time-track',
  templateUrl: './time-track.component.html',
  styleUrls: ['./time-track.component.scss'],
})
export class TimeTrackComponent implements OnInit {
  tabnumber: number = 0;
  timeTrackForm: FormGroup;
  loader = false
  trackOptionsForm: FormGroup;
  filteredusers: any = [];
  ReminderList: any = [];
  currentdate = new Date();

  vacationRequests: any[] = [
    {
      name: 'Maja Andev',
      date: 'Jun 28, 2022',
      message:
        "I'm requesting 40 hours of Vacation for Oct 7, 2022 - Oct 11, 2022.",
      initials: 'MA',
    },
    {
      name: 'Jennifer Caldwell',
      date: 'Jun 28, 2022',
      message:
        "I'm requesting 40 hours of Vacation for Aug 19, 2022 - Aug 23, 2022.",
      initials: 'JC',
    },
  ];

  totalRequests = 2;
  SelectedMode: any;
  afterValue: any;
  beforeValue: any;
  Projects: any;
  AdminStatus: any;
  ManagerStatus: any;
  Trackingmethod: string;
  displayedColumns: string[] = ['projectName', 'dateOfCreation'];

ReminderListDummy = [{triggertype : 'Notification', value: '30', durationtype: 'Minutes', triggerMoment: 'Before', Parameter: 'Start'}]

  ManagerApproval: FormGroup
  projectName: any;
  FontColor: any;
  Color: any;
  previousValue: string;
  currentValue: string;
  selectedProject: any;
  Projectid: any;
  EntryTimeSheet: any;
  SingleApproveForm: FormGroup;
  AllDateApproveForm: FormGroup;
  AllApproveForm: FormGroup;
  transformedData: any;
  Mode: any;
  uniqueDates: any;
  dateEntriesMap: any;
  ToggleValue: any;
  TimeTrackingData: any;
  approveAllDate: boolean = false;
  allRequestIds: any = [];
  UserTotalRequired: any;
  UserTotalWorked: any;
  UserTotalOvertime: any;
  EntryDetails: any;
  TimeUserDetails: any;
  projectsDataLength: any;
  TimeEntryIds: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private injector: Injector,
    private dialogRef: MatDialogRef<TimeTrackComponent>,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.AdminStatus = sessionStorage.getItem("isAdmin") == 'true' ? true : false; 
    this.ManagerStatus =sessionStorage.getItem("isManager") == 'true' ? true : false; 
   
  }

  ngOnInit(): void {
    this.getTimeTracking()
    this.timeTrackForm = this.fb.group({
      timeOption: ['', Validators.required],
    });

    this.SingleApproveForm = this.fb.group({
      ApproveSingle: [false]
    })

    this.AllDateApproveForm = this.fb.group({
      ApproveDateWise: [false],
    })

    this.AllApproveForm = this.fb.group({
      ApproveAllDate: [false],
    })

    

    this.trackOptionsForm = this.fb.group({
      eventMatching: [false],
      addreminders: [false],
      toggleOption: [false],
      selectdatetype: ['2'],
      customDays: [null]
    });

    this.ManagerApproval = this.fb.group({
      startDate: [],
      endDate: []
    })

    this.findNode()
    
  }

  approveRequests(data:any, type:any){
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: type },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.SingleApproveForm.get('ApproveSingle').setValue(false)
        this.showResult(1, 10)
      } else {
        this.SingleApproveForm.get('ApproveSingle').setValue(false)
      }
    });
  }

  findNode(){
    this.loader = true
    this.injector.get(TimeManagementService).getSelectedNode().subscribe((data:any) => {
      console.log(data)
      if(data) {
        this.loader = false
        this.Mode = data?.response[0].message
        const timeOptionValue = this.Mode === 'ClockInClockOut' ? '1' : this.Mode === 'StartEndTime' ? '2' : '3';
        this.timeTrackForm.get('timeOption').setValue(timeOptionValue);
        this.timeTrackForm.get('timeOption').updateValueAndValidity();
      }
    
    }, error => {
      this.loader = false
    })
  }

  approveAllRequests(type:any) {
    // Create an array to store all request IDs
    this.allRequestIds = this.allRequestIds || [];
     console.log(this.allRequestIds)
      const data = {
        "timeEntryIds": [this.allRequestIds.join(',')],
        "status": type === 'Approve' ? 1 : 2,
      }
      this.injector.get(TimeManagementService).approveTimeEntry(data).subscribe(result => {
        if(result) {
          this.allRequestIds = []
          this.showResult(1, 10);
        }
      })
  }

getRequestIdsFromEntry(entry: any): string[] {
    return entry.userRequestDtos?.flatMap(member => 
        member.requestDtos.map(day => day.id)
    ) || []; // Return an empty array if there are no request IDs
}


onCheckboxChange(data: any, event: any, type: any) {
  console.log(data);

  // Reset TimeEntryIds for each checkbox change
  this.TimeEntryIds = [];

  // Handle the "ApproveAll" case
  if (data === 'ApproveAll' && event.checked) {
    for (let entry of this.EntryTimeSheet) {
      const requestIds = this.getRequestIdsFromEntry(entry);
      this.allRequestIds = [...this.allRequestIds, ...requestIds];
    }
    this.SingleApproveForm.get('ApproveSingle').setValue(true);
    this.AllDateApproveForm.get('ApproveDateWise').setValue(true);
  } else if (data === 'ApproveAll' && !event.checked) {
    for (let entry of this.EntryTimeSheet) {
      const requestIds = this.getRequestIdsFromEntry(entry);
      this.allRequestIds = this.allRequestIds.filter(id => !requestIds.includes(id));
    }
    this.SingleApproveForm.get('ApproveSingle').setValue(false);
    this.AllDateApproveForm.get('ApproveDateWise').setValue(false);
  }

  // Handle the "Multiple" case
  else if (type === 'Multiple') {
    if (event.checked) {
      const requestIds = data.requestIds;
      this.EnableDisableAllData(this.TimeEntryIds, true);
      this.allRequestIds = [...this.allRequestIds, ...requestIds];
    } else {
      const requestIds = data.requestIds;
      this.EnableDisableAllData(this.TimeEntryIds, false);
      this.allRequestIds = this.allRequestIds.filter(id => !requestIds.includes(id));
    }
  }

  // Handle the "Single" case
  else if (type === 'Single') {
    if (event.checked) {
      this.TimeEntryIds = data?.map((element: any) => element.timeEntryId);
      this.EnableDisableAllData(this.TimeEntryIds, true);
      this.allRequestIds = [...this.allRequestIds, ...this.TimeEntryIds];
    } else {
      this.TimeEntryIds = data?.map((element: any) => element.timeEntryId);
      this.EnableDisableAllData(this.TimeEntryIds, false);
      this.allRequestIds = this.allRequestIds.filter(id => !this.TimeEntryIds.includes(id));
    }
  }

  console.log(this.allRequestIds);
}



  EnableDisableAllData(date:any, enable: any) {
    this.uniqueDates.forEach(element => {
      if( element.date == date.date){
        element.date.checked = enable
        this.transformedData.forEach(user => {
          this.getDateEntry(user.creatorId, element.date)
          console.log(this.getDateEntry(user.creatorId, element.date))
        });
       
      }
      console.log(date)
      console.log(element)
    });
  }

  openProjectMenu(event: MouseEvent, project: any) {
    // Stop event propagation to prevent the mat-select from trying to select an option
    event.stopPropagation();
    // Open the menu programmatically if needed
    this.selectedProject = project; // Save the currently selected project if needed
  }

  createNode(){
    const timeOptionValue = this.timeTrackForm.get('timeOption').value;
    const mode = Number.isInteger(timeOptionValue) ? timeOptionValue : parseInt(timeOptionValue, 10);
    this.injector.get(TimeManagementService).createMode(mode).subscribe((data:any) => {
      if(data){
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
      }
    })
  }

  getProjects(PageNumber:any, numberofRecords:any){
    this.injector.get(TimeManagementService).getAllProjects(PageNumber, numberofRecords).subscribe((data:any) => {
      console.log(data)
      this.Projects = data
      this.projectsDataLength = data.totalItems
    })
  }

  tabChange(tabIndex: number): void {
    this.tabnumber = tabIndex;
    if (tabIndex === 0) {
      this.tabnumber = 0;
      this.findNode()
    } else if (tabIndex === 1) {
      this.tabnumber = 1;
      this.getProjects(1, 10)
    } else if (tabIndex === 2) {
      this.tabnumber = 2;
      this.setWeekDates()
      this.showResult(1, 10)
    }
  }

  next(e:any, value:any){
    if (value === 'single') {
      this.getProjects(e.pageIndex + 1, 10);
    }
  }

  setWeekDates(): void {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  
    // Calculate the start of the week (Monday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() + distanceToMonday);
  
    // Calculate the end of the week (Friday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);
  
    // Format dates as yyyy-mm-dd
    const formattedStartDate = startOfWeek.toISOString().split('T')[0];
    const formattedEndDate = endOfWeek.toISOString().split('T')[0];
  
    // Set these values in the form
    this.ManagerApproval.get('startDate').setValue(formattedStartDate);
    this.ManagerApproval.get('endDate').setValue(formattedEndDate);
  }

  NextMethod2() {
    this.ReminderList?.forEach(element => {
      if(element.isAfter) {
        this.afterValue = element.value
      } else {
        this.beforeValue = element.value
      }
    });

    this.ReminderListDummy?.forEach(element => {
      if(element.triggerMoment) {
        this.afterValue = element.value
      } else {
        this.beforeValue = element.value
      }
    })
    
    var data = {
      isEventMatching: this.trackOptionsForm.get('eventMatching').value,
      hasReminders: this.trackOptionsForm.get('addreminders').value,
      reminderBeforeMinutes: this.beforeValue,
      reminderAfterMinutes: this.afterValue,
      isApprovalRequired: this.trackOptionsForm.get('toggleOption').value,
      trackingPeriod: Number.isInteger(this.trackOptionsForm.get('selectdatetype').value) ? this.trackOptionsForm.get('selectdatetype').value : parseInt(this.trackOptionsForm.get('selectdatetype').value, 10),
      NumberOfDays: this.trackOptionsForm.get('customDays').value,
    };

    var FinalData = {};
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        FinalData[key] = data[key];
      }
    }

    this.injector.get(TimeManagementService).createTimeTrack(FinalData).subscribe((data:any) => {
      console.log(data)
      if(data){
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
        
      }
    })
  }

  onOptionChange(selectedOption: string) {
    this.Trackingmethod = selectedOption == '1' ? 'Clock In – Clock Out': selectedOption == '2' ? 'Start Time – End Time' : 'No of Hrs'
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { option: this.Trackingmethod, type: 'TimeTrack' },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createNode()
      } else {
        this.findNode()
      }
    });
  }

  getreminderststus(){
    return this.trackOptionsForm.get('addreminders').value
  }

  showreminderspopup() {
    this.trackOptionsForm.get('addreminders').setValue(true);
    this.showreminders();
  }

  showreminders() {
    const showreminders = this.dialog.open(ReminderCreateComponent, {
      width: '500px',
      data: { type: 'TimeTrack', screenType: 'CreateReminder' },

      autoFocus: false,
      disableClose: true,
    });
    showreminders.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
        result.reminderConditions.forEach((element) => {
          this.ReminderList.push(element);
          console.log(this.ReminderList);
        });
      }
    });
  }
  removereminder(index) {
    this.ReminderList.splice(index, 1);
  }

  removereminderDummy(index) {
    this.ReminderListDummy.splice(index, 1);
  }

  getdateStatus(event: any) {
    if (event === '1') {
      this.trackOptionsForm
        .get('EndDate')
        ?.setValue(
          this.datepipe.transform(this.getDateAfterWeeks(1), 'yyyy-MM-dd')
        );
    } else if (event === '2') {
      this.trackOptionsForm
        .get('EndDate')
        ?.setValue(
          this.datepipe.transform(
            this.trackOptionsForm.value.StartDate,
            'yyyy-MM-dd'
          )
        );
    }
  }

  getDateAfterWeeks(weeks: any) {
    const currentDate = new Date(this.trackOptionsForm.value.StartDate);
    const futureDate = new Date(
      currentDate.getTime() + weeks * 7 * 24 * 60 * 60 * 1000
    ); // Add weeks in milliseconds
    const year = futureDate.getFullYear();
    const month = futureDate.toLocaleString('default', { month: 'long' });
    const day = futureDate.getDate();
    // Format the date as "Month Day, Year"
    const formattedDate = `${month} ${1 - day}, ${year}`;

    return formattedDate;
  }

  approveRequest(request: any) {
    console.log('Approved:', request);
  }

  denyRequest(request: any) {
    console.log('Denied:', request);
  }

  AddProject(data:any, type:any) {
    const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
      width: '400px',
      autoFocus: false,
      disableClose: true,
      data: {data: data, type:type}
    });

    TimeTrackDialog.afterClosed().subscribe((result) => {
      if(result) {
        console.log(result)
        this.projectName = result.ProjectName
        this.FontColor = result.fontColor
        this.Color = result.color
        this.Projectid = result?.id
  
        if(result.id != null) {
          this.injector
          .get(TimeManagementService)
          .updateProject(this.Projectid, this.projectName, this.Color, this.FontColor)
          .subscribe((result:any) => {
            console.log(result);
            if (result) {
              this.snackBar.open(result?.response[0].message,'Undo', { duration: 2000 });
              this.getProjects(1, 10)
            }
          });
        } else {
          this.injector
          .get(TimeManagementService)
          .createprojects(this.projectName, this.Color, this.FontColor)
          .subscribe((result:any) => {
            console.log(result);
            if (result) {
              this.snackBar.open(result?.response[0].message,'Undo', { duration: 2000 });
              this.getProjects(1, 10)
            }
          });
        }
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }


  // getPendingTimeRequests(PageNumber:any, PageSize:any){
  
  //   // Format the start and end dates (yyyy-mm-dd)
  //   const currentDate = new Date();
  //   const dayOfWeek = currentDate.getDay();
  //   const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;  
  //   const startOfWeek = new Date(currentDate);
  //   startOfWeek.setDate(currentDate.getDate() + distanceToMonday);  
  
  //   // Calculate the end of the week (Friday)
  //   const endOfWeek = new Date(startOfWeek);
  //   endOfWeek.setDate(startOfWeek.getDate() + 4);
  
  //   // Format the start and end dates (yyyy-mm-dd)
  //  const formattedStartDate = startOfWeek.toISOString().split('T')[0];
  //  const formattedEndDate = endOfWeek.toISOString().split('T')[0];

  //   this.ManagerApproval.get('startDate').setValue(formattedStartDate)
  //   this.ManagerApproval.get('endDate').setValue(formattedEndDate)
   
  //   this.injector.get(TimeManagementService).getTimesheetPendings(formattedStartDate, formattedEndDate, PageNumber, PageSize).subscribe((data:any) => {
  //       console.log(data)
  //       this.EntryTimeSheet = data[0].approvedEntryDtos
  //       this.UserTotalRequired = data[0].totalUsersDuration
  //       this.UserTotalWorked = data[0].totalUsersWorkedDuration
  //       this.UserTotalOvertime = data[0].totalUsersOvertime

  //       var uniqueDates = [];
  //       var dateEntriesMap = {};
    
  //       // Transform data and populate unique dates and dateEntriesMap
  //       const transformedData = this.EntryTimeSheet?.reduce((result, entry) => {
  //         // Check if entry has a date and is formatted correctly
  //         if (entry?.date) {
  //           // Ensure the date is included in uniqueDates even if there are no userRequestDtos
  //           if(entry.fullDayName == null) {
  //             uniqueDates.push({
  //               monthName: entry.monthName,
  //               totalDuration: entry.totalDuration || "00:00:00",
  //               pendingApprovalCount: entry.pendingApprovalCount || 0,
  //               requestIds: [] 
  //             });
  //           }
  //           else if (!uniqueDates.find(d => d.date === entry.date)) {
  //             uniqueDates.push({
  //               date: entry.date,
  //               fullDayName: entry.fullDayName,
  //               monthName: entry.monthName,
  //               pendingApprovalCount: entry.pendingApprovalCount || 0, // Default to 0 if not available
  //               totalDuration: entry.totalDuration || "00:00:00",
  //               requestIds: [] 
  //             });
  //           }
  //         } 

  //         if(entry?.date && entry?.fullDayName){
  //           var dateEntry = uniqueDates.find(d => d.date === entry.date);
  //         } else {
  //           var dateEntry = uniqueDates.find(d => d.monthName === entry.monthName);
  //         }
         
        
  //         if (entry?.userRequestDtos) {
  //           entry.userRequestDtos.forEach(user => {
  //             let existingUser = result.find(u => u.User.creatorId === user.creatorId);
        
  //             // Initialize dateEntriesMap for user if not already present
  //             if (!dateEntriesMap[user.creatorId]) {
  //               dateEntriesMap[user.creatorId] = {};
  //             }
        
  //             // Check if requestDtos exists within the user and is non-empty
  //             if (user?.requestDtos && user.requestDtos.length > 0) {
  //               user.requestDtos.forEach(request => {
    
  //                 dateEntry.requestIds.push(request.id);
  //                 // Initialize date array if not present
  //                 if (!dateEntriesMap[user.creatorId][entry.date]) {
  //                   dateEntriesMap[user.creatorId][entry.date] = [];
  //                 }
                  
                  
  //                     dateEntriesMap[user.creatorId][entry.date].push({
  //                     timeEntryId: request.id,
  //                     checked: false,
  //                     totalDuration: user.totalDuration,
  //                     workedDuration: user.workedDuration,
  //                     overTime: user.overTime
  //                   });
                 
  //                 // Append each request entry for the date
                  
        
  //                 // Log the dateEntriesMap entry after adding request data
  //                 console.log('Updated dateEntriesMap entry:', dateEntriesMap[user.creatorId][entry.date]);
  //               });
  //             } else {
  //               console.log('No requestDtos found for user:', user);
                
  //               // Ensure that even if there are no requestDtos, we still have an entry for the date
  //               if (!dateEntriesMap[user.creatorId][entry.date]) {
  //                 dateEntriesMap[user.creatorId][entry.date] = []; // Initialize empty array for no entries
  //               }
  //             }
        
  //             // Construct transformedData based on existing user
  //             if (existingUser) {
  //               existingUser.date.push({
  //                 date: entry.date,
  //                 fullDayName: entry.fullDayName,
  //                 monthName: entry.monthName,
  //               });
  //             } else {
  //               result.push({
  //                 User: user,
  //                 date: [{
  //                   date: entry.date,
  //                   fullDayName: entry.fullDayName,
  //                   monthName: entry.monthName,
  //                 }]
  //               });
  //             }
  //           });
  //         } else {
  //           console.log('No userRequestDtos found for entry:', entry);
  //         }
        
  //         return result;
  //       }, []);
        
  //       // Assign transformed data to component variables
  //       this.transformedData = transformedData;
  //       this.uniqueDates = uniqueDates;
  //       this.dateEntriesMap = dateEntriesMap;
  //       console.log('this.dateEntriesMap.....', this.dateEntriesMap)
      
  //     })
  // }

  getDateEntry(userId: string, date: string) {
    return this.dateEntriesMap?.[userId]?.[date] || null;
  }

  showResult(PageNumber:any, PageSize:any){
    this.loader = true
    const startDate = this.ManagerApproval.get('startDate').value; 
    const endDate = this.ManagerApproval.get('endDate').value;

    const formattedStartDate = new Date(Date.UTC(
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
      new Date(startDate).getDate()
  )).toISOString().split('T')[0];

  const formattedEndDate = new Date(Date.UTC(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate()
  )).toISOString().split('T')[0];
   
    this.injector.get(TimeManagementService).getTimesheetPendings(formattedStartDate, formattedEndDate, PageNumber, PageSize).subscribe((data:any) => {
    console.log(data)
    if(data) {
      this.loader = false

      this.TimeUserDetails = data[0].userDurationDtos
      this.EntryTimeSheet = data[0].approvedEntryDtos
      this.UserTotalRequired = data[0].totalUsersDuration
      this.UserTotalWorked = data[0].totalUsersWorkedDuration
      this.UserTotalOvertime = data[0].totalUsersOverTimeDuration
  
      var uniqueDates = [];
      var dateEntriesMap = {};
  
      // Transform data and populate unique dates and dateEntriesMap
      const transformedData = this.EntryTimeSheet?.reduce((result, entry) => {
        // Check if entry has a date and is formatted correctly
        if (entry?.date) {
          // Ensure the date is included in uniqueDates even if there are no userRequestDtos
          if(entry.fullDayName == null) {
            uniqueDates.push({
              monthName: entry.monthName,
              totalDuration: entry.totalDuration || "00:00:00",
              pendingApprovalCount: entry.pendingApprovalCount || 0,
              workedDuration: entry.workedDuration || "00:00:00",
              overtime: entry.overtime,
              requestIds: [] 
            });
          }
          else if (!uniqueDates.find(d => d.date === entry.date)) {
            uniqueDates.push({
              date: entry.date,
              fullDayName: entry.fullDayName,
              monthName: entry.monthName,
              pendingApprovalCount: entry.pendingApprovalCount || 0, // Default to 0 if not available
              totalDuration: entry.totalDuration || "00:00:00",
              workedDuration: entry.workedDuration || "00:00:00",
              overtime: entry.overtime,
              requestIds: [] 
            });
          }
        } 
  
  
        if(entry?.date && entry?.fullDayName){
          var dateEntry = uniqueDates.find(d => d.date === entry.date);
        } else {
          var dateEntry = uniqueDates.find(d => d.monthName === entry.monthName);
        }
       
      
        if (entry?.userRequestDtos) {
          entry.userRequestDtos.forEach(user => {
            let existingUser = result.find(u => u.User.creatorId === user.creatorId);
      
            // Initialize dateEntriesMap for user if not already present
            if (!dateEntriesMap[user.creatorId]) {
              dateEntriesMap[user.creatorId] = {};
            }
      
            // Check if requestDtos exists within the user and is non-empty
            if (user?.requestDtos && user.requestDtos.length > 0) {
              user.requestDtos.forEach(request => {
  
                dateEntry.requestIds.push(request.id);
                // Initialize date array if not present
                if (!dateEntriesMap[user.creatorId][entry.date]) {
                  dateEntriesMap[user.creatorId][entry.date] = [];
                }
                
                
                  dateEntriesMap[user.creatorId][entry.date].push({
                    timeEntryId: request.id,
                    startIn12Hours: request.startIn12Hours,
                    endIn12Hours: request.endIn12Hours,
                    duration: request.duration,
                    projectColor: request.projectColor,
                    projectFontColor: request.projectFontColor,
                    projectName: request.projectName,
                    totalDuration: user.totalDuration,
                    workedDuration: user.workedDuration,
                    overTime: user.overTime,
                    siteAddress: request.siteAddress,
                    siteCity: request.siteCity,
                    siteCountry: request.siteCountry,
                    siteName: request.siteName,
                    siteId: request.siteId,
                    siteZipCode: request.siteZipCode,
                    stopIpAddress: request.stopIpAddress,
                    startIpAddress: request.startIpAddress,
                    distanceFromStartIP: request.distanceFromStartIP,
                    distanceFromStopIP: request.distanceFromStopIP,
                    isTimeOff: request.isTimeOff,
                    categoryFontColor: request.categoryFontColor,
                    categoryColor: request.categoryColor, 
                    isFullDay: request.isFullDay,
                    description: request.description,
                    isClockInClockOut: request.isClockInClockOut,
                    formattedDuration: request.formattedDuration,
                    created: request.created,
                    startLatitude: request.startLatitude,
                    startLongitude: request.startLongitude,
                    stopLatitude: request.stopLatitude,
                    stopLongitude: request.stopLongitude,
                  });
               
                // Append each request entry for the date
                
      
                // Log the dateEntriesMap entry after adding request data
                console.log('Updated dateEntriesMap entry:', dateEntriesMap[user.creatorId][entry.monthName ? entry.monthName : entry.date]);
              });
            } else {
              console.log('No requestDtos found for user:', user);
              
              // Ensure that even if there are no requestDtos, we still have an entry for the date
              if (!dateEntriesMap[user.creatorId][entry.date]) {
                dateEntriesMap[user.creatorId][entry.date] = []; // Initialize empty array for no entries
              }
              
            }
      
            // Construct transformedData based on existing user
            if (existingUser) {
              existingUser.date.push({
                date: entry.date,
                fullDayName: entry.fullDayName,
                monthName: entry.monthName,
              });
            } else {
              result.push({
                User: user,
                date: [{
                  date: entry.date,
                  fullDayName: entry.fullDayName,
                  monthName: entry.monthName,
                }]
              });
            }
          });
        } else {
          console.log('No userRequestDtos found for entry:', entry);
        }
      
        return result;
      }, []);
      
      // Assign transformed data to component variables
      this.transformedData = transformedData;
      this.uniqueDates = uniqueDates;
      this.dateEntriesMap = dateEntriesMap;
      console.log('this.dateEntriesMap.....', this.dateEntriesMap)
    
    }
   
  }, error => {
    this.loader = false
  })
  }
  
  formatMonthDate(date: string, monthName: string): string {
    if (!date || !monthName) return ''; // Handle invalid inputs
  
    const dayNumber = new Date(date).getDate();
  
    const shortMonth = monthName.slice(0, 3);
  
    return `${shortMonth} ${dayNumber}`;
  }

  FormatTotalDuration(totalDuration: string): string {
    if (!totalDuration) return '0h 0m'; // Handle empty input
    
    // Handle "00:00:00" format (hh:mm:ss)
    if (totalDuration.includes(':')) {
      const [hours, minutes, seconds] = totalDuration.split(':').map(Number);
      if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return '0h 0m'; // Handle invalid input
      return `${hours}h ${minutes}m`;
    }
  
    // Initialize hours and minutes
    let hours = 0;
    let minutes = 0;
  
    // Check for "Hrs" and extract hours
    const hoursMatch = totalDuration.match(/(\d+)\s*Hrs/);
    if (hoursMatch) {
      hours = parseInt(hoursMatch[1], 10);
      if (isNaN(hours)) hours = 0; // Default to 0 if invalid
    }
  
    // Check for "Min" and extract minutes
    const minutesMatch = totalDuration.match(/(\d+)\s*Min/);
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[1], 10);
      if (isNaN(minutes)) minutes = 0; // Default to 0 if invalid
    }
  
    // Return the formatted result
    return `${hours}h ${minutes}m`;
  }
  
  
  
  

  deleteProject(data:any){
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: 'ProjectDelete' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getProjects(1, 10)
      }
    });


  }

  getTimeTracking(){
    this.injector.get(TimeManagementService).getTimeTrackings().subscribe((data:any) => {
      console.log(data)
      this.TimeTrackingData = data

      if (data) {
        this.trackOptionsForm.get('eventMatching').setValue(data.isEventMatching)
        this.trackOptionsForm.get('toggleOption').setValue(data.isApprovalRequired)
      } 
    })
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  onToggleChange(event:any, type:any){
    console.log(event)
    this.ToggleValue = event.checked
    if(type == 'EventMatching'){
      var data = this.trackOptionsForm.get('eventMatching')?.value
    } else {
      var data = this.trackOptionsForm.get('toggleOption')?.value
    }
    
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: type },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.NextMethod2()
      } else {
        if (this.TimeTrackingData.isEventMatching == true) {
          this.trackOptionsForm.get('eventMatching').setValue(true)
        } else {
          this.trackOptionsForm.get('eventMatching').setValue(false)
        }

        if(this.TimeTrackingData.isApprovalRequired == true){
          this.trackOptionsForm.get('toggleOption').setValue(true)
        } else {
          this.trackOptionsForm.get('toggleOption').setValue(false)
        }
      }
    });
  }

  TimeDetails(user: any, date:any, dateEntries: any[], timeEntryId: number,){
    console.log(dateEntries)
    this.getDateEntry(user.userId, date.date) 
    const specificEntry = dateEntries.find((entry) => entry.timeEntryId === timeEntryId);
      
      console.log('Specific Entry:', specificEntry);

    const TimeDetails = this.dialog.open(
      ReportingDetailsComponent,
      {
        disableClose: true,
        data: { data: specificEntry, user:user, EntryDetails:dateEntries, type: 'TimeTrack' },
        width: '700px',
      }
    );
    
    TimeDetails.afterClosed().subscribe((result: any) => {
      
    })
  }

  ShowUserDEtails(row:any, type: string): void {
    this.dialog.open(UserDetailsComponent, {
      data: { id: row, type: type, },
      disableClose: true,
      width: '500px',
    },);
  }

  openTimeSheet(){
    this.dialogRef.close(true)
    const TimeSheetDialog = this.dialog.open(TimesheetComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true,
      data: {type: 'User-TimeSheet'}
    });

    TimeSheetDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openTimeOff(){
    this.dialogRef.close(true)
    const TimeOffDialog = this.dialog.open(TimeOffComponent, {
      width: '900px',
      height: '90vh',
      autoFocus: false,
      disableClose: true
    });

    TimeOffDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openManagerTime(){
    this.dialogRef.close(true)
    const TimeSheetDialog = this.dialog.open(TimeReportingComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true,
    });

    TimeSheetDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }



 
}
