import { BreakpointState } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { CalendarEvent, WeekDay } from '../../../core/models/calendar-event.model';
import {
  addDays,
  addHours,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import { Observable } from 'rxjs';
import { OpenDayEventsModalPage } from '../open-day-events-modal/open-day-events-modal.page';
import { CreateProjectComponent } from '../time-track/create-project/create-project.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TimeManagementService } from '../../services/time-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportingDetailsComponent } from '../time-reporting/reporting-details/reporting-details.component';
import { TimeTrackComponent } from '../time-track/time-track.component';
import { TimeReportingComponent } from '../time-reporting/time-reporting.component';
import { TimeOffComponent } from '../time-off/time-off.component';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {

  calendarView: string = 'month'; 
  CalendarView = CalendarView;
  viewDate: Date;
  smallDevice!: Observable<BreakpointState>;
  OtherpeplePlanning!: boolean;
  startDate;
  endDate;
  events: CalendarEvent[] = [];
  activeDayIsOpen = false;
  weekDays: Date[] = [];
  timeEntries: { [key: string]: any[] } = {};
  totalHoursThisWeek = 0;
  totalHoursThisPayPeriod = 0;
  ClockInButton: boolean = true
  currentTime = new Date ()

  timesheet = [
    { day: 'Tue', date: 'Aug 1', hours: '0h 00m', details: [] },
    { day: 'Wed', date: 'Aug 2', hours: '0h 00m', details: [] },
    { day: 'Thu', date: 'Aug 3', hours: '0h 00m', details: [] },
    { day: 'Fri', date: 'Aug 4', hours: '6h 18m', details: [
        { time: '6:00 AM - 10:00 AM', duration: '4h 00m' }, 
        { time: '11:34 AM - 1:52 PM', duration: '2h 18m' }] 
    },
    { day: 'Sat', date: 'Aug 5', hours: '0h 00m', details: [] }
  ];
  totalHoursToday = '6h 18m';
  clockedOutTime = '1:52 PM';
  totalHoursWeek = '6h 18m';
  totalHoursPayPeriod = '6h 18m';

  selectedDayIndex: number | null = null;
  categories: any[] = [{Name: 'This Pay Period'}]



TimeSheetForm: FormGroup;
  Mode: any;
  showClock: boolean = false;
  EndClock: boolean = false;
  CurrentTime: string;
  TimeSheetData: any;
  shortDay: string;
  shortDate: string;
  currentDate = new Date ()
  currentMode: number;
  clockedInId: any;
  totalHoursWorked: any;
  lastStartTime: any;
  isEndTimeMidnight: boolean = false;
  ClockInId: any;
  clockInClockOutHistory: any;
  lastendTime: any;
  TotalHoursPerDay: any;
  LoginStartLat: any;
  LoginStartLong: any;
  LoginStopLat: any;
  LoginStopLong: any;
  eventLat: any;
  eventLong: any;
  AdminStatus: boolean = false;
  ManagerStatus: boolean = false;
  siteId: any;

  constructor(
    public datepipe: DatePipe,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private injector: Injector,
    public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<TimesheetComponent>,

  ) {
    this.AdminStatus = sessionStorage.getItem("isAdmin") == 'true' ? true : false; 
    this.ManagerStatus=sessionStorage.getItem("isManager") == 'true' ? true : false; 
   }

  ngOnInit(): void {
    
    this.TimeSheetForm = this.fb.group({
      startDate: [],
      endDate: []
    })
    this.FindClockInHistory()
    this.findNode()
    this.getTimeEntries()
   
  }

  findNode(){
    this.injector.get(TimeManagementService).getSelectedNode().subscribe((data:any) => {
      console.log(data)
      this.Mode = data?.response[0].message
    })
  }

  FindClockInHistory(){
    this.injector.get(TimeManagementService).getAllClockInHistory().subscribe((result:any) => {
      console.log(result)
      this.clockInClockOutHistory = result.clockInClockOut;
      this.TotalHoursPerDay = result.totalHours
        
        // Check if there are entries in clockInClockOut
        if (this.clockInClockOutHistory.length > 0) {
            const lastEntry = this.clockInClockOutHistory[this.clockInClockOutHistory.length - 1];
            
             this.isEndTimeMidnight = lastEntry.endTime === "12:00 AM";
             this.lastStartTime = lastEntry.startTime;
             this.lastendTime = lastEntry.endTime;
             this.ClockInId = lastEntry.trackerId;

            console.log("Is endTime midnight:", this.isEndTimeMidnight);
            console.log("Last startTime:", this.lastStartTime);
        }
    })
  }

  getTimeEntries() {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;  
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() + distanceToMonday);  
  
    // Calculate the end of the week (Friday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);
  
    // Format the start and end dates (yyyy-mm-dd)
    const formattedStartDate = startOfWeek.toISOString().split('T')[0];
    const formattedEndDate = endOfWeek.toISOString().split('T')[0];
    this.TimeSheetForm.get('startDate').setValue(formattedStartDate)
    this.TimeSheetForm.get('endDate').setValue(formattedEndDate)
    
    this.injector.get(TimeManagementService).getEntryWithDate(formattedStartDate, formattedEndDate, '').subscribe(data => {
      console.log(data);
      this.TimeSheetData = data
      this.TimeSheetData.forEach(entry => {
        const [dayName, month, dayNumber] = entry.fullDayName.split(' '); // Split string by spaces
        entry.shortDay = dayName; // 'Mon'
        entry.date = `${month} ${dayNumber}`; // 'Oct 21'

        const [totalHours, totalMinutes] = entry.totalDuration.split(':'); 
        entry.formattedtotal = `${+totalHours}h ${totalMinutes}m`;  

        entry?.entries?.forEach(timeEntry => {
          const [startHours, startMinutes] = timeEntry.start.split(':'); 
        timeEntry.formattedStart = `${+startHours}h ${startMinutes}m`;  

       

        // Format end time
        const [endHours, endMinutes] = timeEntry.end.split(':');
        timeEntry.formattedEnd = `${+endHours}h ${endMinutes}m`; 

        const [durationHours, durationMinutes] = timeEntry.duration.split(':');
        timeEntry.formattedDuration = `${+durationHours}h ${durationMinutes}m`; 
      });
    });
     
    });
  }

  durationFormat(value:any){
    const [durationHours, durationMinutes] = value?.split(':');
    return `${+durationHours}h ${durationMinutes}m`; 
  }

  ShowEntryResult(){
    console.log(this.TimeSheetForm.get('startDate').value, this.TimeSheetForm.get('endDate').value)
    const startDate = this.TimeSheetForm.get('startDate').value; 
    const endDate = this.TimeSheetForm.get('endDate').value;

    const formattedStartDate = new Date(Date.UTC(
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
      new Date(startDate).getDate()
  )).toISOString().split('T')[0];

  const formattedEndDate = new Date(Date.UTC(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate()
  )).toISOString().split('T')[0];

    this.injector.get(TimeManagementService).getEntryWithDate(formattedStartDate, formattedEndDate, '').subscribe(data => {
      console.log(data);
      this.TimeSheetData = data
      this.TimeSheetData.forEach(entry => {
        const [dayName, month, dayNumber] = entry.fullDayName.split(' '); // Split string by spaces
        entry.shortDay = dayName; // 'Mon'
        entry.date = `${month} ${dayNumber}`; // 'Oct 21'

        const [totalHours, totalMinutes] = entry.totalDuration.split(':'); 
        entry.formattedtotal = `${+totalHours}h ${totalMinutes}m`;  

        entry?.entries?.forEach(timeEntry => {
          const [startHours, startMinutes] = timeEntry.start.split(':'); 
        timeEntry.formattedStart = `${+startHours}h ${startMinutes}m`;  
          console.log(timeEntry.formattedStart)
        // Format end time
        const [endHours, endMinutes] = timeEntry.end.split(':');
        timeEntry.formattedEnd = `${+endHours}h ${endMinutes}m`; 
        console.log(timeEntry.formattedEnd)
        const [durationHours, durationMinutes] = timeEntry.duration.split(':');
        timeEntry.formattedDuration = `${+durationHours}h ${durationMinutes}m`; 
      });
    });
    
    });
  }

  getdistanceFromStartIP(data){
    const value = data.split(' ')
    if(value[1] == 'km'){
      return 'red-colorred'
    } else {
      if(value[0] < 500){
        return 'green-colorred'
      } else {
        return ''
      }
    }
  }
  getbodycolor(data){
    if(data.status==1){
    return 'bodycolorblue'
    }else{
      return 'bodycolorblue'
    }

  }



  selectday(index: number) {
    console.log(index)
    this.selectedDayIndex = index;  // Set the selected index
  }

  checkout(){

    if(this.isEndTimeMidnight) {
      const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {type: 'ClockIn', Mode: this.Mode}
      });
  
      TimeTrackDialog.afterClosed().subscribe((result) => {
        console.log(result)
        this.getCurrentTime()
        if(this.Mode == 'ClockInClockOut'){
          const currentDate: Date = new Date();
          const formattedDate: string =
            this.datepipe.transform(currentDate, 'yyyy-MM-dd') +
            'T' +
            currentDate.toTimeString().split(' ')[0];

            // const clockedInIdNumber: number = Number(this.clockedInId);
            // console.log(this.clockedInId)
          var data = [
            {
              "id": this.ClockInId,
              "endTime": formattedDate,
              "stopComments": result.comment,
              "created": formattedDate,
              "isClockIn": true,
            }
          ]
          this.injector.get(TimeManagementService).UpdateEntry(data).subscribe((data:any) => {
            if(data) {
              this.FindClockInHistory()
              this.getTimeEntries()
              const timeParts: string[] = data.totalHours.split(':');
              const hours: string = timeParts[0]; // "10"
              const minutes: string = timeParts[1]; // "01"
              const seconds: string = timeParts[2]; // "42"
              this.totalHoursWorked = `${hours}h ${minutes}m`;
              this.snackBar.open('Clocked Out Successfully','Undo', { duration: 2000 });
              
            }
          })
        }
      })
    } else {
      const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {type: 'ClockOut', Mode: this.Mode}
      });
  
      TimeTrackDialog.afterClosed().subscribe((result) => {
          this.getCurrentTime()
          if(this.Mode == 'ClockInClockOut'){
            console.log(this.currentTime)
            
            const currentDate: Date = new Date();
            const formattedDate: string =
              this.datepipe.transform(currentDate, 'yyyy-MM-dd') +
              'T' +
              currentDate.toTimeString().split(' ')[0];
            var data = [
              {
                "isClockIn": true,
                "startTime": formattedDate,
                "startComments": result.comment,
                "projectId": result.project,
                "mode": 1,
              }
            ]
            this.injector.get(TimeManagementService).CreateEntry(data).subscribe((data:any) => {
              if(data) {
                
                this.clockedInId = data.response[0].message
                console.log(this.clockedInId)
                this.snackBar.open('Clocked In Successfully','Undo', { duration: 2000 });
                this.FindClockInHistory()
              }
            })
          }
      })
    }
  }



  getCurrentTime(): any {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // If hours is 0, set it to 12

    // Pad minutes to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Return the formatted time
    this.CurrentTime = `${hours}:${formattedMinutes} ${period}`;
    console.log(this.CurrentTime)
}

  addEntry(){
    const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
      width: '700px',
      autoFocus: false,
      disableClose: true,
      data: {type: 'TimeEntry', Mode: this.Mode}
    });

    TimeTrackDialog.afterClosed().subscribe((result) => {

    })
  }

  duration(totalDuration: string){
    const [hours, minutes] = totalDuration?.split(':');
    return `${+hours}h ${minutes}m `;
  }

  ShowMapDetails(data: any, type: any) {
    let dialogData: any = {};
  
    if (data) {
      if (type == 'startIp') {
        // Set start location data  
        this.LoginStartLat = data.startLatitude;
        this.LoginStartLong = data.startLongitude;
  
        // Set event location data (common for both)
        this.eventLat = data.eventLatitude;
        this.eventLong = data.eventLongitude;
        this.siteId = data.siteId;
  
        dialogData = {
          startLat: this.LoginStartLat,
          startLong: this.LoginStartLong,
          eventLat: this.eventLat,
          eventLong: this.eventLong,
          siteId: this.siteId,
        };
      } else if (type == 'stopIp') {
        // Set stop location data
        this.LoginStopLat = data.stopLatitude;
        this.LoginStopLong = data.stopLongitude;
  
        // Set event location data (common for both)
        this.eventLat = data.eventLatitude;
        this.eventLong = data.eventLongitude;
        this.siteId = data.siteId;
  
        dialogData = {
          stopLat: this.LoginStopLat,
          stopLong: this.LoginStopLong,
          eventLat: this.eventLat,
          eventLong: this.eventLong,
          siteId: this.siteId,
        };
      }
    } else {
      return '';
    }
  
    // Open dialog with correct data
    const AdvanceSearch = this.dialog.open(ReportingDetailsComponent, {
      disableClose: true,
      data: { data: dialogData, type: 'TimeMap' },
      width: '700px',
    });
  
    AdvanceSearch.afterClosed().subscribe((result: any) => {
      // Handle the result if needed
    });
  }

  openTimeTrack(){
    this.dialogRef.close(true)
    const TimeTrackDialog = this.dialog.open(TimeTrackComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true
    });

    TimeTrackDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openTimeOff(){
    this.dialogRef.close(true)
    const TimeOffDialog = this.dialog.open(TimeOffComponent, {
      width: '900px',
      height: '90vh',
      autoFocus: false,
      disableClose: true
    });

    TimeOffDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openManagerTime(){
    this.dialogRef.close(true)
    const TimeSheetDialog = this.dialog.open(TimeReportingComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true,
    });

    TimeSheetDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  

}
