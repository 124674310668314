<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        {{'Time Report'|translate}}
    </h2>

    <img class="report-icons mx-2" (click)="OpenDashboard()" src="../../../../assets/images/iconsForsvg/report-dashboard.svg">
    <img class="report-icons mx-2" (click)="OpenStatistics()" style="height:25px !important" src="../../../../assets/images/iconsForsvg/report-statistics.svg">
    <img class="report-icons mx-2" (click)="OpenExport()" src="../../../../assets/images/iconsForsvg/report-export.svg">
    <mat-icon class="mx-3" style="color: white;" (click)="AdvanceSearch()">filter_list</mat-icon>
    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreMenu="matMenu" xPosition="before">
      <button *ngIf="(AdminStatus || ManagerStatus)" mat-menu-item (click)="openTimeTrack()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/punch_clock.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Track' | translate }}</strong></span>
      </button>
      <button mat-menu-item (click)="openTimeOff()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/timer_off.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Off' | translate }}</strong></span>
      </button>
      <button mat-menu-item (click)="openTimeSheet()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/overview.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Sheet' | translate }}</strong></span>
      </button>
    </mat-menu>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
  <form [formGroup]="ManagerApproval">
  <div class="row mt-3" style="align-items: center;">
    <div class="col-3">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Select Period</mat-label>
        <mat-select formControlName="period" (selectionChange)="onPeriodChange($event.value)">
          <mat-option *ngFor="let x of period" [value]="x">{{ x }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field appearance="fill" class="w-100" *ngIf="this.ManagerApproval.get('period').value == 'Monthly' || this.ManagerApproval.get('period').value == 'Daily'">
        <mat-label>Select Year</mat-label>
        <mat-select formControlName="Year">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
            <mat-form-field appearance="fill" class="w-100" *ngIf="this.ManagerApproval.get('period').value == 'Custom'">
              <mat-date-range-input [rangePicker]="dateRangePicker">
                <input matStartDate placeholder="Start date" formControlName="startDate">
                <input matEndDate placeholder="End date" formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
            </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field appearance="fill" class="w-100" *ngIf="this.ManagerApproval.get('period').value == 'Daily'">
        <mat-label>Select Month</mat-label>
        <mat-select formControlName="Month">
          <mat-option *ngFor="let x of Months" [value]="x">{{ x }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-3" style="margin-top:-15px">
      <button  mat-button class="approve-button mx-2" (click)="showResult(1, 10)">Show Results</button>
    </div>
    
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-6 text-start" style="margin:auto;text-align: start;">
        <!-- <mat-paginator  [pageSize]="10" [length]="totalUsersCount" (page)="next($event,'single')"></mat-paginator> -->
      </div>
      <div class="col-6" style="margin:auto;text-align: end;">
        <button *ngIf="closeSearch"  mat-button class="reject-button mx-2" (click)="clearSearch()">Clear Search</button>
        
      </div>
    </div>
   
  </div>
</div>
</form>
    <div class="card mt-2" style="width: auto !important;overflow:auto !important">
     
            <table *ngIf="transformedData && transformedData.length != 0">
                <thead>
                  <tr style="background-color: rgb(220, 243, 251);">
                    <th>
                      <!-- <input type="text" class="search-input" placeholder="Search..." /> -->
                    </th>
                    <ng-container *ngFor="let date of uniqueDates">
                      <th class="align_item" *ngIf="date?.fullDayName != null">
                        {{ formatMonthDate(date?.date, date.monthName) }}</th>
                        <th class="align_item" *ngIf="date?.fullDayName == null">
                          {{ date?.monthName }}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <!-- Row 1: Shifts Available -->
                  <!-- <tr style="background-color: rgb(221, 251, 221);">
                    <td class="align_item">
                      <mat-label>{{ "Needed Approvals" | translate }}</mat-label>
                    </td>
                    <ng-container *ngFor="let shift of shiftsAvailable">
                      <td class="align_item">
                        <div class="card" style="color: gray; width: 20px; height: 20px; text-align: center;">
                          {{ shift }}
                        </div>
                      </td>
                    </ng-container>
                  </tr> -->
              
                  <!-- Row 2: Metrics Data -->
                  <tr style="background-color: rgb(255, 207, 118);">
                    <td><div class="row">
                      <div class="col-12 d-flex" style="justify-content: center;">
                        <div class="card" [ngStyle]="{'background-color': UserTotalOvertime ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                            {{ FormatTotalDuration(UserTotalWorked) }} / {{ FormatTotalDuration(UserTotalRequired) }}
                        </div>
                        <span class="card mx-2 px-2" style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center;">
                          {{ FormatTotalDuration(UserTotalOvertime) }}
                        </span>
                      </div>
                    </div></td>
                    <ng-container *ngFor="let date of uniqueDates">
                      
                      <td class="align_item">
                        <div class="row">
                          <div class="col-12 d-flex" style="justify-content: center;">
                            <div class="card" [ngStyle]="{'background-color': date?.overtime ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                                {{ FormatTotalDuration(date?.workedDuration) }} / {{ FormatTotalDuration(date?.totalDuration) }}
                            </div>
                            <span class="card mx-2 px-2" style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center;">
                              {{ FormatTotalDuration(date?.overtime) }}
                            </span>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                  <!-- Dynamic Team Members -->
                  <ng-container>
                    <tr *ngFor="let users of TimeUserDetails; let i = index">
                      <!-- User Details Column -->
                      <td >
                        <div *ngIf="!users?.isUserGroup && !users?.isSiteGroup && !users?.isSite && !users?.isProject" class="row" style="align-items: center;" (click)="ShowUserDEtails(users.userId, 'user')">
                          <img *ngIf="users?.creatorProfile" [src]="users.creatorProfile" alt=""
                            style="width: 30px; height: 30px; border-radius: 50%; margin-right: 8px;">
                          <span *ngIf="!users.creatorProfile" [style.background]="getColor(i)"
                            style="color: white; padding: 10px; border-radius: 50%; margin: 5px; width: 40px; height: 40px;">
                            {{ getuserFLnames(users.creatorFirstName + ' ' + users.creatorLastName) }}
                          </span>
                          <div class="d-flex flex-column">
                            <span style="font-weight: bold;">{{ users.creatorFirstName }} {{ users.creatorLastName }}</span>
                            <span style="font-size: 12px; color: gray;">{{ users.creatorPosition }}</span>
                          </div>
                        </div>

                        <!-- user group -->
                        <div *ngIf="users?.isUserGroup" class="row" style="align-items: center;" (click)="showUserList(users)">
                          <div class="d-flex">
                            <mat-icon class="mx-2"
                            [ngStyle]="{'color':(!users.IsDynamicGroup)?'red':'blue'}">group</mat-icon>
                            <span style="font-weight: bold;margin:auto">{{ users.groupName }}</span>
                          </div>
                        </div>

                        <!-- site -->
                        <div *ngIf="users?.isSite" class="row" style="align-items: center;" (click)="ShowSiteDetails(users.siteId, 'site')">
                          <div class="d-flex">
                            <img class="mx-2" src="../../../../assets/images/iconsForsvg/Site_Icon.svg">
                            <span style="font-weight: bold; margin:atuo">{{ users.siteName }}</span>
                          </div>
                        </div>

                        <!-- site Groups -->
                        <div *ngIf="users?.isSiteGroup" class="row" style="align-items: center;" (click)="ShowUserDEtails(users.groupId)">
                          <div class="d-flex">
                            <mat-icon class="mx-2"
                            [ngStyle]="{'color':(!users.IsDynamicGroup)?'red':'blue'}">group</mat-icon>
                            <span style="font-weight: bold;margin:atuo">{{ users.groupName }}</span>
                          </div>
                        </div>

                        <!-- project -->
                        <div *ngIf="users?.isProject" class="row" style="align-items: center;">
                          <div class="d-flex">
                            <span style="font-weight: bold;margin:atuo">{{ users.projectName }}</span>
                          </div>
                        </div>
                        <div  class="row mt-2">
                          <div class="d-flex flex-row">
                            <span class="card" [ngStyle]="{'background-color': users?.overTimeHours ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                                {{ FormatTotalDuration(users?.workedHours) }} / {{ FormatTotalDuration(users?.requiredHours) }}
                            </span>
                            <span class="card mx-2 px-2" style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center;">
                              {{ FormatTotalDuration(users?.overTimeHours) }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <!-- Date Entries for Each User -->
                      <ng-container *ngFor="let date of uniqueDates">
                        <td>
                          <div *ngIf="date.fullDayName != null && getDateEntry(users.userId, date.date) as dateEntries"  class="card" style="background-color: #f5fdff; text-align: center; border-radius: 5px;">
                            <ng-container >
                              <div class="row d-flex flex-column align-items-center">
                                  <div class="row align-items-center mt-3" style="display: flex; width: max-content; padding: 5px;">
                                    <span (click)="TimeDetails(users, date.date, dateEntries, dateEntries[0]?.timeEntryId, 'date')"
                                      class="card"
                                      [ngStyle]="{'background-color': dateEntries?.[0]?.overTime ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                                      {{ FormatTotalDuration(dateEntries?.[0]?.workedDuration) }} / {{ FormatTotalDuration(dateEntries?.[0]?.totalDuration) }}
                                    </span>
                                  </div>
                                  <div class="row justify-content-center mb-3" style="display: flex; justify-content: center;">
                                    <span
                                      class="card px-2"
                                      style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center; line-height: 25px;">
                                      {{ FormatTotalDuration(dateEntries?.[0]?.overTime) }}
                                    </span>
                                  </div>
                              </div>
                            </ng-container>
                          </div>
                          <div *ngIf="date.fullDayName == null && getDateEntry(users.userId, date.monthName) as dateEntries" class="card" style="background-color: #f5fdff; text-align: center; border-radius: 5px;">
                            <ng-container >
                              <div class="row d-flex flex-column align-items-center">
                                  <div class="row align-items-center mt-3" style="display: flex; width: max-content; padding: 5px;">
                                    <span (click)="TimeDetails(users, date.monthName, dateEntries, dateEntries[0]?.timeEntryId, 'month')"
                                      class="card"
                                      [ngStyle]="{'background-color': dateEntries?.[0]?.overTime ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                                      {{ FormatTotalDuration(dateEntries?.[0]?.workedDuration) }} / {{ FormatTotalDuration(dateEntries?.[0]?.totalDuration) }}
                                    </span>
                                  </div>
                                  <div class="row justify-content-center mb-3" style="display: flex; justify-content: center;">
                                    <span
                                      class="card px-2"
                                      style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center; line-height: 25px;">
                                      {{ FormatTotalDuration(dateEntries?.[0]?.overTime) }}
                                    </span>
                                  </div>
                              </div>
                            </ng-container>
                          </div>
                        </td>
                      </ng-container>
                      
                      
                    </tr>
                  </ng-container>
                </tbody>
               
              </table>
              <div *ngIf="transformedData && transformedData.length == 0" style="text-align:center;margin-top: 50px;font-weight:bold">
                <h2>No Time Reports for this Date Range</h2>
              </div>
            
    </div>
    <ng-container *ngIf="loader">
      <app-skeleton-loader></app-skeleton-loader>
      </ng-container>
</mat-dialog-content>