import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-searchfilterlist',
  templateUrl: './searchfilterlist.component.html',
  styleUrls: ['./searchfilterlist.component.scss']
})
export class SearchfilterlistComponent implements OnInit {
  searchForm: FormGroup;
  senders = ['john@example.com', 'spam@example.com', 'admin@example.com'];
  attachmentExtensions = ['PDF', 'XLSX', 'DOCX', 'TXT'];
  categories = ['Work', 'Personal', 'Spam', 'Promotions'];
  startDate = new Date();
  endDate = new Date();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      includeSearch: [true],
      excludeSearch: [false],
      includeSearchvalue: [''],
      excludeSearchvalue: [''],
      
      includeSender: [true],
      excludeSender: [false],
      SenderList: [[]],

      includeRecipient: [true],
      excludeRecipient: [false],
      RecipientList: [[]],


      includeSenderRecipient: [false],
      excludeSenderRecipient: [false],
      dateRange: [''],
      startDate: [''],
      endDate: [''],
      includeDateRange: [false],
      excludeDateRange: [false],
      attachment: [[]],
      includeAttachment: [false],
      excludeAttachment: [false],
      readUnread: [false],
      includeReadUnread: [false],
      excludeReadUnread: [false],
      emailSize: [''],
      sizeComparison: ['greaterThan'],
      includeSize: [false],
      excludeSize: [false],
      categories: [[]],
      includeCategories: [false],
      excludeCategories: [false],
      urls: [''],
      includeUrls: [false],
      excludeUrls: [false]
    });
  }

  onSubmit(): void {
    console.log(this.searchForm.value);
    // Process the form data and apply search filters
  }

  getIncludesearchststus(){
    return this.searchForm.value.includeSearch
  }
  getexcludeSearchstus(){
    return this.searchForm.value.excludeSearch
  }
  getincludeSenderststus(){
    return this.searchForm.value.includeSender
  }
  getexcludeSenderststus(){
    return this.searchForm.value.excludeSender
  }
  getincludeRecipientststus(){
    return this.searchForm.value.includeSender
  }
  getexcludeRecipientststus(){
    return this.searchForm.value.excludeSender
  }

}
