<div class="d-flex flex-row align-items-center modal-header">
  <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
    {{'Time Track'|translate}}
  </h2>
  <button mat-icon-button [matMenuTriggerFor]="moreMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #moreMenu="matMenu" xPosition="before">
    
    <button mat-menu-item (click)="openTimeOff()">
      <img class="font-icon" src="../../../../assets/images/iconsForsvg/timer_off.svg">
      <span class="ion-text-capitalize"><strong>{{ 'Time Off' | translate }}</strong></span>
    </button>
    <button mat-menu-item (click)="openTimeSheet()">
      <img class="font-icon" src="../../../../assets/images/iconsForsvg/overview.svg">
      <span class="ion-text-capitalize"><strong>{{ 'Time Sheet' | translate }}</strong></span>
    </button>
    <button *ngIf="(AdminStatus || ManagerStatus)" mat-menu-item (click)="openManagerTime()">
      <img class="font-icon" src="../../../../assets/images/iconsForsvg/insert_chart.svg">
      <span class="ion-text-capitalize"><strong>{{ 'Time Report' | translate }}</strong></span>
    </button>
  </mat-menu>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
  <mat-tab-group mat-align-tabs="center" class="no-bg" [(selectedIndex)]="tabnumber"
    (selectedTabChange)="tabChange($event.index)">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tabclas">
          <span>{{'Tracking Mode'|translate}}</span>
        </div>
      </ng-template>
      <div class="mt-5" *ngIf="!loader">
        <form [formGroup]="timeTrackForm">
          <h1>Choose Tracking Method</h1>
          <mat-radio-group formControlName="timeOption" (change)="onOptionChange($event.value)">
            <div class="radio-option">
              <mat-radio-button value="1">Clock In – Clock Out </mat-radio-button>
            </div>
            <div class="radio-option">
              <mat-radio-button value="2">Start Time – End Time</mat-radio-button>
            </div>
            <div class="radio-option">
              <mat-radio-button value="3">Number of Hrs (for a day)</mat-radio-button>
            </div>
          </mat-radio-group>
        </form>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tabclas">
          <span>{{'Tracking Options'|translate}}</span>
        </div>
      </ng-template>
      <div class="mt-5" *ngIf="!loader">
        <form [formGroup]="trackOptionsForm">
          <div class="row">
            <div class="col-12 mb-3" style="text-align:end">
              <button mat-raised-button class="b-blue"
              (click)="AddProject('', 'Create')">{{"Add Projects"|translate}}</button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="team-activity-container">
                <table mat-table  [dataSource]="Projects?.data" class="table mat-elevation-z8" matSort>
              
                  <!-- Team Member Column -->
                  <ng-container  matColumnDef="projectName">
                    <th  mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
                    <td  mat-cell *matCellDef="let element; let i = index">
                      <div class="project-item">
                        <button mat-icon-button [matMenuTriggerFor]="projectMenu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #projectMenu="matMenu">
                          <button mat-menu-item (click)="AddProject(element, 'Edit')">
                            <mat-icon>edit</mat-icon> Edit
                          </button>
                          <button mat-menu-item (click)="deleteProject(element)">
                            <mat-icon>delete</mat-icon> Delete
                          </button>
                        </mat-menu>
                        <span
                          [ngStyle]="{'color': element.fontColor, 'background': element.color}"
                          style="padding: 5px 15px; border-radius: 15px; display: inline-block;"
                        >
                          {{ element?.projectName }}
                        </span>
                       
                      </div>
                    </td>
                  </ng-container>
              
                  <!-- Latest Activity Column -->
                  <ng-container matColumnDef="dateOfCreation">
                    <th  mat-header-cell *matHeaderCellDef mat-sort-header> Date of Creation </th>
                    <td  mat-cell *matCellDef="let element">{{ element.created | date: 'dd/MMM/YYYY'}}</td>
                  </ng-container>
    
                  <!-- Total Tracked Column -->
                  <!-- <ng-container matColumnDef="totalWorkedHours">
                    <th  mat-header-cell *matHeaderCellDef mat-sort-header> Total Worked Hours </th>
                    <td  mat-cell *matCellDef="let element">
                      {{ element?.totalWorkedHours || '00h 00m' }}  
                    </td>
                  </ng-container> -->
              
                  <!-- Header and Row Definitions -->
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [length]="projectsDataLength" (page)="next($event,'single')"></mat-paginator>
              </div>
              
        </div>
          <div class="row align-items-center mb-3">
            <div class="col-12">
              <mat-slide-toggle
                formControlName="eventMatching"
                (change)="onToggleChange($event, 'EventMatching')"
                class="event-matching-toggle"
              >
                {{ "Event Matching" }}
              </mat-slide-toggle>
            </div>
          </div>
          <mat-list>
            <div class="d-flex justify-content-between">
              <h4 style="color: grey;margin-left: 10px;">
                <img src=".../../../../assets/images/iconsForsvg/remainders_Add.svg" alt="" style="margin-right: 3px;">
                <b>{{"Reminders"|translate}}</b>
              </h4>
              <mat-chip-list>
                <mat-chip (click)="showreminderspopup()">{{"Add"|translate}}</mat-chip>
              </mat-chip-list>
            </div>
          </mat-list>
          <ng-container>
            <div class="row mt-2 mb-3" *ngFor="let x of ReminderListDummy;let i = index">
              <span style="margin-left: 7px;">
                <span *ngIf="x.triggertype=='Email' || x.reminderTypeName == 'Email'" style="padding: 5px;"><mat-icon>
                    email</mat-icon></span>
                <span *ngIf="x.triggertype=='Notification' || x.reminderTypeName == 'Notification'"
                  style="padding: 5px;"><mat-icon>campaign</mat-icon></span>
                <span>{{x.value}} {{x.durationtype || x.periodName}} {{x.triggerMoment || x.isAfter ? 'After':
                  'Before'}} the Event {{x.Parameter || x.conditionParameter}} date and time</span>

                <span style="margin-left: 5px;cursor: pointer;"
                  (click)="removereminderDummy(i)"><mat-icon>delete</mat-icon></span>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="getreminderststus()">
            <div class="row mt-2 mb-3" *ngFor="let x of ReminderList;let i = index">
              <span style="margin-left: 7px;">
                <span *ngIf="x.triggertype=='Email' || x.reminderTypeName == 'Email'" style="padding: 5px;"><mat-icon>
                    email</mat-icon></span>
                <span *ngIf="x.triggertype=='Notification' || x.reminderTypeName == 'Notification'"
                  style="padding: 5px;"><mat-icon>campaign</mat-icon></span>
                <span>{{x.value}} {{x.durationtype || x.periodName}} {{x.triggerMoment || x.isAfter ? 'After':
                  'Before'}} the Event {{x.Parameter || x.conditionParameter}} date and time</span>

                <span style="margin-left: 5px;cursor: pointer;"
                  (click)="removereminder(i)"><mat-icon>delete</mat-icon></span>
              </span>
            </div>
          </ng-container>
          <div class="row mb-3">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <mat-label>
                {{ "Settings Approval" | translate }}
              </mat-label>
            </div>
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <mat-slide-toggle (change)="onToggleChange($event, 'SettingsApproval')" formControlName="toggleOption" class="toggle-button">
              </mat-slide-toggle>
            </div>
          </div>

          <!-- <div class="row mt-2 mb-3" *ngIf="trackOptionsForm.get('toggleOption').value == true">
            <mat-radio-group formControlName="selectdatetype" (change)="getdateStatus($event.value)">
              <mat-radio-button value="2">
                {{'Daily'|translate}}</mat-radio-button>
              <mat-radio-button value="1">
                {{'Weekly'|translate}}</mat-radio-button>
              <mat-radio-button value="3">
                {{'Monthly'|translate}}</mat-radio-button>
              <mat-radio-button value="4">
                {{'Custom Days'|translate}}</mat-radio-button>
            </mat-radio-group>
          </div> -->
          <!-- <div class="row mt-2" *ngIf="trackOptionsForm.get('selectdatetype').value == '4'">
            <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
              <mat-form-field class="w-100">
                <mat-label>{{"Custom Days"}}</mat-label>
                <input matInput type="number" formControlName="customDays">
              </mat-form-field>
            </div>
          </div> -->
        </form>
      </div>
      <!-- <div class="col-12 mb-3" style="text-align:end">
        <button mat-raised-button [ngClass]="!trackOptionsForm.valid? 'g-blue':'b-blue'"
          [disabled]="!trackOptionsForm.valid" (click)="NextMethod2()">{{"Add"|translate}}</button>
      </div> -->
       
    </mat-tab>
    <mat-tab *ngIf="(AdminStatus || ManagerStatus) && trackOptionsForm.get('toggleOption').value == true">
      <ng-template mat-tab-label>
        <div class="tabclas">
          <span>{{'Approvals'|translate}}</span>
        </div>
      </ng-template>
      <div class="card mt-2" *ngIf="!loader">
        <form [formGroup]="ManagerApproval">
          <div class="row mt-3" style="align-items: center;">
            <!-- <div class="col-4">
              <button mat-button class="approve-button p-1" (click)="approveAllRequests('Approve')">All Pending Request Approval</button>
            </div> -->
            <div class="col-7">
              <button mat-button *ngIf="allRequestIds?.length > 0" class="approve-buttonAll p-1" (click)="approveAllRequests('Approve')">Approve Selected Request</button>
              <button mat-button *ngIf="allRequestIds?.length > 0" class="deny-buttonAll p-1 mx-2" (click)="approveAllRequests('Reject')">Reject Selected Request</button>
            </div>
            <div class="col-3">
              <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="dateRangePicker">
                  <input matStartDate placeholder="Start date" formControlName="startDate">
                  <input matEndDate placeholder="End date" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-2" style="margin-top:-15px">
              <button  mat-button class="approve-button mx-2" (click)="showResult(1, 10)">Show</button>
            </div>
          </div>
         </form>
          <!-- Scrollable Table Container -->
          <div class="table-container">
            <table *ngIf="transformedData && transformedData.length > 0" style="width:-webkit-fill-available;margin-bottom:75px">
              <thead>
               
                <tr style="background-color: rgb(220, 243, 251);">
                  
                  <th>
                    <form [formGroup]="AllApproveForm">
                    <mat-checkbox (change)="onCheckboxChange('ApproveAll', $event)" formControlName="ApproveAllDate" class="event-matching-checkbox"
                    >Select All</mat-checkbox>
                  </form>
                  </th>
                  <!-- Display each unique date as a column header -->
                  <ng-container *ngFor="let date of uniqueDates">
                    <th class="align_item">
                      <form [formGroup]="AllDateApproveForm">
                        <mat-checkbox (change)="onCheckboxChange(date, $event, 'Multiple')" [(ngModel)]="date.requestIds.checked" formControlName="ApproveDateWise"  class="event-matching-checkbox"
                          style="margin-left: -10px; margin-right: 10px;"></mat-checkbox>
                        {{ formatMonthDate(date?.date, date.monthName) }}
                     </form>
                    </th>
                  </ng-container>
                </tr>
             
              </thead>
              <tbody>
                <!-- Row 1: Pending Approvals for each date -->
                <tr style="background-color: rgb(221, 251, 221);">
                  <td class="align_item">
                    <mat-label>{{ "Needed Approvals" | translate }}</mat-label>
                  </td>
                  <ng-container *ngFor="let date of uniqueDates">
                    <td class="align_item">
                      <div class="card" style="color: gray; width: 20px; height: 20px; text-align: center; margin: auto;">
                        {{ date?.pendingApprovalCount }}
                      </div>
                    </td>
                  </ng-container>
                </tr>
            
                <!-- Row 2: Total Duration for each date -->
                <tr style="background-color: rgb(255, 207, 118);">
                  <td class="align_item">
                    <div class="row d-flex align_item">
                      <div class="card" [ngStyle]="{'background-color': UserTotalOvertime  ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                          {{FormatTotalDuration(UserTotalWorked)}} / {{ FormatTotalDuration(UserTotalRequired) }}
                      </div>
                      <div class="card px-2" style="color: gray; width: max-content; height: 20px; text-align: center; margin: auto;">
                        {{ FormatTotalDuration(UserTotalOvertime)}}
                      </div>
                    </div>
                  </td>
                  <ng-container *ngFor="let date of uniqueDates">
                    <td class="align_item">
                      <div class="row d-flex align_item">
                        <div class="card" [ngStyle]="{'background-color': date?.overtime ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                            {{ FormatTotalDuration(date?.workedDuration) }} / {{ FormatTotalDuration(date?.totalDuration) }}
                        </div>
                        <div class="card px-2" style="color: gray; width: max-content; height: 20px; text-align: center; margin: auto;">
                          {{ FormatTotalDuration(date?.overtime)}}
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
            
                <!-- Dynamic User Rows -->
                <ng-container >
                  <tr *ngFor="let user of TimeUserDetails; let i = index">
                    <!-- User Details Column -->
                    <td>
                      <div class="row" style="align-items: center;" (click)="ShowUserDEtails(user.userId, 'user')">
                        <img *ngIf="user?.creatorProfile" [src]="user.creatorProfile" alt=""
                          style="width: 30px; height: 30px; border-radius: 50%; margin-right: 8px;">
                        <span *ngIf="!user.creatorProfile" [style.background]="getColor(i)"
                          style="color: white; padding: 10px; border-radius: 50%; margin: 5px; width: 40px; height: 40px;">
                          {{ getuserFLnames(user.creatorFirstName + ' ' + user.creatorLastName) }}
                        </span>
                        <div class="d-flex flex-column">
                          <span style="font-weight: bold;">{{ user.creatorFirstName }} {{ user.creatorLastName }}</span>
                          <span style="font-size: 12px; color: gray;">{{ user.creatorPosition }}</span>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="d-flex flex-row">
                          <span class="card" [ngStyle]="{'background-color': user?.overTimeHours ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                            {{ FormatTotalDuration(user?.workedHours) }} / {{ FormatTotalDuration(user?.requiredHours) }}
                          </span>
                          <span class="card mx-2 px-2" style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center;">
                            {{ FormatTotalDuration(user?.overTimeHours) }}
                          </span>
                        </div>
                      </div>
                    </td>
              
                    <!-- Date Entries for Each User -->
                    <ng-container *ngFor="let date of uniqueDates">
                      <td>
                        <div *ngIf="getDateEntry(user.userId, date.date) as dateEntries" class="card" style="background-color: #f5fdff; text-align: center; border-radius: 5px;">
                          <ng-container >
                            <div class="row d-flex flex-column">
                              <form [formGroup]="SingleApproveForm">
                                <div class="row align-items-center mt-3" style="display: flex; width: max-content; padding: 5px;">
                                  <mat-checkbox
                                    (change)="onCheckboxChange(dateEntries, $event, 'Single')"
                                    formControlName="ApproveSingle"
                                    class="event-matching-checkbox"
                                    style="margin-right: 10px;"
                                    [(ngModel)]="dateEntries.checked">
                                  </mat-checkbox>
                                  <span (click)="TimeDetails(user, date.date, dateEntries, dateEntries[0]?.timeEntryId)"
                                    class="card"
                                    [ngStyle]="{'background-color': dateEntries?.[0]?.overTime ? 'rgb(254, 103, 103)' : 'rgb(206 227 255)', 'width': 'max-content', 'height': '25px', 'padding': '3px'}">
                                    {{ FormatTotalDuration(dateEntries?.[0]?.workedDuration) }} / {{ FormatTotalDuration(dateEntries?.[0]?.totalDuration) }}
                                  </span>
                                </div>
                                <div class="row justify-content-center mb-3" style="display: flex; justify-content: center;">
                                  <span
                                    class="card px-2"
                                    style="background-color: rgb(146, 146, 146); color: white; width: max-content; height: 25px; text-align: center; line-height: 25px;">
                                    {{ FormatTotalDuration(dateEntries?.[0]?.overTime) }}
                                  </span>
                                </div>
                              </form>
                            </div>
                          </ng-container>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div *ngIf="transformedData && transformedData.length == 0" style="text-align:center;margin-top: 50px;font-weight:bold">
              <h2>No Pending Approvals for this Date Range</h2>
            </div>
          </div>
      </div>
     
    </mat-tab>
  </mat-tab-group>
  <ng-container *ngIf="loader">
    <app-skeleton-loader></app-skeleton-loader>
    </ng-container>
</mat-dialog-content>