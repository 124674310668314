<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
      {{'Statistics'}}
  </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="statisticsForm">
        <div class="row mt-2">
          <div class="col-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{"Select Option"|translate}} </mat-label>
              <mat-select formControlName="StatisticId">
                  <mat-option *ngFor="let x of StatisticsOption" [value]="x.id">
                      {{x.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          </div>
            <!-- Profile and Date Range -->
             <div class="col-4" *ngIf="statisticsForm.get('StatisticId').value == '1'">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{"Users"|translate}} </mat-label>
                        <mat-select  (openedChange)="filterOptions('','users')" #select formControlName="UsersId">
                            <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')"
                                placeholder="Filter" />
                            <mat-option *ngFor="let user of filteredusers" [value]="user.id">
                                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                    [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                    onError="this.src='assets/avatar.svg'">
                                {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
             </div>
             <div class="col-4" *ngIf="statisticsForm.get('StatisticId').value == '2'">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"User Group"|translate}}</mat-label>
                <mat-select  (openedChange)="filterOptions('','UserGroups')" #select
                    formControlName="UserGroupsId">
                    <input style="padding: 15px 0px 15px 0px;" matInput (keyup)="filterOptions($event.target.value,'UserGroups')"
                        placeholder="Filter" />
                    <mat-option [value]="userGroup.id" *ngFor="let userGroup of filteredusersgroup">
                        <span *ngIf="userGroup.dynamicGroupId ==null">
                            <img style="border-radius: 50%;" width="30" height="30"
                                src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                        </span>
                        <span *ngIf="userGroup.dynamicGroupId !=null">
                            <img style="border-radius: 50%;" width="30" height="30"
                                src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                        </span>
                        {{userGroup.groupName|titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
             </div>
             <div class="col-4" *ngIf="statisticsForm.get('StatisticId').value == '3'">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"Sites "|translate}} </mat-label>
                <mat-select  (openedChange)="filterOptions('','sites')" #select formControlName="SitesId">
                    <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'site')"
                        placeholder="Filter" />
                    <mat-option *ngFor="let site of filteredSites" [value]="site.id">
                        <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                            [src]="site?.profilePicture ? site.profilePicture : 'assets/images/iconsForsvg/Site_Icon.svg'"
                            onError="this.src='assets/images/iconsForsvg/Site_Icon.svg'">
                        {{ site.companyName| titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
             </div>
             <div class="col-4" *ngIf="statisticsForm.get('StatisticId').value == '4'">
              <mat-form-field appearance="fill"
        class="w-100">
        <mat-label>{{'Sites Groups'|translate}}</mat-label>
            <mat-select  (openedChange)="filterOptions('','SitesGroups')" #select
            formControlName="SelactGroupIdes">
            <input style="padding: 15px;" matInput
                (keyup)="filterOptions($event.target.value,'SitesGroups')" placeholder="Filter" />
            <mat-option
                *ngFor="let val of filterGroup"
                [value]="val.id">
                <span
                    *ngIf="val.dynamicGroupId ==null">
                    <img
                        style="border-radius: 50%;"
                        width="30" height="30"
                        src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                </span>
                <span
                    *ngIf="val.dynamicGroupId !=null">
                    <img
                        style="border-radius: 50%;"
                        width="30" height="30"
                        src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                </span>
                {{val.siteGroupName|titlecase}}
            </mat-option>
        </mat-select>
    </mat-form-field>
             </div>
             <div class="col-4" *ngIf="statisticsForm.get('StatisticId').value == '5'">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>
                  {{ "Project Name" | translate }}
                </mat-label>
              
                <mat-select  formControlName="ProjectsId">
                  <mat-option [value]="x.id" *ngFor="let x of Projects?.data">
                    <div class="project-item">
                      <span
                        [ngStyle]="{'color': x.fontColor, 'background': x.color}"
                        style="padding: 6px; border-radius: 5px;display: inline;"
                      >
                        {{ x?.projectName }}
                      </span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
             </div>
             <div class="col-4">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-date-range-input [rangePicker]="dateRangePicker">
                      <input matStartDate placeholder="Start date" formControlName="startDate">
                      <input matEndDate placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                </mat-form-field>
             </div>
        </div>
    </form>
    <div class="row mb-3">
        <!-- Summary Section -->
    <div class="col-12 d-flex">
        <div class="summary-item">
            <div class="summary-value" style="background-color: #d6f5d6; color: #3f8b3f;">
              {{ formatDuration(StatisticsDetails?.workedHours) }}
              <span class="summary-label">Worked Hours</span>
            </div>
          </div>
          <span class="plus-icon" style="margin:auto">+</span>
          <div class="summary-item">
            <div class="summary-value" style="background-color: #fbe8d2; color: #de8706;">
              {{ formatDuration(StatisticsDetails?.overTimeHours) }}
              <span class="summary-label">Overtime Hours</span>
            </div>
          </div>
          <span class="plus-icon" style="margin:auto">+</span>
          <div class="summary-item">
            <div class="summary-value" style="background-color: #fcdada; color: #d64545;">
              {{ formatDuration(StatisticsDetails?.timeOfHours) }}
              <span class="summary-label">Time Off Hours</span>
            </div>
          </div>
          <span class="plus-icon" style="margin:auto">+</span>
          <div class="summary-item">
            <div class="summary-value" style="background-color: #f5e8e8; color: #8a6464;">
              {{ formatDuration(StatisticsDetails?.closedHours) }}
              <span class="summary-label">Close Days Hours</span>
            </div>
          </div>
          <span class="plus-icon" style="margin:auto">=</span>
          <div class="summary-item">
            <div class="summary-value total" style="background-color: #dce6f5; color: #275d8a;">
              {{ formatDuration(StatisticsDetails?.totalHours) }}
              <span class="summary-label">Total Hours</span>
            </div>
          </div>
          
          
                 
      </div>
    </div>
    <table mat-table *ngIf="tableDataSource?.length > 0" [dataSource]="tableDataSource" class="mat-elevation-z8 mb-4">
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element"> {{ element?.dateTime | date: 'dd/MMM/YYYY' }} </td>
        </ng-container>
    
        <!-- Status Column -->
        <!-- <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
              <span [ngStyle]="{'color': element?.fontColor, 'background': element?.color}">{{ element.status }}</span>
          </td>
        </ng-container> -->
    
        <ng-container matColumnDef="totalHours">
            <th mat-header-cell *matHeaderCellDef> Required Hours </th>
            <td mat-cell *matCellDef="let element"> {{ formatDuration(element?.totalHours) }} </td>
          </ng-container>
          <ng-container matColumnDef="dailyTotal">
            <th mat-header-cell *matHeaderCellDef> Worked Hours </th>
            <td mat-cell *matCellDef="let element"> {{ formatDuration(element?.workedHours) }} </td>
          </ng-container>
          <ng-container matColumnDef="closedHours">
            <th mat-header-cell *matHeaderCellDef> Close Days Hours </th>
            <td mat-cell *matCellDef="let element"> {{ formatDuration(element?.closedHours) }} </td>
          </ng-container>
          <ng-container matColumnDef="TimeOffHours">
            <th mat-header-cell *matHeaderCellDef> Time Off Hours </th>
            <td mat-cell *matCellDef="let element"> {{ formatDuration(element?.timeOfHours) }} </td>
          </ng-container>
          <ng-container matColumnDef="totalOvertime">
            <th mat-header-cell *matHeaderCellDef> Overtime Hours </th>
            <td mat-cell *matCellDef="let element"> {{ formatDuration(element?.overTimeHours) }} </td>
          </ng-container>
          
          <ng-container matColumnDef="totalRegular">
            <th mat-header-cell *matHeaderCellDef>Total Hours </th>
            <td mat-cell *matCellDef="let element"> {{ formatDuration(element?.totalHours) }} </td>
          </ng-container>
    
        <!-- Row Definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
  
        <ng-container *ngIf="tableDataSource?.length == 0">
          <div class="card w-100 mb-3 text-center">
            <h3 class="p-4" style="font-weight: bold;"> No Records Available to this date range</h3>
          </div>
          
        </ng-container>

</mat-dialog-content>

  
