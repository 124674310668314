import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { DriveService } from 'src/app/core/services/drive.service';
import { FileService } from 'src/app/core/services/file.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { ManageContactsService } from 'src/app/core/services/manage-contacts.service';
import { ManageProductsService } from 'src/app/core/services/manage-products.service';
import { ManageSitesService } from 'src/app/core/services/manage-sites.service';
import { ManageTransactionsService } from 'src/app/core/services/manage-transactions.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { MessageService } from 'src/app/core/services/message.service';
import { StrategyService } from 'src/app/core/services/strategy.service';
import { PopupErrorMessageComponent } from 'src/app/popup-error-message/popup-error-message.component';
import { ReminderCreateComponent } from '../../Reminder/reminder-create/reminder-create.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-popup-common',
  templateUrl: './popup-common.component.html',
  styleUrls: ['./popup-common.component.scss']
})
export class PopupCommonComponent implements OnInit {
  screenType: any;
  entitydata=[];
  DemoOrganation:boolean=false
  message=null;
  value=''
  NewlableName=null
  Email=null
  filterforuserlist: any;
  UsersList;
    // value: any;
  // auto chip for keyword
  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordlist: string[] = [];
  templateform!: FormGroup;
  htmlContent: string | null = null;
  file: any;
  FromMediaLibrarylist:any
  totalTemplates=0
  NumberOfRecord: number=8;
  PageNumber: number=1;
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  selectedMonthIndex: number = 0;
  januaryData = this.generateMonthData(31, '01/01/2024');
  februaryData = this.generateMonthData(28, '01/02/2024');
  marchData = this.generateMonthData(31, '01/03/2024');
  aprilData = this.generateMonthData(30, '01/04/2024');
  mayData = this.generateMonthData(31, '01/05/2024');
  juneData = this.generateMonthData(30, '01/06/2024');
  julyData = this.generateMonthData(31, '01/07/2024');
  augustData = this.generateMonthData(31, '01/08/2024');
  septemberData = this.generateMonthData(30, '01/09/2024');
  octoberData = this.generateMonthData(31, '01/10/2024');
  novemberData = this.generateMonthData(30, '01/11/2024');
  decemberData = this.generateMonthData(31, '01/12/2024');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MatDialogRefDelet: MatDialogRef<PopupCommonComponent>,
    public snackBar: MatSnackBar,
    private manageContactsService: ManageContactsService,
    private managesitesService: ManageSitesService, 
    private manageproductsService: ManageProductsService,
    private manageUserService: ManageUsersService,
    private calendarService: CalendarService,
    private fileService: FileService,
    private strategyservice: StrategyService,
    private ManageTransactionsService: ManageTransactionsService,
    private graphService: GraphService,
    private DriveService: DriveService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private goole: GoogleApiService,
    private injector: Injector,
    private formBuilder: FormBuilder,
  ) {
    this.templateform = this.formBuilder.group({
      keyword:[],
      Title:[]
    })
    if(sessionStorage.getItem('DemoOrganation')==='true'){
      this.DemoOrganation==true
    }else{
      this.DemoOrganation==false
    }
   }

  ngOnInit(): void {
    this.screenType = this.data?.screenType
    this.entitydata = this.data.data;
    console.log(this.data)
    if(this.screenType=='onDoubleClick'){
      this.value==this.data?.value
      console.log(this.data?.value)
    }

    if(this.screenType=='FromMediaLibrary'){
      this.GetTemplates()
     }
      this.getallusers()
  }
  nextpage(e: any) {
    this.PageNumber=e.pageIndex + 1
    this.GetTemplates()
  }

  GetTemplates(){
    this.injector.get(ManageUsersService).GetTemplates(this.NumberOfRecord,this.PageNumber).subscribe((res: any) => {
      if(res){
        this.totalTemplates=res?.totalItems
        if(res.data.lenght!=0){
          this.FromMediaLibrarylist=[]
          res.data.forEach(element => {
            this.FromMediaLibrarylist.push(element)
          });
        }
      }
  })
}

FromMediaLibrary(data){
  console.log(data)
  this.MatDialogRefDelet.close(data)
  }


  getallusers(){
 // get all users
 this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((res: any) => {
  const data = res;
  this.filterforuserlist = data;
});
  }

  Collaborationbutton(){
    if(this.screenType=='Collaboration_Remove'){
      this.MatDialogRefDelet.close(false)
    }else{
    this.MatDialogRefDelet.close(true)

    }
}

showtablerowdata2(data,type){
  this.injector.get(ManageUsersService).GetEmailGroupEvents(data?.emailGroupId,type).subscribe((result)=>{
    if(result){
    this.barchartlevel2(result,'Tableclicked2','600px')
    }
  })

}
barchartlevel2(value,type,width) {
  const onDoubleClickDialog = this.dialog.open(PopupCommonComponent, {
    data: { data: value, screenType: type },
    disableClose: false,
    width: width,
  });
  onDoubleClickDialog.afterClosed().subscribe((result) => {

  })
}

  onDoubleClickSubmit(){
    if(this.NewlableName!=null){
      this.MatDialogRefDelet.close(this.NewlableName)
    }

  }

  onSubmit(){
    if(this.screenType=='Accpet'){
      this.MatDialogRefDelet.close(this.message)
    }else if(this.screenType=='Comment'){
      this.MatDialogRefDelet.close(this.message)
    }else if(this.screenType=='checkmailsyncststus'){
      this.MatDialogRefDelet.close(this.Email)
    }else if(this.screenType=='RequestedMailpermission'){
      this.dialog.closeAll()
    }else if(this.screenType=='ResetLabels'){
      this.MatDialogRefDelet.close(true)
    }else if(this.screenType=='Reject'){
      this.MatDialogRefDelet.close(true)
    }else if(this.screenType=='Accpet1'){
      this.MatDialogRefDelet.close(true)
    }

  }
  onSubmitforsavetemplate(){
    console.log(this.templateform.value)
    const data={
      Title:this.templateform.value.Title,
      keywordlist:this.keywordlist,
      file:this.file
    }
    this.MatDialogRefDelet.close(data)
  }




    // ----------------
  // auto chip for keyword
  // -----------
  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      this.keywordlist.push(value);
    }
    event.input.value = ''; 
    this.templateform.get('keyword')?.setValue(null);

  }


  remove(value: string) {
    const index = this.keywordlist.indexOf(value);
    if (index >= 0) {
      this.keywordlist.splice(index, 1);
    }
  }


  handleFileInput(event){
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput?.files?.[0];
    this.file=fileInput?.files?.[0];
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        this.htmlContent = e.target?.result as string;
      };

      reader.readAsText(file);
    } else {
      alert('Please select a valid HTML file.');
    }
  }
  
  getMonthData(monthIndex: number) {
    switch (monthIndex) {
      case 0: return this.januaryData;
      case 1: return this.februaryData;
      case 2: return this.marchData;
      case 3: return this.aprilData;
      case 4: return this.mayData;
      case 5: return this.juneData;
      case 6: return this.julyData;
      case 7: return this.augustData;
      case 8: return this.septemberData;
      case 9: return this.octoberData;
      case 10: return this.novemberData;
      case 11: return this.decemberData;
      default: return [];
    }
  }

  generateMonthData(daysInMonth: number, startDate: string) {
    let monthData = [];
    let startDateParts = startDate.split('/');
    let month = parseInt(startDateParts[1], 10); // Extract month from the startDate
    let year = parseInt(startDateParts[2], 10); // Extract year from the startDate
    
    for (let day = 1; day <= daysInMonth; day++) {
      let currentDate = new Date(year, month - 1, day); // Generate current date based on day
      let dateString = `${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}/${month < 10 ? '0' : ''}${month}/${year}`;
  
      // Generate random working hours between 07:00:00 and 10:00:00
      let randomHour = Math.floor(Math.random() * 4) + 7; // Random hours between 7 and 10
      let workingHours = `${randomHour.toString().padStart(2, '0')}:00:00`; // Format as HH:00:00
  
      // Convert required and working hours to minutes
      let requiredHours = '08:00:00';
      let requiredMinutes = this.convertToMinutes(requiredHours);
      let workingMinutes = this.convertToMinutes(workingHours);
  
      // Calculate overtime by subtracting required minutes from working minutes
      let overtimeMinutes = workingMinutes - requiredMinutes;
  
      // Convert overtime minutes back to HH:mm:ss format
      let overtimeHours = this.convertToHHMMSS(overtimeMinutes);
  
      // Push date and hours data into the array
      monthData.push({
        date: dateString,
        requiredHours: requiredHours,
        workingHours: workingHours,
        overtimeHours: overtimeHours
      });
    }
  
    return monthData;
  }
  
  // Helper function to convert HH:mm:ss to total minutes
  convertToMinutes(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 60 + minutes + Math.floor(seconds / 60);  // Return total minutes
  }
  
  // Helper function to convert minutes back to HH:mm:ss format
  convertToHHMMSS(minutes: number): string {
    if (minutes < 0) return '00:00:00'; // Ensure no negative overtime
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const seconds = 0;  // Overtime is in full minutes, no seconds are added
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  onTabChange(event: any): void {
    this.selectedMonthIndex = event.index; // Update the selected month index
  }






}
