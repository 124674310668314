<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <mat-icon style="position: relative;right: 10px;">search</mat-icon>{{'Advance Search'|translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="m-0 p-0">
    <form [formGroup]="ListSearch">
        <div class="row w-100 mt-3">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Users"|translate}} </mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','users')" #select formControlName="UsersId">
                        <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'users')"
                            placeholder="Filter" />
                        <mat-option *ngFor="let user of filteredusers" [value]="user.id">
                            <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                                [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                onError="this.src='assets/avatar.svg'">
                            {{ user.firstName| titlecase}} {{user.lastName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"User Group"|translate}}</mat-label>
                    <mat-select multiple (openedChange)="filterOptions('','UserGroups')" #select
                        formControlName="UserGroupsId">
                        <input style="padding: 15px 0px 15px 0px;" matInput (keyup)="filterOptions($event.target.value,'UserGroups')"
                            placeholder="Filter" />
                        <mat-option [value]="userGroup.id" *ngFor="let userGroup of filteredusersgroup">
                            <span *ngIf="userGroup.dynamicGroupId ==null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                    src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                            </span>
                            <span *ngIf="userGroup.dynamicGroupId !=null">
                                <img style="border-radius: 50%;" width="30" height="30"
                                    src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                            </span>
                            {{userGroup.groupName|titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

       <div class="row w-100 ">
        <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"Sites "|translate}} </mat-label>
                <mat-select multiple (openedChange)="filterOptions('','sites')" #select formControlName="SitesId">
                    <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'site')"
                        placeholder="Filter" />
                    <mat-option *ngFor="let site of filteredSites" [value]="site.id">
                        <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                            [src]="site?.profilePicture ? site.profilePicture : 'assets/images/iconsForsvg/Site_Icon.svg'"
                            onError="this.src='assets/images/iconsForsvg/Site_Icon.svg'">
                        {{ site.companyName| titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
       </div>
       <div class="row w-100 ">
        <mat-form-field appearance="fill"
        class="w-100">
        <mat-label>{{'Sites Groups'|translate}}</mat-label>
            <mat-select multiple (openedChange)="filterOptions('','SitesGroups')" #select
            formControlName="SelactGroupIdes">
            <input style="padding: 15px;" matInput
                (keyup)="filterOptions($event.target.value,'SitesGroups')" placeholder="Filter" />
            <mat-option
                *ngFor="let val of filterGroup"
                [value]="val.id">
                <span
                    *ngIf="val.dynamicGroupId ==null">
                    <img
                        style="border-radius: 50%;"
                        width="30" height="30"
                        src="../../../../../assets/images/iconsForsvg/groupiconRed.svg">
                </span>
                <span
                    *ngIf="val.dynamicGroupId !=null">
                    <img
                        style="border-radius: 50%;"
                        width="30" height="30"
                        src="../../../../../assets/images/iconsForsvg/groupiconblue.svg">
                </span>
                {{val.siteGroupName|titlecase}}
            </mat-option>
        </mat-select>
    </mat-form-field>
       </div>
      

        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>
                      {{ "Project Name" | translate }}
                    </mat-label>
                  
                    <mat-select multiple  formControlName="ProjectsId">
                      <mat-option [value]="x.id" *ngFor="let x of Projects?.data">
                        <div class="project-item">
                          <span
                            [ngStyle]="{'color': x.fontColor, 'background': x.color}"
                            style="padding: 6px; border-radius: 5px;display: inline;"
                          >
                            {{ x?.projectName }}
                          </span>
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
           
        </div>

        <div class="row w-100">
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{"Position"|translate}}</mat-label>
                    <mat-select multiple  formControlName="Position">
                        <mat-option>
                          
                        </mat-option>
                      </mat-select>
                    <input matInput type="text" id="name" formControlName="Position">
                </mat-form-field>
            </div>
        </div>
        <div class="row p-2 mt-3 justify-content-around"
        style="color: white; background-color:#f6272e; font-weight: 600;">
        <div class="col-6">{{' OverTime Conditions'}} </div>
        <div class="col-2">
            <mat-icon style="color: white;" (click)="Addcondition()">add_box</mat-icon>
        </div>
    </div>
    <div style="border: 1px solid gray;padding:10px;" class="row w-100">
        <div formArrayName="Conditions">
                <div *ngFor="let file of Conditions().controls; let i=index" [formGroupName]="i">
                    <div class="row p-2 justify-content-around"
                        style="color: white; background-color:#818582; font-weight: 600;">
                        <div class="col-6"> {{'OverTime'}}-{{i+1}}</div>
                        <div class="col-2">
                            <mat-icon *ngIf="i!=0" (click)="RemoveCondition(i)">delete</mat-icon>
                        </div>
                    </div>
                    <div class="row strategycondictions" style="border: 1px solid gray;padding:10px;">
                        <div class="col-4">
                            
                            <ng-container>
                                <mat-form-field class="w-100">
                                    <mat-label>{{'Parameter'}}</mat-label>
                                    <mat-select formControlName="conditionParameter">
                                        <mat-option>
                                           
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>
                        </div>
                        <div class="col-4">
                            <ng-container>
                            <mat-form-field class="w-100">
                                <mat-label>{{'Comparison'}}</mat-label>
                                <mat-select formControlName="conditionComparison">
                                    <mat-option [value]="x.v1" *ngFor="let x of AllOption">
                                        {{x.v2}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        
                        </div>
                        <div class="col-4">
                            <mat-form-field style="width:80%;">
                                <input matInput type="tel" placeholder="value" formControlName="value">
                            </mat-form-field>
                        </div>
                </div>
        </div>
    </div>
    </div>
    </form>
</mat-dialog-content>


<mat-dialog-actions align="center" class="my-1">
    <div class="row mt-2 w-100 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button   [ngClass]="!ListSearch.valid? 'g-blue':'b-blue'"
             [disabled]="!ListSearch.valid" (click)="onSubmit()">{{"Apply"|translate}}</button>
        </div>
    </div>
</mat-dialog-actions> 
