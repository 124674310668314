import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogOverviewExampleDialog3 } from '../categories-modal/categories-modal.component';
import { CalendarService } from '../../services/calendar.service';
import { TimeManagementService } from '../../services/time-management.service';
import { ProfilePickFormatService } from '../../services/profile-pick-format.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConformationComponent } from 'src/app/pages/management/management-initiatives/conformation/conformation.component';
import { TimeReportingComponent } from '../time-reporting/time-reporting.component';
import { TimesheetComponent } from '../timesheet/timesheet.component';
import { TimeTrackComponent } from '../time-track/time-track.component';

@Component({
  selector: 'app-time-off',
  templateUrl: './time-off.component.html',
  styleUrls: ['./time-off.component.scss'],
})
export class TimeOffComponent implements OnInit {
  tabnumber: number = 0;
  categoriesForm!: FormGroup;
  requestForm: FormGroup;
  categories: any;
  categoryOption: any;
  totalRequests = 2;
  currentDate = new Date ()
  AdminStatus: boolean = false;
  ManagerStatus: boolean = false;
  PendingRequsts: any;
  totalItems: any;
  dayStatus: void;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private calendarService: CalendarService,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<TimeOffComponent>
  ) {
    this.AdminStatus = sessionStorage.getItem("isAdmin") == 'true' ? true : false; 
    this.ManagerStatus=sessionStorage.getItem("isManager") == 'true' ? true : false; 
  }

  ngOnInit(): void {
    this.categoriesForm = this.formBuilder.group({
      categoryId: ['', Validators.required],
      categoryName: ['', Validators.required],
      color: ['#2889e9', Validators.required],
    });
    this.requestForm = this.formBuilder.group({
      fromDate: [this.currentDate, Validators.required],
      toDate: [this.currentDate, Validators.required],
      category: ['', Validators.required],
      note: [''],
      IsFullDay: [this.dayStatus],
    });
    this.viewdialog();
    
  }

  tabChange(tabIndex: number): void {
    this.tabnumber = tabIndex;
    if (tabIndex === 0) {
      this.tabnumber = 0;
      this.viewdialog();
    } else if (tabIndex === 1) {
      this.tabnumber = 1;
    } else if (tabIndex === 2) {
      this.tabnumber = 2;
      this.getPendingApprovals(1, 5)
    }
  }

  approveRequest(data:any){
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: 'ApproveRequest', action: 'Approve' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const status = 1
        const id = data.id
        this.injector.get(TimeManagementService).approveRequest(id, status).subscribe((data:any) => {
          console.log(data)
          if(data) {
            this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
            this.getPendingApprovals(1, 5)
          }
        })
      } else {
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
        this.getPendingApprovals(1, 5)
      }
    });
  }

  denyRequest(data:any){
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: 'ApproveRequest', action: 'Deny' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const status = 2
        const id = data.id
        this.injector.get(TimeManagementService).approveRequest(id, status).subscribe((data:any) => {
          console.log(data)
          if(data) {
            this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
            this.getPendingApprovals(1, 5)
          }
        })
      } else {
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
        this.getPendingApprovals(1, 5)
      }
    });
  }

  createCat() {
    const dialogRef3 = this.dialog.open(DialogOverviewExampleDialog3, {
      width: '400px',
      data: { type: 'TimeOff' },
    });

    dialogRef3.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.viewdialog();
    });
  }

  viewdialog() {
    this.calendarService.getCategoryForTimeOff().subscribe((res) => {
      this.categories = res;
      console.log(this.categories);
    });
  }

  categoryChange(event: any) {
    this.categoryOption = event.value;
  }

  addrequest() {
    const startDate = this.requestForm.get('fromDate').value; 
    const endDate = this.requestForm.get('toDate').value;
    var data = {
      categoryId: this.requestForm.get('category').value,
      startDateTime: startDate instanceof Date ? startDate.toISOString() : startDate,
      endDateTime: endDate instanceof Date ? endDate.toISOString() : endDate,
      description: this.requestForm.get('note').value,
      IsFullDay: this.requestForm.get('IsFullDay').value,
    };

    this.injector.get(TimeManagementService).createRequest(data).subscribe(data => {
      console.log(data)
      if(data){
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
        this.dialogRef.close(true)
      }
    })
  }

  getPendingApprovals(pagenumber:any, numberofrecord:any){
    this.injector.get(TimeManagementService).getPendingApprovals(pagenumber, numberofrecord).subscribe((data:any) => {
      console.log(data)
      this.PendingRequsts = data?.data
      this.totalItems = data.totalItems
    })
  }

  next(e:any) {
    this.getPendingApprovals(e.pageIndex + 1, 5);
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  getStatus(value:any){
    console.log(value)
    if(value == true){
      this.dayStatus = this.requestForm.get('IsFullDay').setValue(true)
    } else {
      this.dayStatus = this.requestForm.get('IsFullDay').setValue(false)
    }
  }

  openTimeTrack(){
    this.dialogRef.close(true)
    const TimeTrackDialog = this.dialog.open(TimeTrackComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true
    });

    TimeTrackDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openTimeSheet(){
    this.dialogRef.close(true)
    const TimeSheetDialog = this.dialog.open(TimesheetComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true,
      data: {type: 'User-TimeSheet'}
    });

    TimeSheetDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  openManagerTime(){
    this.dialogRef.close(true)
    const TimeSheetDialog = this.dialog.open(TimeReportingComponent, {
      width: '1100px',
      height: '90vh',
      autoFocus: false,
      disableClose: true,
    });

    TimeSheetDialog.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
