import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { UserDetailsComponent } from '../user-details/user-details.component';

@Component({
  selector: 'app-showgroup',
  templateUrl: './showgroup.component.html',
  styleUrls: ['./showgroup.component.scss']
})
export class ShowgroupComponent implements OnInit {
  details: any;
  Entity: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService ,
    private dialog: MatDialog,
  ) {
    if(sessionStorage.getItem('lang')){
      const lang:any=sessionStorage.getItem('lang')
      this.translate.use(lang);
    }else{
      this.translate.use('English');
    }
  }

  ngOnInit(): void {
    console.log(this.data)
    this.Entity = this.data.entity
    this.details = this.data.data
  }

  ShowUserDEtails(row:any, type: string): void {
    this.dialog.open(UserDetailsComponent, {
      data: { id: row, type: type, },
      disableClose: true,
      width: '500px',
    },);
  }

}
