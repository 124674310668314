<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        {{'Export Report'|translate}}
    </h2>
    <mat-icon class="mx-3" style="color: white;" (click)="AdvanceSearch()">filter_list</mat-icon>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="ReportForm">
        <div class="row mt-3 mb-3">
            <div class="col-4">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-date-range-input [rangePicker]="dateRangePicker">
                      <input matStartDate placeholder="Start date" formControlName="startDate">
                      <input matEndDate placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                  </mat-form-field>
            </div>
            <div class="col-4 text-end">
            </div>
            <div class="col-4 text-end" style="margin: auto;text-align: end;">
                <button mat-raised-button class="b-blue"
                ><mat-icon style="color:white">download</mat-icon> {{"Export"}}</button>
            </div>
        </div>
    
        <div class="container mb-3">
            <table mat-table [dataSource]="users" class="w-100 table mat-elevation-z8">
              <!-- User Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>UserName</th>
                <td mat-cell *matCellDef="let user; let i = index">
                    <div class="d-flex">
                        <img *ngIf="user?.profile" [src]="user.profile" alt=""
                          style="width: 30px; height: 30px; border-radius: 50%; margin-right: 8px;">
                        <span *ngIf="!user.profile" [style.background]="getColor(i)"
                          style="color: white; padding: 10px; border-radius: 50%; margin: 5px; width: 40px; height: 40px;">
                          {{ getuserFLnames(user.firstName + ' ' + user.lastName) }}
                        </span>
                        <div class="d-flex flex-column mx-0" style="margin: auto;width:max-content;">
                          <span style="font-weight: bold;">{{ user.firstName }} {{ user.lastName }}</span>
                          <span style="font-size: 12px; color: gray;">{{ user.position }}</span>
                        </div>
                      </div>
                </td>
              </ng-container>
          
              <!-- Week Columns -->
              <ng-container *ngFor="let week of weeks; let i = index" [matColumnDef]="week">
                <th mat-header-cell *matHeaderCellDef>{{ week }}</th>
                <td mat-cell *matCellDef="let user">{{ user.weekHours[i] + 'h' }}</td>
              </ng-container>
          
              <!-- Table Header and Rows -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>          
    </form>   
</mat-dialog-content>
