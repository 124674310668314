import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageProductsService } from '../../../../../app/core/services/manage-products.service';
import { ManageUsersService } from '../../../../../app/core/services/manage-users.service';
import { ProfilePickFormatService } from '../../../../../app/core/services/profile-pick-format.service';
@Component({
  selector: 'app-create-price-matrix',
  templateUrl: './create-price-matrix.component.html',
  styleUrls: ['./create-price-matrix.component.scss']
})
export class CreatePriceMatrixComponent implements OnInit {

 // auto chip for keyword
 separatorKeysCodes: number[] = [ENTER, COMMA];
 keywordlist: string[] = [];
 addFileFormdata: any[] = [];
 Form: FormGroup;
 conditioncolumns :any= []
 conditionDropDownList = ''
 screenType=''
 matchValue:any = []
 TransactionType:any = [{ name: 'Buy'}, { name: 'Sale'}]
 PaymentType=[{ name: 'Cash'}, { name: 'Credit Card'},{ name: 'Money Transfer'},{ name: 'Paypal'},{ name: 'Check'}]
 TransactionStatus:any = [{ name: 'Validated'}, { name: 'pending'}]
 stringoptions:any = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }];
 numberoptions:any = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<', v2: 'less than ' }]
 textoptions:any = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }]
 booleanoptions:any = [{ v1: '=', v2: 'equal' }]
 AllOption:any = [{ v1: '=', v2: 'equal' }, { v1: '!=', v2: 'not equal' }, { v1: '>', v2: 'greater than' },
 { v1: '<', v2: 'less than ' }, { v1: '=>', v2: 'greater than or equal' }, { v1: '<=', v2: 'less than or equal' }];
 filteredList7:any;
 filterforuserlist: any;
 products$:any;
 User$:any;
  PriceMatrixstatus: any;
  dayslist=[{name:'Monday',value:8,status:true},{name:'Tuesday',value:8,status:true},{name:'Wednesday',value:8,status:true},{name:'Thursday',value:8,status:true},{name:'Friday',value:8,status:true},{name:'Saturday',value:0,status:false},{name:'Sunday',value:0,status:false}]
  weekDaysWithDailyWorkHour:any=[]
 constructor(
   private dialog: MatDialog,
   private formBuilder: FormBuilder,
   private matDialogRef: MatDialogRef<CreatePriceMatrixComponent>,
   public snackBar: MatSnackBar,
   @Inject(MAT_DIALOG_DATA) public data: any,
   private injector: Injector,

 ) {
   this.Form = this.formBuilder.group({
     id: [''],
     name: ['', [Validators.required]],
     description: [''],
     price: [null],
     DailyHour: [null],
     MonthlyHour: [null],
     productId:[null],
     SelectionId:[null],
     Conditions: this.formBuilder.array([]),
     workingdays:this.formBuilder.array([])
   });
 }

 ngOnInit(): void {
   this.screenType=this.data.screenType 
   console.log(this.data.screenType )
   console.log(this.data )
   this.PriceMatrixstatus=this.data.data.PriceMatrixstatus
   if(this.PriceMatrixstatus==true){
    this.FindImportColumnsTransactions('Transactions')
    if(this.data.screenType=='update'){
      console.log(this.data.data)
      this.Form.get('id')?.setValue(this.data.data.id)
      this.Form.get('name')?.setValue(this.data.data.name)
      this.Form.get('description')?.setValue(this.data.data.description)
      this.data.data.conditionDtos.forEach((e:any) => {
        this.getCondition().push(
          this.formBuilder.group({
            conditionDropDownList:'Transactions',
            conditionParameter: e?.conditionParameter,
            conditionComparison: e?.conditionComparison,
            value: e?.value,
            dataType: e?.dataType,
            isTag: e?.isTag,
          })
        )
      });
    }else{
      this.Addnewcondictuon()
    }
   }else{
    if(this.data.screenType=='Update'){
      this.dayslist.forEach((element)=>{
        this.addnewday(element.name,element.value,element.status)
      })
      console.log(this.data.data)
      this.Form.get('id')?.setValue(this.data.data.id)
      this.Form.get('name')?.setValue(this.data.data.name)
      this.data.data.weekHoursDto.forEach((elementA)=>{
        this.Form.value.workingdays.forEach((elementB,index)=>{
          if(elementB.name==elementA.weekDay){
            this.getworkingdays().at(index).get('Name')?.setValue(elementA.weekDay)
            this.getworkingdays().at(index).get('Value')?.setValue(elementA.dailyHour)
            this.getworkingdays().at(index).get('Status')?.setValue(elementA.dailyHour!=0?true:false)
          }

        })
      })
      this.data.data.conditionDtos.forEach((e:any) => {
        this.getCondition().push(
          this.formBuilder.group({
            conditionDropDownList:'Users',
            conditionParameter: e?.conditionParameter,
            conditionComparison: e?.conditionComparison,
            value: e?.value,
            dataType: e?.dataType,
            isTag: e?.isTag,
          })
        )
      });
    }else{
      this.dayslist.forEach((element)=>{
        this.addnewday(element.name,element.value,element.status)
      })
    }
    this.FindImportColumnsTransactions('Users')
   }
   if(this.data.data.id){
    this.Form.get('productId')?.setValue(this.data.data.id)
   }

     this.findAllProductsDropdown()
     this.findAllUsersDropdown()
 }

 findAllUsersDropdown(){
       // get all users
       this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((res: any) => {
        this.filterforuserlist = res
        this.User$=res
      });
 }
 filterOptions(value: string, type: string): void {
  console.log(type);
  if (type === 'users') {
    this.filterforuserlist = this.User$.filter((option:any) =>
      option.firstName?.toLowerCase().includes(value.toLowerCase())
    );
  }
}
 findAllProductsDropdown(){
  this.injector.get(ManageProductsService).findAllProductsDropdown().subscribe((res) => {
    this.products$ = res;
    this.products$.sort(function (a:any, b:any) {
      var textA = a.productName.toUpperCase();
      var textB = b.productName.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.filteredList7 = this.products$.slice()
  })
 }


 getworkingdays():FormArray{
  return this.Form.get('workingdays') as FormArray
 }

 getlablename(i){
  return this.getworkingdays().at(i).get('Name')?.value
 }
 addnewday(name,value,ststus){
  this.getworkingdays().push(this.newday(name,value,ststus))
 }
 newday(name,value,status):FormGroup{
  return this.formBuilder.group({
    Name:name,
    Value:value,
    Status:status
  })
 }

 getCondition(): FormArray {
  return this.Form.get('Conditions') as FormArray;
}

 newCondition(): FormGroup {
   return this.formBuilder.group({
     conditionParameter: [null],
     conditionComparison: [null],
     value: [null],
     dataType:[''],
     isTag: [false]
   });
 }

 selactdatatype(x:any,index:number) {
   this.conditionDropDownList = x.dataType
   this.getCondition().at(index).get('dataType')?.setValue(x.dataType)
 }

 getdataType(index:number){
   const value = this.getCondition().at(index).get('dataType')?.value
   return value
 }
 gettagename(index:number){
   const value = this.getCondition().at(index).get('conditionParameter')?.value
   return value
 }

 Addnewcondictuon() {
   this.getCondition().push(this.newCondition());
 }
 Removecondictuon(i: number) {
   this.getCondition().removeAt(i);
 }

 FindImportColumnsTransactions(entity) {
   this.injector.get(ManageUsersService).FindParameters(entity,false).subscribe((res: any) => {
     res.forEach((e:any) => {
       this.conditioncolumns.push(e)
     })
   })
 }
  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:any) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  // ---------------- 
 // auto chip for keyword 
 // ----------- 
 add(event: MatChipInputEvent) {
   const value = (event.value || '').trim();
   if (value) { this.keywordlist.push(value); }
   event.input.value = ''

 }
 remove(value: string) {
   const index = this.keywordlist.indexOf(value);
   if (index >= 0) {
     this.keywordlist.splice(index, 1);
   }
 }

 selected(event: MatAutocompleteSelectedEvent) {
   this.keywordlist.push(event.option.viewValue);
 }




// pricematrix 
 onSubmitPriceMatrix() {
   console.log(this.Form.value)
   if(this.screenType==='create'){
     let DynamicFormdata :any= {
       name: this.Form.value.name,
       description: this.Form.value.description,
       entity:'Transactions',
       isKeywordDynamicGroup:false,
       isPriceMatrix: true,
       IsTimeMatrix: false,
       price: this.Form.value.price==null?0:this.Form.value.price,
       productId: this.Form.value.productId==null?0:this.Form.value.productId,
       dynamicGroupConditions: []
     }
     this.Form.value.Conditions.forEach((element:any) => {
       DynamicFormdata.dynamicGroupConditions.push({
         conditionDropDownList:'Transactions',
         conditionParameter: element.conditionParameter.replaceAll(" ", ""),
         conditionComparison: element.conditionComparison,
         value: element.value,
         dataType: element.dataType,
         isTag: element.isTag,
       })
     });
     this.injector.get(ManageUsersService).CreateDynamicGroup(DynamicFormdata).subscribe((result: any) => {
       if (result) {
         this.snackBar.open(result.response[0].message, '', { duration: 2000, });
         this.matDialogRef.close(result)
       }
     }, (err) => { this.snackBar.open(err.statusText, '', { duration: 2000, }); });

   }else{
     let DynamicFormdata :any= {
       id:this.Form.value.id,
       name: this.Form.value.name,
       description: this.Form.value.description,
       entity:'Transactions',
       isKeywordDynamicGroup:false,
       isPriceMatrix: true,
       IsTimeMatrix: false,
       price: this.Form.value.price==null?0:this.Form.value.price==null,
       dynamicGroupConditions: []
     }
     this.Form.value.Conditions.forEach((element:any) => {
       DynamicFormdata.dynamicGroupConditions.push({
         conditionDropDownList:'Transactions',
         conditionParameter: element.conditionParameter.replace(' ', ''),
         conditionComparison: element.conditionComparison,
         value: element.value,
         dataType: element.dataType,
       })
     });
     this.injector.get(ManageUsersService).UpdateDynamicGroup(DynamicFormdata).subscribe((result: any) => {
       if (result) {
         this.snackBar.open(result.response[0].message, '', { duration: 2000, });
         this.matDialogRef.close(result)
       }
     }, (err) => { this.snackBar.open(err.statusText, '', { duration: 2000, }); });

   }
 }

//  timematrix 
 onSubmitontimeMatrix() {
  console.log(this.Form.value)
  if(this.screenType==='create'){
    let DynamicFormdata :any= {
      name: this.Form.value.name,
      description: this.Form.value.description,
      entity:'Users',
      isKeywordDynamicGroup:false,
      isPriceMatrix: false,
      IsTimeMatrix: true,
      weekDaysWithDailyWorkHour:'',
      dynamicGroupConditions: []
    }
    this.Form.value.workingdays.forEach((element:any) => {
      console.log(element)
      if(element.Status==true){
        this.weekDaysWithDailyWorkHour.push(element.Name+'-'+element.Value)
      }
    });
    if(this.weekDaysWithDailyWorkHour.length!=0){
      console.log(this.weekDaysWithDailyWorkHour)
      DynamicFormdata.weekDaysWithDailyWorkHour=this.weekDaysWithDailyWorkHour.toString()
    }
    this.Form.value.Conditions.forEach((element:any) => {
      DynamicFormdata.dynamicGroupConditions.push({
        conditionDropDownList:'Users',
        conditionParameter: element.conditionParameter.replaceAll(" ", ""),
        conditionComparison: element.conditionComparison,
        value: element.value,
        dataType: element.dataType,
        isTag: element.isTag,
      })
    });
    this.injector.get(ManageUsersService).CreateDynamicGroup(DynamicFormdata).subscribe((result: any) => {
      if (result) {
        this.snackBar.open(result.response[0].message, '', { duration: 2000, });
        this.matDialogRef.close(result)
      }
    }, (err) => { this.snackBar.open(err.statusText, '', { duration: 2000, }); });

  }else{
    let DynamicFormdata :any= {
      id:this.Form.value.id,
      name: this.Form.value.name,
      description: this.Form.value.description,
      entity:'Users',
      isKeywordDynamicGroup:false,
      isPriceMatrix: false,
      IsTimeMatrix: true,
      weekDaysWithDailyWorkHour:'',
      dynamicGroupConditions: []
    }
    this.Form.value.workingdays.forEach((element:any) => {
      if(element.Status==true){
        this.weekDaysWithDailyWorkHour.push(element.Name+'-'+element.Value)
      }
    });
    DynamicFormdata.weekDaysWithDailyWorkHour=this.weekDaysWithDailyWorkHour.toString()
    this.Form.value.Conditions.forEach((element:any) => {
      DynamicFormdata.dynamicGroupConditions.push({
        conditionDropDownList:'Users',
        conditionParameter: element.conditionParameter.replace(' ', ''),
        conditionComparison: element.conditionComparison,
        value: element.value,
        dataType: element.dataType,
      })
    });
    this.injector.get(ManageUsersService).UpdateDynamicGroup(DynamicFormdata).subscribe((result: any) => {
      if (result) {
        this.snackBar.open(result.response[0].message, '', { duration: 2000, });
        this.matDialogRef.close(result)
      }
    }, (err) => { this.snackBar.open(err.statusText, '', { duration: 2000, }); });

  }
}

}

