import { Conditional } from '@angular/compiler';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageUsersService } from '../../../../app/core/services/manage-users.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
  categoriesForm!: FormGroup;
  public color1: string = '#f9d797';
  public color2: string = '#1e81b0';
  AllOption = [  'Equal' ,'Not equal','Not in'];
  Parameter=[]
  screenType=''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<CreateCategoryComponent>,
    private injector: Injector,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar 
  ) { 
    this.categoriesForm = this.formBuilder.group({
      categoryId: ['', Validators.required],
      Description: [''],
      categoryName: ['', Validators.required],
      Conditions: this.formBuilder.array([
      ])
    });
  }

  ngOnInit(): void {
    console.log(this.data)
    this.screenType=this.data.screenType
    this.injector.get(ManageUsersService).GetAllFields().subscribe((result:any)=>{
      if(result){
        this.Parameter=result
      }
    })
    if(this.screenType=='EditCategory'){
      this.injector.get(ManageUsersService).GetCategorieById(this.data.details.id).subscribe((result:any)=>{
        if(result){
          this.color1=result?.textColorCode
          this.color2=result?.bgColorCode
          this.categoriesForm.get('categoryId')?.setValue(result?.id)
          this.categoriesForm.get('Description')?.setValue(result?.description)
          this.categoriesForm.get('categoryName')?.setValue(result?.name)
          if(result?.conditions.length!=0){
            result?.conditions.forEach((element,index) => {
              this.addConditionalBody()
              this.getConditionsBody().at(index).get('conditionParameter')?.setValue(element?.fieldName)
              this.getConditionsBody().at(index).get('conditionComparison')?.setValue(element?.condition)
              this.getConditionsBody().at(index).get('value')?.setValue(element?.value)
            });
          }
        }
      })
    }else{
      this.addConditionalBody()
    }
  }


  createConditionalBody(): FormGroup {
    return this.formBuilder.group({
      conditionParameter: [''],
      conditionComparison: [''],
      value: [''],
    })
  }

  removeConditionalBody(i: number) {
    this.getConditionsBody().removeAt(i);
  }
  getConditionsBody() {
    return this.categoriesForm.get('Conditions') as FormArray;
  }

  getConditionsFormArray(i: number) {
    return this.getConditionsBody().at(i).get('conditions') as FormArray;
  }
  getvalue(i: number) {
    return this.getConditionsBody().at(i).get('conditionDropDownList')?.value
  }


  addConditionalBody() {
    this.getConditionsBody().push(this.createConditionalBody())
  }

  public onEventLog(event: string, data: any): void {
    console.log(event, data);
    this.categoriesForm.get('color')?.setValue(data);
  }
  public onEventLog1(event: string, data: any): void {
    console.log(event, data);
    this.categoriesForm.get('fontColor')?.setValue(data);
  }

  createcategory() {
    console.log(this.categoriesForm.value)
    var data={
      "id":this.categoriesForm.value.categoryId,
      "name": this.categoriesForm.value.categoryName,
      "Description": this.categoriesForm.value.Description,
      "bgColorCode": this.color1,
      "textColorCode": this.color2,
      "conditions":[] ,
      // "collaboratedUserIds": "string"
    }
    if(this.categoriesForm.value.Conditions.length!=0){
      var Conditions:any=[]
      this.categoriesForm.value.Conditions.forEach((element:any) => {
        Conditions.push(
          {
            "fieldName": element?.conditionParameter,
            "condition": element?.conditionComparison,
            "value": element?.value
          }
        )
      });
    }
    data.conditions=Conditions
    if(this.screenType=='EditCategory'){
      this.injector.get(ManageUsersService).UpdateEmailCategory(data).subscribe((result)=>{
        if(result){
          console.log(result)
          this.snackBar.open('UpdateEmailCategory done', '', { duration: 2000, });
         this.matDialogRef.close(true)
        }
      },error=>{
        if(error.status==200){
          this.snackBar.open('UpdateEmailCategory done', '', { duration: 2000, });
          this.matDialogRef.close(true)
        }
      })
    }else{
      this.injector.get(ManageUsersService).CreateEmailCategory(data).subscribe((result)=>{
        if(result){
          console.log(result)
          this.snackBar.open('CreateEmailCategory done', '', { duration: 2000, });
         this.matDialogRef.close(true)
        }
      },error=>{
        if(error.status==200){
          this.snackBar.open('CreateEmailCategory done', '', { duration: 2000, });
          this.matDialogRef.close(true)
        }
      })
    }
  }

  closepopup(){
    this.matDialogRef.close()
}


}
