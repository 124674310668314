import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  constructor() {}

  /**
   * Converts an array of tags into FormData format.
   * @param tags Array of tags from the form.
   * @returns A FormData object.
   */
  prepareFormData(tags: any[]): FormData {
    console.log(tags)
    const formData = new FormData();
    let indexValue = 0;

    tags.forEach((element: any) => {
      if (element?.fieldValue != null) {
        formData.append(`TagViewModels[${indexValue}].fieldName`, element.fieldName);
        formData.append(`TagViewModels[${indexValue}].fieldType`, element.fieldType);
        formData.append(`TagViewModels[${indexValue}].tagTableId`, element.tagTableId);
        formData.append(`TagViewModels[${indexValue}].dropdownValues`, element.dropdownValues);
        formData.append(
          `TagViewModels[${indexValue}].fieldValue`,
          element?.fieldValue == null ? ' ' : element?.fieldValue
        );
      } else if (element.fieldType === 'file' && element.files.length !== 0) {
        formData.append(`TagViewModels[${indexValue}].fieldName`, element.fieldName);
        formData.append(`TagViewModels[${indexValue}].fieldType`, element.fieldType);
        formData.append(`TagViewModels[${indexValue}].tagTableId`, element.tagTableId);
        formData.append(`TagViewModels[${indexValue}].dropdownValues`, element.dropdownValues);

        element.files.forEach((file: any) => {
          formData.append(`TagViewModels[${indexValue}].Files`, file.sfile);
        });
      }
      indexValue++;
    });

    return formData;
  }






  // Method for ReminderList
  prepareRemindersFormData(reminders: any[]): FormData {
    const formData = new FormData();

    reminders.forEach((element: any, index: number) => {
      const triggerType =
        element.triggertype === 'Notification' ? 0 : element.triggertype === 'Emails' ? 1 : 2;
      formData.append(`Reminders[${index}].reminderType`, triggerType.toString());
      formData.append(`Reminders[${index}].conditionParameter`, element?.Parameter?.toString());
      
      const triggerMoment = element.triggerMoment === 'After' ? true : false;
      formData.append(`Reminders[${index}].isAfter`, triggerMoment.toString());
      
      const durationType =
        element.durationtype === 'Minutes' ? 0 : element.durationtype === 'Hours' ? 1 : 2;
      formData.append(`Reminders[${index}].period`, durationType.toString());
      
      element?.UserId?.forEach((userId: any, userIndex: number) => {
        formData.append(`Reminders[${index}].reminderUsersIds[${userIndex}]`, userId.toString());
      });
    });

    return formData;
  }











  /**
   * Combines two FormData objects.
   * @param formData1 First FormData object.
   * @param formData2 Second FormData object.
   * @returns A combined FormData object.
   */
  combineFormData(formData1: FormData, formData2: FormData): FormData {
    const combinedFormData = new FormData();

    // Append all entries from formData1
    formData1.forEach((value, key) => {
      combinedFormData.append(key, value);
    });

    // Append all entries from formData2
    formData2.forEach((value, key) => {
      combinedFormData.append(key, value);
    });

    return combinedFormData;
  }
}
