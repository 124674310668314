import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DriveService } from 'src/app/core/services/drive.service';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {
  messange:any;
  screenType:any;
  applicationname = ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MessagePopupComponent>,
    private DriveService: DriveService,

  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.messange = this.data.message
    this.screenType = this.data.screenType
  }


  clear() {
    this.dialogRef.close(true)
  }
  onSubmit() {
    this.DriveService.CreateNewApplication(this.applicationname).subscribe((result) => {
      if (result) {
        this.dialogRef.close(true)
      }
    })
  }














  autocompleteOptions = {
    componentRestrictions: { country: 'us' },  // Restrict to the United States
    fields: ['formatted_address', 'geometry'], // Fields to retrieve
    types: ['address'],  // Only address type
  };

  @ViewChild('searchInput') searchInput!: ElementRef;

  ngAfterViewInit() {
    // Check if the Google Maps library is available
    if (typeof google === 'undefined' || !google.maps) {
      console.error('Google Maps library not loaded.');
      return;
    }

    // Initialize the Google Places Autocomplete
    const autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement, this.autocompleteOptions);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      console.log('Selected Place:', place);
    });
  }

  // Handle the address change event
  handleAddressChange(address: any) {
    console.log('Address changed:', address);
  }

}
