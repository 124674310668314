import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { TimeManagementService } from 'src/app/core/services/time-management.service';
import { ReportingDetailsComponent } from '../reporting-details/reporting-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})
export class ReportDashboardComponent implements OnInit {
  DashboardForm: FormGroup
  displayedColumns: string[] = ['teamMember', 'latestActivity',  'totalTracked'];
  dataSource = [
    {
      firstname: 'Deepak',
      lastname: 'Kumar',
      profile: null,
      avatarColor: '#009688',
      latestActivity: 'Worked on today and tomorrow campaigns',
      totalTracked: '31:32:12',
      duration: '09:37:00',
      projects: [
        { name: 'Campaign A', color: '#FF5722', percentage: 50, hours: '20:00:00' },
        { name: 'Campaign B', color: '#4CAF50', percentage: 30, hours: '12:00:00' },
        { name: 'Campaign C', color: '#2196F3', percentage: 20, hours: '8:00:00' },
      ],
    },
    {
      firstname: 'Sachin',
      lastname: 'Charles',
      profile: null,
      avatarColor: '#3F51B5',
      latestActivity: 'Working on Planning and other UI updates',
      totalTracked: '36:00:00',
      duration: '03:00:00',
      projects: [
        { name: 'UI Updates', color: '#9C27B0', percentage: 60, hours: '21:36:00' },
        { name: 'Planning', color: '#FFC107', percentage: 40, hours: '14:24:00' },
      ],
    },
    {
      firstname: 'Arun',
      lastname: 'Kumar',
      profile: null,
      avatarColor: '#E91E63',
      latestActivity: 'Worked on Ionic path in bitscreen',
      totalTracked: '46:16:10',
      duration: '05:40:00',
      projects: [
        { name: 'UI Updates', color: '#4CAF50', percentage: 100, hours: '21:36:00' },
      ],
    },
    {
      firstname: 'Anand',
      lastname: 'Rakesh',
      profile: null,
      avatarColor: '#9C27B0',
      latestActivity: '(no description)',
      totalTracked: '10:00:00',
      duration: '04:10:00',
      projects: [
        { name: 'UI Updates', color: '#FF5722', percentage: 50, hours: '21:36:00' },
      ],
    },
    {
      firstname: 'Gopi',
      lastname: 'Krishnan',
      profile: null,
      avatarColor: '#FF9800',
      latestActivity: 'Working on Missing UI for approval',
      totalTracked: '00:00:00',
      duration: '02:50:00',
      projects: [
        { name: 'UI Updates', color: '#2196F3', percentage: 60, hours: '21:36:00' },
      ],
    },
    // Add more entries as required
  ];
  data = {
    graphDimension: [700, 400], // Chart dimensions: [width, height]
    yaxisLabelName: 'Total Hours',
    savedBarChartData: [
      {
        name: 'Jan 06',
        series: [
          { name: 'Sample Test', value: 2 },
          { name: 'Test-1', value: 2 },
          { name: 'sample New', value: 1 },
          { name: 'Sales New', value: 3 }
        ]
      },
      {
        name: 'Jan 07',
        series: [
          { name: 'Sample Test', value: 3 },
          { name: 'Test-1', value: 2 },
          { name: 'sample New', value: 2 },
          { name: 'Sales New', value: 2 }
        ]
      },
      {
        name: 'Jan 08',
        series: [
          { name: 'Sample Test', value: 2 },
          { name: 'Test-1', value: 1 },
          { name: 'sample New', value: 3 },
          { name: 'Sales New', value: 2 }
        ]
      },
      {
        name: 'Jan 09',
        series: [
          { name: 'Sample Test', value: 2 },
          { name: 'Test-1', value: 3 },
          { name: 'sample New', value: 3 },
          { name: 'Sales New', value: 0 }
        ]
      },
      {
        name: 'Jan 10',
        series: [
          { name: 'Sample Test', value: 4 },
          { name: 'Test-1', value: 1 },
          { name: 'sample New', value: 1 },
          { name: 'Sales New', value: 2 }
        ]
      },
    ]
  };

  data1 = {
    chartType: 'Pie Doughnut Chart',
    multipleSaleBar: [
      { name: 'Sales Sample Test', value: 4 },
      { name: 'Sales Test-1', value: 2 },
      { name: 'Sales sample New', value: 2 },
      { name: 'Sales Sales New', value: 2 }
    ],
    graphDimension: [300, 300] // Width and Height
  };

  colorScheme = {
    domain: ['#ff8f00', '#ff5722', '#8cfc9d', '#0000ff', '#33ffff', '#f96459']
  };

  chartData: any = {};
  ProjectPieChart:any = {};
  SitePieChart:any = {};
  SiteGroupsPieChart:any = {};

  constructor(
    private fb : FormBuilder,
    private injector: Injector,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) { 

    this.DashboardForm = this.fb.group({
      startDate: [''],
      endDate: [''],
    });
  }

  ngOnInit(): void {

    const currentDate = new Date();
    const firstDayOfWeek = this.getStartOfWeek(currentDate);
    const lastDayOfWeek = this.getEndOfWeek(currentDate);

    this.DashboardForm.patchValue({
      startDate: this.formatDate(firstDayOfWeek),
      endDate: this.formatDate(lastDayOfWeek),
    });

    this.getDataForChart()

    this.DashboardForm.valueChanges.subscribe((values) => {
      const { startDate, endDate } = values;
      if (startDate && endDate) {
        const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
         const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
         if (formattedStartDate && formattedEndDate) {
          console.log(`StartDate: ${formattedStartDate}`);
          console.log(`EndDate: ${formattedEndDate}`);
          this.getDataForChart(formattedStartDate, formattedEndDate);
        }
      }
    });
  }

  onBarClick(data: any): void {
    console.log(data)
    const BarDetails = this.dialog.open(
      ReportingDetailsComponent,
      {
        disableClose: true,
        data: { data: data, type: 'BarChart' },
        width: '700px',
      }
    );
    
    BarDetails.afterClosed().subscribe((result: any) => {
      
    })
  }

  getDataForChart(startDate?:any, endDate?:any){
    const start = startDate || this.DashboardForm.get('startDate')?.value;
    const end = endDate || this.DashboardForm.get('endDate')?.value;


    this.injector.get(TimeManagementService).getchartData(start, end).subscribe((data:any) => {
      console.log(data)
      this.chartData = this.convertToBarChartData(data.dashboardBarCharts);
      this.ProjectPieChart = this.generatePieChartData(data.pieChartForProject);
      this.SitePieChart = this.generateSitePieChartData(data.pieChartForSites);
      this.SiteGroupsPieChart = this.generateSiteGroupsPieChartData(data.pieChartForSiteGroup)
    })

    this.injector.get(TimeManagementService).getDashboardTableData(start, end).subscribe((data:any) => {
      console.log(data)
      this.dataSource = data
    })
  }

  convertToBarChartData(responseData: any) {
    // Define custom color array
    const customColors: any[] = [];
  
    const savedBarChartData = responseData.map((dayData: any) => {
      const formattedDate = new Date(dayData.date);
      const dayName = `Jan ${formattedDate.getDate() < 10 ? '0' + formattedDate.getDate() : formattedDate.getDate()}`;
      const series = [];
  
      dayData.workDetailsPerDays.forEach((workDetail: any) => {
        const workedHours = this.convertTimeToHours(workDetail.workedHours);
        const color = workDetail.color || '#000000'; // Default to black if no color is specified
        const fontColor = workDetail.fontColor || '#ffffff'; // Default to white if no fontColor is specified
  
        const existingSeries = series.find((s: any) => s.name === workDetail.projectName);
        if (existingSeries) {
          existingSeries.value += workedHours;
        } else {
          series.push({
            name: workDetail.projectName,
            value: workedHours,
            color: color,
            fontColor: fontColor
          });
  
          // Add color to customColors array if not already present
          if (!customColors.some((c) => c.name === workDetail.projectName)) {
            customColors.push({ name: workDetail.projectName, value: color });
          }
        }
      });
  
      if (series.length === 0) {
        series.push({
          name: 'No Work',
          value: 0,
          color: '#cccccc',
          fontColor: '#000000'
        });
  
        // Add 'No Work' color to customColors
        if (!customColors.some((c) => c.name === 'No Work')) {
          customColors.push({ name: 'No Work', value: '#cccccc' });
        }
      }
  
      return { name: dayName, series };
    });
  
    return {
      graphDimension: [800, 400],
      yaxisLabelName: 'Total Hours',
      savedBarChartData,
      customColors // Return the custom color array
    };
  }
  

  generatePieChartData(responseData: any): any {
    const customColors: any[] = [];
    
    const multipleSaleBar = responseData.totalWorkedHours.length > 0
      ? responseData.totalWorkedHours.map((workDetail: any) => {
          const workedHours = this.convertTimeToHours(workDetail.workedHours);
          const color = workDetail.color || '#cccccc'; // Default to gray if no color is specified
  
          // Add color to customColors array if not already present
          if (!customColors.some((c) => c.name === workDetail.name)) {
            customColors.push({ name: workDetail.name, value: color });
          }
  
          return {
            name: workDetail.name,
            value: workedHours
          };
        })
      : [{ name: 'No Work', value: 0 }];
  
    // Ensure "No Work" also has a default color
    if (multipleSaleBar.length === 1 && multipleSaleBar[0].name === 'No Work') {
      customColors.push({ name: 'No Work', value: '#cccccc' });
    }
  
    const data = {
      chartType: 'Pie Doughnut Chart',
      multipleSaleBar: multipleSaleBar,
      graphDimension: [500, 300],
      customColors: customColors // Include custom colors
    };
    
    return data;
  }
  

  generateSitePieChartData(responseData: any): any {
    const multipleSaleBar = responseData.totalWorkedHours.length > 0
    ? responseData.totalWorkedHours.map((workDetail: any) => ({
        name: workDetail.name,
        value: this.convertTimeToHours(workDetail.workedHours) // Convert to hours
      }))
    : [{ name: 'No Work', value: 0 }];
    const data = {
      chartType: 'Pie Doughnut Chart',
      multipleSaleBar: multipleSaleBar,
      graphDimension: [500, 300] 
    };
    return data;
  }

  generateSiteGroupsPieChartData(responseData: any): any {
    const multipleSaleBar = responseData.totalWorkedHours.length > 0
    ? responseData.totalWorkedHours.map((workDetail: any) => ({
        name: workDetail.name,
        value: this.convertTimeToHours(workDetail.workedHours) // Convert to hours
      }))
    : [{ name: 'No Work', value: 0 }];
    const data = {
      chartType: 'Pie Doughnut Chart',
      multipleSaleBar: multipleSaleBar,
      graphDimension: [500, 300] 
    };
    return data;
  }

  convertTimeToHours(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours + minutes / 60 + seconds / 3600;
  }

  convertToHours(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return Math.round(totalHours * 2) / 2; // Round to the nearest 0.5
  }

  xAxisTickFormatting(value: any, ...args: any[]) {
    return value
  }

  yAxisTickFormatting(value: any, ...args: any[]) {
    return value.toString()
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  private getStartOfWeek(date: Date): Date {
    const day = date.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when the week starts on Monday
    return new Date(date.setDate(diff));
  }

  private getEndOfWeek(date: Date): Date {
    const firstDayOfWeek = this.getStartOfWeek(date);
    return new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 6)); // Add 6 days to get the last day of the week
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

}
