import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TimeManagementService } from 'src/app/core/services/time-management.service';

@Component({
  selector: 'app-monthly-hours',
  templateUrl: './monthly-hours.component.html',
  styleUrls: ['./monthly-hours.component.scss']
})
export class MonthlyHoursComponent implements OnInit {

  screenType: any;
  selectedYear: number = new Date().getFullYear();
  hours = [];
  years: any[] = [];
  monthData:any;
  DailyHours: any;
  loader=false
  lastselectedYear:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private injector: Injector
  ) { 
  }

  ngOnInit(): void {
    console.log(this.data)
    this.screenType = this.data?.screenType
    if(this.screenType == 'YearlyView') {
      this.getMonthlyHours()
    } else {
      this.getDailyHours()
    }
    this.generateYears();
  }

  getMonthlyHours(){
    this.loader=true
    this.injector.get(TimeManagementService).getUserMonthlyHours(this.data.data.id, this.selectedYear).subscribe(data =>  {
      console.log(data)
      this.monthData = data
      this.loader=false
    },(error:any)=>{
      this.loader=false
    })
  }

  getDailyHours(){
    this.loader=true
    this.injector.get(TimeManagementService).getUserDailyHours(this.data.data, this.data.lastyear, this.data.month).subscribe(data =>  {
      console.log(data)
      this.DailyHours = data
      this.loader=false
    },(error:any)=>{
      this.loader=false
    })
  }

  onYearChange(event:any) {
    console.log(event)
    this.lastselectedYear = event
    this.getMonthlyHours()
  }

  generateYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      this.years.push(i);
    }
  }

  openHourlyView(data:any, type:any, month:any, lastYearSelected:any){
    const onDoubleClickDialog = this.dialog.open(MonthlyHoursComponent, {
      data: {data: data, screenType: type, month:month, lastyear:lastYearSelected },
      disableClose: false,
      width: '500px',
    });
    onDoubleClickDialog.afterClosed().subscribe((result) => {

    })
  }


}
