<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Confirmation"|translate}}</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>

 
  <!-- add-sub-task  -->
  <ng-container *ngIf="screentype=='add-sub-task'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="color: green;text-align: cenete;width: 100%;display: flex;justify-content: center;">Initiative Created Successfully !</h3><br>
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Do you wish to add sub-task ...?</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" (click)="onSubmit('no')">{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmit('yes')">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>



  <ng-container *ngIf="screentype=='ConverttoMilestone'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Do you wish to convert this task into milestone ...??</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmit1()">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="screentype=='EventMatching'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Are you sure want to {{data.data == true ? 'Turn On' : 'Turn Off'}} Event Matching ??</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmitEvent()">{{"YES"|translate}}</button>
      </div>
  </div>                              
  </ng-container>
  <ng-container *ngIf="screentype=='SettingsApproval'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Are you sure want to {{data.data == true ? 'Enable' : 'Disable'}} Settings Approval ??</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmitEvent()">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="screentype=='ApproveRequest'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Do you wish to <b style="margin-left:8px;margin-right:8px">{{data.action}}</b> this Request ...?</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="Approve()">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="screentype=='ProjectDelete'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Are you sure you want to delete this <b style="margin-left:8px;margin-right:8px">{{this.data.data.projectName}}</b> project ?</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="DeleteProject()">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="screentype=='singleApprove' || screentype=='ApproveDateWise'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Are you sure you want to Approve / Deny this Request ?</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="R-blue" (click)="DenyTimeSheet()">{{"Deny"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="ApproveTimeSheet()">{{"Approve"|translate}}</button>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="screentype=='TimeTrack'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: center;width: 100%;display: flex;justify-content: center;">
            Are you sure you want to proceed with <b style="margin-left:8px;margin-right:8px">{{this.data.option}}</b> Tracking Mode ?</h3>
      </div>
      <div class="row" *ngIf="this.data.option == 'Clock In – Clock Out'">
        <span>
          <img class="information" src="../../../../assets/images/iconsForsvg/imformation.svg">
          {{"Select this option to track your time by clicking the Clock In button at the start of your work and the Clock Out button when you're done. This method is ideal for accurately logging your working hours based on your project and site."}} 
        </span>
      </div>
      <div class="row" *ngIf="this.data.option == 'Start Time – End Time'">
        <span>
          <img class="information" src="../../../../assets/images/iconsForsvg/imformation.svg">
          {{"Use this method to manually enter your start time and end time for the day. This is suitable for situations where exact timings are known and need to be logged for specific projects or sites."}} 
        </span>
      </div>
      <div class="row" *ngIf="this.data.option == 'No of Hrs'">
        <span>
          <img class="information" src="../../../../assets/images/iconsForsvg/imformation.svg">
          {{"If you know the total number of hours worked in a day, select this option to directly enter the total hours. This approach is simple and effective for quick time entries linked to specific projects and sites."}} 
        </span>
      </div>
    </div>
     
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmitTrack(this.data.option)">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>
  <ng-container *ngIf="screentype=='taskconvertevent'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Do you wish to convert this task into Event ...??</h3>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmit1()">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>

  <ng-container *ngIf="screentype=='tagdeleteconformation'">
    <div class="container">
      <div class="row mt-2 w-100">
           <h3 style="text-align: cenete;width: 100%;display: flex;justify-content: center;">Are you sure you want to delete  Tag ?</h3>
           <mat-radio-group [(ngModel)]="deleteType"  (change)="onRadioChange($event)" aria-label="Select an option">
           <mat-radio-button value="1">Soft Delete</mat-radio-button>
           <mat-radio-button value="2">Hard Delete</mat-radio-button><br><br>
           <mat-hint *ngIf="deleteType=='1'">A soft delete operation marks a tag as inactive for future reference, ensuring its historical data is preserved without being physically removed from the database</mat-hint>
           <mat-hint *ngIf="deleteType=='2'">A hard delete permanently erases data from the database, leaving no trace behind</mat-hint>
       </mat-radio-group>
      </div>
    </div>
  
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;">
      <div class="col-4">
          <button mat-raised-button class="W-blue" mat-dialog-close>{{"NO"|translate}}</button>
      </div>
      <div class="col-4">
          <button mat-raised-button class="b-blue" (click)="onSubmit2()">{{"YES"|translate}}</button>
      </div>
  </div>
  </ng-container>



  

