<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title *ngIf="data.type == 'TimeTrack'" class="m-0 dialog-title text-center w-100">
        {{'Approval Details'}}
    </h2>
    <h2 *ngIf="data.type == 'TimeReport'" mat-dialog-title class="m-0 dialog-title text-center w-100">
        {{'Time Report Details'}}
    </h2>
    <h2 *ngIf="data.type == 'TimeMap'" mat-dialog-title class="m-0 dialog-title text-center w-100">
      {{'Location Details'}}
  </h2>
  <h2 *ngIf="data.type == 'OtherPeople'" mat-dialog-title class="m-0 dialog-title text-center w-100">
    {{'Worked Details'}}
</h2>
<h2 *ngIf="data.type == 'BarChart'" mat-dialog-title class="m-0 dialog-title text-center w-100">
  {{'Spent Hours Details'}}
</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0" *ngIf="data.type != 'TimeMap' && data.type != 'OtherPeople' && data.type != 'BarChart'">
    <div *ngIf="!DetailedUser?.isUserGroup && !DetailedUser?.isSiteGroup && !DetailedUser?.isSite && !DetailedUser?.isProject" class="row mt-3">
        <div class="col-12" style="display: flex;justify-content: center;">
            <img class="profile_image" *ngIf="DetailedUser.creatorProfile" [src]="DetailedUser.creatorProfile" alt="profile">
            <div *ngIf="!DetailedUser.creatorProfile">
                <div [style.background]="getColor(5)"  class="profile_JG">{{getuserFLnames(DetailedUser.creatorFirstName +' '+ DetailedUser.creatorLastName)}}</div>
                </div> 
        </div> 
    </div>
    <div *ngIf="!DetailedUser?.isUserGroup && !DetailedUser?.isSiteGroup && !DetailedUser?.isSite && !DetailedUser?.isProject" class="row  justify-content-md-center justify-content-center">
        <h2 class="name_red">{{ DetailedUser.creatorFirstName |titlecase}} {{ DetailedUser.creatorLastName |titlecase}}</h2>
    </div>
    <div *ngIf="DetailedUser?.isUserGroup || DetailedUser?.isSiteGroup" class="row mt-3">
      <div class="col-12" style="display: flex;justify-content: center;">
        <mat-icon class="mx-2 mb-3" style="font-size: 50px;"
        [ngStyle]="{'color':(!DetailedUser.IsDynamicGroup)?'red':'blue'}">group</mat-icon>
      </div> 
  </div>
  <div *ngIf="DetailedUser?.isUserGroup || DetailedUser?.isSiteGroup" class="row  justify-content-md-center justify-content-center">
    <h2 class="name_red">{{ DetailedUser.groupName |titlecase}}</h2>
</div>
<div *ngIf="DetailedUser?.isSite" class="row mt-3">
  <div class="col-12" style="display: flex;justify-content: center;">
    <img class="mx-2" style="width:50px" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
  </div> 
</div>
<div *ngIf="DetailedUser?.isSite" class="row  justify-content-md-center justify-content-center">
<h2 class="name_red">{{ DetailedUser.siteName |titlecase}}</h2>
</div>
<div *ngIf="DetailedUser?.isProject" class="row mt-3">
  <div class="col-12" style="display: flex;justify-content: center;">
    
  </div> 
</div>
<div *ngIf="DetailedUser?.isProject" class="row  justify-content-md-center justify-content-center">
<h2 class="name_red">{{ DetailedUser.projectName |titlecase}}</h2>
</div>
    <section class="detail-wrapper mb-3">
        <mat-list>
            <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="vertical-bottom" style="color: #6495ed;">schedule</mat-icon>
                        {{'Required Hours'|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer">{{EntryDetails?.totalDuration}}</span>
            </mat-list-item>
            <mat-list-item>
                <div class="fw-600">
                    <mat-icon class="vertical-bottom" style="color: green;">timer</mat-icon>
                    {{'Worked Hours'|translate}} :
                </div>
                <span class="ml-3 fw-600 color_grey m-t-6 pointer">{{EntryDetails?.workedDuration}}</span>
        </mat-list-item>
            <mat-list-item>
                    <div class="fw-600">
                        <mat-icon class="vertical-bottom" style="color: red;">schedule</mat-icon>
                        {{'Overtime Hours'|translate}} :
                    </div>
                    <span class="ml-3 fw-600 color_grey m-t-6 pointer">
                       {{EntryDetails?.overTime ? EntryDetails?.overTime : '0Hrs'}}</span>
            </mat-list-item>
            <!-- <mat-list-item>
                <div class="fw-600">
                    <mat-icon class="vertical-bottom" style="color: red;">calendar_today</mat-icon>
                    {{'Date'|translate}} :
                </div>
                <span class="ml-3 fw-600 color_grey m-t-6 pointer">{{DetailedHours?.[0].created | date:'dd MMM YYYY'}}</span>
            </mat-list-item> -->
        </mat-list>
    </section>
    <div class="row mb-3">
              
        <!-- Timesheet Section -->
        <div class="col-12 m-0 p-0 date-content border_right" >
          <div
          class="row"
          *ngFor="let entryGroup of DetailedHours; let groupIndex = index"
          style="cursor: pointer;"
        >
          <!-- Single Red Border for the Group -->
          <div class="col-12 day-date gray-background">
            <div class="row">
              <!-- Date Column (col-2) -->
              <div
                class="col-2"
                [ngClass]="{ 'red-background': selectedDayIndex === groupIndex }"
                (click)="selectday(groupIndex)"
              >
                <div class="day">{{ entryGroup?.date | date: 'EEE' }}</div> <!-- Example: Mon -->
                <div class="date">{{ entryGroup?.date | date: 'MMM d' }}</div> <!-- Example: Oct 21 -->
              </div>
        
              <!-- Entries Column (col-10) -->
              <div
                class="col-10 hours entry"
                [ngClass]="{ 'red-border': selectedDayIndex === groupIndex }"
              >
                <!-- Display workedDuration Once -->
                <div *ngIf="entryGroup?.entries[0]?.totalDuration != '160 Hrs'" style="width: 20%; font-weight: bold;">
                  {{ entryGroup?.entries[0]?.workedDuration }}
                </div>
                <div *ngIf="entryGroup?.entries[0]?.totalDuration == '160 Hrs'" style="width: 20%; font-weight: bold;">
                  {{getTotalDurationForGroup(entryGroup)}}
                </div>
        
                <!-- Loop through Entries for the Date -->
                <div
                  style="display: flex; justify-content: start; margin: 2px 0;"
                  *ngFor="let entry of entryGroup.entries"
                >
                <div>
                  <!-- Time Off Details -->
                  <div
                    class="row"
                    *ngIf="entry?.isTimeOff && selectedDayIndex === groupIndex"
                  >
                  <div class="row mt-2" [ngStyle]="{'color': 'black', 'background': 'rgb(233 233 233)'}" style="padding:6px;width: -webkit-fill-available;">
                    <div class="col-6 mt-2">
                      Category : <span [ngStyle]="{'color': entry?.categoryFontColor, 'background': entry?.categoryColor}" style="padding:6px;border-radius: 5px;">{{ entry?.categoryName }}</span>
                    </div>
                    <div class="col-6 mt-2">
                      Type : <span style="color:gray">{{entry.isFullDay == true ? 'Full Day' : 'Half Day'}}</span>
                    </div>
                    <div class="col-12 duration mt-2">Description : <span style="color:gray">{{ entry.description  }}</span></div>
                  </div>
                  </div>
                 
                  <!-- Work Hours Details -->
                  <div
                    class="row blue-background details"
                    *ngIf="!entry?.isTimeOff && selectedDayIndex === groupIndex"
                  >
                  <div class="col-9 time">
                    <div class="row mt-2">
                      <div class="col-5  text-center">{{ entry?.startIn12Hours }}</div>
                      <div class="col-1 text-center">-</div>
                      <div class="col-5 text-center">{{ entry?.endIn12Hours }}</div>
                    </div>

                    <div class="row mt-2" *ngIf="entry?.isClockInClockOut">
                      <div class="col-5 text-center">
                        <span>{{ '(' + entry?.startIpAddress + ')' }}</span>
                        <div class="mt-2" *ngIf="entry?.distanceFromStartIP != null" (click)="ShowMapDetails(entry, 'startIp')" [ngClass]="getdistanceFromStartIP(entry?.distanceFromStartIP)">
                          {{ entry?.distanceFromStartIP }}
                        </div>
                      </div>
                      <div class="col-1 text-center"></div>
                      <div class="col-5 text-center">
                        <span>{{ '(' + entry?.stopIpAddress + ')' }}</span>
                        <div class="mt-2" *ngIf="entry?.distanceFromStopIP != null" (click)="ShowMapDetails(entry, 'stopIp')" [ngClass]="getdistanceFromStartIP(entry?.distanceFromStopIP)">
                          {{ entry?.distanceFromStopIP }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3" style="margin:auto">
                    <span>{{ FormatTotalDuration(entry?.duration)}}</span>
                    
                  </div>
                  <div class="col-10 text-center mt-2 mb-3">
                            <span [ngStyle]="{'color': entry?.projectFontColor, 'background': entry?.projectColor}" class="p-2 rounded">
                                {{ entry?.projectName }}
                              </span>
                  </div>
                 
                    <div class="col-12 mt-3" *ngIf="entry?.siteAddress">
                      <div class="d-flex align-items-center">
                        <mat-icon class="mr-2">location_on</mat-icon>
                        <div>
                          <!-- Display site address -->
                          <div class="fw-600">{{ entry?.siteAddress }}</div>
                          
                          <!-- Display site city -->
                          <span class=" fw-600 color_grey">
                            {{ entry?.siteCity || '' }}
                          </span>
                          
                          <!-- Display site country and zip code -->
                          <div>
                            {{ (entry?.siteCountry || '') + ' - ' + (entry?.siteZipCode || '') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
        
        
          
              
        </div>
      </div>
    
</mat-dialog-content>
<mat-dialog-content class="mr-0 ml-0" *ngIf="data.type == 'TimeMap'">
    <!-- map  -->
    <div class="row mt-2 chart-container justify-content-center">
      <div class="col-12">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [zoomControl]="true" (zoomChange)="onZoomChange($event)"
          (mapClick)="onMapClick($event)">
          <agm-marker-cluster imagePath='assets/images/cluster-icons/m' #markerCluster>
            <agm-marker *ngFor="let m of markerEvents; let i = index" style="position: fixed;"
            (markerClick)="clickedMarker(i, m)" [latitude]="m.lat"
              [longitude]="m.lng" [label]="m.label" [markerDraggable]="false" [iconUrl]="iconURL(m)">
            </agm-marker>
          </agm-marker-cluster>
        </agm-map>
      </div>
    </div>                        
</mat-dialog-content>

<mat-dialog-content class="mr-0 ml-0" *ngIf="data.type == 'OtherPeople'">
  <div class="row mt-3 mb-3">
    <div class="col-8">
      <div class="user-info">
        <img *ngIf="UserData.creatorProfile" [src]="UserData.creatorProfile" alt="User Image" class="user-image" />
        <div *ngIf="UserData?.creatorProfile==''||UserData?.creatorProfile==null" [style.background]="getColor(4)"  class="profile_default">{{getuserFLnames(UserData?.creatorFirstName+' '+UserData?.creatorLastName)}}</div>
        <div style="margin-left: 5px;">
          <div class="m-0 user-name" style="font-weight:bold;font-size: 18px;">{{ UserData.creatorFirstName }} {{ UserData.creatorLastName }}</div>
          <div class="user-role" style="font-size: 18px;color: gray;">{{ UserData.creatorPosition }}</div>
        </div>
      </div>
    </div>
    <div class="col-4 text-end">
      <span class="font-style ">
        {{EventData.date | date: 'dd-MMM-YYYY'}}
      </span>
    </div>

  </div>
  <div class="row">
    <div class="col-8">
      <button
      style="color:white;background:rgb(6, 47, 120);border: none; border-radius:20px;padding: 5px 29px">
      <span style="position: relative; left: 3px;">{{'External Default'}}</span></button>
    </div>
    <div class="col-4 text-end">
      <span class="font-style">{{'6h 36m'}}</span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-8">
      <h3 class="fw-600">{{'Required Hours'}}</h3>
    </div>
    <div class="col-4 text-end">
      <span class="font-style">{{'8h 00m'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <h3 class="fw-600">{{'Worked Hours'}}</h3>
    </div>
    <div class="col-4 text-end">
      <span class="font-style">{{'6h 36m'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <h3 class="fw-600">{{'Remaining Hours'}}</h3>
    </div>
    <div class="col-4 text-end">
      <span class="font-style">{{'1h 24m'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <h3 class="fw-600">{{'Overtime Hours'}}</h3>
    </div>
    <div class="col-4 text-end">
      <span class="font-style">{{'0h 00m'}}</span>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-content class="mr-0 ml-0" *ngIf="data.type == 'BarChart'">
  <section class="detail-wrapper mb-3">
    <mat-list>
      <mat-list-item>
        <div class="fw-600">
            <mat-icon class="vertical-bottom" style="color: red;">calendar_today</mat-icon>
            {{'Date'|translate}} :
        </div>
        <span class="ml-3 fw-600 color_grey m-t-6 pointer">{{data.data?.series}}</span>
    </mat-list-item>
        <mat-list-item>
                <div class="fw-600">
                    <mat-icon class="vertical-bottom" style="color: #6495ed;">assignment</mat-icon>
                    {{'Project Name'|translate}} :
                </div>
                <span [ngStyle]="{'color': data.data.fontColor, 'background': data.data.color}" style="padding: 6px; border-radius: 5px;" class="ml-3 fw-600 color_grey m-t-6 pointer">{{data.data?.name}}</span>
        </mat-list-item>
        <mat-list-item>
            <div class="fw-600">
                <mat-icon class="vertical-bottom" style="color: green;">timer</mat-icon>
                {{'Worked Hours'|translate}} :
            </div>
            <span class="ml-3 fw-600 color_grey m-t-6 pointer">{{convertDecimalToDuration(data.data?.value)}}</span>
    </mat-list-item>
       
    </mat-list>
</section>
</mat-dialog-content>
