<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title  class="m-0 dialog-title text-center w-100">
        {{'Time Report Dashboard'}}
    </h2>

    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="DashboardForm">
        <div class="row mt-3" style="background: aliceblue;">
            <div class="col-6 text-center" style="margin:auto">
                <div class="d-flex flex-column align-items-center">
                    <span class="font-weight-bold" style="font-size:20px;">
                        {{ 'Total Time' }}
                    </span>
                    <span class="mt-2 font-weight-bold" style="font-size:20px; color:gray;">
                        {{ '42 Hrs' }}
                    </span>
                </div>
            </div>
            <div class="col-2" style="margin:auto">
            </div>
            <div class="col-4 mt-2" style="margin:auto">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-date-range-input [rangePicker]="dateRangePicker">
                      <input matStartDate placeholder="Start date" formControlName="startDate">
                      <input matEndDate placeholder="End date" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                  </mat-form-field>
            </div>
        </div>
    </form>
   
    <div class="row chart-container">
        <div class="col-12 mb-3">
            <ngx-charts-bar-vertical-stacked [barPadding]="60"  [view]="chartData.graphDimension"
            [customColors]="chartData.customColors"  [results]="chartData.savedBarChartData" [gradient]="false" [xAxis]="true" [yAxis]="true"
                [legend]="true" [legendPosition]="'below'" [showGridLines]="false" [showXAxisLabel]="true" [showYAxisLabel]="true"
                [yAxisLabel]="chartData.yaxisLabelName" [yAxisTickFormatting]="yAxisTickFormatting"
                [xAxisTickFormatting]="xAxisTickFormatting" (select)="onBarClick($event)">
            </ngx-charts-bar-vertical-stacked>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-6">
             <h3 style="font-weight: bold;">1.Project Based Distribution</h3>
            <ngx-charts-pie-chart [view]="ProjectPieChart.graphDimension"  [customColors]="ProjectPieChart.customColors"
            [results]="ProjectPieChart.multipleSaleBar" [legend]="true" [legendPosition]="'below'" [legendTitle]="''"
            [arcWidth]=".5" [doughnut]="true">
            <!-- <ng-template #tooltipTemplate let-model="model">
                <h1>{{model.name|titlecase}}</h1>
                <h2>{{transform(model.value)}}</h2>
            </ng-template> -->
        </ngx-charts-pie-chart>
        </div>
        <div class="col-6" >
            <h3 style="font-weight: bold;">2.Site Based Distribution</h3>
            <ngx-charts-pie-chart [view]="SitePieChart.graphDimension" [scheme]="colorScheme"
            [results]="SitePieChart.multipleSaleBar" [legend]="true" [legendPosition]="'below'" [legendTitle]="''"
            [arcWidth]=".5" [doughnut]="true">
            <!-- <ng-template #tooltipTemplate let-model="model">
                <h1>{{model.name|titlecase}}</h1>
                <h2>{{transform(model.value)}}</h2>
            </ng-template> -->
        </ngx-charts-pie-chart>
        </div>
        
    </div>
    <div class="row mb-3">
      <div class="col-6" >
        <h3 style="font-weight: bold;">3.Site Groups Based Distribution</h3>
        <ngx-charts-pie-chart [view]="SiteGroupsPieChart.graphDimension" [scheme]="colorScheme"
        [results]="SiteGroupsPieChart.multipleSaleBar" [legend]="true" [legendPosition]="'below'" [legendTitle]="''"
        [arcWidth]=".5" [doughnut]="true">
        <!-- <ng-template #tooltipTemplate let-model="model">
            <h1>{{model.name|titlecase}}</h1>
            <h2>{{transform(model.value)}}</h2>
        </ng-template> -->
    </ngx-charts-pie-chart>
    </div>
    </div>

    <div class="row mb-3">
        <div class="team-activity-container">
            <table mat-table *ngIf="dataSource.length > 0" [dataSource]="dataSource" class="table mat-elevation-z8" matSort>
          
              <!-- Team Member Column -->
              <ng-container  matColumnDef="teamMember">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Team Member </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div class="team-member">
                    <img class="avatar" *ngIf="element?.profilepics" [src]="element?.profilepics" alt="">
                    <span *ngIf="!element?.profilepics" [style.background]="getColor(i)"
                        style="color: white; padding: 11px; border-radius: 25%; margin-right: 8px; width: 42px; height: 42px;">
                        {{ getuserFLnames(element.firstName + ' ' + element.lastName) }}
                    </span>
                    <span style="font-size: 16px;">{{ element.firstName + ' ' + element.lastName}}</span>
                  </div>
                </td>
              </ng-container>
          
              <!-- Latest Activity Column -->
              <ng-container matColumnDef="latestActivity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Latest Activity </th>
                <td mat-cell *matCellDef="let element">{{ element.descripition }}</td>
              </ng-container>

              <!-- <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
              </ng-container> -->
          
              <!-- Total Tracked Column -->
              <ng-container matColumnDef="totalTracked">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Tracked </th>
                <td mat-cell *matCellDef="let element">
                    <div class="progress-container">
                        <div class="custom-progress-bar">
                            <div
                            *ngFor="let project of element.wordKedDetails"
                            class="progress-segment"
                            [style.background-color]="project.color"
                            [style.width.%]="project.workedHoursinPercent"
                            matTooltip="{{ project.projectName }}: {{ project.workedHours }}">
                          </div>
                          <div 
                            class="progress-segment empty-segment"
                            [style.width.%]="100 - element.workedHoursinPercent">
                          </div>
                        </div>
                        <span>{{ element.totalWorkedHours }}</span>
                      </div>
                </td>
              </ng-container>
          
              <!-- Header and Row Definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
          </div>
          
    </div>
</mat-dialog-content>
