import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ManageAutomationService } from 'src/app/core/services/manage-automation.service';
import { UserDetailsComponent } from '../../../manage-users/user-details/user-details.component';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';

@Component({
  selector: 'app-accordian-view',
  templateUrl: './accordian-view.component.html',
  styleUrls: ['./accordian-view.component.scss']
})
export class AccordianViewComponent implements OnInit {

  itemsList: string[] = ['FirstName', 'LastName', 'Gender', 'Position', 'PhoneNumber', 'Email', 'Address', 'City', 'Country', 'ZipCode', 'Created'];
  EntityName: string = '';
  ColoumnList: Object;

  private entityMapping: { [key: string]: string } = {
    'Create User': 'User',
    'Create Contact': 'Contact',
    'Create Site': 'Site',
    'Create Product': 'Product',
    'Create Transaction': 'Transaction',
    'Create Order': 'OrderQuote',
    'Create Task': 'Task',
    'Create Initiative': 'Initiative',
    'Send Email': 'Send Email',
    'Create Event': 'Create Event'
  };
  getuser: Object;
  entitycolumnName: string;

  constructor(
    public dialogRef: MatDialogRef<AccordianViewComponent>,
    private dialog: MatDialog,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.setEntityName();
   }

  ngOnInit(): void {
    console.log(this.data)
    if(this.data.type == 'ViewUsers') {
      this.getUserList()
    }
    this.setEntityName()
    this.getColoumns()
  }

  getUserList(){
    this.injector.get(ManageAutomationService).getUsersByID(this.data.data).subscribe(data => {
      console.log(data)
      this.getuser = data
    })
  }

  ShowUserDEtails(row:any, type: string): void {
    this.dialog.open(UserDetailsComponent, {
      data: { data: row, type: type, },
      disableClose: true,
      width: '500px',
      position: { top: '125px', left: '700px' },
    },);
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  setEntityName() {
    if (this.data.ParentEntity === 'Send Mail') {
      this.EntityName = this.entityMapping[this.data.beforeParentEntity] || this.data.mappingEntity;
    } else {
      this.EntityName = this.entityMapping[this.data.ParentEntity] || this.entityMapping[this.data.beforeParentEntity] || this.data.mappingEntity;
    }
  }

  getColoumns(){
    this.entitycolumnName = this.EntityName == 'OrderQuote' ? 'Order' : this.EntityName;
      this.injector.get(ManageAutomationService).getEntityColoumns(this.entitycolumnName).subscribe((data) => {
        if (data) {
          this.ColoumnList = data
          console.log(data);
        }
      });
  }

  selectItem(item: string): void {
    this.dialogRef.close(this.EntityName === 'Task' ? 'TaskTable.' + item : this.EntityName + '.' + item);
  }

}
