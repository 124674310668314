import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {
  Categorydetails
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CategoryDetailsComponent>,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.injector.get(ManageUsersService).GetCategorieById(this.data.details.id).subscribe((result)=>{
      if(result){
    console.log(result)
      this.Categorydetails=result
      }
    }) 
    console.log(this.data)
  }

}
