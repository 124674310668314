<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        {{'Time Off'|translate}}
    </h2>
    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreMenu="matMenu" xPosition="before">
      <button *ngIf="(AdminStatus || ManagerStatus)" mat-menu-item (click)="openTimeTrack()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/punch_clock.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Track' | translate }}</strong></span>
      </button>
      <button mat-menu-item (click)="openTimeSheet()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/overview.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Sheet' | translate }}</strong></span>
      </button>
      <button *ngIf="(AdminStatus || ManagerStatus)" mat-menu-item (click)="openManagerTime()">
        <img class="font-icon" src="../../../../assets/images/iconsForsvg/insert_chart.svg">
        <span class="ion-text-capitalize"><strong>{{ 'Time Report' | translate }}</strong></span>
      </button>
    </mat-menu>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <mat-tab-group mat-align-tabs="center" class="no-bg" (selectedTabChange)="tabChange($event.index)">
        <mat-tab *ngIf="(AdminStatus || ManagerStatus)">
            <ng-template mat-tab-label>
                <div class="tabclas">
                    <span>{{'Category'|translate}}</span>
                </div>
            </ng-template>
            <div>
                <form [formGroup]="categoriesForm" id="categories-form">
                    <div style="float: right"><button mat-raised-button (click)="createCat()" class="b-blue">{{'Create'|translate}}</button></div>
                    <div class="w-100 mb-3">
                      <mat-list>
                        <div mat-subheader style="font-weight: bold;color: black;">{{'List of Categories'|translate}}</div> <br>
                        <mat-list-item *ngFor="let categroy of categories">
                          <div mat-line>
                            <button [style.background]="categroy.color" [style.color]="categroy.fontColor"
                              style="border: none; border-radius:20px;padding: 5px 29px">
                              <span style="position: relative; left: 3px;">{{categroy.categoryName}}</span></button>
                          </div>
                
                          <button *ngIf="categroy.id != null" mat-icon-button>
                            <mat-icon (click)="editCategory(categroy)">edit</mat-icon> 
                          </button>
                          <button *ngIf="categroy.id != null" mat-icon-button>
                            <mat-icon (click)="deletecategroy(categroy,'deletecategroy')">delete</mat-icon>
                          </button>
                
                        </mat-list-item>
                      </mat-list>
                    </div>
                
                    <mat-form-field appearance="legacy" class="w-100" *ngIf="categoryOption === '1'">
                      <mat-label>{{'Category Name'|translate}}<span class="danger"> * </span></mat-label>
                      <input matInput type="text" placeholder="Category Name " formControlName="categoryName">
                    </mat-form-field>
                
                    <div class="col-md-5" *ngIf="categoryOption === '1'">
                      <mat-label>{{'Category Color'|translate}} <span class="danger"> * </span></mat-label>
                      <input [style.background]="color1" [(colorPicker)]="color1"
                        (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                        (colorPickerClose)="onEventLog('colorPickerClose', $event)" />
                    </div>
                  </form>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tabclas">
                    <span>{{'Request'|translate}}</span>
                </div>
            </ng-template>
        
        <div>
          <form [formGroup]="requestForm">
            <div class="row mt-3">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <label for="fromDate" class="required">From</label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput [matDatepicker]="fromPicker" formControlName="fromDate" id="fromDate" placeholder="mm/dd/yyyy">
                  <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                  <mat-datepicker #fromPicker></mat-datepicker>
                </mat-form-field>
              </div>
          
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <label for="toDate" class="required">To</label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput [matDatepicker]="toPicker" formControlName="toDate" id="toDate" placeholder="mm/dd/yyyy">
                  <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                  <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style="margin:auto">
                <mat-radio-group formControlName="isHalfDay" (change)="getStatus($event.value)">
                  <mat-radio-button [value]="false">
                    {{ 'Full Day' | translate }}</mat-radio-button>
                  <mat-radio-button [value]="true">
                    {{ 'Half Day' | translate }}</mat-radio-button>
                </mat-radio-group>
              </div>
          
              <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <label for="category" class="required">Time Off Category</label>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select formControlName="category" id="category" placeholder="-Select-">
                    <mat-option *ngFor="let x of categories" [value]="x.id">
                      <span [ngStyle]="{'color': x.fontColor, 'background': x.color}" style="padding:6px;border-radius: 5px;"> {{ x.categoryName }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label for="note">Note</label>
                <mat-form-field appearance="outline" class="w-100">
                  <textarea matInput formControlName="note" id="note" rows="3" placeholder="Enter note"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
          
        </div>
        <div class="col-12 mb-3" style="text-align:end">
            <button mat-raised-button (click)="addrequest()" [ngClass]="!requestForm.valid? 'g-blue':'b-blue'"[disabled]="!requestForm.valid"
            >{{"Add Request"|translate}}</button>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="tabclas">
                <span>{{'Approvals'|translate}}</span>
            </div>
        </ng-template>
        <div>
            <div class="container">
                <mat-card>
                  <mat-list *ngIf="PendingRequsts?.length > 0">
                    <mat-list-item *ngFor="let request of PendingRequsts; let i = index" class="vacation-request-item">
                      <div mat-line>
                        <b>{{ request.creatorFirstName }} {{request.creatorLastName}}</b> - {{ request.created  | date: 'dd-MMM-YYY'}}
                      </div>
                      <div mat-line>{{ request.description }}</div>
                      <span mat-list-avatar class="avatar" *ngIf="request.creatorProfile != null">
                        <img [src]="request.creatorProfile" style="width:35px;height:35px;margin-top:-5px">
                      </span>
                      <span mat-list-avatar class="avatar" *ngIf="request.creatorProfile == null"
                       [style.background]="getColor(i)"
                        style="color: white; padding: 10px;border-radius: 50%;position: relative;margin: 5px;">{{getuserFLnames(request.creatorFirstName+' '+request.creatorLastName)}}</span> 
                      <button *ngIf="(AdminStatus || ManagerStatus) && request.status == 'Pending'" mat-button class="approve-button mx-2" (click)="approveRequest(request)">
                        <img src="../../../../assets/images/iconsForsvg/approve_time.svg">
                      </button>
                      <button *ngIf="(AdminStatus || ManagerStatus) && request.status == 'Pending'" mat-button class="deny-button" (click)="denyRequest(request)">
                        <img src="../../../../assets/images/iconsForsvg/deny_time.svg">
                      </button>
                      <p *ngIf="(!AdminStatus && !ManagerStatus) && request.status == 'Pending'" style="margin:auto;font-weight:bold">
                        <img src="../../../../assets/images/iconsForsvg/waiting_approval.svg" style="margin-right:5px">Waiting for Approval</p>
                        <p *ngIf="request.status == 'Denied'" style="margin:auto;font-weight:bold">
                          <img src="../../../../assets/images/img/deactive.png" style="margin-right:5px;width:25px;height:25px">Approval Denied</p>
                    </mat-list-item>
                  </mat-list>
                  <mat-list *ngIf="PendingRequsts?.length == 0">
                    <mat-list-item>
                      <div class="row">
                        <div class="col-12">
                          <span style="font-size: 20px;font-weight: bold;">No Pending Requests Available</span>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                  <mat-card-footer>
                    <mat-paginator [pageSize]="5" [length]="totalItems" (page)="next($event)"></mat-paginator>
                  </mat-card-footer>
                </mat-card>
              </div>                  
        </div> 
    </mat-tab>
    </mat-tab-group>
</mat-dialog-content>