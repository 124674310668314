import { Component, Inject, Injector, OnInit } from '@angular/core';
import { CreatePriceMatrixComponent } from '../create-price-matrix/create-price-matrix.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ManageProductsService } from '../../../../../app/core/services/manage-products.service';

@Component({
  selector: 'app-price-matrix',
  templateUrl: './price-matrix.component.html',
  styleUrls: ['./price-matrix.component.scss']
})
export class PriceMatrixComponent implements OnInit {
pricematrixlist:any=[]
timematrixlist:any=[]
PriceMatrixstatus
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
   private injector: Injector,

  ) { }

  ngOnInit(): void {
    console.log(this.data.data)
    this.PriceMatrixstatus=this.data.data.PriceMatrixstatus
    if(this.PriceMatrixstatus==true){
      this.getpricematricedetails(this.data.data.id)
    }else{
      this.FindTimeMatrixConditions()
    }
  }




  getpricematricedetails(id:any){
    this.injector.get(ManageProductsService).FindProductMatrixConditionsByProductId(id).subscribe((result)=>{
      if(result){
        this.pricematrixlist=result
        console.log(result)
      }
    })

  }
  
  FindTimeMatrixConditions(){
    this.injector.get(ManageProductsService).FindSettings().subscribe((result)=>{
      if(result){
        this.timematrixlist=result.timeMatrixDtos
        console.log(result)
      }
    })

  }










  addpricematrix(screenType:any){
    const Createdynamickeywords=this.dialog.open(CreatePriceMatrixComponent, {
      width: '550px',
      data: { screenType:screenType,data:this.data },
      autoFocus: false,
      disableClose: true,
    });

    Createdynamickeywords.afterClosed().subscribe(result => {
      if (result) {
        if(this.PriceMatrixstatus==true){
          this.getpricematricedetails(this.data.data.id)
        }else{
          this.FindTimeMatrixConditions()
        }
        console.log(result)
      }
    });
  } 

}
