import { Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmailSettingComponent } from './email-setting/email-setting.component';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { SyncOptionsListComponent } from 'src/app/core/components/sync-options-list/sync-options-list.component';
import { EmailPopupsComponent } from '../dashboard/email-popups/email-popups.component';
import { CreateTaskComponent } from '../management/manage-tasks/create-task/create-task.component';
import { CreateEventComponent } from '../calendar/create-event/create-event.component';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver'; 
import { EventPopUpDialog } from 'src/app/core/components/event-lists/event-lists.component';
import { ViewTaskComponent } from '../management/manage-tasks/view-task/view-task.component';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { ResizeService } from 'src/app/core/services/resize.service';
import { MatSidenav } from '@angular/material/sidenav';
import { DeleteCommomComponent } from '../commonForAll/delete-commom/delete-commom.component';
import { CreateInitiativesComponent } from '../management/management-initiatives/create-initiatives/create-initiatives.component';
import { CheckScreenWidthPercentageService } from 'src/app/core/services/check-screen-width-percentage.service';
import { SearchfilterlistComponent } from './searchfilterlist/searchfilterlist.component';
import { ActivitLogComponent } from '../management/activit-log/activit-log.component';
import { interval, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {EmailOptionsService} from '../../../app/core/services/email-options.service'

@Component({
  selector: 'app-emaildashboard',
  templateUrl: './emaildashboard.component.html',
  styleUrls: ['./emaildashboard.component.scss'],
})
export class EmaildashboardComponent implements OnInit {
  @ViewChild('myIframe') myIframe!: ElementRef;
  mailids=[]
  date=new Date()
  dropdownstatus='group'
  allmails=[]
  timerIntervals: any[] = [];  // To store intervals for each event
  mailbody=null
  sidemenusection='Inbox'
  SyncAccDetails:any;
  SyncAccDetails1:any;
  seletedmails=[]
  color = 'primary';
  // mode = 'indeterminate';
  value = 10;
  spinner=false
  mailLength=100;
  categorieslist=[]
  Adminstatus=false;
  MailsSettings=false
  AccountForm: FormGroup;
  isMobileView!: boolean;
  mailbodyview:boolean=false;
  mailListview:boolean=true;
  size: any;
  width!: number;
  @ViewChild('drawer', { static: false })
  drawer!: MatSidenav;
  panelOpenState = false;
  mode!: string;
  opened!: boolean;
  toggleSidenav() {
    this.opened = !this.opened;
  }
  pageno:any=1
  @ViewChild('templateContainer', { static: true }) templateContainer!: ElementRef;
  Form: FormGroup;
  futureDate: string = '2024-12-19T23:59:59'; 

  constructor(
    private matDialogRef: MatDialogRef<EmaildashboardComponent>,
    private dialog: MatDialog,
    private GoogleApiService:GoogleApiService,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private emailOptionsService: EmailOptionsService
  ) { 
    this.AccountForm = this.formBuilder.group({
      AccountList: [],
    });
    this.Form = this.formBuilder.group({
      SearchValue: [''],
    })
  }
  ngOnInit(): void {

    this.injector.get(ResizeService).size$.subscribe((user) => {
      this.size = user.size;
      if (this.size < 992) {
        this.opened = false;
        this.mode = 'over';
        this.isMobileView = true;
      } else {
        this.opened = true;
        this.mode = 'side';
        this.isMobileView = false;
      }
    });
    this.width = window.innerWidth;
    if (!this.size && this.width < 992) {
      this.opened = false;
      this.mode = 'over';
      this.isMobileView = true;
    } else if (!this.size && this.width > 992) {
      this.opened = true;
      this.mode = 'side';
      this.isMobileView = false;
    }
    this.Adminstatus = sessionStorage.getItem('isAdmin')=='true'?true:false;
    this.MailsSettings=sessionStorage.getItem('MailsSettings')=='true'?true:false
    if(this.allmails.length==0){
       this.spinner=true;
    }else{
       this.spinner=false;
    }
    this.getallCategory()
    this.GetSyncAccDetails()
  }

  ngOnDestroy(): void {
    // Clear all intervals to avoid memory leaks
    this.timerIntervals.forEach(interval => clearInterval(interval));
  }

  getallCategory(){
    this.injector.get(ManageUsersService).GetAllCategories().subscribe((result:any)=>{
      if(result){
        this.categorieslist=result
      }
    })
  }

  getseleteduserscount(){
     return this.AccountForm.get('AccountList').value.length
  }

  GetSyncAccDetails(){
    this.spinner=true
    this.GoogleApiService.GetAllSynchMailAccounts().subscribe((result:any)=>{
      if(result){
        this.spinner=false
        this.mailids=result
        console.log(result)
        this.AccountForm.get('AccountList').setValue([result[0]])
        this.seletedmails.push(result[0])
        this.getmails(10,'')

      }
    },error=>{
      this.spinner=false
    })
  } 
  onSearch(){
    console.log(this.Form.value.SearchValue)
    this.getmails(10,'')
  }

  onSearchfilterlist(){
    const CreateTask = this.dialog.open(SearchfilterlistComponent, {
      width: '800px',
      position: { top: '125px',  },
      autoFocus: false,
      data: { type: 'SearchfilterlistC' },
      disableClose: true,
      
    },);

    CreateTask.afterClosed().subscribe((result) => {
      if(result){
    
      }
    });
  }

  gethoverclass(x){
    if(this.mailbody){
      if(x.isUnReaded){
        return 'normalclass'
      }else if(!x.isUnReaded){
        return 'Readedclass'
      }
    }
  }

  selectChange(event){
    console.log(event)
    console.log(event.value)
    this.seletedmails=event.value
    this.getmails(10,'')
  }
  getfiletype(name:any){
    if(name!=null){
    return name.split('.')[1]
    }else{
      return ' No Name'
    }
  }
  refreshpage(){
    this.getmails(10,'')
  }

  getmails(maxResults,pageToken){
    var body:any
    body= {
      "search":this.Form.value.SearchValue,
      "label": this.sidemenusection.toUpperCase(),
      "maxResults": maxResults,
      "pageno":this.pageno,
      "selectedAccounts": []
    }
    this.seletedmails.forEach(element => {
      body.selectedAccounts.push(
        {
          "id": element.id,
          "pageToken": pageToken,
          "isCollabrative": element.isCollabrative,
          "isGmail": element.isGmail
        }
      )
    });
    this.getmailapicall(body)
  }

  getmailid(mail){
    let value=0
    this.mailids.forEach(element => {
      if(element.email==mail){
        value=element.id
      }
    });
    return value
  }

  getmailapicall(body){
    this.spinner=true
    this.GoogleApiService.GetAllMails(body).subscribe((result:any)=>{
      if(result){
        if(result?.emails.length!=0){
          this.allmails=result.emails
          this.mailLength=result?.totalCount
          if(this.sidemenusection=='Schedule'){
            this.startCountdowns();
          }
        }else{
          this.allmails=[]
        }
        this.spinner=false
      }
    },error=>{
      this.spinner=false
    })
  }
    // Method to start countdown for each event
    startCountdowns(): void {
      this.allmails.forEach((event, index) => {
        this.timerIntervals[index] = setInterval(() => {
          const timeRemaining = this.getTimeRemaining(event.datetime);
          this.allmails[index].countdown = timeRemaining;  // Update the event's countdown data
  
          if (timeRemaining.finished) {
            clearInterval(this.timerIntervals[index]);  // Stop the countdown when finished
          }
        }, 1000);  // Update every second
      });
    }

  siddemenu(value){
    if(this.sidemenusection==value){
      return 'siddemenucolor'
    }else{
      return 'siddemenu'
    }
  } 

  sidemenuclick(value){
    this.pageno=1
    this.mailLength=0
    this.sidemenusection=value
    this.mailbody=null
    if(this.seletedmails.length!=0){
      this.getmails(10,'')
    }else{
      this.getmailapicall(
        {
          "search":this.Form.value.SearchValue,
          "label": this.sidemenusection.toUpperCase(),
          "pageno":this.pageno,
          "maxResults": 10,
        }
      )
    }
  }

  CreateTask(x){
    const CreateTask = this.dialog.open(CreateTaskComponent, {
      width: '500px',
      autoFocus: false,
      data: {data: x, type: 'Createtask_with_mail' },
      disableClose: true,
    });

    CreateTask.afterClosed().subscribe((result) => {
      if(result){
        this.mailbody=null
        this.getmails(10,'')
      }
    });
  }
  CreateEvent(x){
    const CreateTask = this.dialog.open(CreateEventComponent, {
      width: '500px',
      autoFocus: false,
      data: {data:x, screenType: 'CreateEvent_with_mail' },
      disableClose: true,
    });

    CreateTask.afterClosed().subscribe((result) => {
        if(result){
          this.mailbody=null
          this.getmails(10,'')
        }
    });
  }
  CreateInitiative(x){
    const CreateTask = this.dialog.open(CreateInitiativesComponent, {
      width: this.injector.get(CheckScreenWidthPercentageService).screenpercentage(),
      autoFocus: false,
      data: {data:x, type: 'CreateInitiative_with_mail' },
      disableClose: true,
    });

    CreateTask.afterClosed().subscribe((result) => {
        if(result){
          this.mailbody=null
          this.getmails(10,'')
        }
    });
  }

  nextpage(e: any) {
    console.log(e)
    this.pageno=e.pageIndex + 1
    this.getmails(10,'')

  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  }

  getlastindex(number){
    let lastIndex = number.lastIndex()
  }


  clickingsetting(){
    const emailsetting = this.dialog.open(EmailSettingComponent, {
      width: '99%',
      autoFocus: false,
      disableClose: true,
    });

    emailsetting.afterClosed().subscribe((result) => {

    });
  }

  showmailbody(){
    if(this.sidemenusection=='Inbox'||this.sidemenusection=='Sent'){
      if(this.mailListview&&this.allmails.length!=0){
        this.mailListview=false;
        this.mailbodyview=true;
      }
    }
  }

  gettomail(input){
    const match = input.match(/<([^>]+)>/);
    return match[1]
  }

  getmailbody(value){
    if(this.sidemenusection=='Inbox'||this.sidemenusection=='Sent'){
      this.mailbody=null
      this.GoogleApiService.GetMailDtlById(value?.accountId,this.sidemenusection.toLocaleUpperCase(),value?.id,value?.isGmail).subscribe((result:any)=>{
        if(result){
          this.mailbody=result
        }
      })
    }else if(this.sidemenusection=='Schedule'||this.sidemenusection=='Draft'){
      if(this.sidemenusection=='Schedule'){
        this.GoogleApiService.GetScheduledEmailById(value?.id).subscribe((result:any)=>{
          if(result){
            console.log(result)
          }
        })
      }else if(this.sidemenusection=='Draft'){
        this.GoogleApiService.GetDraftEmailById(value?.id).subscribe((result:any)=>{
          if(result){
            console.log(result)
            this.dialog.open(EmailPopupsComponent, {
              width: '600px',
              data:{data:result,screentype:'editDraft'},
              autoFocus: false,
              disableClose: true,
            });
          }
        })
      }
    }

  }

  backarrow(){
    this.allmails.forEach(element => {
      if(element.id==this.mailbody.id){
        element.isUnReaded=true
      }
    });
    this.mailListview=true;
    this.mailbodyview=false;
  }

  ConverttoContact(value){
    console.log(value)
    this.GoogleApiService.CreateContactFromMail(value?.accountId,value?.id,value?.isGmail).subscribe((result)=>{
      if(result){
        console.log(result)
        this.snackBar.open('Create Contact From Mail done', '', { duration: 2000, })
      }
    },error=>{
      if(error.status==200){
        this.snackBar.open('Create Contact From Mail done', '', { duration: 2000, })
      }
    })
  } 

  syncaccount(){
    this.dialog.open(SyncOptionsListComponent, {
      data:{screentype:'twooptions'},
      autoFocus: false,
      disableClose: true,
    });
  }

  Composemail(){
    console.log(this.seletedmails)
    if(this.seletedmails.length!=0){
      this.dialog.open(EmailPopupsComponent, {
        width: '600px',
        data:{seletedmails:this.seletedmails,screentype:'Composemail'},
        autoFocus: false,
        disableClose: true,
      });
    }
  }
  Replyemail(data){
    this.EmailPopup(data,'Replyemail','600px');
    
  } 
  ReplyAllemail(data){
    this.EmailPopup(data,'ReplyAllemail','600px')
  } 
  Forwardemail(data){
    this.EmailPopup(data,'Forwardemail','600px')
  }

  deletemail(data){
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: 'deleteMail' },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.getmails(10,'')
    });
  }

  Markspammail(data){
    const Markspammail = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: 'Markspammail' },
    });
    Markspammail.afterClosed().subscribe((result: any) => {
      this.getmails(10,'')
    });
  }
  BlockEmail(data){
    const Markspammail = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: 'BlockEmail' },
    });
    Markspammail.afterClosed().subscribe((result: any) => {
      this.getmails(10,'')
    });
  }


  Markasunread(data){
    const Markspammail = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: 'Markasunread' },
    });
    Markspammail.afterClosed().subscribe((result: any) => {
      this.getmails(10,'')
    });
  }

  showdropdownstatus(value){
    this.dropdownstatus=value
  }

  lablecolor(value){
    if(value?.isSocial){
     return 'Socialstyle'
    }else if(value?.isPromotions){
      return 'Promotionalstyle'
    }
    // else if(value?.isUnReaded){
    //   return 'Healthstyle'
    // }
  }
  lablename(value){
    if(value?.isSocial){
     return 'Social'
    }else if(value?.isPromotions){
      return 'Promotion'
    }
  }

  closepopup(){
    this.matDialogRef.close()
  }


downloadmailmessage(subject) {
// Get the HTML content of the 'printSection'
const printContents = document.getElementById('printSection')?.innerHTML;
const originalContents = document.body.innerHTML;
// Create the blob with the content you want to download
const blob = new Blob([printContents], { type: 'text/html' });
// Use FileSaver.js library to save the blob as 'body.html'
saveAs(blob,   `${subject}.html`);
}

printmailmessage(){
  const printContents = document.getElementById('printSection')?.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents || '';
    window.print();
    document.body.innerHTML = originalContents;
    
}



       showevent(data){
        console.log(data)
        this.GoogleApiService.FindEventConvertorFromMail(data.eventId).subscribe((result)=>{
          if(result){
            console.log(result)
         this.EmailPopup(result,'EventDetails','500px')
          }
        })
       }
       showtask(data){
        console.log(data)
        this.GoogleApiService.FindTaskConvertorFromMail(data.taskId).subscribe((result)=>{
          if(result){
            console.log(result)
         this.EmailPopup(result,'TaskDetails','500px')
          }
        })
       }
       showInitiative(data){
        console.log(data)
        this.GoogleApiService.FindInitiativeConvertorFromMail(data.initiativeId).subscribe((result)=>{
          if(result){
            console.log(result)
         this.EmailPopup(result,'InitiativeDetails','500px')
          }
        })
       }
       showgroupinUser(data){
        console.log(data)
         this.EmailPopup(data,'groupUserDetails','500px')
       }

       EmailPopup(data: any,screentype,width) {
        this.dialog.open(EmailPopupsComponent, {
          width: width,
          data:{maildata:data,screentype:screentype},
          autoFocus: false,
          disableClose: true,
        });
      }

      Activitylogs(data){
        const activitydialog = this.dialog.open(ActivitLogComponent, {
          width: '600px',
          data: { type: 'Email', data:data, Entity: 'Email' },
          autoFocus: false,
          disableClose: true,
        });
        activitydialog.afterClosed().subscribe(result => {
          if(result){
          }
        })
      }


      httpGet(theUrl:any) {
        var xmlHttp:any= null;
        xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', theUrl, false);
        xmlHttp.onreadystatechange = function () {
          if (xmlHttp.readyState === 4) {
            if (xmlHttp.status === 200 || xmlHttp.status == 0) {
              this.allText = xmlHttp.responseText;
              this.doc = new DOMParser().parseFromString(this.allText, 'text/html')
            }
          }
        }
        xmlHttp.send(null);
        // setTimeout(() => {
        //   this.scaleTemplateToFit();
        // }, 100);
        return xmlHttp.responseText;
      }





  // Method to calculate time remaining for each event
  getTimeRemaining(futureDate: string): any {
    const now = new Date().getTime();
    const targetDate = new Date(futureDate).getTime();
    const timeLeft = targetDate - now;

    if (timeLeft <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        finished: true
      };
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    return {
      days,
      hours,
      minutes,
      seconds,
      finished: false
    };
  }

  isActionEnabled(action: any): boolean {
    return this.emailOptionsService.checkStatus(action, this.sidemenusection);
  }
  Cancelschedule(x){
    this.GoogleApiService.cancelScheduledEmail(x.id).subscribe((result)=>{
      if(result){
        this.snackBar.open('cancel Scheduled Email done', 'Undo', { duration: 2000 });
        this.getmails(10,'')
      }
    },error=>{
      this.snackBar.open('cancel Scheduled Email faild', 'Undo', { duration: 2000 });
    })
  }

  
  editSchedule(data){
    this.GoogleApiService.GetScheduledEmailById(data?.id).subscribe((result:any)=>{
      if(result){
        
        console.log(result)
        this.dialog.open(EmailPopupsComponent, {
          width: '600px',
          data:{data:result,screentype:'editSchedule'},
          autoFocus: false,
          disableClose: true,
        });
      }
    })    
  }

}
