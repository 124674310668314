<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title  class="m-0 dialog-title text-center w-100">
      Schedule Date and Time
    </h2> 
    <mat-icon mat-dialog-close>close</mat-icon>
</div> 

<mat-dialog-content class="mr-0 ml-0">

<ng-container>
  <div>
    <div class="row mt-2 mb-2">
      <div class="col-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>{{"Schedule Date"|translate}}</mat-label>
          <input matInput [matDatepicker]="enddatepicker1" style="color: black;" [(ngModel)]="selectedDate" (ngModelChange)="onDateChange()">
          <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
          <mat-datepicker #enddatepicker1></mat-datepicker>
      </mat-form-field>
      </div>
      <div class="col-4">
        <input type="time" id="time-picker" [(ngModel)]="selectedTime" (ngModelChange)="onTimeChange()" />
      </div>
    </div>
    <div class="row">
      <p [innerHTML]="selectedDateTime"></p>
    </div>

    <div class="row mt-2 mb-4 justify-content-end">
      <div class="col-3">
        <button mat-raised-button class="b-blue" (click)="AddSchedule()">{{"ADD"|translate}}</button>

      </div>
    </div>
  </div>
</ng-container>

      
      
      
</mat-dialog-content>