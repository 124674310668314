<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span *ngIf="screenType == 'CreateSite'">
            <img
                src="../../../../../assets/images/iconsForsvg/Site_Icon_white.svg">
            {{'Create Site'|translate}}</span>
        <span *ngIf="screenType == 'UpdateSite' || screenType == 'EditSiteAutomation'">
            <img 
                src="../../../../../assets/images/iconsForsvg/Site_Icon_white.svg">
            {{'Update Site'|translate}}</span>

    </h2>
    <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
    <mat-menu #menu="matMenu">
        <button class="fw-600" (click)=" importdata()" mat-menu-item> <mat-icon>
                import_export</mat-icon>{{"Import Data"|translate}}</button>
        <button class="fw-600" (click)="downloadTemplate()" mat-menu-item><mat-icon
                style="vertical-align: middle">download</mat-icon> {{"Download Template"|translate}}</button>
    </mat-menu>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="siteForm">
   
       <div class="row w-100 mt-2">
        <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label (dblclick)="onDoubleClick('CompanyName')">{{getlable('CompanyName')|translate}} <span *ngIf="isFieldMandatory('CompanyName')" style="color: red;">*</span></mat-label>
                <input matInput type="text" 
                    formControlName="CompanyName" (click)="openPopup('CompanyName')">
                <mat-error
                    *ngIf=" siteForm.controls['CompanyName'].hasError('required')">
                    {{getlable('CompanyName')|translate}} is <strong>{{"required"|translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>
       </div>
    <div class="row w-100">
        <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label (dblclick)="onDoubleClick('ActivityName')">{{getlable('ActivityName')|translate}} <span *ngIf="isFieldMandatory('ActivityName')" style="color: red;">*</span></mat-label>
                <input matInput type="text" 
                    formControlName="ActivityName" (click)="openPopup('ActivityName')">
                <mat-error
                    *ngIf=" siteForm.controls['ActivityName'].hasError('required')">
                    {{getlable('ActivityName')|translate}} is <strong>{{"required"|translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

  <div class="row w-100">
    <div class="col-12">
        <mat-form-field class="w-100">
            <mat-label (dblclick)="onDoubleClick('ContactPhone')">{{getlable('ContactPhone')|translate}} <span *ngIf="isFieldMandatory('ContactPhone')" style="color: red;">*</span></mat-label>
            <input matInput type="text"   formControlName="ContactPhone" (click)="openPopup('ContactPhone')">
                <mat-error
                *ngIf=" siteForm.controls['ContactPhone'].hasError('required')">
                {{getlable('ContactPhone')|translate}} is <strong>{{"required"|translate}}</strong>
            </mat-error>
        </mat-form-field>
    </div>
  </div>

     <div class="row w-100">
        <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Contact'|translate}} <span *ngIf="isFieldMandatory('ContactsId')" style="color: red;">*</span></mat-label>
            <mat-select (openedChange)="filterOptions('','Contacts')" #select multiple formControlName="ContactsId">
                <input style="padding: 15px;" matInput (keyup)="filterOptions($event.target.value,'Contacts')" placeholder="Filter" />
                <mat-option *ngFor="let contact of filteredcontacts" [value]="contact.id">
                <img style="border-radius: 50%; margin-right: 6px;" width="30" height="30"
                    [src]="contact?.profilePicture ? contact.profilePicture:'assets/avatar.svg'"
                    onError="this.src='assets/avatar.svg'">
                {{ contact.firstName|titlecase }} {{contact.lastName|titlecase}}
            </mat-option>
        </mat-select>
        <mat-error
        *ngIf=" siteForm.controls['ContactsId'].hasError('required')">
        {{"Contact"|translate}} is <strong>{{"required"|translate}}</strong>
    </mat-error>
    </mat-form-field>
        </div>
     </div>

        <!-- ---------- / address  -->
    <div class="row w-100">
        <div class="col-12">
            <mat-form-field class="w-100">
                <mat-label (dblclick)="onDoubleClick('Address')">{{getlable('Address')|translate}} <span *ngIf="isFieldMandatory('Address')" style="color: red;">*</span></mat-label>
                <input ngx-google-places-autocomplete [options]='options'
                    #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" matInput
                    type="text" 
                    formControlName="Address" (click)="openPopup('Address')">
                <mat-error *ngIf=" siteForm.controls['Address'].hasError('required')">
                    {{getlable('Address')|translate}} is <strong>{{"required"|translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

        <div class="row countryCityZipCode" >
            <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                <mat-form-field class="w-100">
                    <mat-label (dblclick)="onDoubleClick('Country')">{{getlable('Country')|translate}} <span *ngIf="isFieldMandatory('Country')" style="color: red;">*</span></mat-label>
                    <input matInput type="text" formControlName="Country" (click)="openPopup('Country')">
                    <mat-error
                        *ngIf="siteForm.controls['Country'].hasError('required')">
                        {{getlable('Country')|translate}} is <strong>{{'required'|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                <mat-form-field class="w-100">
                    <mat-label (dblclick)="onDoubleClick('City')">{{getlable('City')|translate}} <span *ngIf="isFieldMandatory('City')" style="color: red;">*</span></mat-label>
                    <input matInput type="text" formControlName="City" (click)="openPopup('City')">
                    <mat-error
                        *ngIf=" siteForm.controls['City'].hasError('required')">
                        {{getlable('City')|translate}} is <strong>{{'required'|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-12  col-md-12 col-lg-4 col-xl-4">
                <mat-form-field class="w-100">
                    <mat-label (dblclick)="onDoubleClick('ZipCode')">{{getlable('ZipCode')|translate}} <span *ngIf="isFieldMandatory('ZipCode')" style="color: red;">*</span></mat-label>
                    <input matInput type="text" formControlName="ZipCode" (click)="openPopup('ZipCode')">
                    <mat-error
                        *ngIf=" siteForm.controls['ZipCode'].hasError('required')">
                        {{getlable('ZipCode')|translate}} is <strong>{{'required'|translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <ng-container *ngIf="screenType == 'UpdateSite'">
            <div>
                <!-- Attachment  -->
                <div class="row mt-3">
                    <span class="fw-600 pointer">{{"Files"|translate}} & {{"Notes"|translate}}</span><mat-icon
                        (click)="addNewFileAndNote()">add_box</mat-icon>
                </div>
                <!-- file list  -->
                <div class="row mt-3">
                    <div class="col-3 fw-600">
                        <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>
                        {{"Files"|translate}} :
                    </div>
                    <div class="col-9">
                        <span class=" fw-600   pointer"
                            [ngClass]="{'color_grey': filesCount > 0,'name_red': filesCount == 0 }"
                            (click)="findfilesLinked()">{{filesCount+' '}}{{filesCount>
                            0 ? 'Files' : 'File'}}</span>
                    </div>
                </div>
                <!-- ShowAttachmentFile  -->
                <ng-container *ngIf="ShowAttachmentFile">
                    <div class="row mt-4 pointer"
                        *ngFor=" let x of AttachmentFileList">
                        <div class="col-8 pointer">
                            <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon>
                            <mat-icon class="lock" *ngIf="x.isPrivate">lock</mat-icon>
                            <img class="lock" *ngIf="!x.isPrivate"
                                src="../../../../assets/images/iconsForsvg/Unlock.svg"
                                alt>
                            <span (click)="ViewLink('File',x.id)">
                                {{x.title|titlecase}}

                            </span>
                        </div>
                        <div class="col-1">
                            <mat-icon (click)="Downloadfile(x)"
                                class="vertical-bottom">download</mat-icon>
                        </div>
                        <div class="col-1">
                            <mat-icon (click)="sharefileAndNote('File',x)"
                                class="vertical-bottom">share</mat-icon>
                        </div>
                        <div class="col-1">
                            <mat-icon (click)="EditfileAndNote('File',x.id)"
                                class="vertical-bottom">edit</mat-icon>
                        </div>
                        <div class="col-1">
                            <mat-icon
                                (click)="deleteDialogConfirmation(x,'deletefileAndNote')"
                                class="vertical-bottom">delete</mat-icon>
                        </div>
                    </div>
                </ng-container>

                <!-- note list  -->
                <div class="row mt-3">
                    <div class="col-3 fw-600">
                        <mat-icon style="color:rgb(255,193,111) ;">speaker_notes
                        </mat-icon> {{"Notes"|translate}} :
                    </div>
                    <div class="col-9">
                        <span class=" fw-600   pointer"
                            [ngClass]="{'color_grey': notesCount > 0,'name_red': notesCount == 0 }"
                            (click)="findnotesLinked()">{{notesCount+' '}}{{notesCount>
                            0 ? 'Notes' : 'Note'}}</span>
                    </div>
                </div>
                <ng-container *ngIf="ShowAttachmentNotes">
                    <div class="row mt-4" *ngFor=" let x of AttachmentNotesList">
                        <div class="col-8 pointer">
                            <mat-icon style="color:rgb(255,193,111) ;">speaker_notes
                            </mat-icon>
                            <mat-icon class="lock" *ngIf="x.isPrivate">lock</mat-icon>
                            <img class="lock" *ngIf="!x.isPrivate"
                                src="../../../../assets/images/iconsForsvg/Unlock.svg"
                                alt>
                            <span (click)="ViewLink('Note',x.id)">
                                {{x.title|titlecase}}
                            </span>
                        </div>
                        <div class="col-1">
                            <mat-icon (click)="ViewNoteContent(x)"
                                class="vertical-bottom">remove_red_eye</mat-icon>
                        </div>
                        <div class="col-1">
                            <mat-icon (click)="sharefileAndNote('Note',x)"
                                class="vertical-bottom">share</mat-icon>
                        </div>
                        <div class="col-1">
                            <mat-icon (click)="EditfileAndNote('Note',x.id)"
                                class="vertical-bottom">edit</mat-icon>
                        </div>
                        <div class="col-1">
                            <mat-icon
                                (click)="deleteDialogConfirmation(x,'deletefileAndNote')"
                                class="vertical-bottom">delete</mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>

        </ng-container>
        <div class="w-100" *ngFor="let product of tagarray">
            <mat-checkbox class="w-100" formControlName="ischecked"
                *ngIf="showcheckbox == true && product.fieldType == 'Boolean' ">
                {{product.fieldName}}
            </mat-checkbox>
        </div>
         <!-- ---------  -->
        <!-- show tags  -->
        <div class="row w-100" formArrayName="Addtages">
            <div class="row w-100" *ngFor="let conditionsBodyGroup of gettageBody().controls; let i=index"[formGroupName]="i">
                <div class="row w-100">
                    <!-- Checkbox  -->
                    <ng-container *ngIf="fieldType(i)=='Checkbox'">
                        <div class="col-10 w-100" style="display: flex;">
                            
                            <mat-checkbox formControlName="fieldValue"  style="font-weight: 900;color: black;" 
                                class="w-100">{{fieldname(i)}} 
                            </mat-checkbox>
                        </div>
                    </ng-container>
                    <!-- Freefeild  -->
                    <ng-container *ngIf="fieldType(i)=='Freefeild'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">text_fields</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="text"  
                                    formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container>
                      <!-- Currency  -->
                      <ng-container *ngIf="fieldType(i)=='Currency'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">payments</span>
                            <mat-form-field class="w-100">
                                <mat-label >{{fieldname(i)}} : </mat-label>
                                <input matInput type="text" formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!-- Dropdown -->
                    <ng-container *ngIf="fieldType(i)=='Dropdown'">
                        <div class="col-10 w-100">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <mat-select  
                                    formControlName="dropdownValues">
                                    <mat-option [value]="x"
                                        *ngFor="let x of dropdownvaluetotal(i)">
                                        {{x|titlecase}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>  
                     <!-- email  -->
                    <ng-container *ngIf="fieldType(i)=='email'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">mail</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="email" formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!-- URL  -->
                    <ng-container *ngIf="fieldType(i)=='URL'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">link</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="text" formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!-- PhoneNumber  -->
                    <ng-container *ngIf="fieldType(i)=='PhoneNumber'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">call</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="number"formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container> 
                    <!-- percentage  -->
                    <ng-container *ngIf="fieldType(i)=='percentage'">
                        <div class="col-10 w-100" style="display: flex;">
                            <!-- Inline style for color -->
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">percent</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="number"formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container> 
                     <!-- date  -->
                    <ng-container *ngIf="fieldType(i)=='date'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">calendar_month</span>
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput [matDatepicker]="enddatepicker1" style="color: black;"
                                    formControlName="fieldValue" >
                                <mat-datepicker-toggle matSuffix [for]="enddatepicker1"></mat-datepicker-toggle>
                                <mat-datepicker #enddatepicker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </ng-container> 
                     <!-- Squarefeet  Squaremeter -->
                    <ng-container *ngIf="fieldType(i)=='Squarefeet'||fieldType(i)=='Squaremeter' ">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">square_foot</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="number"formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container>  
                      <!-- Days -->
                     <ng-container *ngIf="fieldType(i)=='Days'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">timer</span>
                            <mat-form-field class="w-100">
                                <mat-label >{{fieldname(i)}} : </mat-label>
                                <mat-select multiple formControlName="fieldValue">
                                <mat-option value="monday">Monday</mat-option>
                                <mat-option value="tuesday">Tuesday</mat-option>
                                <mat-option value="wednesday">Wednesday</mat-option>
                                <mat-option value="thursday">Thursday</mat-option>
                                <mat-option value="friday">Friday</mat-option>
                                <mat-option value="saturday">Saturday</mat-option>
                                <mat-option value="sunday">Sunday</mat-option>
                            </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>  
                     <!-- hour&minutes -->
                    <ng-container *ngIf="fieldType(i)=='hour&minutes'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">calendar_clock</span>
                            <mat-form-field class="w-100">
                                <mat-label style="font-weight: 900;color: black;">{{fieldname(i)}} : </mat-label>
                                <input matInput type="number"formControlName="fieldValue">
                            </mat-form-field>
                        </div>
                    </ng-container> 
                    <!-- Entity  -->
                    <ng-container *ngIf="fieldType(i)=='Entity'">
                        <ng-container *ngIf="fieldname(i)=='Users'">
                                    <div class="col-10">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"Users"|translate}}</mat-label>
                                            <mat-select multiple formControlName="fieldValue">
                                                <mat-select-filter [placeholder]="'Filter'"
                                                    [displayMember]="'firstName'" [array]="filterforuserlist"
                                                    (filteredReturn)="filterforuserlist=$event"></mat-select-filter>
                                                <mat-option [value]="user.id"
                                                    *ngFor="let user of filterforuserlist">
                                                    <img style="border-radius: 50%; margin-right: 6px;"
                                                        width="30" height="30"
                                                        [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                                        onError="this.src='assets/avatar.svg'">
                                                    {{user.firstName|titlecase}}
                                                    {{user.lastName|titlecase}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                        </ng-container>
                        <ng-container *ngIf="fieldname(i)=='Contacts'">
                                    <div class="col-10">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"Contacts"|translate}}</mat-label>
                                            <mat-select multiple formControlName="fieldValue">
                                                <mat-select-filter [placeholder]="'Filter'"
                                                    [displayMember]="'firstName'" [array]="filterforcontactlist"
                                                    (filteredReturn)="filterforcontactlist=$event"></mat-select-filter>
                                                <mat-option [value]="user.id"
                                                    *ngFor="let user of filterforcontactlist">
                                                    <img style="border-radius: 50%; margin-right: 6px;"
                                                        width="30" height="30"
                                                        [src]="user?.profilePicture ? user.profilePicture:'assets/avatar.svg'"
                                                        onError="this.src='assets/avatar.svg'">
                                                    {{user.firstName|titlecase}}
                                                    {{user.lastName|titlecase}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                        </ng-container>
                        <ng-container *ngIf="fieldname(i)=='Sites'">
                                    <div class="col-10">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"Sites"|translate}}</mat-label>
                                            <mat-select multiple formControlName="fieldValue">
                                                <mat-select-filter [placeholder]="'Filter'"
                                                    [displayMember]="'firstName'" [array]="filterforsiteslist"
                                                    (filteredReturn)="filterforsiteslist=$event"></mat-select-filter>
                                                <mat-option [value]="site.id"
                                                    *ngFor="let site of filterforsiteslist">
                                                    <span>
                                                        <img
                                                            src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
                                                    </span>
                                                    {{site.companyName|titlecase}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                        </ng-container>
                        <ng-container *ngIf="fieldname(i)=='Products'">
                                    <div class="col-10">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>{{"Products"|translate}}</mat-label>
                                            <mat-select multiple formControlName="fieldValue">
                                                <mat-select-filter [placeholder]="'Filter'"
                                                    [displayMember]="'firstName'" [array]="filterforProsuctlist"
                                                    (filteredReturn)="filterforProsuctlist=$event"></mat-select-filter>
                                                <mat-option [value]="Product.id"
                                                    *ngFor="let Product of filterforProsuctlist">
                                                    <img style="border-radius: 50%; margin-right: 6px;"
                                                        width="30" height="30"
                                                        [src]="Product?.profilePicture ? Product.profilePicture:'assets/images/iconsForsvg/icon_product.svg '"
                                                        onError="this.src='assets/images/iconsForsvg/icon_product.svg '">
                                                    {{Product.productName|titlecase}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                        </ng-container>
                    
                    </ng-container>

                      <!-- file  -->
                      <ng-container *ngIf="fieldType(i)=='Files'">
                        <div class="col-10 w-100" style="display: flex;">
                            <span style="margin-top: 15px;padding-right: 5px;" class="material-icons" [ngStyle]="{'color':colorCode(i)}">description</span>
                            <div class="row mt-1">
                                <div class="col-6"><span style="font-weight: 900;color: gray;">{{fieldname(i)}}</span></div>
                                <div class="col-6" style="display: flex;justify-content: end;">
                                    <button mat-raised-button class="b-blue" (click)="addfilefortag(i)">{{"Add"|translate}}</button>
                                </div>
                            </div> 
                            <div class="row w-100" formArrayName="files"> 
                                <div class="row w-100" *ngFor="let file of gettagfiles(i).controls; let g=index">
                                    <div class="row w-100 mt-2">
                                        <div class="col-10">
                                            <input  type="file" (change)="handleFileInput($event,i,g)"  /> 
                                        </div>
                                        <div class="col-2">
                                            <mat-icon (click)="deletetagfile(i,g)" class="pointer">delete</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </ng-container> 
                        <div class="col-2">
                            <mat-icon class="pointer" (click)="deletetagDialog(i,'removetag')">delete</mat-icon>
                        </div>
                </div>
            </div>
        </div>
        <mat-list>
            <div class="d-flex justify-content-between">
                <h4 class="m-0" style="color: grey;"><b>{{"Add another feild"|translate}}</b></h4>
                <mat-chip-list>
                    <mat-chip (click)="showdropdown()">{{"Add"|translate}}</mat-chip>
                </mat-chip-list>
            </div>
        </mat-list> 
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center" class="my-1">
    <div class="row mt-2 mb-4 justify-content-center" style="margin-bottom: 10px !important;" >
        <div class="col-4">
            <button mat-raised-button mat-dialog-close  class="W-blue" >{{"Cancel"|translate}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="b-blue" [disabled]="!siteForm.valid"  (click)="onSubmit()">{{"Apply"|translate}}</button>
        </div>
    </div>
</mat-dialog-actions>
