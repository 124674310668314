import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarComponent } from '../../../pages/calendar/calendar.component'
import { MapPageComponent } from '../../../pages/map/map.page';
import { ManageUsersService } from '../../services/manage-users.service';
import { SpinnerService } from '../../services/spinner.service';
import { FiltershareService } from '../filtershare.service';
import { MapfilterService } from '../mapfilter.service';
import { ProfilePickFormatService } from '../../services/profile-pick-format.service';
import { ReportingDetailsComponent } from '../time-reporting/reporting-details/reporting-details.component';
// import { MapfilterService } from '../Mapfilter.service';


@Component({
  selector: 'app-other-pepole',
  templateUrl: './other-pepole.component.html',
  styleUrls: ['./other-pepole.component.scss']
})
export class OtherPepoleComponent implements OnInit {
  otherPepoleform!: FormGroup;
  OtherPlanningForm: FormGroup;
  showicon: boolean = false
  screenType: any;
  AllUsersdata:any= [];
  AllUsersdatabaseddate:any= [];
  Analytics: FormGroup;
  id: any;
  adminId: any;
  period = ['Monthly', 'Daily', 'Custom'];
  Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]

  // Filters for dropdown
  filters = ['All', 'Assigned Tasks', 'Unassigned Tasks'];

  // Displayed columns in the table
  displayedColumns: string[] = ['name', 'workload'];

  // Chart.js Options
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { display: false },  // Hide the x-axis since you don't need to display it
      y: {
        min: 0,  // Y-axis should start from 0
        max: 8,  // Maximum value of 8 (representing the peak of the "mountain")
        ticks: {
          stepSize: 1,  // Each tick will represent 1 hour
        },
      },
    },
    elements: {
      point: { radius: 0 },  // No points on the line
      line: { tension: 0.4 },  // Smooth the curve of the line
    },
    plugins: {
      legend: { display: false },  // Hide the legend
      tooltip: { enabled: false },  // Disable tooltips if not needed
    },
  };
  selectedPeriod: any;
  years: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private manageUserService: ManageUsersService,
    public spinner: SpinnerService,
    public fileshareservice: FiltershareService,
    public MapfilterService: MapfilterService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private injector: Injector,
  ) {
    this.adminId = sessionStorage.getItem('id');
    this.calculateDaysInMonth(this.year, this.month);
    this.otherPepoleform = this.formBuilder.group({
      search: [''],
      IsPublic: [true],
      User: [''],
    });

    this.OtherPlanningForm = this.formBuilder.group({
      Year: [''],
      startDate: [''],
      endDate: [''],
      Month: [''],
      period: [''],
    })
    this.Analytics = this.formBuilder.group({
      EndDate: [''],
      StartDate: [''],
    }); 
  }

  ngOnInit(): void {

    this.screenType = this.data.screenType
    this.loadUsers()
    this.generateYears()
    this.calculateDaysInMonth(this.year, this.month); // Initialize days for the current month
    this.calculateMonthsInYear(); // Initialize all months in the year
    const { startDate, endDate } = this.getCurrentMonthDates();
    this.Analytics.get('StartDate')?.setValue(startDate)
    this.Analytics.get('EndDate')?.setValue(endDate)
    this.getotheruserplandetails()
  }

getdayonly(date){
  return this.datepipe.transform(date, 'dd')
}

  getfilterdata(){
    this.getotheruserplandetails()
  }
  getotheruserplandetails(){
    const startDate = this.Analytics.value.StartDate
    const  endDate  = this.Analytics.value.EndDate
    this.manageUserService.GetEventTimeEnties(this.datepipe.transform(startDate, 'yyyy-MM-dd'),this.datepipe.transform(endDate, 'yyyy-MM-dd')).subscribe(res=>{
      if(res){
        console.log(res)
        this.AllUsersdatabaseddate=res
      }
    })

  }
  generateYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      this.years.push(i);
    }
  }

  filterSearch(event:any) {
    this.showicon = true
    this.loadUsers()
  }
  closesearch() {
    this.showicon = false
    this.loadUsers()
  }

  onPeriodChange(event:any){
    console.log(event)
    this.selectedPeriod = event
  }

  showResult(){

  }

  // checkBoxSelact
  checkBoxSelact(event:any) {
    this.otherPepoleform.get('User')?.setValue(event)
    console.log(this.otherPepoleform.value)
  }



  GetData() {
    sessionStorage.setItem("SelectedFilter", 'Users');
    if (this.screenType == 'Map') {
      sessionStorage.setItem("FilterType", 'map');
      const openAddEventDialog = this.dialog.open(MapPageComponent, {
        width: '100%',
        height: '700px',
        autoFocus: false,
        disableClose: true,
        data: { data: this.otherPepoleform.value, screenType: 'otherUserMap' },
      });
    } else if (this.screenType == 'Planning') {
      sessionStorage.setItem("FilterType", 'Events');
      const openAddEventDialog = this.dialog.open(CalendarComponent, {
        width: '100%',
        height: '700px',
        autoFocus: false,
        disableClose: true,
        data: { data: this.otherPepoleform.value, screenType: 'otherUserPlanning' },

      });
    }
  }


  showotheruserplan(data){
    const user={
      firstName:data.creatorFirstName,
      lastName:data.creatorLastName,
      profilePicture:data.creatorProfile,
      id:data.creatorId
    }
    console.log(data)
     data.StartDate=this.Analytics.value.StartDate
     data.EndDate=this.Analytics.value.EndDate
     data.User=user
    this.GetPlanningData(data)
  }

  GetPlanningData(data:any){
    sessionStorage.setItem("SelectedFilter", 'Events');
      const openAddEventDialog = this.dialog.open(CalendarComponent, {
        width: '100%',
        height: '700px',
        autoFocus: false,
        disableClose: true,
        data: { data: data, screenType: 'otherUserPlanning' },
  })
  }

  // getalluser
  loadUsers() {
    this.AllUsersdata.length = 0;
    this.manageUserService.FindAllUsersUnderMine().subscribe((data: any) => {
      if (data) {
        data.forEach((e:any) => {
            this.AllUsersdata.push(e)
        })
      }
    });
  }

  generateWorkloadChartData(workload: number) {
    const maxWorkload = 8;  // Set the peak value (e.g., 8 hours)
    const minWorkload = 0;  // Set the minimum value (0 hours)
  
    // Generate a "mountain" shaped dataset
    const data = [
      minWorkload, workload, maxWorkload, workload, minWorkload
    ];
  
    return [
      {
        data: data,  // The "mountain" dataset
        borderColor: '#3b82f6',  // Blue color for the line
        backgroundColor: 'rgba(59, 130, 246, 0.2)',  // Light blue background
        fill: true,  // Fill the area under the curve
        lineTension: 0.4,  // Smooth curve
      }
    ];
  }

  getWorkloadForDate(date: string, member: any): number {
    return member.workload[date] || 0;  // Return workload if available, else return 0
  }






  year: number = new Date().getFullYear(); // Current year
  month: number = new Date().getMonth() + 1; // Current month (January is 0 in JavaScript)
  daysInMonth: number[] = [];
  monthsInYear: { id: number; name: string }[] = [];
  viewMode: 'month' | 'year' = 'month'; // Toggle between Month and Year views

  users = [
    {
      name: 'John Doe',
      role: 'Developer',
      image: 'path/to/image.jpg',
      User:{id:123},
      data: this.generateRandomData() // Random data for each day of the month
    },
    {
      name: 'Gopi Doe',
      role: 'Developer',
      image: 'path/to/image.jpg',
      User:{id:123},
      data: this.generateRandomData()
    },
    {
      name: 'Krish Doe',
      role: 'Developer',
      image: 'path/to/image.jpg',
      User:{id:123},
      data: this.generateRandomData()
    }
  ];



  calculateDaysInMonth(year: number, month: number) {
    const days = new Date(year, month, 0).getDate(); // Get total days in the given month and year
    this.daysInMonth = Array.from({ length: days }, (_, i) => i + 1); // Array from 1 to total days
  }

  calculateMonthsInYear() {
    this.monthsInYear = Array.from({ length: 12 }, (_, i) => ({
      id: i + 1,
      name: new Date(0, i).toLocaleString('default', { month: 'long' }) // Convert month index to name
    }));
  }

  generateRandomData(): number[] {
    const days = new Date(this.year, this.month, 0).getDate(); // Days in the current month
    return Array.from({ length: days }, () => Math.floor(Math.random() * 10)); // Random data for each day
  }

  calculateYearlyData(user: any, monthId: number): number {
    return Math.floor(Math.random() * 10); 
  }





  getCurrentMonthDates(): { startDate: Date, endDate: Date } {
    const now = new Date();
    
    // Start of the month
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
  
    // End of the month
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
    return { startDate, endDate };
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  }


  roundedValue (value){
    return Math.round(value)
  }

  ViewHours(data:any, user:any){
    const TimeDetails = this.dialog.open(
      ReportingDetailsComponent,
      {
        disableClose: true,
        data: { data: data, user:user, type: 'OtherPeople' },
        width: '500px',
      }
    );
    
    TimeDetails.afterClosed().subscribe((result: any) => {
      
    })
  }
}
