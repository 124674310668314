
<pnm-top-navbar></pnm-top-navbar>
<div mat-dialog-title style="display: flex;justify-content: space-around;background-color: #f3f0f069;overflow: hidden;">
    <div style="width: 10%;position: relative;top: 10px;">
        <!-- <mat-icon style="color: black;" (click)="popupcolse()">keyboard_backspace</mat-icon> -->
    </div>
    <div style="width: 80%;position: relative;top: 10px;"><h1
            style="text-align: center;"><b>{{"Policy"|translate}}</b></h1></div>
    <div style="width: 10%;position: relative;top: 10px;">
        <mat-icon style="color: black;" (click)="downloadFile()">cloud_download</mat-icon>
    </div>
</div>

   <div class="row justify-content-center">
    <div class="col-11">
        <div class="row mt-3">
            <h2><b>{{"Planning&Me Privacy Policy"|translate}}</b></h2>
            <h2> {{"Protecting your private information is our priority. This Statement of Privacy applies to PlanningandMe"|translate}} <b>{{"(the Platform on cloud)"|translate}}</b>  {{"and Planning&Me"|translate}}  <b>{{"(the Company)"|translate}}</b>  {{"and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Planning&Me include www.PlanningandMe.com and Planning&Me. The PlanningandMe platform is a B2B on cloud platform. By using the PlanningandMe on cloud platform, you consent to the data practices described in this statement. For the purposes of EU data protection laws, Planning&Me is the data controller and our full contact details are contained at the bottom of this policy."|translate}}</h2>
            <h2><b>{{"How We Collect Your Personal Data"|translate}}</b></h2>
            <h2>{{"We also engage third parties to use legitimate business practices and software to scrape personal data already in the public domain from on cloud platforms or social media profiles, which will include your name, contact details, and job title, for sales, marketing, and recruitment purposes. We will not use that personal data for marketing purposes either without your consent (where we are required to obtain it) or unless we reasonably intend to enter into a contract with you."|translate}}</h2>
            <h2>{{"We may also receive personal data from you indirectly, through our partners, who will pass your personal data to us in accordance with their own privacy policies."|translate}}</h2>
            <h2><b>{{"Why We Collect Your Personal Information"|translate}}</b></h2>
            <h2>{{"In order to better provide you with products and services offered on our On cloud platform, PlanningandMe will collect the following categories of your personally identifiable information:"|translate}}</h2>
            <h2>1.{{"First and Last Name"|translate}}</h2>
            <h2>2.{{"Mailing Address"|translate}}</h2>
            <h2>3.{{"E-mail Address"|translate}}</h2>
            <h2>4.{{"Employer"|translate}}</h2>
            <h2>5.{{"Job Title"|translate}}</h2>
            <h2>6.{{"Interest in select products and services from Planning&Me"|translate}}</h2>
            <h2>7.{{"And more …"|translate}}</h2>
            <h2>{{"You may be required to send us an email or otherwise provide certain personal information to us when you elect to use certain products or services available on the On cloud platform. We will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us."|translate}}</h2>
            <h2><b>{{"Use of Your Personal Information"|translate}}</b></h2>
            <h2>{{"Planning&Me collects and uses your personal information to operate its on cloud platform(s) and deliver the services you have requested."|translate}}</h2>
            <h2>{{"Planning&Me may also use your personally identifiable information to inform you of other products or services available from Planning&Me and its affiliates (where you give us your permission to do so)."|translate}}</h2>
            <h2><b>{{"Sharing Information with Third Parties"|translate}}</b></h2>
            <h2>{{"Planning&Me does not sell, rent or lease its customer lists to third parties. However, Planning&Me may share data trusted partners for marketing and sales communications. Any such third parties would have restricted access to your personal data, only to the extent necessary to provide these specific services to you or Planning&Me, and be required to maintain the confidentiality of your information. All third parties are engaged on standard written terms, or individually negotiated written agreements."|translate}}</h2>
            <h2>{{"Planning&Me may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to comply with applicable law, protect and defend our rights or property, and/or act under exigent circumstances to protect the personal safety of our users or the public."|translate}}</h2>
            <h2><b>{{"How Our On cloud platform Works"|translate}}</b></h2>
            <h2>{{"Our On cloud platform is hosted on AWS. All technical data we collect about your visit to our on cloud platform is hosted on Amazon Web Services (AWS) in Europe region. Amazon is EU-US privacy shield accredited and all data is hosted with them on servers located in Europe."|translate}} </h2>
            <h2>{{"Their privacy policy is well-publicized at  https://aws.amazon.com/privacy/?nc1=f_pr.]"|translate}}</h2>
            <h2>{{"You may correspond with us through our live chat function on our On cloud platform. Any personal data contained in that correspondence (including your IP address and location) will be retained by AWS, on Europe-based servers."|translate}} </h2>
            <h2><b>{{"Tracking User Behavior"|translate}}</b></h2>
            <h2>{{"Planning&Me may keep track of the on cloud platforms and pages our users visit within Planning&Me, in order to determine what Planning&Me services are the most popular. This data is used to deliver customized content and advertising within Planning&Me to customers whose behavior indicates that they are interested in a particular subject area."|translate}}</h2>
            <h2>{{"Our On cloud platform uses Matomo, a web analytics service. Matomo uses “cookies”, which are text files placed on your computer to help analyze how you use the On cloud platform (such as time spent per page, scrolling behavior, and mouse movement). The information generated by the cookie about your use of the On cloud platform (including your IP address) will not be transmitted. Matomo will use this information for the purpose of evaluating your use of the On cloud platform, compiling reports on your activity for us and providing other services relating to on cloud platform activity and internet usage. Matomo may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Matomo’s behalf. Matomo will not associate your IP address with any other data. You may refuse the use of cookies by selecting the appropriate settings on your browser. By using the On cloud platform, you consent to the processing of data about you by Matomo in the manner and for the purposes set out above."|translate}}</h2>
            <h2>{{"Our On cloud platform leverages AWS to optimize our on cloud platform through search engines. They also improve the readability of any content on our On cloud platform."|translate}}</h2>
            <h2>{{"Behavioral tracking data could also be used to inform on cloud platform owners about the best locations on the on cloud platform for online advertisements. This information is collected and secured according to the terms and conditions of the respective third party. For these purposes, Planning&Me only partners with third-party technologies that have earned Privacy Shield status."|translate}} </h2>
            <h2><b>{{"Automatically Collected Information"|translate}}</b></h2>
            <h2>{{"Information about your computer hardware and software will be automatically collected by Planning&Me. This information will include your IP address, browser type, domain names, access times, and referring on cloud platform addresses. This information is used for the operation of the service, to maintain the quality of the service, and to provide general statistics regarding the use of the Planning&Me on cloud platform."|translate}}</h2>
            <h2><b>{{"Use of Cookies"|translate}}</b></h2>
            <h2>{{"The Planning&Me on cloud platform may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you."|translate}}</h2>
            <h2>{{"One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Planning&Me pages, or register with Planning&Me on cloud platform or services, a cookie helps Planning&Me to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Planning&Me on cloud platform, the information you previously provided can be retrieved, so you can easily use the Planning&Me features that you customized."|translate}}</h2>
            <h2>{{"You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Planning&Me services or on clou platforms you visit."|translate}}</h2>
            <h2><b>{{"Links"|translate}}</b></h2>
            <h2>{{"This on cloud platform contains links to other on cloud platforms. Please be aware that we are not responsible for the content or privacy practices of such other on cloud platforms. We encourage our users to be aware that, when they leave our on cloud platform, they should read the privacy statements of any other on cloud platform that collects personally identifiable information and consider whether to proceed."|translate}}</h2>
            <h2><b>{{"Security and Storage of your Personal Information"|translate}}</b></h2>
            <h2>{{"Planning&Me uses SSL encryption protocols to secures your personal information from unauthorized access, use, or disclosure. We endeavor to maintain appropriate security measures to protect against unauthorized access to or manipulation of your personal information. We train our staff on the protection of personal data and restrict access to our databases as appropriate. Notwithstanding our efforts, there are security and privacy limitations inherent to the Internet which are beyond our control and we cannot guarantee the security, integrity, and privacy of any and all information and data exchanged between you and us through this On cloud platform."|translate}}</h2>
            <h2>{{"All personal data hosted on our internal CRM and email systems are hosted on Cloud-based storage solutions through AWS."|translate}} </h2>
            <h2>{{"All data stored on Cloud-based storage solutions are regularly and securely backed-up."|translate}}</h2>
            <h2><b>{{"Children Under Thirteen"|translate}}</b></h2>
            <h2>{{"Planning&Me does not knowingly collect personally identifiable information from children under the age of thirteen. Children under the age of thirteen should not have the permission to use this on cloud platform. We ask our clients to check their staff to be complient to this rule."|translate}}</h2>
            <h2><b>{{"E-mail Communications"|translate}}</b></h2>
            <h2>{{"Planning&Me may periodically contact you via email in order to provide company announcements, alerts, and confirmations only."|translate}}</h2>
            <h2>{{"With your permission, we may contact you (via email or phone, on the details you give us) to provide information about related Planning&Me products and services that are of legitimate interest to you, based on your purchase and inquiry history with Planning&Me or to conduct surveys on our services. Planning&Me keeps digital records of all legitimate business inquiries for a period of three (3) years."|translate}}</h2>
            <h2>{{"If you would like to stop receiving marketing or promotional communications via email from Planning&Me at any time, you may opt-out of such communications by clicking unsubscribe and submitting the form on the opt-out page."|translate}}</h2>
            <h2><b>{{"External Data Storage On cloud platforms"|translate}}</b></h2>
            <h2>{{"We may store your data on servers provided by third-party hosting vendors with whom we have contracted."|translate}}</h2>
            <h2><b>{{"Changes to this Statement"|translate}}</b></h2>
            <h2>{{"Planning&Me reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our on cloud platform, and/or by updating any privacy information on this page. Your continued use of the On cloud platform and/or Services available through this On cloud platform after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy."|translate}}</h2>
            <h2><b>{{"Your Rights"|translate}}</b></h2>
            <h2>{{"If you are an EU citizen, you have the following rights under the General Data Protection Regulation 2016/679 to ask us:"|translate}}</h2>
            <h2>1.{{"not to process your personal data for marketing purposes;"|translate}}</h2>
            <h2>2.{{"to clarify what data we hold about you, how it was obtained, to whom it has"|translate}} </h2>
            <h2>3.{{"been disclosed and for how long it will be stored;"|translate}} </h2>
            <h2>4.{{"to amend any inaccurate data we hold about you;"|translate}} </h2>
            <h2>5.{{"to delete any of your data (where you no longer think we need to hold it, or you think we have obtained or processed it without your consent at any time); and"|translate}}</h2>
            <h2>6.{{"to only process your personal data in limited circumstances, for limited purposes."|translate}}</h2>
            <h2>7.{{"We have the capacity to extract your personal data from our databases and provide it to you in a structured, commonly-used way (typically by .csv file)."|translate}}</h2>
            <h2>{{"If you wish to exercise any of your rights at any time, please contact us on the details contained below in the first instance. We will require you to verify your identity to us before we provide any personal data to you, and reserve the right to ask you to specify the types of personal data to which your request relates."|translate}}</h2>
            <h2>{{"Where you wish to exercise any of your rights, they may be subject to payment of a nominal administration fee (to cover our costs incurred in processing your request) and any clarification we may reasonably require in relation to your request. Such fees may be charged where we consider (acting reasonably) that your request is excessive, unfounded, or repetitive."|translate}}</h2>
            <h2><b>{{"Contact Information"|translate}}</b></h2>
            <h2>{{"Planning&Me welcomes your questions or comments regarding this Statement of Privacy. If you believe that Planning&Me has not adhered to this Statement, please contact Planning&Me at:"|translate}}</h2>
            <h2>{{"Planning&Me"|translate}}</h2><br>
           <h2>{{"Landry NGAKAM"|translate}}</h2><br>
           <h2>{{"40 Avenue de Saint Mandé"|translate}}</h2><br>
           <h2>{{"75012 PARIS"|translate}}</h2><br>
           <h2>{{"FRANCE"|translate}}  </h2><br>
           <h2>{{"Email"|translate}}: info@PlanningandMe.com</h2>
        </div>
    </div>
   </div>
   <pnm-navbar-footer></pnm-navbar-footer>
