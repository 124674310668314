import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-auth-request',
  templateUrl: './auth-request.component.html',
  styleUrls: ['./auth-request.component.scss']
})
export class AuthRequestComponent implements OnInit {

  CreateBasicAuthForm: FormGroup
  apiMethods = [{id:1, name: 'GET'}, {id:2, name: 'HEAD'}, {id:3, name: 'POST'}, {id:4, name: 'PUT'}, {id:5, name: 'PATCH'}, {id:6, name: 'DELETE'}, {id:7, name: 'OPTIONS'}]
  BodyType = [{id:1, name: 'Raw'}, {id:2, name: 'Application/x-www-form-urlencoded'}, {id:3, name: 'Multipart/form-data'}]
  ContentType = [{id:1, name: 'Text (text/plain)'}, {id:2, name: 'JSON (application/json'}, {id:3, name: 'XML (application/xml)'}, {id:4, name: 'XML (text/xml)'}, 
    {id:5, name: 'HTML (text/html)'}, {id:6, name: 'Custom'}]
  FieldType = [{id:1, name:'Text'}, {id:2, name:'File'}]
  SelectedBody= 'Raw';
  SelectedContent: any;

  constructor(
    private formBuilder: FormBuilder
  ) {

    this.CreateBasicAuthForm = this.formBuilder.group({
      url: [],
      method: [],
      bodyType: ['Raw'],
      Customvalue: [],
      RequestValue: [],
      Conditions: this.formBuilder.array([]),
      QueryConditions: this.formBuilder.array([]),
      QueryFields: this.formBuilder.array([]),
    })
    this.AddHeaders()
    this.AddQuery()
    this.AddItem()
   }


  ngOnInit(): void {
  }

  AddHeaders() {
    this.Conditions().push(this.newFile());
  }

  Conditions(): FormArray {
    return this.CreateBasicAuthForm.get("Conditions") as FormArray
  }

  newFile(): FormGroup {
    return this.formBuilder.group({
      HeaderName: [''],
      Headervalue: [''],
    })
  }

  RemoveHeader(i: number) {
    this.Conditions().removeAt(i);
  }

  AddQuery(){
    this.QueryConditions().push(this.newFile1());
  }

  QueryConditions(): FormArray {
    return this.CreateBasicAuthForm.get("QueryConditions") as FormArray
  }

  newFile1(): FormGroup {
    return this.formBuilder.group({
      QueryName: [''],
      Queryvalue: [''],
    })
  }

  RemoveQuery(i: number) {
    this.QueryConditions().removeAt(i);
  }

  AddItem() {
    this.QueryFields().push(this.newFile2());
  }

  QueryFields(): FormArray {
    return this.CreateBasicAuthForm.get("QueryFields") as FormArray
  }

  newFile2(): FormGroup {
    return this.formBuilder.group({
      FieldKey: [''],
      Fieldvalue: [''],
    })
  }

  RemoveItem(i: number){
    this.QueryFields().removeAt(i);
  }

  onBodyTypeChange(event:any) {
    const selectedBodyType = this.BodyType.find(app => app.id === event.value);
    if (selectedBodyType) {
      this.SelectedBody = selectedBodyType.name
      console.log('Selected Body Type Name:', selectedBodyType.name);
      // You can use selectedBodyType.name wherever needed
  }
  }

  onContentTypeChange(event:any){
    const selectedContentType = this.ContentType.find(app => app.id === event.value);
    if (selectedContentType) {
      this.SelectedContent = selectedContentType.name
      console.log('Selected Body Type Name:', selectedContentType.name);
      // You can use selectedBodyType.name wherever needed
  }
  
  }


}
